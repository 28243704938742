import { get } from '@/utils/http';
const slugify = require('slugify');

export function getPickupPoints(locality) {
	const local = slugify(locality, {
		replacement: ' ',
		strict: true,
	});

	return get('/api/dpd/pickup-points?[locality][$containsi]=' + local);
}
