<template>
	<section>
		<div class="header-container">
			<div class="is-inline-flex">
				<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" class="is-clickable" size="is-medium"> </b-icon>
				<p class="header ml-2">{{ t.change }}</p>
			</div>
		</div>
		<!-- <div class="media center-inside mb-6 mt-4">
			<div class="media-left">
				<div class="photo-container mt-4">
					<div class="photo center-inside">
						<img v-if="getProfilePicture" class="profile-photo" :src="getProfilePicture" />
						<b-icon v-else icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
					</div>
				</div>
			</div>
			<div class="media-content"></div>
			<div class="media-right"></div>
		</div> -->

		<h2 class="has-text-dark is-size-5 pt-18">{{ t.change }}</h2>

		<form @submit.prevent="change">
			<b-field>
				<b-input
					name="current-password"
					class="input mt-6"
					size="is-large"
					required
					:placeholder="t.old"
					password-reveal
					type="password"
					v-model="credentials.oldPassword"
				></b-input>
			</b-field>
			<b-field>
				<b-input
					name="new-password"
					class="input mt-6"
					size="is-large"
					required
					:placeholder="t.new"
					password-reveal
					type="password"
					v-model="credentials.password"
				></b-input>
			</b-field>

			<b-field>
				<b-input
					name="new-password"
					class="input mt-6"
					size="is-large"
					required
					:placeholder="t.confirm"
					password-reveal
					type="password"
					v-model="confirmPassword"
				></b-input>
			</b-field>
			<p v-show="passwordError" class="has-text-red">{{ t.match }}</p>
			<p v-show="oldPasswordError" class="has-text-red">{{ t.invalidOld }}</p>

			<div class="button-container has-text-centered">
				<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">{{ t.update }}</b-button>
			</div>
		</form>
	</section>
</template>

<script>
	import { updatePreferences } from '@/api/user';
	export default {
		data() {
			return {
				t: this.$t(`components.Shared.ChangePassword`),
				showMenu: false,

				credentials: {
					password: null,
					oldPassword: null,
				},

				confirmPassword: null,
				passwordError: false,
				oldPasswordError: false,
				isLoading: false,
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			change() {
				if (this.credentials.password !== this.confirmPassword || this.credentials.password.length < 8) {
					this.passwordError = true;
				} else {
					this.isLoading = true;
					this.passwordError = false;
					this.oldPasswordError = false;
					updatePreferences(this.credentials)
						.then((response) => {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: this.t.changed,
								position: 'is-top',
								type: 'is-primary',
							});

							this.goToPage('Homepage');
						})
						.catch((error) => {
							this.isLoading = false;
							if (error.response.data.error.message == 'oldPassword doesnt match') {
								this.oldPasswordError = true;
								this.passwordError = false;
							}
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.top-hr {
		position: fixed;
		top: 76px;
		width: 100%;
		z-index: 40;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 41;
	}

	.main-container {
		padding-left: 140px;
		padding-right: 140px;
		margin-top: 80px;
		padding-bottom: 10rem;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $white;
		border: solid 1px black;
	}

	.profile-photo {
		height: 72px;
	}

	.pointer {
		cursor: pointer;
	}

	.header {
		font-size: 1.5rem;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 45%;
	}

	.btn {
		width: 20%;
		height: 52px;
	}

	/deep/ .button {
		border-radius: 10px;
	}

	.button-container {
		width: 100%;
		margin-top: 210px;
	}

	/deep/ input.input.is-large {
		height: 20px !important;
	}
</style>
