<template>
	<div :key="key">
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<p class="has-text-grey-dark is-size-2">Vouchers</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-button class="header-btn ml-4 right" @click="showFilter = !showFilter" v-if="tab == 1">
						<b-icon pack="rt" class="mr-2" icon="sort" size="is-size-6" type="is-black"></b-icon>{{ t.sort }}</b-button
					>
				</div>
			</div>

			<div v-show="showFilter" class="filter-window mt-2 px-6 py-2" v-if="tab == 1">
				<p class="is-size-6">{{ t.orderBy }}</p>
				<b-field class="mt-3">
					<b-radio native-value="startAt:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="discount:desc" @input="changeSort" v-model="sort">{{ t.discountHigh }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="discount:asc" @input="changeSort" v-model="sort">{{ t.discountLow }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="startAt:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
				</b-field>
			</div>
		</div>

		<b-tabs size="is-medium" :animateInitially="true" class="pt-6" :destroy-on-hide="true" v-model="tab">
			<b-tab-item :label="t.create">
				<CreateVoucher @changeTab="changeTab" />
			</b-tab-item>
			<b-tab-item label="Vouchers"><ListVoucher :sort="sort" /></b-tab-item>
		</b-tabs>
	</div>
</template>

<script>
	import CreateVoucher from '@/components/Desktop/Marketing/CreateVoucher.vue';
	import ListVoucher from '@/components/Desktop/Marketing/ListVouchers.vue';
	export default {
		components: {
			CreateVoucher,
			ListVoucher,
		},

		data() {
			return {
				showFilter: false,
				key: 0,
				sort: 'startAt:desc',
				tab: 0,
				t: this.$t(`views.Marketing`),
			};
		},

		methods: {
			changeSort() {
				this.key++;
				this.tab = this.tab;
			},

			changeTab(value) {
				this.tab = value;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.filter-window {
		width: 250px;
		height: auto;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 0;
		z-index: 10;
		background-color: #ffffff;
		top: 60px;
	}
</style>
