import { get, post, put, remove } from '@/utils/http';
const qs = require('qs');

export function getProductsOnSale(page, pageSize, mainCategory, date) {
	console.log(date);

	if (mainCategory.length > 0) {
		/* if (mainCategory.includes(1) || mainCategory.includes(54)) {
			if (!mainCategory.includes(109)) {
				mainCategory.push(109);
			}
		} else if (mainCategory.includes(97) && !mainCategory.includes(1) && !mainCategory.includes(54)) {
			let remove = mainCategory.filter((item) => item !== 109);
			mainCategory = remove;
		} */

		const query = qs.stringify(
			{
				filters: {
					isActive: true,

					product: {
						$and: [
							...(mainCategory
								? [
										{
											$or: [
												{
													category: {
														parent: {
															parent: {
																id: {
																	$in: mainCategory,
																},
															},
														},
													},
												},
												{
													category: {
														parent: {
															id: {
																$in: mainCategory,
															},
														},
													},
												},
											],
										},
								  ]
								: []),
						],

						isActive: true,
					},
					...(date && { endAt: date }),
				},

				populate: 'product,product.pictures, product.productVariants.color',
				pagination: {
					page,
					pageSize,
				},
			},
			{ encodeValuesOnly: true }
		);
		return get('/api/product-sales?' + query);
	} else
		return get(
			`/api/product-sales?populate=product,product.pictures,product.productVariants.color&filters[product][id][$notNull]=a&filters[isActive]=true${
				date ? '&filters[endAt]=' + date : ''
			}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
		);
}

export function getProducts(
	page,
	pageSize,
	mainCategory,
	subCategory,
	size,
	minPrice,
	maxPrice,
	color,
	brand,
	onlySale,
	sort,
	comments,
	shopped,
	reviewed,
	liked,
	stringFilter,
	stores
) {
	/* let newMainCategory = mainCategory;
	if (mainCategory.includes(1) || mainCategory.includes(54)) {
		if (!mainCategory.includes(109)) {
			newMainCategory.push(109);
		}
	} else if (mainCategory.includes(97) && !mainCategory.includes(1) && !mainCategory.includes(54)) {
		let remove = mainCategory.filter((item) => item !== 109);
		newMainCategory = remove;
	}
 */
	const query = qs.stringify(
		{
			filters: {
				$and: [
					...(subCategory
						? [
								{
									$or: [
										{
											category: {
												id: {
													$in: subCategory,
												},
											},
										},
										/* 	{
											category: {
												parent: {
													id: {
														$in: subCategory,
													},
												},
											},
										}, */
										{
											category: {
												parent: {
													parent: {
														id: {
															$in: subCategory,
														},
													},
												},
											},
										},
										/* {
											category: {
												parent: {
													parent: {
														parent: {
															id: {
																$in: subCategory,
															},
														},
													},
												},
											},
										}, */
										/* 	{
											category: {
												parent: {
													parent: {
														parent: {
															parent: {
																id: {
																	$in: mainCategory,
																},
															},
														},
													},
												},
											},
										}, */
									],
								},
						  ]
						: []),
					...(mainCategory
						? [
								{
									$or: [
										{
											category: {
												parent: {
													parent: {
														id: {
															$in: mainCategory,
														},
													},
												},
											},
										},
										{
											category: {
												parent: {
													id: {
														$in: mainCategory,
													},
												},
											},
										},
									],
								},
						  ]
						: []),
					{
						store: {
							id: {
								$notNull: true,
							},
						},
					},
					...(stores
						? [
								{
									store: {
										id: {
											$in: stores,
										},
									},
								},
						  ]
						: []),
					{
						isActive: true,
					},
					...(size
						? [
								{
									productVariants: {
										productSizes: {
											size: {
												slug: {
													$in: size,
												},
											},
										},
									},
								},
						  ]
						: []),
					...(minPrice && maxPrice
						? [
								{
									price: {
										$lte: maxPrice,
										$gte: minPrice,
									},
								},
						  ]
						: []),
					...(color
						? [
								{
									productVariants: {
										color: {
											id: {
												$in: color,
											},
										},
									},
								},
						  ]
						: []),
					...(brand
						? [
								{
									brand: {
										id: {
											$in: brand,
										},
									},
								},
						  ]
						: []),
					...(onlySale
						? [
								{
									productSales: {
										isActive: {
											$eq: true,
										},
									},
								},
						  ]
						: []),
				],
			},
			sort: [
				comments ? 'num_comments:desc' : [],
				shopped ? 'num_shopped:desc' : [],
				reviewed ? 'rating:desc' : [],
				reviewed ? 'num_reviews:desc' : [],
				liked ? 'num_likes:desc' : [],
				sort ? sort : [],
			],

			...(stringFilter ? { _q: stringFilter } : []),

			populate:
				'pictures,category,store,store.pictures,productSales,reviews,productVariants.productSizes.size,productVariants.color, brand,productSales, likes,comments,productVariants.pictures',
			pagination: {
				page,
				pageSize,
			},
		},
		{ encodeValuesOnly: true }
	);

	return get('/api/products?' + query);
}

export function getProductDetails(id) {
	return get(
		'/api/products/' +
			id +
			'?populate=pictures,productVariants.color,productVariants.productSizes.size,reviews,store,productSales, likes, store.pictures,category, reviews.user.picture, comments.user.picture, productVariants.pictures,brand'
	);
}

export function addReview(data) {
	return post('/api/reviews?populate=user.picture', data);
}

export function getReviews(id, page, pageSize) {
	return get('/api/reviews?populate=user.picture&filters[product]=' + id + '&pagination[page]=' + page + '&pagination[pageSize]=' + pageSize);
}

export function getUserReviewByProduct(query = {}) {
	query = qs.stringify(query);
	return get('/api/reviews?populate=user.picture&' + query);
}

export function getSizes() {
	return get('/api/sizes?pagination[limit]=1000');
}

export function getCategories(query = {}) {
	query = qs.stringify(query);
	return get('/api/categories?' + query);
}

export function getSubCategories(query = {}) {
	query = qs.stringify(query);
	console.log(query);
	return get('/api/categories?' + query + '&populate=children, children.children, children.children.children,children.children.children.children,sibling');
}

export function getComments(id, page, pageSize) {
	return get('/api/comments?populate=user.picture&filters[product]=' + id + '&pagination[page]=' + page + '&pagination[pageSize]=' + pageSize);
}

export function addComment(data) {
	return post('/api/comments?populate=user.picture', data);
}

export function getColors() {
	return get('/api/colors?sort[0]=name&pagination[limit]=1000');
}
export function getBrands() {
	return get('/api/brands?sort[0]=name&pagination[limit]=1000');
}

export function addLike(data) {
	return post('/api/likes?populate=user,product', data);
}

export function getLikes(query = {}) {
	query = qs.stringify(query);
	return get('/api/likes?' + query);
}

export function removeLike(id) {
	return remove('/api/likes/' + id);
}

export function addWhishlist(data) {
	return post('/api/wishlists?populate=product', data);
}

export function getWhishlist(query = {}) {
	query = qs.stringify(query);
	return get('/api/wishlists?populate=product, product.pictures, product.productSales&' + query);
}

export function removeWhishlist(id) {
	return remove('/api/wishlists/' + id);
}

export function removeManyWishlist(data) {
	return put('/api/wishlists/', data);
}

export function canReview(id) {
	return get('/api/reviews/' + id + '/verify');
}

export function updateProductViews(id) {
	return put('/api/products/' + id + '/views');
}

export function getStoreFilter(query = {}) {
	query = qs.stringify(query);
	return get('/api/stores/?' + query);
}

export function getProductsByStore(page, pageSize, storeId, sort) {
	const query = qs.stringify(
		{
			filters: {
				$and: [
					{
						store: {
							id: {
								$in: storeId,
							},
						},
					},
				],
				isActive: true,
			},
			sort: sort,
			populate: 'pictures,category,store,store.pictures,productSales,reviews,productVariants.productSizes.size,productVariants.color, brand,productSales, likes,comments',
			pagination: {
				page,
				pageSize,
			},
		},

		{
			encodeValuesOnly: true,
		}
	);
	return get('/api/products?' + query);
}

export function getStores() {
	return get('/api/stores/?filters[isActive]=true&&sort[0]=name&pagination[limit]=1000');
}

export function getMaxProductValue() {
	return get('/api/products?filters[isActive]=true&&sort[0]=price:desc&pagination[limit]=1');
}

export function getBrandsFilter(query = {}) {
	console.log(query);
	query = qs.stringify(query);
	return get('/api/brands/?sort[0]=name&pagination[limit]=1000&' + query);
}

export function getProductsByBrand(page, pageSize, brandId, sort) {
	const query = qs.stringify(
		{
			filters: {
				$or: [
					{
						brand: {
							id: {
								$in: brandId,
							},
						},
					},
				],
				isActive: true,
			},
			populate: 'pictures,category,store,store.pictures,productSales,reviews,productVariants.productSizes.size,productVariants.color, brand,productSales, likes,comments',
			pagination: {
				page,
				pageSize,
			},
			sort: sort,
		},

		{
			encodeValuesOnly: true,
		}
	);
	return get('/api/products?' + query);
}
