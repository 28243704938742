<template>
	<section>
		<div class="header-container">
			<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
			<p class="header ml-2">{{ t.profile }}</p>
		</div>

		<div class="mb-10">
			<form @submit.prevent="update">
				<b-tabs expanded>
					<b-tab-item :label="t.mainInfo">
						<div class="mt-10">
							<b-upload v-model="newStoreImage" class="center-inside" accept="image/*">
								<figure class="mx-auto image is-96x96 is-1by1 pt-0 has-radius" style="border: 1px solid black">
									<img class="profile-photo has-radius" v-if="newStoreImage == null" :src="storeImage" />
									<img class="profile-photo has-radius" v-else-if="newStoreImage" :src="getFileURL" />
									<b-icon v-else icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
								</figure>
							</b-upload>
						</div>
						<div style="justify-content: center; display: grid">
							<b-upload v-model="newStoreImage" class="file-label" accept="image/*" name="photo"
								><p class="mt-2 has-text-centered" style="font-weight: 500">{{ t.changePhoto }}</p></b-upload
							>
						</div>

						<b-field
							><b-input class="input mt-10" size="is-medium" name="email" type="text" required v-model.trim="store.data.name" :placeholder="t.name"></b-input
						></b-field>
						<b-field>
							<b-autocomplete
								class="input mt-3"
								style="height: 42px"
								size="is-medium"
								rounded
								:data="filteredCities"
								:keep-first="true"
								:open-on-focus="true"
								field="city"
								:placeholder="t.city"
								icon="magnify"
								clearable
								ref="city"
								name="city"
								v-model.trim="store.data.location_municipality"
								required
							>
								<template #empty>{{ t.noResults }}</template>
							</b-autocomplete>
						</b-field>

						<b-input class="input" size="is-medium" :placeholder="t.address" type="text" v-model.trim="store.data.location_address" required></b-input>

						<b-field
							><b-input
								class="input mt-3"
								size="is-medium"
								v-validate="'required|mobile-phone|numeric'"
								v-model.trim="store.data.telephone"
								:placeholder="t.phone"
								name="phone"
							></b-input
						></b-field>
						<p v-show="errors.has('phone')" class="help is-danger">{{ t.invalidPhone }}</p>

						<!-- <b-field>
							<b-input class="input mt-3" size="is-medium" :placeholder="t.country" name="country" v-model.trim="store.data.location_country" required></b-input>
						</b-field> -->
						<b-field>
							<b-select class="mt-5" size="is-medium" :placeholder="t.country" name="country" v-model.trim="store.data.location_country" required>
								<option value="Portugal">Portugal</option>
							</b-select></b-field
						>

						<b-field>
							<b-input class="input mt-3" size="is-medium" :placeholder="t.iban" v-model.trim="store.data.iban" type="text" required></b-input>
						</b-field>

						<b-field>
							<b-input class="input mt-3" size="is-medium" v-model.trim="getUser.store.email" disabled type="text"></b-input>
						</b-field>

						<b-field>
							<b-input class="input mt-3" size="is-medium" disabled v-model.trim="getUser.store.cae" type="text"></b-input>
						</b-field>

						<b-field>
							<b-input class="input mt-3" size="is-medium" v-model.trim="getUser.store.vat" disabled type="text"></b-input>
						</b-field>
					</b-tab-item>

					<b-tab-item :label="t.aditionalInfo">
						<b-field>
							<b-input
								size="is-medium"
								name="description"
								v-model.trim="store.data.description"
								maxlength="200"
								type="textarea"
								:placeholder="t.description"
							></b-input>
						</b-field>

						<b-field>
							<b-input class="input" size="is-medium" v-model.trim="store.data.networks_instagram" placeholder="Instagram" type="text"></b-input>
						</b-field>

						<b-field>
							<b-input class="input mt-2" size="is-medium" v-model.trim="store.data.networks_facebook" placeholder="Facebook" type="text"></b-input>
						</b-field>
					</b-tab-item>
				</b-tabs>

				<div class="button-container px-6 has-text-centered">
					<b-button type="is-primary" :loading="isLoading" native-type="submit" class="btn">{{ t.save }}</b-button>
				</div>
			</form>
		</div>
		<BottomMenu />
	</section>
</template>

<script>
	import { uploadPhoto } from '@/api/user';
	import { updateStore } from '@/api/store';
	import BottomMenu from '@/components/Parts/BottomBar.vue';

	const cities = require('@/assets/cities.json');
	export default {
		components: {
			BottomMenu,
		},
		data() {
			return {
				t: this.$t(`components.Profile.EditStoreProfile`),

				newStoreImage: null,
				storeImage: null,

				store: {
					data: {
						name: null,
						description: null,
						telephone: null,
						location_address: null,
						location_country: null,
						location_municipality: null,
						location_zipCode: null,
						networks_facebook: null,
						networks_instagram: null,
						iban: null,
						pictures: [],
					},
				},

				isLoading: false,
				email: null,
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			update() {
				this.isLoading = true;
				this.$validator.validateAll().then(async (result) => {
					if (result) {
						let newPhotoId = [];
						if (this.newStoreImage) {
							await uploadPhoto(this.newStoreImage).then((response) => {
								newPhotoId.push(response.data[0].id);
								this.store.data.pictures = newPhotoId;
								console.log(this.store.data.pictures);
							});
						}
						await updateStore(this.getUser.store.id, this.store)
							.then((response) => {
								console.log(response);
								this.updateStore(response.data.data);
								this.isLoading = false;
								this.$buefy.toast.open({
									message: this.t.updated,
									position: 'is-top',
									type: 'is-primary',
								});
								this.goToPage('Homepage');
							})
							.catch((error) => {
								console.log(error);
								this.isLoading = false;
							});
					} else this.isLoading = false;
				});
			},
		},

		computed: {
			getFileURL() {
				return window.URL.createObjectURL(this.newStoreImage);
			},

			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.store.data.location_municipality.toLowerCase()) >= 0;
				});
			},
		},

		created() {
			this.citiesList = cities;
			this.storeImage = this.getUser.store.pictures[0].url;
			this.store.data.pictures = this.getUser.store.pictures[0].id;
			this.store.data.name = this.getUser.store.name;
			this.store.data.description = this.getUser.store.description;
			this.store.data.telephone = this.getUser.store.telephone;
			this.store.data.location_address = this.getUser.store.location_address;
			this.store.data.location_country = this.getUser.store.location_country;
			this.store.data.location_municipality = this.getUser.store.location_municipality;
			this.store.data.location_zipCode = this.getUser.store.location_zipCode;
			this.store.data.networks_facebook = this.getUser.store.networks_facebook;
			this.store.data.networks_instagram = this.getUser.store.networks_instagram;
			this.store.data.iban = this.getUser.store.iban;
			this.email = this.getUser.store.email;
			console.log(this.email);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
		height: 100vh;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $white;
		border: $grey-light solid 2px;
		display: flex;
		margin: 0 auto;
	}

	img {
		width: 80px;
		height: 74px;
		object-fit: cover;
	}

	.small-input {
		display: flex;
	}
	.small {
		width: 96%;
	}

	.gender-text {
		font-size: 1rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		padding-bottom: 80px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	/deep/ textarea.textarea.is-medium {
		border: solid 3px $white-ter;
		border-radius: 11px;
	}

	/deep/ .mdi-magnify,
	/deep/ .mdi-close-circle {
		color: $grey-dark !important;
	}

	/deep/.tabs {
		overflow: hidden;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}

	/deep/ .mdi-24px.mdi:before {
		transform: translateY(-9px);
	}

	/deep/ span.icon.is-left {
		transform: translateY(6px);

		@include until($mobile-s) {
			transform: translateY(12px);
		}
	}
	/deep/ span.icon.is-right {
		transform: translateY(6px);
		@include until($mobile-s) {
			transform: translateY(12px);
		}
	}

	/deep/ .control {
		width: 100%;
	}

	/deep/span.select.is-medium {
		width: 100%;
	}
	/deep/select {
		width: 100%;
	}
</style>
