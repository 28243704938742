<template>
	<div>
		<div class="header-container pt-8">
			<b-icon @click.native="back" class="is-clickable" icon="chevron-left" type="is-grey-darker" custom-size="is-size-4"></b-icon>
			<p @click="back" class="header ml-2 is-clickable">{{ t.back }}</p>
		</div>
		<p class="sign-text">{{ t.create }}</p>

		<hr />
		<form @submit.prevent="create">
			<div style="display: inline-flex">
				<b-field :label="t.name">
					<b-input class="input" size="is-medium" :placeholder="t.name" name="name" type="text" required v-model.trim="form.name"></b-input>
				</b-field>

				<b-field class="ml-4" :label="t.storeName">
					<b-input class="input" size="is-medium" :placeholder="t.storeName" name="organization" type="text" required v-model.trim="store.data.name"></b-input>
				</b-field>
			</div>

			<div style="display: inline-flex" class="mt-4">
				<b-field :label="t.address">
					<b-input class="input" size="is-medium" :placeholder="t.address" type="text" v-model.trim="store.data.location_address" required></b-input>
				</b-field>
				<div>
					<b-field class="ml-4" :label="t.storeEmail">
						<b-input class="input" size="is-medium" name="email" :placeholder="t.email" type="email" v-model.trim="form.email" required></b-input>
					</b-field>
					<p v-show="errorEmail" class="has-text-red is-size-7 mt-2 ml-4">{{ t.errorEmail }}</p>
				</div>
			</div>

			<div style="display: inline-flex" class="mt-4">
				<b-field :label="t.storePhone">
					<b-input
						class="input"
						size="is-medium"
						:placeholder="t.phone"
						type="text"
						v-model="store.data.telephone"
						v-validate="'required|mobile-phone|numeric'"
						name="phone"
						ref="phone"
					></b-input>
				</b-field>
				<b-field :label="t.storeCategry" class="ml-4">
					<b-input class="input" size="is-medium" :placeholder="t.storeCategry" type="text" v-model.trim="store.data.category" required></b-input>
				</b-field>
			</div>
			<p v-show="errors.has('phone')" class="help is-danger">{{ t.invalidPhone }}</p>
			<div style="display: inline-flex" class="mt-4">
				<b-field :label="t.password">
					<b-input class="input" size="is-medium" :placeholder="t.password" type="password" v-model="form.password" password-reveal required></b-input>
				</b-field>
				<b-field :label="t.confirm" class="ml-4">
					<b-input class="input" size="is-medium" :placeholder="t.confirm" type="password" v-model="confirmPassword" password-reveal required></b-input>
				</b-field>
			</div>
			<br />
			<p v-show="this.errorPassword" class="has-text-red is-size-7 mt-2">{{ t.errorPassword }}</p>

			<b-field :label="t.date" class="mt-4">
				<b-datepicker
					class="input"
					size="is-medium"
					:placeholder="t.date"
					name="bday"
					:mobile-native="false"
					:locale="$i18n.locale"
					:icon-right-clickable="true"
					@icon-right-click="clearBirthday()"
					trap-focus
					v-validate="'required'"
					v-model="store.data.birthday"
					:max-date="today"
				></b-datepicker>
			</b-field>
			<p v-show="errors.has('bday')" class="help is-danger">{{ t.emptyDate }}</p>
			<p v-if="errorAge" class="help is-danger">{{ t.errorAge }}</p>

			<div class="mt-4">
				<b-field>
					<b-checkbox class="mt-6" v-model="acceptsTerms" required></b-checkbox
					><span class="remember"
						>{{ t.accept }}<span class="has-text-primary is-clickable" @click="openTerms">{{ t.terms }} </span>
					</span>
				</b-field>
			</div>

			<div class="center-inside pt-14">
				<b-button type="is-primary" :loading="isLoading" class="btn-signin" native-type="submit">{{ t.next }}</b-button>
			</div>
		</form>
	</div>
</template>

<script>
	import { createAccount, stripeAcceptTerms, login } from '@/api/auth';
	import { createStore } from '@/api/store';

	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Register`),
				form: {
					email: null,
					password: null,
					name: null,
					role: 'store',
				},

				store: {
					data: {
						name: null,
						email: null,
						location_address: null,
						location_country: null,
						location_municipality: null,
						location_zipCode: null,
						cae: null,
						vat: null,
						iban: null,
						user: null,
						telephone: null,
						category: null,
						user: null,
						shippingMinValue: null,
						usermaxValue: null,
						birthday: null,
					},
				},

				confirmPassword: null,
				errorPassword: false,
				errorEmail: false,
				acceptsTerms: null,
				isLoading: false,
				errorAge: false,
				today: new Date(),
			};
		},

		methods: {
			updateStep() {
				this.addToken(null);
				this.$store.commit('desktopLoginStep/setStep', 0);
				this.$store.commit('desktopLoginStep/setStoreMessage', true);
			},

			back() {
				this.$store.commit('desktopLoginStep/setStep', 0);
			},

			create() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						if (this.form.password !== this.confirmPassword || this.form.password.length < 8) {
							this.errorPassword = true;
						} else if (new Date().getFullYear() - this.store.data.birthday.getFullYear() < 18) {
							this.isLoading = false;
							this.errorAge = true;
						} else {
							this.errorPassword = false;
							this.errorEmail = false;
							this.isLoading = true;
							this.errorAge = false;
							createAccount(this.form)
								.then((response) => {
									console.log(response);
									this.addUser(response.data.user);
									this.addToken(response.data.jwt);
									this.store.data.user = this.getUser.id;
									this.store.data.email = this.form.email;

									createStore(this.store)
										.then((response) => {
											console.log(response);

											stripeAcceptTerms()
												.then((response) => {
													console.log(response);
													this.isLoading = false;
													this.$store.commit('desktopLoginStep/setStep', 0);
													let loginData = {
														identifier: this.form.email,
														password: this.form.password,
													};
													login(loginData)
														.then((response) => {
															this.onLogin(response);
															this.goToPage('Homepage');
														})
														.catch((error) => {
															this.$buefy.toast.open({
																message: this.$t('shared.error'),
																position: 'is-top',
																type: 'is-danger',
															});
														});
												})
												.catch((error) => {
													console.log(error);
													this.isLoading = false;
												});
										})
										.catch((error) => {
											console.log(error);
											this.isLoading = false;
										});
								})
								.catch((error) => {
									this.isLoading = false;
									if (error.response.data.error.message === 'Email is already taken') {
										this.errorEmail = true;
									}
								});
						}
					}
				});
			},
			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.open(routeData.href, '_blank');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 20px;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	hr {
		width: 40px;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 300px;
	}

	.remember {
		font-size: 1rem;
		transform: translate(0, 20px);
		color: $grey;
		width: 280px;
	}

	.btn-signin {
		width: 360px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		width: 600px;
	}

	.is-full-round {
		box-shadow: 5px 5px 27px #00000021 !important;
	}

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
