<template>
	<div>
		<p class="sign-text">{{ t.signin }}</p>
		<p>
			{{ t.youHave }} <span class="has-text-primary">{{ t.sell }}</span> {{ t.or }} <span class="has-text-primary">{{ t.buy }}</span>
		</p>
		<div style="display: inline-flex">
			<b-field>
				<b-radio-button class="checkbox-seller" size="is-medium" v-model="userType" native-value="store" type="is-black">
					<span class="btn-text">{{ t.seller }}</span>
				</b-radio-button>
			</b-field>
			<b-field>
				<b-radio-button class="checkbox ml-4" size="is-medium" v-model="userType" native-value="authenticated" type="is-black">
					<span class="btn-text">{{ t.buyer }}</span>
				</b-radio-button>
			</b-field>
		</div>
		<p v-show="accountError" class="has-text-red">{{ t.selectAccount }}</p>
		<hr />
		<form @submit.prevent="onSubmitPress">
			<div style="display: inline-flex">
				<b-field :label="t.email">
					<b-input class="input" size="is-medium" name="email" :placeholder="t.emailPlaceholder" type="email" v-model.trim="login.identifier" required></b-input>
				</b-field>
				<b-field :label="t.password" class="ml-4">
					<b-input
						class="input"
						size="is-medium"
						name="current-password"
						:placeholder="t.password"
						type="password"
						password-reveal
						v-model="login.password"
						required
					></b-input>
				</b-field>
			</div>
			<p v-show="wrongCredentials" class="has-text-red has-text-centered mt-2">{{ t.wrongCredentials }}</p>

			<b-field>
				<div class="media center-inside mb-6">
					<div class="media-content pt-4"></div>
					<div class="media-right">
						<span class="has-text-primary pointer" @click="forgotPassword()">{{ t.forgot }}</span>
					</div>
				</div>
			</b-field>
			<p class="mt-8">
				{{ t.newUser }}
				<span class="has-text-primary pointer" @click="updateStep">{{ t.createAccount }}</span>
			</p>
			<p class="has-text-primary mt-2 is-clickable" @click="goToPage('Homepage')">{{ t.backToFeed }}</p>
			<div class="center-inside pt-6" :style="userType == 'authenticated' ? 'justify-content: space-between !important' : 'justify-content:center !important'">
				<b-button type="is-primary" class="btn-signin" :loading="isLoading" native-type="submit">{{ t.signin }}</b-button>
				<span v-if="userType == 'authenticated'">{{ t.or }}</span>
				<b-button v-if="userType == 'authenticated'" class="is-full-round" @click="google" type="is-white">
					<b-icon icon="google" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button>
				<b-button v-if="userType == 'authenticated'" class="is-full-round" @click="facebook" type="is-white">
					<b-icon icon="facebook" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button>
				<!-- <b-button v-if="userType == 'authenticated'" class="is-full-round" type="is-white" @click="apple">
					<b-icon icon="apple" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button> -->
			</div>
		</form>
	</div>
</template>

<script>
	import { login, googleLogin, facebookLogin } from '@/api/auth';
	// import { Firebase } from '@iotechpis/modules-front';
	import socialAuth from '@/mixins/modules/auth/socialAuth';
	export default {
		data() {
			return {
				userType: 'authenticated',
				t: this.$t(`components.Desktop.SignIn`),
				login: {
					identifier: null,
					password: null,
				},
				wrongCredentials: false,
				accountError: false,
				isLoading: false,
			};
		},
		mixins: [socialAuth],
		methods: {
			updateStep() {
				if (this.userType == null || this.userType.length < 1) {
					this.accountError = true;
				} else {
					this.$store.commit('desktopLoginStep/setStep', 1);
				}
			},
			forgotPassword() {
				this.$store.commit('desktopLoginStep/setStep', 4);
			},
			onSubmitPress() {
				this.isLoading = true;
				login(this.login)
					.then((response) => {
						this.isLoading = false;
						this.wrongCredentials = false;
						console.log(response);
						this.onLogin(response);
						this.$socket.connect();
						if (response.data.user.favoriteCategories.length == 0 && response.data.user.role.type == 'authenticated') {
							this.setIsLoginReset(true);
							this.setMainCategorySelected([]);
							this.$store.commit('desktopLoginStep/setStep', 3);
						} else {
							this.setIsLoginReset(true);
							this.setMainCategorySelected([]);
							this.goToPage('Homepage');
						}
					})
					.catch((error) => {
						console.log(error?.response?.data?.error?.message);
						this.isLoading = false;
						if (error?.response?.data?.error?.message === 'Invalid identifier or password') {
							this.wrongCredentials = true;
							this.isLoading = false;
						} else if (error?.response?.data?.error?.message === 'Your account email is not confirmed' && this.getAccountType == 'store') {
							this.$buefy.snackbar.open({
								message: this.t.approvement,
								type: 'is-primary',
								position: 'is-top',
								actionText: 'Ok',
								indefinite: true,
								onAction: () => {
									this.$store.commit('desktopLoginStep/setStoreMessage', false);
								},
							});
						} else if (error?.response?.data?.error?.message === 'Your account email is not confirmed' && this.getAccountType == 'authenticated') {
							this.addPw(this.login.password);
							this.addRecoverEmail(this.login.identifier);
							this.addToken(error.response.data.error.details.jwt);
							this.$store.commit('desktopLoginStep/setStep', 2);
						} else {
							this.$buefy.toast.open({
								message: this.$t('shared.error'),
								position: 'is-top',
								type: 'is-danger',
							});
						}
					});
			},
			facebook() {
				this.facebookLogin(this.nextStep);
			},
			google() {
				this.googleLogin(this.nextStep);
			},
			apple() {
				this.appleLogin(this.nextStep);
			},
			nextStep() {
				this.$socket.connect();
				if (!this.getUser.favoriteCategories || this.getUser.favoriteCategories.length == 0) {
					this.$store.commit('desktopLoginStep/setStep', 3);
				} else this.goToPage('Homepage');
			},
		},

		watch: {
			userType() {
				this.addAccount(this.userType);
			},
		},

		mounted() {
			this.addAccount(this.userType);
			if (this.$store.getters['desktopLoginStep/getStoreMessage']) {
				this.$buefy.snackbar.open({
					message: this.t.approvement,
					type: 'is-primary',
					position: 'is-top',
					actionText: 'Ok',
					indefinite: true,
					onAction: () => {
						this.$store.commit('desktopLoginStep/setStoreMessage', false);
					},
				});
			}
		},
		created() {
			this.getAuthResult(this.nextStep);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 22px;
		width: 200px;

		@include until($mobile-s) {
			width: 100%;
		}
	}
	/deep/ .checkbox-seller {
		border-radius: 20px;
		width: 330px;
		@include until($mobile-s) {
			width: 100%;
		}
	}

	/deep/ .b-radio.radio.button {
		z-index: 1;
		background-color: white;
		border: solid 1px black;
		color: $grey;
	}

	/deep/ .b-radio.radio.button.is-selected {
		z-index: 1;
		background-color: black;
		outline: none !important;
	}
	/deep/ .b-radio.radio.button.is-focused {
		z-index: 1;
		background-color: black;
		border: solid 1px black;
		outline: none !important;
		box-shadow: none;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ .button {
		border-radius: 22px;
	}

	hr {
		width: 40px;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 16px;
	}

	.remember {
		font-size: 1rem;

		color: $grey;
	}

	.remember-checkbox {
		width: auto !important;
	}

	.btn-signin {
		width: 360px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		width: 540px;
	}

	.is-full-round {
		box-shadow: 5px 5px 27px #00000021 !important;
	}

	.pointer {
		cursor: pointer;
	}

	/deep/ .field .label {
		font-weight: inherit;
		margin-bottom: 10px !important;
	}

	.media-left {
		display: inline-flex;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
