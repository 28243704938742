<template>
	<section>
		<div class="header-container px-6 mt-10">
			<b-icon pack="rt" class="mt-1" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
			<p @click="$router.go(-1)" class="is-size-5 ml-2">{{ t.product }}</p>
		</div>

		<div class="mt-10">
			<p v-if="productRating" class="has-text-grey-darker has-text-centered is-size-2">{{ productRating }}</p>
			<p v-else class="has-text-grey-darker has-text-centered is-size-2">0</p>
			<b-rate class="is-justify-content-center" v-model="rating" disabled> </b-rate>
			<p v-if="receivedReviews.length == 1" class="has-text-grey-darker has-text-centered is-size-5">{{ receivedReviews.length }}+ {{ t.review }}</p>
			<p v-if="receivedReviews.length > 1" class="has-text-grey-darker has-text-centered is-size-5">{{ receivedReviews.length }}+ {{ t.reviews }}</p>
			<p v-else-if="receivedReviews.length == 0" class="has-text-grey-darker has-text-centered is-size-5">0 {{ t.reviews }}</p>
		</div>

		<div class="review-container" v-if="receivedReviews.length > 0" @scroll="reviewScroll">
			<div v-for="review in receivedReviews" :key="review.id" class="mt-6 px-6 pb-4">
				<div class="media">
					<div class="media-left center-inside">
						<figure
							class="image is-48x48 is-background-size-cover is-rounded pointer center-inside"
							:style="[review.user.picture ? { 'background-image': `url(${review.user.picture.url})` } : { background: '#FFF', border: 'solid 1px black' }]"
						>
							<b-icon v-if="!review.user.picture" icon="account-outline" type="is-black" custom-size="is-size-3"> </b-icon>
						</figure>
						<p class="has-text-grey-darker is-size-5 ml-3">{{ review.user.name }}</p>
					</div>
					<div class="media-content"></div>
					<div class="media-right">
						<figure class="image is-48x48">
							<img class="has-object-fit-contain has-radius" :src="image" alt="" />
						</figure>
					</div>
				</div>
				<p class="has-text-grey mt-3">{{ review.comment }}</p>

				<div class="media">
					<div class="media-left">
						<p class="has-text-grey-dark mt-3">{{ review.createdAt | moment() }}</p>
					</div>
					<div class="media-content"></div>
					<div class="media-right"><b-rate class="mt-3" disabled v-model="review.rating" size="is-small"> </b-rate></div>
				</div>
			</div>
		</div>
		<b-rate v-if="canReview && getUserType == 'authenticated'" class="px-6 mt-8" v-model="myRating"></b-rate>
		<b-field v-if="canReview && getUserType == 'authenticated'" class="px-6 mt-4">
			<b-input class="text-comment" type="textarea" maxlength="300" v-model.trim="comment" icon-right="send" icon-right-clickable @icon-right-click="sendReview"> </b-input>
		</b-field>
		<p v-if="errorReview" class="px-6 has-text-danger" style="transform: translateY(-20px)">{{ t.errorMessage }}</p>
	</section>
</template>

<script>
	import moment from 'moment';
	import { addReview, getReviews, canReview, getUserReviewByProduct, getProductDetails } from '@/api/feed';
	export default {
		props: ['rating', 'image', 'id'],

		data() {
			return {
				t: this.$t(`components.Products.ProductReview`),
				isLoading: false,
				comment: null,
				myRating: null,
				receivedReviews: [],
				reviewPage: 1,
				reviewPageSize: 10,
				errorReview: false,
				canReview: false,
				productRating: 0,
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('D MMM YYYY');
			},
		},

		methods: {
			showMoreReviews() {
				this.reviewsToShow.push(...this.reviews.splice(0, 3));
			},

			sendReview() {
				if (!this.myRating || !this.comment) {
					this.errorReview = true;
				} else {
					this.errorReview = false;
					let review = {
						data: {
							product: this.id,
							user: this.getUser.id,
							comment: this.comment,
							rating: this.myRating,
						},
					};

					getUserReviewByProduct({
						'filters[product]': this.$route.params.id,
						'filters[user][id]': this.getUser.id,
					}).then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: 'Já fez uma review a este produto',
								position: 'is-top',
								type: 'is-danger',
							});
						} else {
							addReview(review)
								.then((response) => {
									console.log(response);
									this.receivedReviews.unshift(response.data.data);
									this.myRating = null;
									this.comment = null;
									this.isModalOpen = false;
									this.canReview = false;
									this.$buefy.toast.open({
										message: this.t.added,
										position: 'is-top',
										type: 'is-primary',
									});
									getProductDetails(this.id)
										.then((response) => {
											this.productRating = response.data.data.rating;
										})
										.catch((error) => {
											console.log(error);
										});
								})
								.catch((error) => {
									console.log(error);
								});
						}
					});
				}
			},

			reviewScroll(e) {
				let target = {
					scrollTop: e.target.scrollTop,
					clientHeight: e.target.clientHeight,
					scrollHeight: e.target.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
					if (this.isRequesting) return;

					this.isRequesting = true;
					this.commentsPage++;

					getReviews(this.id, this.reviewPage, this.reviewPageSize)
						.then((response) => {
							console.log(response);
							this.receivedReviews = response.data.data.reverse();
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		async mounted() {
			if (this.getUserType == 'authenticated') {
				await canReview(this.id).then((response) => {
					console.log(response);
					this.canReview = response.data.canReview;
				});
				if (this.canReview) {
					await getUserReviewByProduct({
						'filters[product]': this.$route.params.id,
						'filters[user][id]': this.getUser.id,
					}).then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.canReview = false;
						} else this.canReview = true;
					});
				}
			}
		},

		created() {
			this.productRating = this.rating;
			getReviews(this.id, this.reviewPage, this.reviewPageSize)
				.then((response) => {
					this.receivedReviews = response.data.data.reverse();

					window.addEventListener('scroll', this.reviewScroll);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.reviewScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	/deep/ .rate .rate-item.set-on .icon,
	/deep/ .rate .rate-item.set-half .is-half {
		color: $primary;
	}

	.btn {
		display: flex;
		width: 70%;
		height: 52px;
		border-radius: 14px;
		margin: 0 auto;
	}

	img {
		border: 1px solid $grey;
	}

	.modal-card {
		height: 50%;
		width: 90%;
		background-color: white;
		border-radius: 50px;
		padding: 1rem;
	}

	.review-container {
		overflow: auto;
		height: 50vh;
	}

	/deep/ textarea.textarea {
		border: solid 2px $grey-light;
		border-radius: 12px;
		resize: none;
		min-height: 4em;
		background-color: white;
	}

	/deep/ .control.has-icons-right .icon {
		color: $primary;
		top: 10px;
		right: 2px;
	}
</style>
