<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileBalance />
		</div>
		<div class="container" v-else>
			<DesktopBalance />
		</div>
	</section>
</template>

<script>
	import DesktopBalance from '@/components/Desktop/User/Balance.vue';
	import MobileBalance from '@/components/User/Balance.vue';

	export default {
		components: {
			DesktopBalance,
			MobileBalance,
		},

		data() {
			return {};
		},
	};
</script>
