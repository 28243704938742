<template>
	<section class="section">
		<div v-if="IS_MOBILE" class="px-4">
			<UserMobileSettings v-if="getUserType == 'authenticated'" />
			<StoreMobileSettings v-if="getUserType == 'store'" />
		</div>
		<div v-else class="container">
			<DesktopStoreSettings v-if="getUserType == 'store'" />
			<DesktopSettings v-if="getUserType == 'authenticated'" />
		</div>
	</section>
</template>

<script>
	import UserMobileSettings from '@/components/User/Settings.vue';
	import StoreMobileSettings from '@/components/Store/Settings.vue';
	import DesktopStoreSettings from '@/components/Desktop/Store/Settings.vue';
	import DesktopSettings from '@/components/Desktop/User/Settings.vue'
	export default {
		components: {
			UserMobileSettings,
			StoreMobileSettings,
			DesktopStoreSettings,
			DesktopSettings
		},

		data() {
			return {};
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},
	};
</script>
