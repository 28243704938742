<template>
	<section>
		<div class="is-flex">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-dark" size="is-medium"></b-icon>
			<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.transactions }}</p>
		</div>
		<!-- <b-field>
			<b-select class="mt-8" name="gender" @input="changeGraph" v-model="graphFilter">
				<option value="month">{{ t.spendingMonth }}</option>
				<option value="year">{{ t.spendingYear }}</option>
			</b-select>
		</b-field>
		<p class="is-size-4">{{ totalAmount.toFixed(2) }} €</p>

		<highcharts class="mt-4" :options="chartOptions"></highcharts> -->

		<p class="is-size-4 mt-10">{{ t.activity }}</p>
		<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
			<img v-if="order.user.picture" :src="order.store.pictures[0].url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto; max-width: 130px">
				<p class="has-text-grey">{{ order.store.name }}</p>
				<p class="has-text-dark is-size-6">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
			</div>
			<div>
				<p class="price mr-2" style="min-width: 80px; text-align: right">{{ (order.amount / 100).toFixed(2) }} €</p>
				<b-button class="has-background-primary has-text-white ml-4" v-if="order.orderStatus.id == 8" @click="devolution(order.id)">{{ t.return }}</b-button>
			</div>
		</div>
	</section>
</template>

<script>
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';
	import { getUserLineGraph, getOrders } from '@/api/orders';
	import moment from 'moment';

	exportingInit(Highcharts);
	export default {
		data() {
			return {
				t: this.$t(`components.Store.TransactionsHistory`),
				chartOptions: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'areaspline',
					},
					title: {
						text: '',
					},

					xAxis: {
						categories: [],
						gridLineWidth: 0,
					},
					yAxis: {
						title: {
							text: '',
						},
						gridLineWidth: 1,
					},
					legend: {
						layout: 'vertical',
						align: 'left',
						verticalAlign: 'bottom',
						x: 150,
						y: 100,
						floating: true,
						borderWidth: 1,
						backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
					},
					tooltip: {
						headerFormat: '<table><span style="font-size:12px;text-align:center">{point.key}</span></table>',
						pointFormat: '<p style="font-size:15px"><b>{point.y:1f} €</b></p>',

						shared: true,
						useHTML: true,
						backgroundColor: '#99cca8',
						style: {
							color: 'white',
							textAlign: 'center',
						},
					},
					credits: {
						enabled: false,
					},
					plotOptions: {
						areaspline: {
							fillOpacity: 0.0,
						},
					},
					series: [
						{
							name: '',
							data: [],
							color: '#99cca8',
						},
					],
				},
				graphFilter: 'month',
				orders: [],
				totalAmount: 0,
				page: 1,
				pageSize: 10,
				sort: 'id:desc',
				offset: 100,
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},

		methods: {
			changeGraph() {
				this.totalAmount = 0;
				this.setIsPageLoading(true);
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						response.data.data.data.map((order) => {
							this.totalAmount += order;
						});

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},

			devolution(id) {
				this.$router.push({ name: 'Devolution', params: { id: id } });
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[user][id]': this.getUser.id,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: this.sort,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},

			openFilter() {
				this.showFilter = !this.showFilter;
			},
		},

		created() {
			this.setIsPageLoading(true);
			getOrders({
				'filters[user][id]': this.getUser.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					this.orders = response.data.data;

					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				});

			getUserLineGraph(this.graphFilter)
				.then((response) => {
					response.data.data.data.map((order) => {
						this.totalAmount += order;
					});

					this.chartOptions.xAxis.categories = response.data.data.categories;
					this.chartOptions.series[0].data = response.data.data.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.chart-card {
		box-shadow: 0px 2px 48px #0000000a;
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $primary !important;
	}

	img,
	.no-img {
		width: 125px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 100px;
			height: 84px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 86px;
		}
	}

	.price {
		color: $primary;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;

		@include until($mobile) {
			margin-left: 0;
			min-width: 50px;
			font-size: 1rem;
			letter-spacing: 1px;
		}
	}
</style>
