// import facades from './CapacitorFirebaseAuth';
import { appleLogin, googleLogin, facebookLogin } from '@/api/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FacebookAuthProvider, GoogleAuthProvider, signInWithRedirect, getAuth, getRedirectResult } from 'firebase/auth';

export default {
	mixins: [],
	methods: {
		//FACEBOOK
		async facebookLogin(callback) {
			this.setIsPageLoading(true);
			let userAgent = window.navigator.userAgent;
			if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
				setTimeout(() => {
					this.setIsPageLoading(false);
					/* 	this.$buefy.toast.open({
						message: 'Ocorreu um erro, volte a tentar!',
						position: 'is-top',
						type: 'is-danger',
					}); */
				}, 3000);
			}

			let provider = new FacebookAuthProvider();
			provider.addScope('email');
			provider.addScope('public_profile');
			const auth = getAuth();
			let that = this;

			await signInWithRedirect(auth, provider).catch((error) => {
				that.$buefy.toast.open({
					message: 'Já existe uma conta com o mesmo e-mail!',
					position: 'is-top',
					type: 'is-danger',
				});
				that.setIsPageLoading(false);
				console.log(error);
				// throw error;
			});
			this.setIsPageLoading(false);
		},
		async facebookLoginAPI(token, callback) {
			facebookLogin({ idToken: token })
				.then(async (response) => {
					this.isLoading = false;
					this.wrongCredentials = false;
					await this.onLogin(response);
					this.setSocialLogin(true);
					return callback();
				})
				.catch((error) => {
					this.isLoading = false;
					let message = 'Não é possível autenticar';
					if (error.response.data.code == 'UserNotActivated') {
						this.activationEmail();
					} else {
						this.$buefy.toast.open({
							message: message,
							position: 'is-top',
							type: 'is-danger',
						});
					}
					throw error;
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
		//GOOGLE
		async googleLogin(callback) {
			this.setIsPageLoading(true);
			let userAgent = window.navigator.userAgent;

			if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
				setTimeout(() => {
					this.setIsPageLoading(false);
				}, 3000);
			}
			let provider = new GoogleAuthProvider();
			provider.addScope('https://www.googleapis.com/auth/userinfo.email');
			provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

			const auth = getAuth();
			let that = this;
			await signInWithRedirect(auth, provider).catch((error) => {
				that.$buefy.toast.open({
					message: 'Já existe uma conta com o mesmo e-mail!',
					position: 'is-top',
					type: 'is-danger',
				});
				that.setIsPageLoading(false);
				console.log(error);
				// throw error;
			});
			this.setIsPageLoading(false);
		},
		async googleLoginAPI(token, callback) {
			googleLogin({ idToken: token })
				.then(async (response) => {
					this.wrongCredentials = false;
					await this.onLogin(response);
					this.setSocialLogin(true);
					return callback();
				})
				.catch((error) => {
					this.isLoading = false;
					let message = 'Não é possível autenticar';

					if (error.response.data.code == 'UserNotActivated') {
						this.activationEmail();
					} else {
						this.$buefy.toast.open({
							message: message,
							position: 'is-top',
							type: 'is-danger',
						});
					}
					throw error;
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
		//APPLE
		async appleLogin(callback) {
			this.setIsPageLoading(true);
			await facades.cfaSignInApple().subscribe(
				(result) => {
					console.log(result);
					appleLogin(result)
						.then(async (response) => {
							this.wrongCredentials = false;
							await this.onLogin(response);
							this.setSocialLogin(true);
							return callback();
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: this.$t('mixins.auth.invalidSocial'),
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.setIsPageLoading(false);
						});
				},
				(error) => {
					console.log(error);
					this.setIsPageLoading(false);
					this.$buefy.toast.open({
						message: this.$t('mixins.auth.invalidSocial'),
						position: 'is-top',
						type: 'is-danger',
					});
				},
				() => {}
			);
		},
		async getAuthResult(callback) {
			const auth = getAuth();
			let that = this;
			that.setIsPageLoading(true);
			const { token, result } = await getRedirectResult(auth)
				.then((result) => {
					if (!result) {
						return {
							token: null,
							result: null,
						};
					}
					let token = null;
					if (result.providerId == 'facebook.com') {
						const credential = FacebookAuthProvider.credentialFromResult(result);
						token = credential.accessToken;
					}
					if (result.providerId == 'google.com') {
						const credential = GoogleAuthProvider.credentialFromResult(result);
						token = credential.idToken;
					}
					return { token, result };
				})
				.catch((error) => {
					that.$buefy.toast.open({
						message: 'Já existe uma conta com o mesmo e-mail!',
						position: 'is-top',
						type: 'is-danger',
					});
					that.setIsPageLoading(false);
					console.log(error);
				});

			if (result) {
				if (result.providerId == 'facebook.com') {
					that.facebookLoginAPI(token, callback);
				}
				if (result.providerId == 'google.com') {
					that.googleLoginAPI(token, callback);
				}
			} else {
				that.setIsPageLoading(false);
			}
		},
	},
};
