<template>
	<section>
		<div>
			<div class="is-flex">
				<b-icon @click.native="$router.go(-1)" class="is-clickable mt-2" icon="chevron-left" type="is-dark" custom-size="is-size-2"></b-icon>
				<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.balance }}</p>
			</div>
			<p class="has-text-primary is-size-6 mt-4">{{ t.info }}</p>
			<p class="mt-4" v-if="balanceInfo.length == 1">{{ balanceInfo.length }} {{ t.credit }}</p>
			<p class="mt-4" v-else>{{ balanceInfo.length }} {{ t.credits }}</p>
			<div class="mb-2 mt-3 items-container center-inside" v-for="balance in balanceInfo" :key="balance.id" @click="seeStore(balance.store.id)">
				<img v-if="balance.store.pictures" :src="balance.store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ balance.store.name }}</p>
				</div>
				<div style="display: inline-flex">
					<p class="price ml-10 has-text-primary">{{ balance.credit }} €</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { storeBalance } from '@/api/balance';
	export default {
		data() {
			return {
				t: this.$t(`components.Balance`),
				balanceInfo: [],
				page: 1,
				pageSize: 10,
				offset: 100,
			};
		},

		created() {
			this.setIsPageLoading(true);
			storeBalance({
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				'filters[credit][$gt]': 0,
			})
				.then((response) => {
					console.log(response);
					this.balanceInfo = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},

		methods: {
			seeStore(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					storeBalance({
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						'filters[credit][$gt]': 0,
					})
						.then((response) => {
							this.balanceInfo.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 100px;
			height: 78px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 80px;
		}
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;

		@include until($mobile) {
			margin-left: 0;
			min-width: 50px;
			font-size: 1rem;
			letter-spacing: 1px;
		}
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;
	}
</style>
