<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileTracking/>
		</div>
		<div class="container" v-else>
			<DesktopTracking />
		</div>
	</section>
</template>

<script>
	import DesktopTracking from '@/components/Desktop/Orders/Tracking.vue';
	import MobileTracking from '@/components/Orders/Tracking.vue'
	
	export default {
		components: {
			DesktopTracking,
			MobileTracking
		},

		data() {
			return {};
		},
	};
</script>