import { get, post, put } from '@/utils/http';
const queryString = require('qs');

export function createAccount(data) {
	console.log(data);
	return post('/api/auth/local/register', data);
}

export function activateAccount(query) {
	console.log(query);
	return get('/api/auth/email-confirmation?confirmation=' + query);
}

export function login(data) {
	return post('/api/auth/local?populate=store.pictures,role,picture,favoriteCategories', data, false);
}

export function googleLogin(data) {
	return post('/api/auth/google?populate=role,picture,store.pictures,favoriteCategories', data, false);
}

export function facebookLogin(data) {
	return post('/api/auth/facebook?populate=role,picture,store.pictures,favoriteCategories', data, false);
}

export function appleLogin(data) {
	return post('/api/auth/apple?populate=role,picture,store.pictures,favoriteCategories', data, false);
}

export function logout() {
	return post('/auth/logout', {});
}

export function forgotPassword(email) {
	return post('/api/auth/forgot-password', {
		email: email,
	});
}

export function changePassword(query = {}, data) {
	let _query = '?' + queryString.stringify(query);
	return post('/api/auth/reset-password' + _query, {
		password: data.password,
		passwordConfirmation: data.passwordConfirmation,
	});
}

export function resendActivation(data) {
	return post('/api/auth/send-email-confirmation', data);
}

export function stripeAcceptTerms() {
	return post('/api/stripe/stores/terms');
}
