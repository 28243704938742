<template>
	<section>
		<div v-if="IS_MOBILE">
			<Home v-if="getUserType == 'authenticated' || !getUserType || (getUserType == 'store' && !getToken)" />
			<Dashboard v-if="getUserType == 'store' && getToken" />
		</div>
		<div v-else>
			<DesktopHome v-if="getUserType == 'authenticated' || !getUserType || (getUserType == 'store' && !getToken)" />
			<DesktopDashboard v-if="getUserType == 'store' && getToken" />
		</div>
	</section>
</template>

<script>
	import Home from '@/components/Homepage/Homepage.vue';
	import DesktopHome from '@/components/Desktop/Homepage/Homepage.vue';
	import DesktopDashboard from '@/components/Desktop/Store/Dashboard.vue';
	import Dashboard from '@/components/Store/Dashboard.vue';

	export default {
		components: {
			Home,
			DesktopHome,
			DesktopDashboard,
			Dashboard,
		},

		data() {
			return {};
		},
		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		created() {
			if (this.getToken) {
				this.$socket.emit('get_notifications', { token: this.getToken });
			}
			/* 	if (!this.getToken) {
				this.onSessionExpired();
			} */
			console.log(this.$route);

			document.addEventListener('visibilitychange', () => {
				const state = document.visibilityState;

				if (state === 'visible' && this.IS_MOBILE && this.$route.name != 'Login') {
					location.reload();
				}
			});
		},
	};
</script>
