<template>
	<div>
		<div class="header-container pt-8 px-6">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-grey" custom-size="is-size-4"></b-icon>
			<p @click="$router.go(-1)" class="header ml-2 is-clickable has-text-grey">{{ t.back }}</p>
		</div>

		<p class="px-6 mt-2 is-size-3 has-text-grey-darker ml-2">{{ t.payment }}</p>

		<div class="header-container px-6">
			<p class="is-size-6 has-text-grey ml-2">Checkout</p>
			<b-icon icon="chevron-right" type="is-grey-dark" custom-size="is-size-4"></b-icon>
			<p class="is-size-6 has-text-grey ml-2">{{ t.paymentDetails }}</p>
		</div>

		<form @submit.prevent="checkout" class="columns is-mobile mt-4 px-8 pb-20">
			<div class="column is-12-touch is-8-desktop">
				<!-- <p class="px-6 mt-10 is-size-6 has-text-dark ml-2">{{t.method}}</p> -->

				<div class="card-container mt-2" v-if="cards.length > 0">
					<div class="credit-card mt-3 mr-5" v-for="card in cards" :key="card.id">
						<b-image v-if="card.card.brand == 'mastercard'" class="img" :src="require('@/assets/svg/mc_symbol.svg')" alt="root"></b-image>
						<b-image v-else-if="card.card.brand == 'visa'" class="img" :src="require('@/assets/svg/visa_symbol.svg')" alt="root"></b-image>

						<p v-else class="has-text-white is-size-3">{{ card.card.brand }}</p>
						<p class="has-text-white is-size-4 mt-2">**** **** **** {{ card.card.last4 }}</p>

						<div style="display: inline-flex" class="mt-15">
							<p class="has-text-white is-size-5" style="margin-left: 170px">{{ card.card.exp_month }}/ {{ card.card.exp_year }}</p>
						</div>
					</div>

					<div class="add-card ml-6 mt-5">
						<div class="center-inside">
							<b-button @click="addCard" class="add-btn mt-10"><b-icon pack="rt" class="mt-3" type="is-white" custom-size="is-size-2" icon="plus"></b-icon></b-button>
						</div>

						<p class="has-text-centered mt-4">{{ t.addCard }}</p>
					</div>
				</div>

				<div v-else class="add-card ml-6 mt-5">
					<div class="center-inside">
						<b-button @click="addCard" class="add-btn mt-10"><b-icon pack="rt" class="mt-3" type="is-white" custom-size="is-size-2" icon="plus"></b-icon></b-button>
					</div>

					<p class="has-text-centered mt-4">{{ t.addCard }}</p>
				</div>

				<div class="level mt-4">
					<div class="level-left">
						<p class="level-item has-text-dark">{{ t.shipping }}</p>
					</div>
					<div class="level-right"></div>
				</div>

				<div style="display: inline-flex">
					<b-field>
						<b-radio size="is-medium" v-model="shippingPoint" native-value="home"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.home }}</span>
					</b-field>
					<b-field class="ml-15">
						<b-radio size="is-medium" v-model="shippingPoint" native-value="pickUp"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.pickup }}</span>
					</b-field>
				</div>

				<p v-show="errorShipping" class="has-text-danger mt-2">{{ t.selectShipping }}</p>

				<p class="is-size-6 has-text-primary">{{ t.deliveryTime }}</p>

				<div class="level mt-8" v-if="shippingPoint == 'home'">
					<div class="level-left">
						<div class="level-item" style="display: inline-flex">
							<p class="has-text-dark mt-2 mr-3 is-clickable" @click="isModalOpen = true">{{ t.addShippingAddress }}</p>
							<b-button class="add-btn-small" @click="isModalOpen = true"
								><b-icon pack="rt" class="mt-1" type="is-white" custom-size="is-size-6" icon="plus"></b-icon
							></b-button>
						</div>
					</div>
					<div class="level-right"></div>
				</div>

				<b-collapse v-if="!shippingPoint || shippingPoint == 'home'" class="card" animation="slide" :open="isOpen" @open="isOpen = true">
					<template #trigger="props">
						<div class="card-header" role="button">
							<p class="card-header-title mt-4">{{ t.yourAddress }}</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
							</a>
						</div>
					</template>
					<div class="card-content">
						<div class="content info-container mt-2">
							<p class="has-text-grey mb-0" v-if="homeShipping.address && homeShipping.zip_code">{{ homeShipping.address }} , {{ homeShipping.zip_code }}</p>
							<p class="has-text-grey mb-0">{{ homeShipping.city }}</p>
							<p class="has-text-grey mb-0">{{ homeShipping.country }}</p>
						</div>
					</div>
				</b-collapse>

				<div v-else>
					<b-autocomplete
						class="mt-8"
						style="height: 42px"
						size="is-medium"
						:data="filteredCities"
						:keep-first="true"
						:open-on-focus="true"
						field="city"
						:placeholder="t.city"
						icon="magnify"
						clearable
						ref="city"
						name="city"
						v-model.trim="city"
						@input="changePickupLocality(city)"
					>
						<template #empty>{{ t.noResults }}</template>
					</b-autocomplete>

					<b-select class="mt-8" :placeholder="t.selectPickup" v-model="pickupId">
						<option v-for="pickup in pickups" :value="pickup.store_number" :key="pickup.id">
							{{ pickup.name }}
						</option>
					</b-select>
				</div>
				<p class="has-text-danger mt-5" v-if="pickUpError">{{ t.pickUpError }}</p>

				<b-collapse class="card mt-10" animation="slide" :open="isOpen" @open="isOpen = true">
					<template #trigger="props">
						<div class="card-header" role="button">
							<div class="is-flex card-header-title">
								<span class="card-header-title"
									>{{ t.billingData }} <span class="has-text-grey is-size-7 ml-2">({{ t.required }})</span></span
								>
							</div>

							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
							</a>
						</div>
						<b-field class="mt-4" v-if="!shippingPoint || shippingPoint == 'home'">
							<b-checkbox v-model="useDeliveryAddress">{{ t.useDelivery }}</b-checkbox>
						</b-field>
					</template>
					<div class="level mt-4 mb-4" v-if="showBillingContainer">
						<div class="level-left">
							<div class="level-item" style="display: inline-flex">
								<p class="has-text-dark mt-2 mr-3 is-clickable" @click="openBilling">{{ t.addBillingData }}</p>
								<b-button class="add-btn-small" @click="openBilling"
									><b-icon pack="rt" class="mt-1" type="is-white" custom-size="is-size-6" icon="plus"></b-icon
								></b-button>
							</div>
						</div>
						<div class="level-right"></div>
					</div>

					<div class="card-content">
						<div class="content info-container mt-2" v-if="showBillingContainer">
							<p class="has-text-grey mb-0" v-if="newBillingAddress.address && newBillingAddress.zip_code">
								{{ newBillingAddress.address }} , {{ newBillingAddress.zip_code }}
							</p>
							<p class="has-text-grey mb-0">{{ newBillingAddress.city }}</p>
							<p class="has-text-grey mb-0">{{ newBillingAddress.country }}</p>
						</div>
					</div>
				</b-collapse>

				<div class="mt-10" style="display: inline-flex">
					<b-field>
						<b-radio size="is-medium" v-model="payment" native-value="money"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.money }}</span>
					</b-field>
					<b-field class="ml-15">
						<b-radio size="is-medium" v-model="payment" native-value="coupon"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.code }}</span>
					</b-field>
				</div>
				<br />
				<div class="mt-4" style="display: inline-flex">
					<b-field :label="t.insertMoney">
						<b-input
							size="is-large"
							type="number"
							min="0"
							v-model="credit_discount"
							:max="userMaxDiscount"
							:disabled="!payment.includes('money') ? true : false"
							step="0.01"
							ref="credit"
							@input="verifyDecimalPlaces"
						></b-input>
					</b-field>
					<b-button style="transform: translate(-80px, 25px)" type="is-primary" size="is-medium" @click="setMaxMoney"> MAX </b-button>

					<b-field class="ml-0" :label="t.insertCode">
						<!-- tinha ml-10 -->
						<b-input size="is-large" v-model="coupon" :disabled="!payment.includes('coupon') ? true : false"> </b-input> </b-field
					><b-icon icon="check" v-if="discountString" class="check" type="is-primary"> </b-icon>
					<b-icon icon="close" v-if="discountString == false && coupon != null && coupon.length != 0" class="check" type="is-danger"> </b-icon>
				</div>
				<p v-if="decimalError" class="has-text-danger is-size-7">{{ t.decimalError }}</p>
			</div>

			<div class="column is-hidden-touch is-4-desktop pl-12">
				<div class="has-radius p-10 has-background-white" style="border: 0.5px solid #e6e6e6">
					<h2 class="is-size-5 has-text-dark">{{ $t('views.Cart.summary') }}</h2>
					<div class="level pt-12 mb-0">
						<div class="level-left">
							<div class="level-item">
								<h2 class="is-size-5 has-text-dark">{{ t.shippingCosts }}</h2>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<h2 class="is-size-3 has-text-primary">{{ getRates }}€</h2>
							</div>
						</div>
					</div>
					<div class="level mb-0" v-if="discountApplied > 0">
						<div class="level-left">
							<div class="level-item">
								<h2 class="is-size-5 has-text-dark">{{ t.discountApplied }}</h2>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<h2 class="is-size-3 has-text-primary">{{ discountApplied }}%</h2>
							</div>
						</div>
					</div>
					<div class="level mb-0" v-if="credit_discount > 0 && payment == 'money'">
						<div class="level-left">
							<div class="level-item">
								<h2 class="is-size-5 has-text-dark">{{ t.creditsApplied }}</h2>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<h2 class="is-size-3 has-text-primary">{{ credit_discount }}€</h2>
							</div>
						</div>
					</div>
					<div class="level pb-12 mb-0">
						<div class="level-left">
							<div class="level-item">
								<h2 class="is-size-5 has-text-dark">{{ $t('views.Cart.total') }}</h2>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<h2 class="is-size-3 has-text-primary">{{ getTotalWithRates.toFixed(2) }}€</h2>
							</div>
						</div>
					</div>
					<b-button native-type="submit" expanded type="is-primary" size="is-large" :loading="isLoading">
						<span class="is-size-5 is-uppercase">{{ $t('views.Cart.checkout') }}</span>
					</b-button>
					<div class="has-text-centered pt-6">
						<a @click="goToPage('Homepage')" class="has-text-primary is-size-6">{{ $t('views.Cart.backShopping') }}</a>
					</div>
				</div>
				<div>
					<img src="../../../assets/stripe-badge.png" alt="" />
					<p class="is-size-6 has-text-justified">{{ t.stripe }}</p>
				</div>
			</div>
		</form>

		<AddressModal :isModalOpen="isModalOpen" @close="closeModal" @changeValues="changeValues" />
		<BillingModal :isModalOpen="isBillingModalOpen" @close="closeBillingModal" @changeBillingValues="changeBillingValues" :type="currentType" />
	</div>
</template>

<script>
	import cart from '@/mixins/modules/cart';
	import { addCard, getCard } from '@/api/paymentsMethod';
	import AddressModal from '@/components/Desktop/Shipping/ShippingAddressModal.vue';
	import BillingModal from '@/components/Desktop/Shipping/ShippingBillingModal.vue';
	import { getPickupPoints } from '@/api/shipping.js';
	const cities = require('@/assets/cities.json');
	import { StripeCheckout } from '@/api/cart';
	import { storeBalance } from '@/api/balance';
	import { getShippingRates } from '@/api/cart';
	import { getVouchers } from '@/api/marketing';
	import moment from 'moment';
	export default {
		mixins: [cart],
		components: {
			AddressModal,
			BillingModal,
		},
		data() {
			return {
				isLoading: null,
				cart: null,
				cards: [],
				shippingPoint: null,
				payment: [],
				isOpen: true,
				isModalOpen: false,

				homeShipping: {
					address: null,
					zip_code: null,
					city: null,
					country: null,
				},
				billingData: {
					address: null,
					zip_code: null,
					city: null,
					country: null,
				},
				pickups: [],
				citiesList: [],
				city: '',
				pickupId: null,
				storeMoneyLimit: 0,
				userMoneyLimit: 0,
				userMaxDiscount: 0,
				credit_discount: 0,
				coupon: null,
				errorShipping: false,
				total: 0,
				totalWeight: 0,
				shippingRates: [],
				shippingValue: 0,
				pickUpError: false,
				vouchers: [],
				discountString: null,
				discountApplied: 0,
				useDeliveryAddress: false,
				showBillingContainer: true,

				newBillingAddress: {
					address: null,
					zip_code: null,
					city: null,
					country: null,
					nif: null,
					company: null,
					phone: null,
				},
				isBillingModalOpen: false,
				currentType: null,
				decimalError: false,
				t: this.$t(`views.Shipping`),
			};
		},

		methods: {
			addCard() {
				if (this.shippingPoint == 'home') {
					this.setShippingType(this.shippingPoint);
					this.setAddress(this.homeShipping.address);
					this.setZipCode(this.homeShipping.zip_code);
					this.setCity(this.homeShipping.city);
					this.setCountry(this.homeShipping.country);
				} else if (this.shippingPoint == 'pickUp') {
					this.setShippingType(this.shippingPoint);
					this.setPickupId(this.pickupId);
				} else {
					this.setShippingType(this.shippingPoint);
					this.setAddress(this.homeShipping.address);
					this.setZipCode(this.homeShipping.zip_code);
					this.setCity(this.homeShipping.city);
					this.setCountry(this.homeShipping.country);
				}

				if (this.useDeliveryAddress && this.shippingPoint == 'home') {
					this.setUseDeliveryAddress(true);
				} else {
					this.setUseDeliveryAddress(false);
					this.setBillingAddress(this.newBillingAddress.address);
					this.setBillingZipCode(this.newBillingAddress.zip_code);
					this.setBillingCity(this.newBillingAddress.city);
					this.setBillingCountry(this.newBillingAddress.country);
					this.setNif(this.newBillingAddress.nif);
					this.setCompany(this.newBillingAddress.company);
					this.setPhone(this.newBillingAddress.phone);
				}
				this.setExtraDiscount(this.credit_discount);
				this.setDiscountCode(this.coupon);
				this.setCameFromCard(true);
				this.setPaymentType(this.payment);

				let url = {
					success_url: this.$route.path,
					cancel_url: this.$route.path,
				};
				addCard(url)
					.then((response) => {
						console.log(response);
						window.location.href = response.data.url;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			closeModal() {
				this.isModalOpen = false;
			},
			closeBillingModal() {
				this.isBillingModalOpen = false;
			},

			changeValues(userAddress) {
				console.log(userAddress);
				this.homeShipping.address = userAddress.address;
				this.homeShipping.city = userAddress.city;
				this.homeShipping.zip_code = userAddress.zip_code;
				this.homeShipping.country = userAddress.country;
				this.city = this.homeShipping.city;
				this.isModalOpen = false;
			},

			changeBillingValues(userAddress) {
				console.log(userAddress);
				this.newBillingAddress.address = userAddress.address;
				this.newBillingAddress.city = userAddress.city;
				this.newBillingAddress.zip_code = userAddress.zip_code;
				this.newBillingAddress.country = userAddress.country;
				this.newBillingAddress.nif = userAddress.nif;
				this.newBillingAddress.company = userAddress.company;
				this.newBillingAddress.phone = userAddress.phone;
				this.isBillingModalOpen = false;
			},

			changePickupLocality(city) {
				console.log(city);
				if (city != '') {
					getPickupPoints(city)
						.then((response) => {
							console.log(response);
							this.pickups = response.data;
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},

			checkout() {
				this.isLoading = true;
				this.pickUpError = false;
				console.log(this.shippingPoint);

				if (!this.shippingPoint) {
					this.errorShipping = true;
					this.isLoading = false;
				} else if (this.shippingPoint) {
					if (this.shippingPoint == 'home' && (!this.homeShipping.address || !this.homeShipping.zip_code || !this.homeShipping.city || !this.homeShipping.country)) {
						this.isLoading = false;
						this.isModalOpen = true;
						this.errorShipping = false;
						this.errorShipping = false;
					} else if (this.shippingPoint == 'pickUp' && (!this.shippingPoint || !this.pickupId)) {
						this.isLoading = false;
						this.pickUpError = true;
						this.errorShipping = false;
					} else if (
						!this.useDeliveryAddress &&
						(!this.newBillingAddress.address || !this.newBillingAddress.zip_code || !this.newBillingAddress.city || !this.newBillingAddress.country)
					) {
						this.isLoading = false;
						this.pickUpError = false;
						this.errorShipping = false;
						this.isBillingModalOpen = true;
					} else {
						let checkout = {
							store: {
								id: this.cart.cartProducts[0].product.store.id,
								account: this.cart.cartProducts[0].product.store.stripe_account,
							},
							products: [],
							shipping: {
								type: null,
								address: null,
								zip_code: null,
								locality: null,
								country: null,
								pickupId: null,
								weight: 0,
							},
							billing: {
								address: null,
								zip_code: null,
								locality: null,
								country: null,
								nif: null,
								company: null,
								phone_number: null,
							},
							credit_discount: null,
							coupon: null,
						};

						if (this.shippingPoint == 'home') {
							checkout.shipping.type = this.shippingPoint;
							checkout.shipping.address = this.homeShipping.address;
							checkout.shipping.zip_code = this.homeShipping.zip_code;
							checkout.shipping.locality = this.homeShipping.city;
							checkout.shipping.country = this.homeShipping.country;
						} else {
							checkout.shipping.type = this.shippingPoint;
							checkout.shipping.pickupId = this.pickupId;
						}

						if (this.useDeliveryAddress && this.shippingPoint == 'home') {
							checkout.billing.address = this.homeShipping.address;
							checkout.billing.zip_code = this.homeShipping.zip_code;
							checkout.billing.locality = this.homeShipping.city;
							checkout.billing.country = this.homeShipping.country;
							if (this.getUser.vat) {
								checkout.billing.nif = this.getUser.vat;
							}
						} else {
							checkout.billing.address = this.newBillingAddress.address;
							checkout.billing.zip_code = this.newBillingAddress.zip_code;
							checkout.billing.locality = this.newBillingAddress.city;
							checkout.billing.country = this.newBillingAddress.country;
							checkout.billing.nif = this.newBillingAddress.nif;
							checkout.billing.company = this.newBillingAddress.company;
							checkout.billing.phone_number = this.newBillingAddress.phone;
						}

						if (this.payment == 'money') {
							checkout.credit_discount = this.credit_discount;
						} else if (this.payment == 'coupon' && this.discountString == true) {
							checkout.coupon = this.coupon;
						}

						this.cart.cartProducts.map((product) => {
							console.log(product);
							checkout.shipping.weight = checkout.shipping.weight + product.product.weight * 100;
							checkout.products.push({
								id: product.product.id,
								stripe_id: product.product.stripe_id,
								quantity: product.quantity,
								variant: product.productVariant.id,
								size: product.productSize.id,
							});
						});

						console.log(checkout);

						StripeCheckout(checkout)
							.then((response) => {
								console.log(response);
								window.location.href = response.data.url;
							})
							.catch((error) => {
								console.log(error);
								if (error.response.data.error.message == 'Invalid Voucher') {
									this.$buefy.toast.open({
										message: this.t.invalidVoucher,
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									this.$buefy.toast.open({
										message: this.t.error,
										position: 'is-top',
										type: 'is-danger',
									});
								}
							})
							.finally(() => {
								this.isLoading = false;
							});
					}
				}
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			getTotalAmount() {
				console.log(this.cart);

				this.cart.cartProducts.forEach((cartProduct) => {
					console.log(cartProduct);
					if (cartProduct.product.productSales[0].isActive == true) {
						this.total += parseInt(cartProduct.quantity) * this.calculateSale(cartProduct.product.price, cartProduct.product.productSales[0].discount);
					} else this.total += parseInt(cartProduct.quantity) * cartProduct.product.price;
					this.totalWeight = this.totalWeight + cartProduct.product.weight * parseInt(cartProduct.quantity);
				});
			},

			setMaxMoney() {
				this.credit_discount = this.userMaxDiscount;
				this.$refs.credit.focus();
			},

			openBilling() {
				this.isBillingModalOpen = true;
				this.currentType = this.shippingPoint;
			},

			verifyDecimalPlaces(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalError = true;
				} else this.decimalError = false;
			},
		},

		mounted() {
			console.log(this.payment);
			getShippingRates().then((response) => {
				console.log(response);
				this.shippingRates = response.data.data;
			});
		},

		updated() {
			if (this.shippingPoint == 'pickUp') {
				this.useDeliveryAddress = false;
			}
		},

		created() {
			console.log(this.getUser);
			this.setIsPageLoading(true);
			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));
			console.log(this.getCameFromCard);
			console.log(this.getAddress);
			console.log(this.getZipCode);
			console.log(this.getCity);
			console.log(this.getCountry);
			if (this.getCameFromCard == true) {
				if (this.getShippingType) {
					this.shippingPoint = this.getShippingType;
				}

				this.homeShipping = {
					address: this.getAddress,
					zip_code: this.getZipCode,
					city: this.getCity,
					country: this.getCountry,
				};

				this.newBillingAddress = {
					address: this.getBillingAddress,
					zip_code: this.getBillingZipCode,
					city: this.getBillingCity,
					country: this.getBillingCountry,
					nif: this.getNif,
					company: this.getCompany,
					phone: this.getPhone,
				};
				this.useDeliveryAddress = this.getUseDeliveryAddress;

				this.credit_discount = this.getExtraDiscount;
				this.coupon = this.getDiscountCode;
				if (this.getPaymentType) {
					this.payment = this.getPaymentType;
				}

				if (this.getShippingType == 'pickUp') {
					this.changePickupLocality(this.getCity);
				}

				this.pickupId = +this.getPickupId;
			} else {
				this.homeShipping = {
					address: this.getUser.location_address,
					zip_code: this.getUser.location_zipCode,
					city: this.getUser.location_municipality,
					country: this.getUser.location_country,
				};
			}

			if (this.homeShipping.city) {
				this.city = this.homeShipping.city;
			} else this.city = '';

			this.getCartFromAPI(this.getUser.id).then(() => {
				this.cart = this.getCart;
				console.log(this.cart);
				this.getTotalAmount();

				this.storeMoneyLimit = this.cart.cartProducts[0].product.store.userMaxValue;
				console.log(this.storeMoneyLimit);

				storeBalance({ 'filters[store]': this.cart.cartProducts[0].product.store.id })
					.then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.userMoneyLimit = (this.storeMoneyLimit / 100) * response.data.data[0].credit;

							this.userMaxDiscount = this.userMoneyLimit.toFixed(2);
						} else this.userMaxDiscount = 0;
					})
					.catch((error) => {
						console.log(error);
					});
			});

			if (this.getUser.location_municipality) {
				getPickupPoints(this.getUser.location_municipality)
					.then((response) => {
						console.log(response);
						this.pickups = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				let local = '';
				getPickupPoints(local)
					.then((response) => {
						console.log(response);
						this.pickups = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			}

			getCard()
				.then((response) => {
					console.log(response);
					this.cards = response.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getVouchers({
				'filters[users]': this.getUser.id,
				...(this.getCart.cartProducts[0].product.store.id && { 'filters[store][id]': this.getCart.cartProducts[0].product.store.id }),
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					let filter = response.data.data;

					let date = moment().format('YYYY-MM-DD');

					filter.map((voucher) => {
						if (moment(date).isBetween(voucher.startAt, voucher.endAt, undefined, '[]')) {
							this.vouchers.push(voucher);
						}
					});

					console.log(this.vouchers);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => this.setIsPageLoading(false));
		},

		computed: {
			getTotalWithRates() {
				console.log(this.discountApplied);

				if (this.discountApplied > 0 && this.payment == 'coupon') {
					return this.total - this.total * (this.discountApplied / 100) + this.getRates;
				} else if (this.credit_discount > 0 && this.payment == 'money') {
					return this.total - this.credit_discount + this.getRates;
				} else return this.total + this.getRates;
			},

			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.city.toLowerCase()) >= 0;
				});
			},

			getRates() {
				if (this.total < this.getCart.cartProducts[0].product.store.shippingMinValue) {
					this.shippingRates.map((rates) => {
						if (this.shippingPoint == 'home' && rates.delivery_type == 'home' && this.totalWeight <= 5 && rates.isLarge == false) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'home' && rates.delivery_type == 'home' && this.totalWeight > 5 && rates.isLarge == true) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'pickUp' && rates.delivery_type == 'pickUp' && this.totalWeight <= 5 && rates.isLarge == false) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'pickUp' && rates.delivery_type == 'pickUp' && this.totalWeight > 5 && rates.isLarge == true) {
							this.shippingValue = rates.value;
						}
					});

					return this.shippingValue;
				} else return 0;
			},

			applyVoucher() {
				return this.coupon;
			},

			getDeliveryOption() {
				return this.useDeliveryAddress;
			},
		},

		watch: {
			applyVoucher(newVal) {
				if (newVal || newVal == '') {
					let voucher = this.vouchers.filter((code) => code.code == this.coupon);
					console.log(voucher);
					if (voucher.length > 0) {
						this.discountString = true;
						this.discountApplied = voucher[0].discount;
					} else {
						this.discountString = false;
						this.discountApplied = 0;
					}
				}
			},

			getDeliveryOption(newVal) {
				if (newVal) {
					this.showBillingContainer = false;
				} else {
					this.showBillingContainer = true;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	.add-card {
		min-width: 150px;
		height: 189px;
		background-color: $white-ter;
		border-radius: 21px;
	}

	.add-btn {
		background-color: $primary;
		min-width: 89px;
		height: 89px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 99999px;
		border: none;
	}

	.add-btn-small {
		background-color: $primary;
		width: 30px;
		height: 30px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 99999px;
		border: none;
	}

	.credit-card {
		min-width: 300px;
		height: 201px;
		background-color: $primary;
		border-radius: 20px;
		padding: 1rem;
		background: rgb(86, 9, 255);
		background: linear-gradient(160deg, rgba(86, 9, 255, 1) 0%, rgba(130, 79, 255, 1) 29%, rgba(140, 70, 255, 1) 54%, rgba(221, 99, 250, 1) 75%, rgba(238, 107, 249, 1) 100%);
	}

	.img {
		width: 60px;
		height: 60px;
	}

	.card-container {
		display: flex;
		overflow: auto;
		width: 100%;
		padding-bottom: 10px;
	}

	::-webkit-scrollbar {
		height: 6px;
		margin-top: 10px;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:active {
		background: #aab2b7;
	}

	.card,
	.card-header {
		box-shadow: none !important;
	}

	.card-header-title,
	.card-content {
		padding: 0 !important;
		font-weight: normal;
	}

	.info-container {
		border: 1px solid #e6e6e6;
		border-radius: 20px;
		padding: 1rem;
		padding-top: 2rem;
		min-height: 158px;
	}

	/deep/ input.input {
		background-color: white;
		border: 1px solid #e6e6e6;
		border-radius: 15px;
		width: 410px;
	}

	/deep/ select {
		background-color: white;
		height: 43px;
		width: 410px;
		border: 1px solid #e6e6e6;
	}

	.check {
		transform: translate(-40px, 36px);
	}

	/deep/.autocomplete .dropdown-content {
		max-width: 400px;
	}

	/deep/.control {
		max-width: 375px;
	}
</style>
