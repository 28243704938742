<template>
	<section>
		<div class="items-list px-3 mt-6">
			<div>
				<span>{{ totalProducts }} Items</span>

				<div class="options-container mt-2 is-flex">
					<b-field v-for="category in categories" :key="category.id">
						<b-checkbox-button
							class="checkbox mr-4"
							size="is-medium"
							v-model="categoryOptionSelected"
							@input="selectedFilter(category.id)"
							:native-value="category.id"
							type="is-grey-darker"
						>
							<span class="btn-text">{{ category.name }}</span>
						</b-checkbox-button>
					</b-field>
				</div>

				<b-button @click.native="openFilter" class="btn ml-4 right">
					<b-icon pack="rt" class="mr-2" icon="sort" size="is-size-6" type="is-black"></b-icon>{{ t.sort }}</b-button
				>
			</div>
			<div v-show="showFilter" class="filter-window mt-2">
				<b-field class="mt-3 ml-2">
					<b-radio native-value="id:desc" @input="updateSort" v-model="sort">{{ t.new }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="price:desc" @input="updateSort" v-model="sort">{{ t.priceHigh }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="price:asc" @input="updateSort" v-model="sort">{{ t.priceLow }}</b-radio>
				</b-field>
			</div>

			<div class="columns mt-6 is-desktop is-multiline">
				<div v-for="(product, index) in storeProducts" :key="index" class="column is-one-fifth-widescreen is-one-quarter-desktop">
					<div class="products-container" @click="productDetails(product.id, 1)">
						<figure class="image is-4by3 is-clickable">
							<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
								>{{ product.productSales[0].discount }}% {{ t.off }}</span
							>
							<img class="has-object-fit-contain has-radius" :src="product.pictures[0].url" alt="" />
						</figure>
						<div class="media">
							<div class="media-left" style="width: 100%">
								<p class="product-name mt-2">{{ product.title }}</p>

								<div style="display: inline-flex">
									<p
										class="price"
										:style="[
											product.productSales.length > 0 && product.productSales[0].isActive == true
												? { textDecoration: 'line-through' }
												: { textDecoration: 'none' },
										]"
									>
										{{ product.price }} €
									</p>
									<p class="price ml-4" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
										{{ calculateSale(product.price, product.productSales[0].discount) }} €
									</p>
								</div>

								<!-- <p class="price">{{ product.price }} €</p> -->
							</div>
							<div class="media-content"></div>
							<div class="media-right"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getProducts, getCategories } from '@/api/store';
	import parts from '@/mixins/modules/shared/parts';
	export default {
		props: ['storeId', 'subCategoriesSelected', 'sizesSelected', 'colorSelected', 'priceSelected', 'brandSelected', 'onlySale', 'categoryOption'],
		mixins: [parts],
		data() {
			return {
				t: this.$t(`components.Store.StoreProducts`),
				showFilter: false,
				storeProducts: [],
				sort: 'id:desc',
				page: 1,
				totalProducts: 0,
				pageItems: 15,
				endScroll: false,
				categories: [],
				categoryOptionSelected: [],
			};
		},
		methods: {
			openFilter() {
				this.showFilter = !this.showFilter;
			},

			/* 	search() {
				this.storeProducts = [];
				this.page = 1;
				getProdaucts(this.storeId, this.page, this.pageItems, { _q: this.searchString })
					.then((response) => {
						console.log(response);
						this.storeProducts.push(...response.data.data);
					})
					.catch((error) => {
						console.log(error);
					});
			},

			clear() {
				this.searchString = '';
				this.search();
			},
 */
			handleScroll(e) {
				if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.endScroll == false) {
					this.endScroll = true;

					if (this.endScroll) {
						this.page++;

						getProducts(
							this.storeId,
							this.page,
							this.pageItems,
							this.categoryOptionSelected,
							this.subCategoriesSelected,
							this.sizesSelected,
							this.priceSelected[0],
							this.priceSelected[1],
							this.colorSelected,
							this.brandSelected,
							this.onlySale,
							this.sort,
							this.getFilterString,
							true
						)
							.then((response) => {
								console.log(response);
								this.storeProducts.push(...response.data.data);
								this.endScroll = false;
							})
							.catch((error) => {
								console.log(error);
							});
					}
				}
			},

			productDetails(id, tab) {
				parts.methods.setMenuVisible(false);
				parts.methods.setNotificationsVisible(false);
				this.$router.push({ name: 'ProductDetails', params: { id: id, tab: tab } });
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			selectedFilter(id) {
				this.$emit('selectedFilter', id);
			},

			updateSort() {
				this.page = 1;
				getProducts(
					this.storeId,
					this.page,
					this.pageItems,
					this.categoryOptionSelected,
					this.subCategoriesSelected,
					this.sizesSelected,
					this.priceSelected[0],
					this.priceSelected[1],
					this.colorSelected,
					this.brandSelected,
					this.onlySale,
					this.sort,
					this.getFilterString,
					true
				)
					.then((response) => {
						console.log(response);
						this.storeProducts = response.data.data;
						this.totalProducts = response.data.meta.pagination.total;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},
		},
		computed: {
			searchString() {
				return this.getFilterString;
			},
		},

		watch: {
			searchString(newVal) {
				if (newVal || newVal == '') {
					this.setIsPageLoading(true);
					this.page = 1;
					this.products = [];

					getProducts(
						this.storeId,
						this.page,
						this.pageItems,
						this.categoryOptionSelected,
						this.subCategoriesSelected,
						this.sizesSelected,
						this.priceSelected[0],
						this.priceSelected[1],
						this.colorSelected,
						this.brandSelected,
						this.onlySale,
						this.sort,
						this.getFilterString,
						true
					)
						.then((response) => {
							console.log(response);
							this.storeProducts = response.data.data;
							this.totalProducts = response.data.meta.pagination.total;
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
						});
				}
			},
		},

		created() {
			this.categoryOptionSelected = this.categoryOption;

			window.addEventListener('scroll', this.handleScroll);

			getCategories({
				'filters[level]': 0,
				populate: 'parent',
			})
				.then((response) => {
					this.categories = response.data.data.filter((category) => category.name != 'Unissexo');
					console.log(this.categories);
					if (this.categoryOptionSelected.length == 0) {
						console.log(this.categories);
						this.selectedFilter(this.categories[0].id);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		mounted() {
			this.setIsPageLoading(true);
			getProducts(
				this.storeId,
				this.page,
				this.pageItems,
				this.categoryOptionSelected,
				this.subCategoriesSelected,
				this.sizesSelected,
				this.priceSelected[0],
				this.priceSelected[1],
				this.colorSelected,
				this.brandSelected,
				this.onlySale,
				this.sort,
				this.getFilterString,
				true
			)
				.then((response) => {
					console.log(response);
					this.storeProducts = response.data.data;
					this.totalProducts = response.data.meta.pagination.total;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.search-container .field {
		border: 0.5px solid $grey;
		border-radius: 5px;
		width: 274px;
		@include until($mobile-s) {
			width: 230px;
		}
	}

	.icon-box {
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.search-container {
		display: inline-flex;
	}

	.right {
		float: right;
	}

	/deep/ .control.has-icons-left .icon,
	.control.has-icons-right .icon {
		color: $black;
	}

	.filter-window {
		width: 169px;
		height: 108px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 5px;
		z-index: 10;
		padding: 1px;
		background-color: $white;
		top: -160px;
	}

	/deep/ .b-checkbox.checkbox input[type='checkbox'] + .check {
		width: 14px;
		height: 14px;
		border-radius: 50px;
	}

	.items-list {
		padding-bottom: 90px;
	}

	.img {
		border-radius: 7px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		width: 245px;
		height: 144px;
		border: 1px solid $grey-light;
	}

	.tag {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 2;
		width: 81px;
		height: 30px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
	}

	.product-name {
		font-size: 0.858rem;
		text-align: left;
		font-weight: 300;
	}

	.price {
		font-size: 1rem;
		font-weight: 600;
		text-align: left;
	}

	.columns {
		justify-content: unset !important;
	}

	.btn {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
		position: absolute;
		top: -200px;
		right: 0;
	}

	img {
		border: 1px solid $grey-light;
	}
</style>
