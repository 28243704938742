<template></template>

<script>
	import { stripeActivate } from '@/api/store';
	export default {
		created() {
			stripeActivate()
				.then((response) => {
					this.goToPage('Homepage');
				})
				.catch((error) => {});
		},
	};
</script>
