<template>
	<section class="center-inside">
		<div class="modal" :class="{ 'is-active': isModalOpen }">
			<div class="modal-background"></div>
			<div class="modal-card px-6">
				<header class="modal-card-head">
					<p class="modal-card-title center-inside">{{ t.newAddress }}</p>
					<button type="button" class="delete ml-4" @click="$emit('close')" />
				</header>
				<section class="modal-card-body">
					<form @submit.prevent="setNewAddress">
						<b-field><b-input class="mt-8" size="is-medium" name="address" type="text" :placeholder="t.address" v-model.trim="address"></b-input></b-field>
						<b-field>
							<b-autocomplete
								class="mt-8"
								style="height: 42px"
								size="is-medium"
								rounded
								:data="filteredCities"
								:keep-first="true"
								:open-on-focus="true"
								field="city"
								:placeholder="t.city"
								icon="magnify"
								clearable
								ref="city"
								name="city"
								v-model.trim="city"
							>
								<template #empty>{{ t.noResults }}</template>
							</b-autocomplete>
						</b-field>
						<b-field
							:type="zipCodeError ? 'is-danger' : ''"
							:message="zipCodeError ? 'Infelizmente ainda não estamos a enviar para a tua zona, estamos a trabalhar nisso' : ''"
							><b-input class="mt-8" size="is-medium" name="zip_code" type="text" :placeholder="t.zip" pattern="\d{4}-\d{3}" v-model.trim="zip_code"></b-input
						></b-field>
						<b-field>
							<b-select class="mt-8" size="is-medium" v-model="country">
								<option value="Portugal">Portugal</option>
							</b-select></b-field
						>

						<b-button class="mt-6 btn" native-type="submit">{{ t.save }}</b-button>

						<p v-show="errorMessage" class="has-text-danger has-text-centered mt-2">{{ t.fillError }}</p>
					</form>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
	const cities = require('@/assets/cities.json');
	import { updatePreferences } from '@/api/user';
	export default {
		props: ['isModalOpen'],

		data() {
			return {
				citiesList: [],
				city: '',
				address: null,
				zip_code: null,
				country: 'Portugal',
				errorMessage: false,

				userInfo: {
					location_address: null,
					location_zipCode: null,
					location_municipality: '',
					location_country: null,
				},
				t: this.$t(`views.ShippingAdressModal`),
				zipCodeError: false,
			};
		},

		methods: {
			setNewAddress() {
				let zip;
				if (this.zip_code) {
					zip = +this.zip_code.split('-')[0];

					if (this.city == '' || !this.address || !this.zip_code || !this.country) {
						this.errorMessage = true;
						this.zipCodeError = false;
					} else if (zip >= 9000 && zip <= 9980) {
						(this.errorMessage = false), (this.zipCodeError = true);
					} else {
						this.errorMessage = false;
						this.zipCodeError = false;
						let address = {
							city: this.city,
							address: this.address,
							zip_code: this.zip_code,
							country: this.country,
						};

						this.$buefy.dialog.confirm({
							title: this.t.addAddress,
							message: this.t.addAdressText,
							confirmText: this.t.yes,
							cancelText: this.t.no,
							type: 'is-primary',
							hasIcon: true,
							onConfirm: () => {
								this.userInfo.location_address = address.address;
								this.userInfo.location_zipCode = address.zip_code;
								this.userInfo.location_municipality = address.city;
								this.userInfo.location_country = address.country;
								updatePreferences(this.userInfo)
									.then((response) => {
										this.addUser(response.data);
										this.isLoading = false;
										this.$buefy.toast.open({
											message: this.t.addressUpdated,
											position: 'is-top',
											type: 'is-primary',
										});
									})
									.catch((error) => {
										this.$buefy.toast.open({
											message: this.t.error,
											position: 'is-top',
											type: 'is-danger',
										});
									});
							},
						});

						this.$emit('changeValues', address);
					}
				} else this.errorMessage = true;
			},
		},

		computed: {
			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.city.toLowerCase()) >= 0;
				});
			},
		},

		created() {
			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.modal-card {
		height: auto;
		width: auto;

		@include until($touch) {
			font-size: 1rem;
			width: 96%;
		}
	}

	.modal-card-head {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.modal-card-body {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		min-height: 400px;
	}

	.input {
		border-radius: 11px;
		border: solid 1px $grey-light;
	}

	.btn {
		background-color: $primary;
		color: white;
		display: flex;
		margin: 0 auto;
		width: 50%;
		height: 40px;
	}

	.modal-card-title {
		@include until($touch) {
			font-size: 1rem;
		}
	}
</style>
