import { get, put, post } from '@/utils/http';
const qs = require('qs');

export function getOrders(query = {}) {
	query = qs.stringify(query);
	return get('/api/orders?populate=user, user.picture,store.pictures,orderStatus&' + query);
}

export function getOrderById(orderId) {
	return get('/api/orders/' + orderId + '?populate=user,user.picture,orderStatus, product.productSales');
}

export function updateProductSize(id, data) {
	console.log(id);
	return put('/api/product-sizes/' + id, { data: data });
}

export function updateOrderStatus(id, data) {
	return put('/api/orders/' + id, { data: data });
}

export function tracking(id) {
	return get('/api/dpd/tracking/' + id);
}

export function getPickupPoints(query = {}) {
	query = qs.stringify(query);
	return get('/api/dpd/pickup-points?' + query);
}

export function devoltuionMotives() {
	return get('/api/return-motives');
}

export function userReturnProduct(id, data) {
	return post('/api/orders/' + id + '/return', data);
}

export function storeConfirmRefund(id, data) {
	return post('/api/orders/' + id + '/refund', data);
}

export function getUserLineGraph(filter) {
	return get('/api/orders/history?interval=' + filter);
}

export function uploadImage(file) {
	console.log(file);
	let form = new FormData();
	form.append('files', file);

	return post('/api/upload', form);
}

export function rejectionMotives() {
	return get('/api/refund-reject-motives');
}

export function rejectDevolution(id, data) {
	return post('/api/orders/' + id + '/reject', { data });
}
