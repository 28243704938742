<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileUserInterests />
		</div>
		<div class="container" v-else>
			<DesktopUserInterests />
		</div>
	</section>
</template>

<script>
	import MobileUserInterests from '@/components/User/ChooseUserStyle.vue';
	import DesktopUserInterests from '@/components/Desktop/User/ChooseStyle.vue';

	export default {
		components: {
			MobileUserInterests,
			DesktopUserInterests,
		},

		data() {
			return {};
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},
	};
</script>
