<template>
	<section>
		<div class="media px-6 center-inside mb-6 mt-10">
			<div class="media-left">
				<div class="photo-container mt-4 has-radius">
					<div class="photo center-inside" :style="!img ? 'border:solid 1px black' : 'border:none'">
						<img class="has-radius has-border-black" v-if="img" :src="img" alt="" />
						<b-icon v-else icon="account-outline " type="is-black" custom-size="is-size-2"> </b-icon>
					</div>
				</div>
			</div>
			<div class="media-content">
				<p class="name">{{ getUser.name }}</p>
				<p class="address has-text-grey">{{ address }}</p>
				<p class="description has-text-grey">{{ getUser.description }}</p>
			</div>
			<div class="media-right"></div>
		</div>

		<b-tabs class="oveflow-tab" v-model="tab" position="is-centered" :destroy-on-hide="true">
			<b-tab-item :label="t.discounts">
				<Discounts />
			</b-tab-item>
			<b-tab-item :label="t.order">
				<Orders />
			</b-tab-item>
			<b-tab-item :label="t.devolutions"> <Devolutions /> </b-tab-item>
		</b-tabs>
	</section>
</template>

<script>
	import Discounts from '@/components/Profile/DiscountsTab.vue';
	import Orders from '@/components/Profile/OrdersTab.vue';
	import Devolutions from '@/components/Profile/DevolutionsTab.vue';

	export default {
		components: {
			Discounts,
			Orders,
			Devolutions,
		},

		data() {
			return {
				t: this.$t(`views.UserProfile`),
				tab: 0,
				address: null,
				img: null,
			};
		},

		mounted() {
			if (this.getUser.location_municipality && this.getUser.location_country) {
				this.address = this.getUser.location_municipality + ', ' + this.getUser.location_country;
			}

			if (this.getUser.picture) {
				this.img = this.getUser.picture.url;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		/* border: solid 1px black; */
		background-color: $white;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.name {
		font-size: 1rem;
	}

	.address {
		font-size: 0.857rem;
	}

	.description {
		font-size: 0.714rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: black;
	}
	/deep/ nav.tabs.is-centered {
		overflow: hidden;
	}
</style>
