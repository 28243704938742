<template>
	<section v-if="IS_MOBILE">
		<div>
			<MobileUserProfile />
		</div>
	</section>
	<section v-else>
		<div class="container">
			<DesktopUserProfile />
		</div>
	</section>
</template>

<script>
	import MobileUserProfile from '@/components/User/UserProfile.vue';
	import DesktopUserProfile from '@/components/Desktop/User/UserProfile.vue';
	export default {
		components: {
			MobileUserProfile,
			DesktopUserProfile,
		},

		data() {
			return {};
		},
	};
</script>
