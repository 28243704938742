<template>
	<section>
		<div class="left-container">
			<p class="title mx-10 has-text-grey-darker">Pense grande, pense local.</p>
			<p class="pt-2 mx-10 is-size-5 has-text-grey-darker">A plataforma social para o comércio local.</p>
			<b-image class="img" :src="require('@/assets/svg/root-icons.svg')" alt="root"></b-image>
		</div>
		<div class="right-container">
			<div v-if="(getStep < 3 || getStep > 3) && getStep != 6" class="content" :style="getStep == 1 ? `width:610px` : ''">
				<SignIn v-if="getStep == 0" />
				<Register v-else-if="getStep == 1 && getUserType == 'authenticated'" />
				<StoreRegister v-else-if="getStep == 1 && getUserType == 'store'" style="position: absolute; top: 100px" />
				<ForgotPassword v-else-if="getStep == 4" />
			</div>
			<div class="content-higher">
				<ConfirmCode v-if="getStep == 2 && getUserType == 'authenticated'" />
				<ChooseStyle v-else-if="getStep == 3 && getUserType == 'authenticated'" />
				<CheckEmail v-else-if="getStep == 5" />
				<ConfirmResetPasswordCode v-else-if="getStep == 6" />
			</div>
		</div>
	</section>
</template>

<script>
	import SignIn from '@/components/Desktop/Auth/SingIn.vue';
	import Register from '@/components/Desktop/Auth/Register.vue';
	import ChooseStyle from '@/components/Desktop/User/ChooseStyle.vue';
	import ForgotPassword from '@/components/Desktop/Auth/ForgotPassword.vue';
	import CheckEmail from '@/components/Desktop/Auth/CheckEmail.vue';
	import StoreRegister from '@/components/Desktop/Auth/StoreRegister.vue';
	import ConfirmCode from '@/components/Desktop/Auth/ConfirmEmailCode.vue';
	import ConfirmResetPasswordCode from '@/components/Desktop/Auth/ResetPasswordCode.vue';
	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Auth`),
			};
		},
		components: {
			SignIn,
			Register,
			ChooseStyle,
			ForgotPassword,
			CheckEmail,
			StoreRegister,
			ConfirmCode,
			ConfirmResetPasswordCode,
		},

		computed: {
			getStep() {
				return this.$store.getters['desktopLoginStep/getStep'];
			},

			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			if (!this.getUserType) {
				this.$store.commit('desktopLoginStep/setStep', 0);
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.left-container {
		width: 50%;
		background-color: $white-ter;
		height: 100vh;
		display: inline-block;
	}
	.right-container {
		width: 50%;
		display: inline-block;
		position: absolute;
		right: 0;
		height: 100%;
	}

	.img {
		width: 450px;
		margin: 0 auto;
		padding-top: 10%;
	}

	.title {
		padding-top: 60px;
		font-size: 2.5rem;
	}

	.content {
		margin-top: 30%;
		margin-left: auto;
		margin-right: auto;
		width: 550px;
	}

	.content-higher {
		margin-top: 20%;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
	}
</style>
