<template>
	<section class="center-inside">
		<div class="modal" :class="{ 'is-active': isModalOpen }">
			<div class="modal-background"></div>
			<div class="modal-card px-6">
				<header class="modal-card-head">
					<p class="modal-card-title center-inside">{{ t.newAddress }}</p>
					<button type="button" class="delete ml-4" @click="$emit('close')" />
				</header>
				<section class="modal-card-body">
					<form @submit.prevent="setNewAddress">
						<b-field><b-input class="mt-8" size="is-medium" name="address" type="text" :placeholder="t.address" v-model.trim="address"></b-input></b-field>
						<b-field>
							<b-autocomplete
								class="mt-8"
								style="height: 42px"
								size="is-medium"
								rounded
								:data="filteredCities"
								:keep-first="true"
								:open-on-focus="true"
								field="city"
								:placeholder="t.city"
								icon="magnify"
								clearable
								ref="city"
								name="city"
								v-model.trim="city"
							>
								<template #empty>{{ t.noResults }}</template>
							</b-autocomplete>
						</b-field>
						<b-field
							><b-input class="mt-8" size="is-medium" type="text" :placeholder="t.zip" pattern="\d{4}-\d{3}" name="zip_code" v-model.trim="zip_code"></b-input
						></b-field>
						<b-field
							><b-input
								class="mt-8"
								size="is-medium"
								type="text"
								:placeholder="t.vatNumber"
								v-validate="'numeric|length:9|'"
								name="vat"
								ref="vat"
								v-model.trim="nif"
							></b-input
						></b-field>
						<p v-show="errors.has('vat')" class="help is-danger ml-4">{{ t.invalidVat }}</p>
						<b-field
							><b-input
								class="mt-8"
								size="is-medium"
								type="text"
								:placeholder="t.phone"
								v-validate="'required|mobile-phone|numeric'"
								name="phone"
								ref="phone"
								v-model.trim="phone"
								v-if="type == 'pickUp'"
							></b-input>
						</b-field>
						<p v-show="errors.has('phone')" class="help is-danger ml-4">{{ t.invalidPhone }}</p>
						<b-field><b-input class="mt-8" size="is-medium" type="text" placeholder="compay (opcional)" v-model.trim="company"></b-input></b-field>
						<b-field>
							<b-select class="mt-8" size="is-medium" v-model="country">
								<option value="Portugal">Portugal</option>
							</b-select></b-field
						>

						<b-button class="mt-6 btn" native-type="submit">{{ t.save }}</b-button>

						<p v-show="errorMessage" class="has-text-danger has-text-centered mt-2">{{ t.fillError }}</p>
					</form>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
	const cities = require('@/assets/cities.json');
	export default {
		props: ['isModalOpen', 'type'],

		data() {
			return {
				citiesList: [],
				city: '',
				address: null,
				zip_code: null,
				country: 'Portugal',
				errorMessage: false,
				nif: null,
				company: null,
				phone: null,
				t: this.$t(`views.BillingAddressModal`),
			};
		},

		methods: {
			setNewAddress() {
				if (this.type == 'pickUp') {
					if (this.city == '' || !this.address || !this.zip_code || !this.country || !this.phone) {
						this.errorMessage = true;
					} else {
						this.$validator.validateAll().then(async (result) => {
							console.log(result);
							if (result) {
								let address = {
									city: this.city,
									address: this.address,
									zip_code: this.zip_code,
									country: this.country,
									nif: this.nif,
									company: this.company,
									phone: this.phone,
								};

								this.$emit('changeBillingValues', address);
							}
						});
					}
				} else {
					if (this.city == '' || !this.address || !this.zip_code || !this.country) {
						this.errorMessage = true;
					} else {
						this.$validator.validateAll().then(async (result) => {
							console.log(result);
							if (result) {
								let address = {
									city: this.city,
									address: this.address,
									zip_code: this.zip_code,
									country: this.country,
									nif: this.nif,
									company: this.company,
									phone: this.phone,
								};

								this.$emit('changeBillingValues', address);
							}
						});
					}
				}
			},
		},

		computed: {
			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.city.toLowerCase()) >= 0;
				});
			},
		},

		created() {
			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.modal-card {
		height: auto;
		width: auto;

		@include until($touch) {
			font-size: 1rem;
			width: 96%;
		}
	}

	.modal-card-head {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.modal-card-body {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		min-height: 400px;
	}

	.input {
		border-radius: 11px;
		border: solid 1px $grey-light;
	}

	.btn {
		background-color: $primary;
		color: white;
		display: flex;
		margin: 0 auto;
		width: 50%;
		height: 40px;
	}

	.modal-card-title {
		@include until($touch) {
			font-size: 1rem;
		}
	}
</style>
