<template>
	<section @scroll="handleScroll">
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" size="is-medium"> </b-icon>
					<p class="is-size-4 ml-2">{{ t.transactions }}</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-button @click.native="openFilter" type="is-white" class="btn-sort"
						><b-icon pack="rt" icon="sort" size="is-size-4" type="is-black"></b-icon><span class="sort-text">{{ t.sort }}</span></b-button
					>
				</div>
			</div>
		</div>
		<div v-show="showFilter" class="filter-window mt-2">
			<b-field class="mt-3 ml-2">
				<b-radio native-value="id:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
			</b-field>
			<b-field class="mt-3 ml-2">
				<b-radio native-value="id:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
			</b-field>
		</div>

		<!-- 	<div style="border: solid 1px #e6e6e6; padding: 4rem; border-radius: 20px">
			<div class="level">
				<div class="level-left">
					<p class="level-item">{{ totalAmount.toFixed(2) }} €</p>
				</div>

				<b-field class="level-right">
					<b-select class="mt-2 level-item" name="gender" @input="changeGraph" v-model="graphFilter">
						<option value="month">{{ t.month }}</option>
						<option value="year">{{ t.year }}</option>
					</b-select>
				</b-field>
			</div>

			<highcharts class="mt-10" :options="chartOptions"></highcharts>
		</div> -->

		<p class="is-size-4 mt-10 mb-8">{{ t.activity }}</p>
		<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
			<img v-if="order.user.picture" :src="order.store.pictures[0].url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-grey">{{ order.store.name }}</p>
				<p class="has-text-dark is-size-5">{{ getItems(order) }} items</p>
				<p class="has-text-dark is-size-5">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
			</div>
			<div style="display: inline-flex">
				<b-button class="has-background-primary has-text-white btn" v-if="order.orderStatus.id == 8" @click="devolution(order.id)">{{ t.return }}</b-button>
				<p class="price ml-10 mr-4" style="text-align: right">{{ (order.amount / 100).toFixed(2) }}€</p>
			</div>
		</div>
	</section>
</template>

<script>
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';
	import { getUserLineGraph, getOrders } from '@/api/orders';
	import moment from 'moment';

	exportingInit(Highcharts);
	export default {
		data() {
			return {
				t: this.$t(`components.Store.TransactionsHistory`),
				chartOptions: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'areaspline',
					},
					title: {
						text: '',
					},

					xAxis: {
						categories: [],
						gridLineWidth: 1,
					},
					yAxis: {
						title: {
							text: '',
						},
						gridLineWidth: 0,
					},
					legend: {
						layout: 'vertical',
						align: 'left',
						verticalAlign: 'bottom',
						x: 150,
						y: 100,
						floating: true,
						borderWidth: 1,
						backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
					},
					tooltip: {
						headerFormat: '<table><span style="font-size:12px;text-align:center">{point.key}</span></table>',
						pointFormat: '<p style="font-size:15px"><b>{point.y:1f} €</b></p>',

						shared: true,
						useHTML: true,
						backgroundColor: '#99cca8',
						style: {
							color: 'white',
							textAlign: 'center',
						},
					},
					credits: {
						enabled: false,
					},
					plotOptions: {
						areaspline: {
							fillOpacity: 0.5,
						},
					},
					series: [
						{
							name: '',
							data: [],
							color: '#99cca8',
						},
					],
				},
				graphFilter: 'month',
				page: 1,
				pageSize: 10,
				isRequesting: false,
				orders: [],
				offset: 100,
				showFilter: false,
				sort: 'id:desc',
				totalAmount: 0,
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},

		methods: {
			changeGraph() {
				this.setIsPageLoading(true);
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						console.log(response);

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},

			devolution(id) {
				this.$router.push({ name: 'Devolution', params: { id: id } });
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[user][id]': this.getUser.id,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: this.sort,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},

			openFilter() {
				this.showFilter = !this.showFilter;
			},

			changeSort() {
				this.setIsPageLoading(true);
				this.page = 1;
				getOrders({
					'filters[user][id]': this.getUser.id,
					'pagination[page]': this.page,
					'pagination[pageSize]': this.pageSize,
					sort: this.sort,
				})
					.then((response) => {
						this.orders = response.data.data;
						console.log(this.orders);
						window.addEventListener('scroll', this.handleScroll);
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
						this.showFilter = false;
					});
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},
		},

		created() {
			this.setIsPageLoading(true);
			getOrders({
				'filters[user][id]': this.getUser.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					this.orders = response.data.data;

					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				});

			getUserLineGraph(this.graphFilter)
				.then((response) => {
					console.log(response);

					this.chartOptions.xAxis.categories = response.data.data.categories;
					this.chartOptions.series[0].data = response.data.data.data;

					this.chartOptions.series[0].data.map((order) => {
						this.totalAmount += order;
					});
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $primary !important;
	}

	.btn-sort {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
	}

	img,
	.no-img {
		width: 195px;
		height: 124px;
		border-radius: 22px;
		border: solid 1px $grey;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 125px;
		border: 1px solid $grey-light;
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;
	}

	.filter-window {
		width: 116px;
		height: 74px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 5px;
		z-index: 10;
		padding: 1px;
		background-color: $white;
		top: 40px;
	}
</style>
