<template>
	<section>
		<div class="header-container pt-8 px-6">
			<b-icon @click.native="updateStep" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon>
			<p class="header ml-2">Tell us about You</p>
		</div>

		<div class="main-categories px-6 mt-10">
			<b-checkbox-button class="checkbox-category" v-model="mainCategory" native-value="man" type="is-black">
				<span class="btn-text">Man</span>
			</b-checkbox-button>

			<b-collapse animation="slide" class="mt-8" :open="false" aria-id="contentIdForA11y1">
				<template #trigger="props">
					<a aria-controls="contentIdForA11y1">
						<span class="category-name">Clothing</span>
						<b-icon style="transform: translateY(4px)" :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
					</a>
				</template>
				<div>
					<div class="sub-categories-container mt-4">
						<b-checkbox-button v-for="(item, index) in 3" :key="index + 10" class="checkbox-sub-one mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
					<div class="sub-categories-container">
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 20" class="checkbox-sub-two mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 30" class="checkbox-sub-three mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
				</div>
			</b-collapse>

			<b-collapse animation="slide" class="mt-8" :open="false" aria-id="contentIdForA11y1">
				<template #trigger="props">
					<a aria-controls="contentIdForA11y1">
						<span class="category-name">Shoeware</span>
						<b-icon style="transform: translateY(4px)" :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
					</a>
				</template>
				<div>
					<div class="sub-categories-container mt-4">
						<b-checkbox-button v-for="(item, index) in 3" :key="index + 10" class="checkbox-sub-one mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
					<div class="sub-categories-container">
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 20" class="checkbox-sub-two mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 30" class="checkbox-sub-three mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
				</div>
			</b-collapse>

			<b-collapse animation="slide" class="mt-8" :open="false" aria-id="contentIdForA11y1">
				<template #trigger="props">
					<a aria-controls="contentIdForA11y1">
						<span class="category-name">Jevelary</span>
						<b-icon style="transform: translateY(4px)" :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
					</a>
				</template>
				<div>
					<div class="sub-categories-container mt-4">
						<b-checkbox-button v-for="(item, index) in 3" :key="index + 10" class="checkbox-sub-one mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
					<div class="sub-categories-container">
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 20" class="checkbox-sub-two mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 30" class="checkbox-sub-three mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
				</div>
			</b-collapse>

			<b-collapse animation="slide" class="mt-8" :open="false" aria-id="contentIdForA11y1">
				<template #trigger="props">
					<a aria-controls="contentIdForA11y1">
						<span class="category-name">Brands</span>
						<b-icon style="transform: translateY(4px)" :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
					</a>
				</template>
				<div>
					<div class="sub-categories-container mt-4">
						<b-checkbox-button v-for="(item, index) in 3" :key="index + 10" class="checkbox-sub-one mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
					<div class="sub-categories-container">
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 20" class="checkbox-sub-two mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
						<b-checkbox-button v-for="(item, index) in 2" :key="index + 30" class="checkbox-sub-three mt-3" v-model="mainCategory" native-value="man" type="is-primary">
							<span class="btn-text">Man</span>
						</b-checkbox-button>
					</div>
				</div>
			</b-collapse>
		</div>

		<div class="px-6">
			<div class="button-container has-text-centered mt-12 pb-8">
				<b-button type="is-primary" native-type="submit" class="btn" @click="finish">Finish</b-button>
			</div>
		</div>
	</section>
</template>

<script>
	import { updatePreferences } from '@/api/user';
	export default {
		data() {
			return {
				mainCategory: [],
			};
		},
		methods: {
			updateStep() {
				this.$store.commit('preferencesStep/setStep', 0);
			},

			finish() {
				let data = {
					/* info: {
						meals: this.getMeals,
						intolerances: this.getIntolerances,
						metrics: this.metric,
					}, */
					isRegistered: true,
				};
				updatePreferences(data)
					.then((response) => {
						console.log(response);
						this.updateStep();
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
		height: 100vh;
	}

	.main-categories {
		width: 100%;

		@include from($mobile-l) {
			width: 30%;
			margin: 0 auto;
		}
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	/deep/ .checkbox-category {
		width: 67px;
		border-radius: 6px;
	}

	.btn-text {
		font-size: 1rem;
	}

	/deep/ .checkbox-sub-one {
		width: 100px;
		@include until($mobile-s) {
			width: 80px;
		}
	}

	/deep/ .checkbox-sub-two {
		width: 80px;
		@include until($mobile-s) {
			width: 70px;
		}
	}

	/deep/ .checkbox-sub-three {
		width: 66px;
		@include until($mobile-s) {
			width: 56px;
		}
	}

	.sub-categories-container {
		display: flex;
		justify-content: space-between;
	}

	.category-name {
		font-size: 1rem;
		font-weight: bold;
	}

	.button-container {
		width: 100%;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	a {
		color: $black !important;
	}
</style>
