<template>
	<section class="section is-flex">
		<div class="container">
			<div class="image-container">
				<b-image class="img" :src="require('@/assets/svg/root-icons.svg')" alt="root"></b-image>
			</div>

			<div class="bottom-container px-6">
				<div class="slogan-container mb-3">
					<p>Pense grande, pense local.</p>
				</div>
				<p class="subtitle-container has-text-grey mb-14">A plataforma social para o comércio local.</p>
				<div class="button-container has-text-centered">
					<b-button type="is-primary" @click="goToPage('AccountType')" class="btn">{{ t.getStarted }}</b-button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.Auth`),
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	section {
		position: relative;
		height: 100vh;
	}

	.image-container {
		padding-top: 60px;
		@include until($mobile-l) {
			padding-top: 20px;
		}
	}

	.img {
		width: 100%;
		@include until($mobile-m) {
			width: 100%;
			margin: 0 auto;
		}

		@include from($mobile-l) {
			width: 30%;
			margin: 0 auto;
		}
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 40px;
	}

	.slogan-container {
		font-size: 1.7rem;
	}

	.subtitle-container {
		font-size: 1rem;
	}

	.button-container {
		width: 100%;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}
</style>
