<template>
	<section v-if="vouchers.length > 0">
		<div class="mb-2 mt-4 items-container center-inside" v-for="(voucher, index) in vouchers" :key="voucher.id">
			<img v-if="voucher.store.pictures" :src="voucher.store.pictures[0].url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-dark user-name">{{ voucher.title }}</p>
				<p class="has-text-dark user-name">{{ t.discount }} {{ voucher.discount }}%</p>
				<p class="has-text-dark user-name">{{ voucher.startAt | moment() }} - {{ voucher.endAt | moment() }}</p>
			</div>
			<div style="display: inline-flex">
				<b-button v-if="isApplied(voucher.startAt, voucher.endAt)" class="has-background-primary has-text-white btn mr-8">{{ t.applied }}</b-button>
				<b-button v-else class="has-background-danger has-text-white btn mr-8">{{ t.notApplied }}</b-button>
				<b-button class="has-text-white mr-8 trash-btn" @click="remove(voucher.id, index)"
					><b-icon class="is-clickable" pack="rt" icon="remove" size="is-size-5" type="is-red"></b-icon
				></b-button>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noVouchers }}</p>
	</section>
</template>

<script>
	import { getVouchers, deleteVoucher } from '@/api/marketing';
	import moment from 'moment';
	export default {
		props: ['sort'],
		data() {
			return {
				vouchers: [],
				page: 1,
				pageSize: 10,
				endScroll: false,
				t: this.$t(`components.ListVoucher`),
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},

		methods: {
			remove(id, index) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteVoucher,
					message: this.t.deleteMessage,
					confirmText: this.t.deleteVoucher,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;
						deleteVoucher(id)
							.then((response) => {
								console.log(response);
								this.$buefy.toast.open({
									message: this.t.deletedSucess,
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isLoading = false;
							});
						this.vouchers.splice(index, 1);
					},
				});
			},

			isApplied(startDate, endDate) {
				let date = moment().format('YYYY-MM-DD');

				return moment(date).isBetween(startDate, endDate, undefined, '[]');
			},

			handleScroll(e) {
				if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.endScroll == false) {
					this.endScroll = true;

					if (this.endScroll) {
						this.page++;

						getVouchers({
							'filters[store]': this.getUser.store.id,
							'pagination[page]': this.page,
							'pagination[pageSize]': this.pageSize,
							sort: this.sort,
						})
							.then((response) => {
								console.log(response);
								this.vouchers.push(...response.data.data);
								this.endScroll = false;
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.setIsPageLoading(false);
							});
					}
				}
			},
		},

		created() {
			this.setIsPageLoading(true);
			window.addEventListener('scroll', this.handleScroll);
			getVouchers({
				'filters[store]': this.getUser.store.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					this.vouchers = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 1rem;
	}

	.order-items,
	.user-name {
		font-size: 1.2rem;
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;
	}

	.trash-btn {
		border-radius: 5px;
		height: 43px;
		background-color: #eb57574d;
	}
</style>
