<template>
	<div>
		<div class="header-container">
			<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
			<p class="header ml-2">{{ t.profile }}</p>
		</div>

		<div class="mt-22">
			<div class="mt-4">
				<div class="photo-container mt-4">
					<b-upload v-model="img" class="center-inside" accept="image/*">
						<figure class="mx-auto image is-96x96 is-1by1 pt-0 has-radius" style="border: solid 1px black" :class="!getProfilePicture ? 'center-inside' : []">
							<b-icon v-if="!getProfilePicture && !img" class="has-radius" custom-size="is-size-2" icon="account-outline" type="is-black"> </b-icon>
							<img style="max-height: 95px; object-fit: cover" class="has-radius" ref="imageExif" v-else-if="img" :src="getFileURL" />
							<img v-else class="has-radius" style="max-height: 95px; object-fit: cover" :src="getProfilePicture" />
						</figure>
					</b-upload>
				</div>
			</div>
			<b-upload v-model="img" class="center-inside mt-2">
				<p style="font-weight: 500">{{ t.changePhoto }}</p>
			</b-upload>

			<form @submit.prevent="updateInfo">
				<b-field><b-input class="input mt-10" size="is-medium" name="name" type="text" required :placeholder="t.name" v-model.trim="userInfo.name"></b-input></b-field>
				<b-field><b-input class="input mt-2" size="is-medium" type="text" :placeholder="t.bio" v-model.trim="userInfo.description"></b-input></b-field>
				<b-field
					><b-input class="input mt-2" size="is-medium" name="street-address" type="text" :placeholder="t.address" v-model.trim="userInfo.location_address"></b-input
				></b-field>

				<b-field :type="zipCodeError ? 'is-danger' : ''" :message="zipCodeError ? 'Infelizmente ainda não estamos a enviar para a tua zona, estamos a trabalhar nisso' : ''"
					><b-input
						class="input mt-2"
						size="is-medium"
						pattern="\d{4}-\d{3}"
						name="zip_code"
						type="text"
						:placeholder="t.zip"
						v-model.trim="userInfo.location_zipCode"
					></b-input
				></b-field>
				<b-field>
					<b-autocomplete
						class="input mt-2"
						style="height: 42px"
						size="is-medium"
						rounded
						:data="filteredCities"
						:keep-first="true"
						:open-on-focus="true"
						field="city"
						:placeholder="t.city"
						icon="magnify"
						clearable
						ref="city"
						name="address-level2"
						v-model.trim="userInfo.location_municipality"
					>
						<template #empty>{{ t.noResults }}</template>
					</b-autocomplete>
				</b-field>

				<div class="small-input">
					<!-- <b-field
						><b-input
							class="input small mt-2 mr-2"
							size="is-medium"
							name="address-level1"
							type="text"
							:placeholder="t.country"
							v-model.trim="userInfo.location_country"
						></b-input
					></b-field> -->
					<b-field>
						<b-select class="mt-2" size="is-medium" :placeholder="t.country" v-model.trim="userInfo.location_country">
							<option value="Portugal">Portugal</option>
						</b-select></b-field
					>
					<b-field
						><b-input
							class="input small mt-2 ml-2"
							size="is-medium"
							type="number"
							:placeholder="t.nif"
							v-model.trim="userInfo.vat"
							v-validate="'numeric|length:9|'"
							name="vat"
							ref="vat"
						></b-input
					></b-field>
				</div>
				<p v-show="errors.has('vat')" class="help is-danger" style="position: absolute; right: 30px">{{ t.invalidVat }}</p>

				<span style="font-weight: 500">{{ t.gender }}</span>
				<div class="small-input">
					<b-field>
						<b-radio size="is-large" class="mt-6 mr-8" native-value="M" v-model="userInfo.gender"
							><span class="gender-text has-text-grey-dark">{{ t.male }}</span></b-radio
						>
					</b-field>
					<b-field>
						<b-radio size="is-large" class="mt-6" native-value="F" v-model="userInfo.gender"
							><span class="gender-text has-text-grey-dark">{{ t.female }}</span></b-radio
						>
					</b-field>
				</div>

				<b-field
					><b-input
						class="input mt-2"
						size="is-medium"
						name="phone"
						type="number"
						:placeholder="t.phone"
						v-model.trim="userInfo.telephone"
						v-validate="'mobile-phone|numeric'"
						ref="phone"
					></b-input
				></b-field>
				<p v-show="errors.has('phone')" class="help is-danger ml-4">{{ t.invalidPhone }}</p>

				<div class="button-container pb-20 has-text-centered">
					<b-button type="is-primary" :loading="isLoading" class="btn" native-type="submit">{{ t.save }}</b-button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { updatePreferences, uploadPhoto } from '@/api/user';

	const cities = require('@/assets/cities.json');
	export default {
		data() {
			return {
				t: this.$t(`components.Profile.EditUserProfile`),
				citiesList: [],
				isLoading: false,
				userInfo: {
					name: null,
					description: null,
					location_address: null,
					location_zipCode: null,
					location_municipality: '',
					location_country: null,
					picture: null,

					vat: null,
					gender: null,
					telephone: null,
				},
				imgReceived: null,
				img: null,
				zipCodeError: false,
			};
		},

		methods: {
			async updateInfo() {
				let zip = +this.userInfo.location_zipCode.split('-')[0];
				if (zip >= 9000 && zip <= 9980) {
					this.zipCodeError = true;
					return;
				} else {
					this.zipCodeError = false;
					this.$validator.validateAll().then(async (result) => {
						if (result) {
							this.isLoading = true;
							if (this.img) {
								await uploadPhoto(this.img)
									.then((response) => {
										console.log(response);
										this.userInfo.picture = response.data[0];
										updatePreferences(this.userInfo)
											.then((response) => {
												console.log(response);
												this.addUser(response.data);
												this.isLoading = false;
												this.$buefy.toast.open({
													message: this.t.updated,
													position: 'is-top',
													type: 'is-primary',
												});
												this.goToPage('UserProfile');
											})
											.catch((error) => {
												this.$buefy.toast.open({
													message: this.t.error,
													position: 'is-top',
													type: 'is-danger',
												});
												this.isLoading = false;
												console.log(error);
											});
									})
									.catch((error) => {
										this.isLoading = false;
										console.log(error);
									});
							} else {
								this.isLoading = true;
								updatePreferences(this.userInfo)
									.then((response) => {
										console.log(response);
										this.addUser(response.data);
										this.isLoading = false;
										this.$buefy.toast.open({
											message: this.t.updated,
											position: 'is-top',
											type: 'is-primary',
										});
										this.goToPage('UserProfile');
									})
									.catch((error) => {
										this.$buefy.toast.open({
											message: this.t.error,
											position: 'is-top',
											type: 'is-danger',
										});
										this.isLoading = false;
										console.log(error);
									});
							}
						}
					});
				}
			},
		},

		created() {
			this.citiesList = cities;
			this.userInfo.name = this.getUser.name;

			this.userInfo.location_address = this.getUser.location_address;
			this.userInfo.location_zipCode = this.getUser.location_zipCode;

			if (this.getUser.location_municipality) {
				this.userInfo.location_municipality = this.getUser.location_municipality;
			}

			this.userInfo.location_country = this.getUser.location_country;

			this.userInfo.description = this.getUser.description;

			if (this.getUser.picture) {
				this.userInfo.picture = this.getUser.picture;
			}

			if (this.getUser.vat) {
				this.userInfo.vat = this.getUser.vat;
			}

			if (this.getUser.gender) {
				this.userInfo.gender = this.getUser.gender;
			}
			if (this.getUser.telephone) {
				this.userInfo.telephone = this.getUser.telephone;
			}
			if (this.getUser.picture) {
				this.imgReceived = this.getUser.picture.url;
			}
		},

		computed: {
			getFileURL() {
				if (this.img) {
					return window.URL.createObjectURL(this.img);
				}
			},

			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.userInfo.location_municipality.toLowerCase()) >= 0;
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		border: solid 1px black;
	}

	.small-input {
		display: flex;
	}
	.small {
		width: 96%;
	}

	.gender-text {
		font-size: 1rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		@include from($mobile-s) {
			margin-top: 60px;
		}
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	/deep/ .mdi-magnify,
	/deep/ .mdi-close-circle {
		color: $grey-dark !important;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
	}
</style>
