import { get, post, put, remove } from '@/utils/http';

export function updatePreferences(data) {
	return put('/api/auth/me?populate=picture,favoriteCategories', data);
}

export function getLoggedUser() {
	return get('/api/users/me?populate=store.pictures,role,picture');
}

export function uploadPhoto(img) {
	console.log(img);
	let form = new FormData();
	form.append('files', img);

	return post('/api/upload', form);
}

export function deleteAccount() {
	return remove('/api/users/destroy');
}
