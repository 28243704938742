<template>
	<section v-if="!noProduct">
		<div class="header-container px-6 mt-10">
			<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
		</div>

		<div class="media center-inside mt-6 px-6">
			<div class="media-left" @click="storeDetails(productInfo.store.id)">
				<div class="photo-container mt-4">
					<figure
						class="image is-32x32 is-background-size-cover is-rounded pointer center-inside"
						:style="[
							productInfo.store.pictures ? { 'background-image': `url(${productInfo.store.pictures[0].url})` } : { background: '#FFF', border: 'solid 1px black' },
						]"
					>
						<b-icon v-if="!productInfo.store.pictures" icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
					</figure>
				</div>
			</div>
			<div class="media-content pt-4" @click="storeDetails(productInfo.store.id)">
				<p class="has-text-grey-darker is-size-6">{{ productInfo.store.name }}</p>
				<p class="is-size-6 has-text-grey">{{ productInfo.store.location_municipality }}</p>
			</div>
			<div class="media-right">
				<div v-if="productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true" class="sale has-text-white center-inside">
					{{ productInfo.productSales[0].discount }}% {{ t.off }}
				</div>
			</div>
		</div>
		<b-carousel class="mt-2" :autoplay="false" :overlay="isFullscrean" @click="isFullscrean = true">
			<b-carousel-item v-for="(image, i) in variantPictures" :key="i">
				<figure class="image" :class="!isFullscrean ? 'is-4by3' : 'is-4by5'">
					<img class="has-object-fit-contain" :class="!isFullscrean ? 'has-radius' : ''" :src="image.url" alt="" />
				</figure>
			</b-carousel-item>
			<span @click="isFullscrean = false" class="modal-close is-large" v-if="isFullscrean" />
		</b-carousel>

		<div class="card">
			<div class="px-10 mt-4" style="display: inline-flex">
				<b-icon
					v-if="!productsLiked.includes(productInfo.id) && getUserType == 'authenticated'"
					icon="heart-outline"
					@click.native="like(productInfo.id)"
					class="is-clickable"
					type="is-primary"
					custom-size="is-size-4"
				></b-icon>
				<b-icon
					v-else-if="getUserType == 'authenticated'"
					icon="heart"
					@click.native="unlike(productInfo.id)"
					class="is-clickable"
					type="is-primary"
					custom-size="is-size-4"
				>
				</b-icon>
				<b-icon v-else icon="heart-outline" type="is-primary" custom-size="is-size-4"></b-icon>
				<p class="counter ml-2">{{ productInfo.likes.length }}</p>
				<b-icon class="ml-4" icon="message-outline" type="is-primary" custom-size="is-size-4" @click.native="seeComments"> </b-icon>
				<p class="counter ml-2" @click="seeComments">{{ productInfo.comments.length }}</p>
				<b-icon
					class="ml-4 right is-clickable"
					v-if="!productsWished.includes(productInfo.id)"
					@click.native="wish(productInfo.id)"
					icon="bookmark-outline"
					type="is-primary"
					custom-size="is-size-4"
				>
				</b-icon>
				<b-icon class="ml-4 right is-clickable" v-else @click.native="deleteWish(productInfo.id)" icon="bookmark" type="is-primary" custom-size="is-size-4"> </b-icon>
			</div>
			<div class="px-10 mt-2">
				<p class="has-text-grey-darker is-size-4">{{ productInfo.title }}</p>
				<!-- <p class="has-text-grey is-size-5">{{ productInfo.brand.name }}</p> -->
				<div style="display: inline-flex" class="mt-1">
					<p class="subname has-text-grey">{{ productInfo.category.name }}</p>

					<div class="right" style="display: inline-flex">
						<p class="price has-text-primary mb-0" v-if="productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true">
							{{ calculateSale(productInfo.price, productInfo.productSales[0].discount) }} €
						</p>
						<p
							class="price has-text-primary ml-4 mb-0"
							:style="[
								productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true
									? { fontSize: '16px', textDecoration: 'line-through' }
									: { textDecoration: 'none' },
							]"
						>
							{{ productInfo.price.toFixed(2) }} €
						</p>
					</div>
				</div>
				<br />
				<div style="display: inline-flex" @click="seeReviews">
					<b-icon class="mt-2" pack="rt" icon="star" type="is-yellow" custom-size="is-size-6"> </b-icon>
					<p class="has-text-grey mt-2" v-if="reviews.length == 1">{{ productInfo.rating }} ({{ reviews.length }} {{ t.review }})</p>
					<p class="has-text-grey mt-2" v-else>{{ productInfo.rating }} ({{ reviews.length }} {{ t.reviews }})</p>
				</div>
				<!-- 	<p class="description mt-4">{{ t.description }}</p> -->
				<div class="description-text has-text-grey mt-4" id="description-div" style="line-height: 20px; white-space: pre-wrap" :class="{ 'hide-text': hideText }">
					{{ productInfo.description }}
				</div>

				<span class="is-clickable" v-if="hideText && totalLines > 2" @click="hideText = !hideText">{{ t.seeMore }}</span>
				<span class="is-clickable" v-if="!hideText && totalLines > 2" @click="hideText = !hideText">{{ t.seeLess }}</span>

				<br />
				<div class="is-flex pt-4">
					<div
						class="sizes mr-2 colors"
						@click="selectColor(variant.id, variant.color.hex, index)"
						:style="[
							variantSelected == index && variant.color.hex != null
								? { border: `solid 2px white`, backgroundColor: `${variant.color.hex}`, outline: `solid 2px ${variant.color.hex}` }
								: { border: `solid 1px grey`, backgroundColor: `${variant.color.hex}` },

							variantSelected == index && variant.color.hex === '#FFFFFF'
								? { border: `solid 2px grey`, backgroundColor: `${variant.color.hex}`, outline: `solid 2px black` }
								: '',
						]"
						v-for="(variant, index) in productInfo.productVariants"
						:key="variant.id"
					></div>
				</div>

				<br />
				<div style="display: inline-flex" class="mt-8">
					<p class="options">{{ t.sizes }}</p>
					<div class="sizes-container">
						<div
							v-for="(size, index) in sizes"
							:key="index"
							class="sizes center-inside"
							:style="sizeSelected === size.id ? `backgroundColor: #99cca8;color:white` : 'backgroundColor:white;'"
							@click="chooseSize(size.id)"
						>
							{{ size.size.name }}
						</div>
						<p class="has-text-primary is-size-6 mt-1" v-if="sizes.length == 0">{{ t.noStock }}</p>
					</div>
				</div>
			</div>

			<div v-if="productInfo.givesMoneyOnCard" class="offer-container center-inside mt-8">
				<p class="offer-message">{{ t.message }} {{ productInfo.moneyOnCardPercentage }}% {{ t.card }}</p>
			</div>

			<div class="px-6 btn-container mt-7" :style="sizes.length > 0 ? 'justify-content: space-evenly;' : 'justify-content: unset;'">
				<!-- <span class="cart-counter" v-if="getUserType == 'authenticated'">{{ getTotal }}</span>
				<b-button
					type="is-primary"
					:style="sizes.length > 0 ? 'margin-left:0' : 'margin-left:8px'"
					class="btn-add"
					@click="goToPage('Cart')"
					v-if="getUserType == 'authenticated'"
					><b-icon icon="cart" pack="rt" type="is-white" custom-size="is-size-5"></b-icon
				></b-button> -->
				<b-button type="is-primary" class="btn" @click="add" v-if="getUserType == 'authenticated' && getToken && sizes.length > 0">{{ t.add }}</b-button>
				<p v-else class="has-text-primary is-size-5" style="cursor: pointer" @click="goToPage('Home')">{{ t.registerNow }}</p>
			</div>
		</div>
	</section>
	<section v-else>
		<div class="content">
			<div class="header-container px-6 mt-10">
				<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
			</div>
			<div class="columns is-mobile is-vcentered" style="height: 90vh">
				<div class="column">
					<p class="has-text-centered is-size-2 has-text-primary">This product is not available</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import VSwatches from 'vue-swatches';
	import { getProductDetails, getReviews, addLike, getLikes, removeLike, addWhishlist, getWhishlist, removeWhishlist, updateProductViews } from '@/api/feed';

	export default {
		data() {
			return {
				imageIndex: 0,
				t: this.$t(`components.Products.ProductDetails`),
				hideText: false,
				swatches: [],
				color: null,
				sizeSelected: null,
				activeTab: 0,
				getPercentage: [],
				productInfo: [],
				sizes: [],
				reviewsRating: [],
				totalReviewers: 0,
				rating: null,
				comment: null,
				isLoading: false,
				ratingError: false,
				reviews: [],
				reviewsPage: 1,
				reviewsPageSize: 10,
				offset: 200,
				cart: null,
				variantPictures: [],
				variantSelected: null,
				productsLiked: [],
				productsWished: [],
				isAddingLike: false,
				isAddingWhishlist: false,
				noProduct: false,
				totalLines: 0,
				isFullscrean: false,
			};
		},
		components: {
			VSwatches,
		},
		methods: {
			chooseSize(id) {
				console.log(id);
				this.sizeSelected = id;
			},

			add() {
				if (this.getToken) {
					let variantId = null;
					if (this.cart.cartProducts.length > 0) {
						if (this.productInfo.store.id == this.cart.cartProducts[0].product.store.id) {
							this.productInfo.productVariants.map((variant) => {
								if (variant.color.hex == this.color) {
									variantId = variant.id;
								}
							});

							if (this.sizeSelected && variantId) {
								this.addCartProduct(this.$route.params.id, variantId, this.sizeSelected, 1);
								this.$buefy.toast.open({
									message: this.t.addToCart,
									position: 'is-top',
									type: 'is-primary',
									duration: 2000,
								});
							} else {
								this.$buefy.toast.open({
									message: this.t.cartErrorMessage,
									position: 'is-top',
									type: 'is-danger',
									duration: 2000,
								});
							}
						} else {
							this.$buefy.toast.open({
								message: this.t.errorStore,
								position: 'is-top',
								type: 'is-danger',
								duration: 2000,
							});
						}
					} else {
						this.productInfo.productVariants.map((variant) => {
							if (variant.color.hex == this.color) {
								variantId = variant.id;
							}
						});

						if (this.sizeSelected && variantId) {
							this.addCartProduct(this.$route.params.id, variantId, this.sizeSelected, 1);
							this.$buefy.toast.open({
								message: this.t.addToCart,
								position: 'is-top',
								type: 'is-primary',
								duration: 2000,
							});
						} else {
							this.$buefy.toast.open({
								message: this.t.cartErrorMessage,
								position: 'is-top',
								type: 'is-danger',
								duration: 2000,
							});
						}
					}
				} else this.goToPage('Home');
			},

			storeDetails(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			seeReviews() {
				this.$router.push({
					name: 'ProductReview',
					params: { rating: this.productInfo.rating, reviews: this.productInfo.reviews, image: this.productInfo.pictures[0].url, id: this.$route.params.id },
				});
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			seeComments() {
				this.$router.push({
					name: 'ProductComment',
					params: { product: this.productInfo },
				});
			},

			selectColor(id, color, index) {
				this.color = color;
				this.sizes = [];
				this.sizeSelected = null;

				this.productInfo.productVariants.map((variant) => {
					console.log(variant);
					if (variant.id == id) {
						variant.productSizes.map((sizes) => {
							if (sizes.stockNumber > 0) {
								this.sizes.push(sizes);
							}
						});
						this.variantPictures = variant.pictures;
						this.variantSelected = index;
						this.color = variant.color.hex;
					}
				});
			},

			like(id) {
				if (this.getToken) {
					this.productsLiked.push(id);
					this.productInfo.likes.push({});
					let like = {
						data: {
							product: id,
						},
					};

					console.log(like);
					if (!this.isAddingLike) {
						this.isAddingLike = true;

						addLike(like)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			unlike(id) {
				if (this.getToken) {
					this.productsLiked.splice(this.productsLiked.findIndex((element) => element == id));
					this.productInfo.likes.splice(0, 1);

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						removeLike(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			wish(id) {
				if (this.getToken && this.getUserType == 'authenticated') {
					this.productsWished.push(id);
					let whish = {
						data: {
							product: id,
						},
					};
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						addWhishlist(whish)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			deleteWish(id) {
				if (this.getToken && this.getUserType == 'authenticated') {
					this.productsWished.splice(this.productsWished.findIndex((element) => element == id));
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						removeWhishlist(id)
							.then((response) => {})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			countLines() {
				var el = document.getElementById('description-div');

				var divHeight = el.offsetHeight;
				var lineHeight = parseInt(el.style.lineHeight);
				var lines = divHeight / lineHeight;
				if (lines > 2) {
					this.totalLines = lines;
					this.hideText = true;
				}
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		created() {
			this.setIsPageLoading(true);
			console.log(this.getResetFilter);

			if (this.getUserType == 'authenticated' && this.getToken) {
				this.getCartFromAPI(this.getUser.id).then(() => {
					this.cart = this.getCart;
				});

				getWhishlist({
					'filters[product][id]': this.$route.params.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsWished = response.data.data.map((whish) => {
							return whish.product.id;
						});
					})
					.catch((error) => {
						console.log(error);
					});
			}

			if (this.getUserType != 'store') {
				updateProductViews(this.$route.params.id);
			}

			if (this.getToken) {
				getLikes({
					'filters[product][id]': this.$route.params.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsLiked = response.data.data.map((like) => {
							return like.product.id;
						});
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: 'Ocorreu um erro',
							position: 'is-top',
							type: 'is-danger',
						});
					});
			}

			getReviews(this.$route.params.id, this.reviewsPage, this.reviewsPageSize)
				.then((response) => {
					this.reviews = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getProductDetails(this.$route.params.id)
				.then((response) => {
					console.log(response);
					this.productInfo = response.data.data;
					console.log(this.productInfo);
					this.productInfo.productVariants.map((variant, index) => {
						console.log(variant);
						if (index == 0) {
							variant.productSizes.map((sizes) => {
								if (sizes.stockNumber > 0) {
									this.sizes.push(sizes);
								}
							});

							this.variantPictures = variant.pictures;
							this.variantSelected = index;
							this.color = variant.color.hex;
						}
					});
				})
				.catch((error) => {
					if (error.response.data.error.message == 'Not Found') {
						this.noProduct = true;
					}
				})
				.finally(() => {
					this.setIsPageLoading(false);
					this.countLines();
				});

			if (this.getResetFilter) {
				this.goToPage('Filter');
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: $primary;
	}

	.header-container {
		display: inline-flex;
	}

	.sale {
		width: 86px;
		height: 37px;
		background-color: $primary;
		border-radius: 30px;
		font-size: 0.8rem;
	}

	.register-steps > div {
		width: 8px;
		height: 8px;
		background: white;
		border: solid 1px $primary;
		border-radius: 50%;
		margin: 15px;
	}
	.register-steps > .step-1 {
		background: $primary;
	}

	.recipe-steps {
		margin: 8px !important;
	}

	.card {
		width: 100%;
		border: 1px solid $white-ter;
		border-radius: 24px 24px 0px 0px;
		box-shadow: none;
		border-bottom: none;
	}

	.counter {
		color: $grey;
		font-size: 0.9rem;
	}

	.right {
		position: absolute;
		right: 32px;
	}

	.price {
		transform: translateY(-5px);
		font-size: 1.3rem;
	}

	.description {
		font-size: 1.3rem;
	}

	.description-text {
		display: -webkit-box;

		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 10px;
	}

	.hide-text {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		overflow: hidden;
		height: 2.8rem;
	}

	/deep/ .vue-swatches__wrapper {
		display: inline-flex !important;
		position: absolute;
		right: 20px;
	}

	/deep/ .check {
		background-color: white;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		font-size: 10px;
		transform: translate(8px, 6px);
	}

	.options {
		font-size: 1.2rem;
	}

	.sizes {
		min-width: 30px;
		height: 30px;
		border-radius: 8px;
		margin-left: 8px;
		text-align: center;
		color: black;
		border: 1px solid $grey;
		width: auto;
		padding-left: 5px;
		padding-right: 5px;
	}

	.sizes-container {
		position: absolute;
		right: 20px;
		display: inline-flex;
	}

	.offer-message {
		font-size: 0.9rem;
		color: $dark;
		opacity: 0.7;
	}

	.offer-container {
		background: transparent linear-gradient(90deg, #99cca800 0%, #99cca890 16%, #99cca8 51%, #99cca8a2 79%, #4d665400 100%) 0% 0% no-repeat padding-box;
		height: 42px;
		width: 100%;
	}

	.btn-container {
		display: flex;
		justify-content: space-evenly;
		padding-bottom: 30px;
	}

	.btn {
		width: 70%;
		height: 52px;
		border-radius: 14px;
	}
	.btn-add {
		width: 23%;
		height: 52px;
		border-radius: 14px;

		@include from($mobile-l) {
			width: 100px !important;
		}
	}

	.cart-counter {
		background-color: white;
		height: 17px;
		width: 16px;
		position: absolute;
		left: 80px;
		z-index: 4;
		border-radius: 50%;
		text-align: center;
		font-size: 11px;
		transform: translateY(3px);
		color: $primary;

		@include until($mobile-s) {
			left: 68px;
		}
		@include from(411px) {
			left: 96px;
		}
		@include from($mobile-l) {
			left: 170px;
		}
		@include from(1024px) {
			left: 260px;
		}
	}
</style>
