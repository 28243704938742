<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileMarketing/>
		</div>
		<div class="container" v-else>
			<DesktopMarketing />
		</div>
	</section>
</template>

<script>
	import DesktopMarketing from '@/components/Desktop/Marketing/Marketing.vue';
	import MobileMarketing from '@/components/Marketing/Marketing.vue'
	
	export default {
		components: {
			DesktopMarketing,
			MobileMarketing
		},

		data() {
			return {};
		},
	};
</script>