<template>
	<section>
		<div class="header-container">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-dark" size="is-medium"></b-icon>
			<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.details }}</p>
		</div>

		<b-upload
			v-model="file"
			accept="application/pdf"
			:native="true"
			class="file-label header-btn center-inside mt-4"
			v-if="getUserType == 'store' && order.orderStatus.id == 9"
		>
			<b-icon class="mr-2" icon="upload" size="is-size-6" type="is-black"></b-icon>{{ t.upload }}
		</b-upload>
		<p v-if="file" class="has-text-primary mt-2">{{ file.name }}</p>

		<div class="mb-2 mt-10 items-container center-inside">
			<img v-if="order.user.picture" :src="order.user.picture.url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-dark is-size-6">{{ order.user.name }}</p>
				<p class="has-text-dark is-size-7">ID {{ order.id }}</p>
				<p class="has-text-dark is-size-6">{{ getItems(order) }} Item(s)</p>
			</div>
			<div style="display: inline-flex">
				<p class="is-size-6 mr-10 mt-2">{{ (order.amount / 100).toFixed(2) }} €</p>
			</div>
		</div>

		<div v-if="getUserType == 'store'">
			<div class="level">
				<div class="level-item" style="display: unset">
					<div class="box mt-4">
						<p class="has-text-centered is-size-5">{{ t.refundSummary }}</p>
						<div class="level is-mobile mb-0 mt-3">
							<div class="level-left">
								<p class="level-item is-size-6">{{ t.order }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-6">{{ (order.amount / 100 + order.discount).toFixed(2) }} €</p>
							</div>
						</div>
						<div class="level is-mobile mb-0" v-if="order.discount > 0">
							<div class="level-left">
								<p class="level-item is-size-6">{{ t.discount }}</p>
							</div>
							<div class="level-right">
								<p class="level-item is-size-6">
									- {{ order.discount }} € ({{ calculateDiscount(order.amount / 100 + order.discount - order.shipping / 100, order.discount) }}%)
								</p>
							</div>
						</div>
						<div class="level is-mobile mb-0">
							<div class="level-left">
								<p class="level-item is-size-6">{{ t.shippingFees }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-6">- {{ order.shipping / 100 }} €</p>
							</div>
						</div>
						<div class="level is-mobile mb-0 mt-6">
							<div class="level-left">
								<p class="level-item is-size-6">{{ t.total }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-6">{{ getValueToRefund() }} €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-8" v-for="(detail, index) in order.products.data" :key="detail.id">
			<div
				class="product-container"
				:class="[detail.motive && getUserType == 'store' ? 'highlighted-div' : 'transparent-div', getUserType == 'authenticated' ? 'opaque-div' : '']"
			>
				<!-- 	<div class="product-quantity center-inside mr-3" v-if="getUserType == 'store'">{{ index + 1 }}</div> -->
				<div class="product-detail">
					<div style="margin: auto; display: flex">
						<img class="product-image" :style="detail.motive || getUserType == 'authenticated' ? 'opacity:1' : 'opacity:0.4'" :src="detail.product.pictures[0].url" />
						<div class="ml-1" style="display: table; margin: auto">
							<div style="display: table-cell; vertical-align: middle">
								<p class="has-text-grey is-size-7" style="width: 120px" v-if="getUserType == 'authenticated'">{{ detail.product.title }}</p>
								<p class="has-text-grey-darker is-size-7" style="width: 90px" v-if="getUserType == 'store'">{{ detail.product.title }}</p>

								<div style="display: inline-flex" v-if="getUserType == 'authenticated'">
									<p class="has-text-dark is-size-7 mr-2" style="width: 30px">
										{{ detail.quantity }} x {{ getVariantSize(detail.variant, detail.product.productVariants, detail.size) }}
									</p>
									<p class="has-text-dark is-size-7">{{ getVariantColorName(detail.variant, detail.product.productVariants, detail.size) }}</p>
								</div>
								<p class="has-text-grey is-size-7">{{ detail.price }} €</p>
								<p class="has-text-grey is-size-7" v-if="order.discount > 0">{{ calculateProductDiscount(detail) }} € {{ t.valueWithDiscount }}</p>
							</div>
						</div>
						<div style="display: table" class="mr-4">
							<div style="display: table-cell; vertical-align: middle">
								<div style="display: inline-flex" v-if="getUserType == 'authenticated'">
									<b-field class="mr-2 select-container mt-2" v-show="devolutionArray[index] == true">
										<b-select
											v-validate="devolutionArray[index] == true ? 'required' : ''"
											:data-vv-name="'reason' + index"
											:placeholder="t.returningReason"
											v-model="reasonArray[index]"
											size="is-default"
										>
											<option :value="motive.id" v-for="motive in motives" :key="motive.id">{{ motive.description }}</option>
										</b-select>
									</b-field>

									<b-field class="mt-3">
										<b-checkbox v-model="devolutionArray[index]"> </b-checkbox>
									</b-field>
								</div>
								<p v-show="errors.has('reason' + index)" class="help is-danger mr-4">{{ t.selectReason }}</p>

								<div class="Components-Cart-CartLine-Desktop mb-2" v-if="getUserType == 'authenticated' && devolutionArray[index] == true">
									<div class="is-flex is-align-self-flex-end">
										<b-numberinput
											custom-class="has-background-white"
											:step="1"
											class="is-size-5"
											controls-position="compact"
											min="1"
											:editable="false"
											v-model="returnNumber[index]"
											:max="detail.quantity"
										></b-numberinput>
									</div>
								</div>

								<div v-if="getUserType == 'store'">
									<p class="has-text-dark is-size-6 mr-2" style="width: 45px">
										{{ detail.quantity }} x {{ getVariantSize(detail.variant, detail.product.productVariants, detail.size) }}
									</p>
									<div
										class="size-color center-inside"
										:style="{ backgroundColor: getVariantColor(detail.variant, detail.product.productVariants, detail.size) }"
										:class="getVariantColorName(detail.variant, detail.product.productVariants, detail.size) == 'Branco' ? 'has-text-black' : 'has-text-white'"
									>
										{{ getVariantColorName(detail.variant, detail.product.productVariants, detail.size) }}
									</div>

									<p class="has-text-dark is-size-7 mr-2" style="width: 62px" v-if="getUserType == 'store' && detail.return_quantity > 0">
										{{ t.returnedProducts }}{{ detail.return_quantity }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p class="has-text-grey has-text-centered mt-10" v-if="getUserType == 'store' && detail.returned == true">{{ t.motive }} {{ detail.motive.description }}</p>
		</div>

		<b-checkbox class="mt-10" name="confirm" v-validate="'required:true'" v-model="checkConfirm" v-if="getUserType == 'authenticated'"
			>{{ t.confirm }} <br />
			{{ t.confirmTwo }}</b-checkbox
		>
		<p v-show="errors.has('confirm')" class="help is-danger is-size-6" v-if="getUserType == 'authenticated'">
			{{ t.confirmError }}
		</p>

		<div class="button-container mt-10">
			<b-button
				class="is-fullwidth is-medium"
				type="is-primary"
				:loading="isLoading"
				@click="confirm"
				v-if="getUserType == 'authenticated'"
				:disabled="devolutionArray.includes(true) ? false : true"
				>{{ t.next }}</b-button
			>
			<b-button class="is-fullwidth is-medium" type="is-primary" :loading="isLoading" @click="storeConfirm" v-if="getUserType == 'store' && order.orderStatus.id == 9">{{
				t.refund
			}}</b-button>
			<b-button
				class="is-fullwidth is-medium mt-4"
				type="is-danger"
				:loading="isLoading"
				@click="rejectReasonsModal = true"
				v-if="getUserType == 'store' && order.orderStatus.id == 9"
				>{{ t.reject }}</b-button
			>
		</div>

		<RejectModal v-if="getUserType == 'store'" :reasonsModal="rejectReasonsModal" @close="closeModal" @setMotive="setMotive" />
	</section>
</template>

<script>
	import { getOrderById, devoltuionMotives, userReturnProduct, storeConfirmRefund, rejectDevolution, uploadImage } from '@/api/orders.js';
	import RejectModal from '@/components/Desktop/Orders/RejectModal.vue';
	export default {
		components: {
			RejectModal,
		},
		data() {
			return {
				order: [],
				devolutionArray: [],
				isLoading: false,
				reasonArray: [],
				motives: [],
				t: this.$t(`components.DevolutionDetails`),
				checkConfirm: null,
				rejectReasonsModal: false,
				file: null,
				returnNumber: [],
			};
		},

		methods: {
			getVariantSize(id, variants, size) {
				let variant = variants.filter((variant) => variant.id == id);

				let finalSize = variant[0].productSizes.filter((productSize) => productSize.id == size);

				return finalSize[0].size.name;
			},

			getVariantColorName(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.name;
			},

			getVariantColor(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.hex;
			},

			confirm() {
				this.isLoading = true;
				this.$validator.validateAll().then((result) => {
					if (result) {
						console.log(this.devolutionArray);
						console.log(this.reasonArray);

						let data = {
							products: [],
						};

						for (let index = 0; index < this.devolutionArray.length; index++) {
							console.log(this.devolutionArray[index]);
							if (this.devolutionArray[index] == true) {
								if (this.reasonArray[index] == null) {
									this.$buefy.toast.open({
										message: this.t.reason,
										position: 'is-top',
										type: 'is-danger',
									});
									this.isLoading = false;
									break;
								} else {
									data.products.push({
										id: this.order.products.data[index].product.id,
										motive: this.reasonArray[index],
										return_quantity: this.returnNumber[index],
										variant: this.order.products.data[index].variant,
										size: this.order.products.data[index].size,
									});
								}
							}
						}

						console.log(data);
						userReturnProduct(this.order.id, data)
							.then((response) => {
								console.log(response);
								this.$buefy.toast.open({
									message: this.t.request,
									position: 'is-top',
									type: 'is-primary',
								});
								this.goToPage('UserProfile');
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isLoading = false;
							});
					} else this.isLoading = false;
				});
			},

			async storeConfirm() {
				if (!this.file) {
					this.$buefy.toast.open({
						message: this.t.verifyUpload,
						position: 'is-top',
						type: 'is-danger',
					});
				} else {
					this.isLoading = true;

					let data = {
						data: {
							credit_note: null,
						},
					};

					await uploadImage(this.file).then((response) => {
						console.log(response);
						data.data.credit_note = response.data[0].id;
					});

					await storeConfirmRefund(this.order.id, data)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.refundConfirmed,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('StoreOrders');
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},

			closeModal() {
				this.rejectReasonsModal = false;
			},

			setMotive(val) {
				console.log(val);
				let data = {
					motive: val,
				};
				this.rejectReasonsModal = false;
				this.isLoading = true;
				rejectDevolution(this.$route.params.id, data)
					.then((response) => {
						console.log(response);
						this.$buefy.toast.open({
							message: this.t.refundRejected,
							position: 'is-top',
							type: 'is-primary',
						});
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: this.t.error,
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.isLoading = false;
						this.goToPage('StoreOrders');
					});
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			getValueToRefund() {
				let value = 0;
				console.log(this.order.products.data);
				if (this.order.coupon == true || this.order.discount > 0) {
					let percentageDiscount = 0;
					let totalAmmount = (this.order.amount / 100 + this.order.discount - this.order.shipping / 100).toFixed(2);
					console.log(totalAmmount);
					percentageDiscount = (this.order.discount * 100) / totalAmmount;
					console.log(percentageDiscount / 100);

					this.order.products.data.map((product) => {
						if (product.motive) {
							let valueToSubtract = product.price * product.return_quantity * (percentageDiscount / 100);
							value += product.price * product.return_quantity - valueToSubtract;
						}
					});
				} else
					this.order.products.data.map((product) => {
						if (product.motive) {
							value += product.price * product.return_quantity;
						}
					});

				return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
			},

			calculateDiscount(originalPrice, discountValue) {
				let salePrice = originalPrice - discountValue;

				let discount = ((originalPrice - salePrice) / originalPrice) * 100;

				if (Number.isInteger(discount)) {
					return discount;
				} else return discount.toFixed(2);
			},

			calculateProductDiscount(product) {
				let value = 0;
				let percentageDiscount = 0;
				let totalAmmount = (this.order.amount / 100 + this.order.discount - this.order.shipping / 100).toFixed(2);
				percentageDiscount = (this.order.discount * 100) / totalAmmount;
				let valueToSubtract = product.price * (percentageDiscount / 100);
				value += product.price - valueToSubtract;

				return value.toFixed(2);
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		created() {
			this.setIsPageLoading(true);

			devoltuionMotives()
				.then((response) => {
					console.log(response);
					this.motives = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getOrderById(this.$route.params.id)
				.then((response) => {
					this.order = response.data.data;
					console.log(this.order);
					console.log(this.order.products.data);

					for (let index = 0; index < this.order.products.data.length; index++) {
						this.devolutionArray.push(false);
						this.reasonArray.push(null);
						this.returnNumber.push(1);
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	img,
	.no-img {
		width: 80px;
		height: 80px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 80px;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.btn {
		width: 422px;
		height: 54px;
		display: flex;
		margin: 0 auto;
	}

	.product-container {
		display: flex;
	}

	.product-quantity {
		background-color: $primary;
		border-radius: 8px;
		width: 69px;
		height: 88px;
		color: white;
		font-size: 2rem;

		@include until($mobile-s) {
			width: 40px;
		}
	}

	.product-detail {
		box-shadow: 0px 2px 48px #0000000a;
		width: 100%;
		min-height: 100px;
		height: auto;
		border-radius: 20px;
	}

	.product-image {
		min-height: 100px;
		height: auto;
		border-radius: 22px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: 70px;
			min-height: 100px;
			height: auto;
		}
	}

	.size-color {
		border: solid 1px grey;
		min-width: 65px;
		height: 25px;
		border-radius: 5px;
		text-align: center;
		font-size: 0.7rem;
	}

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
		text-align: center;
		font-size: 0.8rem;
		padding: 1rem;
	}

	.select-container {
		max-width: 100px;

		@include until($mobile-s) {
			max-width: 70px;
		}

		@include from($mobile-l) {
			max-width: 300px;
		}
	}

	.b-checkbox.checkbox {
		align-items: start;
	}

	.transparent-div {
		background-color: #efefef;
		opacity: 0.6;
		border-radius: 20px;
	}

	.opaque-div {
		background-color: white;
		opacity: 1;
		border-radius: 20px;
	}

	.highlighted-div {
		border: $primary solid 2px;
		border-radius: 20px;
	}

	.Components-Cart-CartLine-Desktop .b-numberinput {
		height: 36px !important;
		max-width: 107px !important;

		@include until($mobile-s) {
			max-width: 89px !important;
		}
	}

	/deep/ .Components-Cart-CartLine-Desktop .b-numberinput input {
		height: 32px !important;
	}

	/* .button-container {
		position: fixed;
		bottom: 30px;
		width: 88%;
		padding-top: 60px;
	} */
</style>
