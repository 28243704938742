<template>
	<section class="section">
		<div class="container">
			<div class="media center-inside mb-6">
				<div class="media-left">
					<p class="dashboard has-text-grey-dark">{{ t.myStore }}</p>
					<p class="has-text-grey-dark">{{ totalProducts }} Items</p>
				</div>
				<div class="media-content pt-4"></div>
				<div class="media-right">
					<b-button @click="goToPage('DesktopAddProduct')" class="btn">
						<b-icon pack="rt" class="mr-2" icon="plus" size="is-size-6" type="is-black"></b-icon>{{ t.add }}</b-button
					>
					<b-button class="btn ml-4" @click="showFilter = !showFilter">
						<b-icon pack="rt" class="mr-2" icon="sort" size="is-size-6" type="is-black"></b-icon>{{ t.sort }}</b-button
					>
				</div>
			</div>
			<div v-show="showFilter" class="filter-window mt-2 px-6 py-2">
				<p class="is-size-6">{{ t.orderBy }}</p>
				<b-field class="mt-3">
					<b-radio native-value="createdAt:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="price:desc" @input="changeSort" v-model="sort">{{ t.priceHigh }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="price:asc" @input="changeSort" v-model="sort">{{ t.priceLow }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="createdAt:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
				</b-field>
			</div>

			<div>
				<div class="columns is-desktop is-multiline">
					<div v-for="(product, index) in storeProducts" :key="index" class="column is-one-fifth-widescreen is-one-quarter-desktop">
						<figure class="image is-4by3 is-clickable" @click="editProduct(product.id)">
							<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
								>{{ product.productSales[0].discount }}% {{ t.off }}</span
							>
							<img class="has-object-fit-contain has-radius" :src="product.pictures[0].url" alt="" />
						</figure>

						<div class="media">
							<div class="media-left" style="max-width: 170px; min-width: 170px">
								<p class="product-name mt-2">{{ product.title }}</p>
								<div class="is-flex">
									<p
										:style="[
											product.productSales.length > 0 && product.productSales[0].isActive == true
												? { textDecoration: 'line-through' }
												: { textDecoration: 'none', fontWeight: 'bold' },
										]"
									>
										{{ product.price.toFixed(2) }} €
									</p>
									<p class="ml-4 has-text-weight-bold" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
										{{ calculateSale(product.price, product.productSales[0].discount) }} €
									</p>
								</div>
							</div>
							<div class="media-content"></div>
							<div class="media-right ml-0">
								<p style="cursor: pointer" @click="editProduct(product.id)" class="product-name mt-2 mr-4">{{ t.edit }}</p>
								<b-icon class="is-clickable mt-1" @click.native="removeProduct(product.id, index)" pack="rt" icon="remove" size="is-size-5" type="is-red"></b-icon>
							</div>
						</div>
						<p class="has-text-danger" v-if="!product.isActive">{{ t.inactive }}</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getProducts, deleteProduct } from '@/api/store';
	export default {
		components: {},

		data() {
			return {
				t: this.$t(`components.Desktop.Store.StoreProducts`),
				storeProducts: [],
				showMenu: false,
				page: 1,
				totalProducts: 0,
				pageItems: 15,
				endScroll: false,
				showFilter: false,
				sort: 'createdAt:desc',
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			editProduct(id) {
				this.$router.push({ name: 'DesktopEditProduct', params: { id: id } });
			},

			removeProduct(id, index) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteProduct,
					message: this.t.deleteMessage,
					confirmText: this.t.deleteProduct,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						deleteProduct(id);
						this.$buefy.toast.open({
							message: this.t.deletedSuccess,
							position: 'is-top',
							type: 'is-primary',
						});
						this.storeProducts.splice(index, 1);
					},
				});
			},

			handleScroll(e) {
				if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.endScroll == false) {
					this.endScroll = true;

					if (this.endScroll) {
						this.page++;

						getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
							.then((response) => {
								console.log(response);
								this.storeProducts.push(...response.data.data);
								this.endScroll = false;
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: this.$t('shared.error'),
									position: 'is-top',
									type: 'is-danger',
								});
							});
					}
				}
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			changeSort() {
				this.setIsPageLoading(true);
				this.page = 1;
				getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
					.then((response) => {
						console.log(response);
						this.storeProducts = response.data.data;
						this.totalProducts = response.data.meta.pagination.total;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: this.$t('shared.error'),
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},
		},

		created() {
			window.addEventListener('scroll', this.handleScroll);
			this.setIsPageLoading(true);
			getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
				.then((response) => {
					console.log(response);
					this.storeProducts = response.data.data;
					this.totalProducts = response.data.meta.pagination.total;
				})
				.catch((error) => {
					console.log(error);
					this.$buefy.toast.open({
						message: this.$t('shared.error'),
						position: 'is-top',
						type: 'is-danger',
					});
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.main-container {
		padding-left: 140px;
		padding-right: 140px;
		padding-bottom: 150px;
	}

	.dashboard {
		font-size: 2.5rem;
	}

	.dashboard-icon {
		width: 50px;
		height: 50px;
		border-radius: 5px;
	}

	.card {
		display: flex;
		border-radius: 20px;
		border: 1px solid $grey-light;
		box-shadow: 0px 2px 48px #0000000b;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.btn {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
	}

	img {
		border: 1px solid $grey-light;
	}

	.tag {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 2;
		width: 80px;
		height: 32px;
		background-color: #99cca8;
		color: #fff;
		border-radius: 50px;
	}

	.product-name {
		font-size: 0.858rem;
		text-align: left;
		font-weight: 300;
	}

	.price {
		font-size: 1rem;
		font-weight: 600;
		text-align: left;
	}

	.columns {
		justify-content: unset !important;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 31;
	}

	.filter-window {
		width: 200px;
		height: auto;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 0;
		z-index: 10;
		padding: 1px;
		background-color: #ffffff;
		top: 60px;
	}
</style>
