<template>
	<div v-if="IS_MOBILE" class="mobile" style="overflow-x: hidden">
		<div @mouseover="actionShown = 'all'" @mouseleave="actionShown = null" class="is-flex is-justify-content-space-between is-flex-direction-row is-align-items-center mb-4">
			<div
				:class="showQuantity ? 'slide-in-left' : 'slide-out-left'"
				class="btn-quantity has-radius is-flex is-flex-direction-column is-justify-content-space-between has-background-primary mr-4"
			>
				<b-button @click="changeQuantity('plus')" :disabled="cartProduct.quantity == sizes[0].stockNumber ? true : false" type="is-primary"
					><b-icon type="is-white" icon="plus"></b-icon
				></b-button>
				<span class="has-text-centered has-text-white is-size-4">{{ cartProduct.quantity }}</span>
				<b-button @click="changeQuantity('minus')" type="is-primary"><b-icon type="is-white" icon="minus"></b-icon></b-button>
			</div>

			<article @click="actionShown = null" v-touch:swipe.left="onSwipe" v-touch:swipe.right="onSwipe" class="media has-background-white has-radius">
				<figure
					class="media-left image is-96x96 is-background-size-cover"
					style="background-position-y: center"
					:style="{ 'background-image': 'url(' + cartProduct.product.pictures[0].url + ')' }"
				></figure>
				<div class="media-content is-align-self-center">
					<h3 class="is-size-6 has-text-grey-darker">{{ cartProduct.product.title }}</h3>
					<h4 class="is-size-7 has-text-grey" v-if="cartProduct.product.productSales[0].isActive == false">{{ cartProduct.product.price.toFixed(2) }}€</h4>
					<h4 class="is-size-7 has-text-grey" v-else>{{ calculateSale(cartProduct.product.price, cartProduct.product.productSales[0].discount) }}€</h4>
					<h4 class="is-size-7 has-text-grey">Size: {{ sizes[0].size.name }}</h4>
					<h4 class="is-size-7 has-text-grey">Color: {{ variants[0].color.name }}</h4>
				</div>
			</article>

			<b-button :class="showRemove ? 'slide-in-right' : 'slide-out-right'" @click="removeCartProduct" class="btn-remove ml-4" type="is-red-light"
				><b-icon type="is-red" icon="remove" pack="rt"></b-icon
			></b-button>
		</div>
	</div>
	<div v-else class="desktop Components-Cart-CartLine-Desktop">
		<div class="is-flex is-justify-content-space-between is-flex-direction-row is-align-items-center mb-4">
			<article class="media has-background-white has-radius py-8 pr-20 pl-8">
				<b-checkbox v-model="cartProduct.selected" size="is-medium" class="is-align-self-center"></b-checkbox>
				<figure
					class="media-left image is-128x128 is-background-size-cover has-radius"
					:style="{ 'background-image': 'url(' + cartProduct.product.pictures[0].url + ')' }"
					style="background-position-y: center"
				></figure>
				<div class="media-content is-align-self-center">
					<h3 class="is-size-4 has-text-dark has-text-weight-bold">{{ cartProduct.product.title }}</h3>
					<h4 class="is-size-5 has-text-grey" v-if="cartProduct.product.productSales[0].isActive == false">{{ cartProduct.product.price.toFixed(2) }}€</h4>
					<h4 class="is-size-5 has-text-grey" v-else>{{ calculateSale(cartProduct.product.price, cartProduct.product.productSales[0].discount) }}€</h4>

					<h4 class="is-size-5 has-text-grey">Size: {{ sizes[0].size.name }}</h4>

					<div style="display: table">
						<div style="display: table-cell; vertical-align: middle">
							<div style="display: inline-flex">
								<div class="size-color center-inside" :style="{ backgroundColor: variants[0].color.hex }">
									{{ variants[0].color.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="is-flex is-align-self-flex-end">
					<b-numberinput
						custom-class="has-background-white"
						:step="1"
						@input="changeQuantity"
						class="is-size-4"
						controls-position="compact"
						min="1"
						:max="sizes[0].stockNumber"
						v-model="cartProduct.quantity"
						:editable="false"
					></b-numberinput>
					<b-button @click="removeCartProduct" class="ml-4 btn-remove-desktop" type="is-red-light"><b-icon type="is-red" icon="remove" pack="rt"></b-icon></b-button>
				</div>
				
			</article>
		</div>
	</div>
</template>

<script>
	import { getVariantById, getSizeById } from '@/api/cart';
	export default {
		name: 'CartLine',
		props: {
			cartProduct: {
				type: Object,
				default: null,
			},
			index: {},
		},
		data() {
			return {
				actionShown: null,
				variants: [],
				sizes: [],
			};
		},
		computed: {
			showQuantity() {
				if (this.actionShown == 'quantity' || this.actionShown == 'all') return true;
				return false;
			},
			showRemove() {
				if (this.actionShown == 'remove' || this.actionShown == 'all') return true;
				return false;
			},
		},
		methods: {
			onSwipe(swipe) {
				if (!this.IS_MOBILE) return;
				if (swipe == 'left') {
					this.actionShown = 'remove';
				} else if (swipe == 'right') {
					this.actionShown = 'quantity';
				}
			},
			changeQuantity(type) {
				console.log(type);
				let quantity = 1;
				if (type == 'minus') quantity = parseInt(this.cartProduct.quantity) - 1;
				else if (type == 'plus') quantity = parseInt(this.cartProduct.quantity) + 1;
				else quantity = parseInt(type);

				if (Number.isInteger(quantity)) return this.$emit('quantity', quantity, this.index);
			},
			removeCartProduct() {
				this.$emit('remove', this.index);
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},
		},

		created() {
			console.log(this.cartProduct);
			getVariantById(this.cartProduct.productVariant.id)
				.then((response) => {
					console.log(response);
					this.variants.push(response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
			getSizeById(this.cartProduct.productSize.id)
				.then((response) => {
					console.log(response);
					this.sizes.push(response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';

	.media {
		box-shadow: 0px 2px 48px #0000000a;
		width: -webkit-fill-available;
	}
	.mobile figure.image {
		border-radius: $radius 0px 0px $radius;
	}
	h3 {
		letter-spacing: 1px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 250px;
	}
	h4 {
		letter-spacing: 0.79px;
	}

	.btn-quantity {
		height: 96px;
		width: 49px;
		min-width: 49px;
		transition: transform 0.3s, width 0.3s, min-width 0.3s, margin 0.3s, padding 0.3s, border 0.3s;
	}

	.btn-remove {
		height: 96px;
		width: 49px;
		min-width: 49px;
		transition: transform 0.3s, width 0.3s, min-width 0.3s, margin 0.3s, padding 0.3s, border 0.3s;
	}

	.slide-in-left {
		transform: translateX(0px);
	}

	.slide-out-left {
		transform: translateX(-100px);
		width: 0px;
		min-width: 0px;
		margin-right: 0px !important;
		padding: 0px !important;
		border: 0px !important;
	}

	.slide-in-right {
		transform: translateX(0px);
	}

	.slide-out-right {
		transform: translateX(100px);
		width: 0px;
		min-width: 0px;
		margin-left: 0px !important;
		padding: 0px !important;
		border: 0px !important;
	}

	.btn-remove-desktop {
		height: 55px;
		width: 55px;
	}

	.size-color {
		color: white;
		height: 35px;
		border-radius: 5px;
		text-align: center;
		min-width: 50px;
		padding: 1rem;
	}
</style>
