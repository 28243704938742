<template>
	<div class="mt-6 px-6" v-if="productSales.length > 0">
		<span class="has-text-grey-darker container-title">{{ t.flash }}</span>
		<span class="timer-container has-text-grey-dark"
			>{{ t.end }} <span class="has-text-black timer ml-2"> {{ t.today }}</span>
		</span>
		<div @scroll="handleScroll" class="products-container">
			<span v-for="sale in productSales" :key="sale.id" class="mx-2">
				<div v-if="sale.isActive && verifyDate(sale.endAt)">
					<figure class="image img is-5by3 is-clickable" @click="productDetails(sale.product.id, 1)">
						<img class="has-object-fit-contain" :src="sale.product.pictures[0].url" />

						<span class="tag">{{ sale.discount }}% {{ t.off }}</span>
						<br v-if="sale.product.givesMoneyOnCard" />
						<span v-if="sale.product.givesMoneyOnCard" class="tag mt-12"
							>{{ calculateMoneyCard(sale.product.moneyOnCardPercentage, calculateSale(sale.product.price, sale.discount)) }}€ {{ t.card }}</span
						>
					</figure>

					<p class="product-name">{{ sale.product.discount }}</p>
					<div class="level">
						<div class="level-left">
							<div class="is-flex level-item">
								<p class="price">{{ sale.product.price }} €</p>
								<p class="ml-4 has-text-weight-bold">{{ calculateSale(sale.product.price, sale.discount) }} €</p>
							</div>
						</div>

						<div class="level-right">
							<div class="level-item" v-if="window <= 768">
								<div class="is-flex" style="transform: translateY(-20px)">
									<div
										class="color-container ml-2 mt-2"
										v-for="variant in sale.product.productVariants.slice(0, 3)"
										:key="variant.id"
										:style="`backgroundColor: ${variant.color.hex}`"
										v-touch:tap="productDetailsMobile(sale.product.id)"
									></div>
									<span class="is-size-5 has-text-grey ml-2 mt-2" v-if="sale.product.productVariants.length > 3"
										>+ {{ sale.product.productVariants.length - 3 }}</span
									>
								</div>
							</div>
							<div class="level-item" v-else>
								<div class="is-flex">
									<div
										class="color-container ml-2 mt-2 is-clickable"
										v-for="variant in sale.product.productVariants.slice(0, 3)"
										:key="variant.id"
										:style="`backgroundColor: ${variant.color.hex}`"
										@click="productDetails(sale.product.id, 1)"
									></div>
									<span class="is-size-5 has-text-grey ml-2 mt-2" v-if="sale.product.productVariants.length > 3"
										>+ {{ sale.product.productVariants.length - 3 }}</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>

		<!-- <div v-else>
			<p class="has-text-centered has-text-primary is-size-4-desktop is-size-5-mobile mt-2">{{ t.noProducts }}</p>
		</div> -->
	</div>
</template>

<script>
	import { getProductsOnSale } from '@/api/feed';
	import moment from 'moment';
	import parts from '@/mixins/modules/shared/parts';
	import router from '@/router/index';
	export default {
		mixins: [parts],
		data() {
			return {
				t: this.$t(`components.Homepage.FlashSale`),
				productSales: [],
				page: 1,
				pageItems: 10,
				activeSales: 0,
				window: 0,
			};
		},

		methods: {
			handleScroll(e) {
				const { target } = e;
				if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth) {
					this.page++;
					getProductsOnSale(this.page, this.pageItems, this.getMainCategorySelected)
						.then((response) => {
							console.log(response);
							this.productSales.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},

			productDetails(id, tab) {
				parts.methods.setMenuVisible(false);
				parts.methods.setNotificationsVisible(false);
				this.$router.push({ name: 'ProductDetails', params: { id: id, tab: tab } });
			},

			verifyDate(date) {
				let a = moment(new Date());
				let b = moment(date);

				if (moment(a).isSameOrBefore(b, 'day')) {
					return true;
				} else return false;
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			calculateMoneyCard(num1, num2) {
				console.log(num1);
				let valueToShow = num1 * (num2 / 100);

				return valueToShow;
			},

			verifySize() {
				this.window = window.innerWidth;
			},

			productDetailsMobile(id) {
				return function (direction, event) {
					console.log(id);
					parts.methods.addSidebarStatus(false);
					router.push({ name: 'ProductDetails', params: { id: id } });
				};
			},
		},

		mounted() {
			this.verifySize();
			window.addEventListener('resize', this.verifySize);

			getProductsOnSale(this.page, this.pageItems, this.getMainCategorySelected, moment().utc().format('YYYY-MM-DD'))
				.then((response) => {
					console.log(response);
					this.productSales = response.data.data;
					this.$emit('getProductsOnSale', this.productSales);
					console.log(this.productSales);
				})
				.catch((error) => {
					console.log(error);
				});

			this.verifyDate();
		},

		filters: {
			moment: function (date) {
				return moment(date).utc().format('DD/MM/YYYY');
			},
		},

		destroyed() {
			window.removeEventListener('resize', this.verifySize);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.timer-container {
		float: right;
		font-size: 0.858rem;
		transform: translateY(-3px);
		@include from($tablet) {
			font-size: 1.2rem;
		}
	}

	.timer {
		font-weight: bold;
		font-size: 1.286rem;
		@include from($tablet) {
			font-size: 1.4rem;
		}
	}

	.products-container {
		text-align: center;
		display: flex;
		overflow: auto;
		white-space: nowrap;
		margin-top: 5rem;
		margin-bottom: 10px;
		@include until($tablet) {
			margin-top: 2rem;
		}
	}

	.img {
		width: 132px;
		height: 140px;
		border-radius: 7px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		border: 1px solid $grey-light;

		@include from($tablet) {
			width: 245px;
			height: 144px;
			border: 1px solid $grey-light;
		}
	}

	.tag {
		width: 85px;
		height: 35px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		position: absolute;
		top: 10px;
		left: 10px;
	}

	.product-name {
		font-size: 0.858rem;
		text-align: left;
		white-space: break-spaces;
		overflow: hidden;
		max-width: 249px;
		@include until($tablet) {
			max-width: 136px;
		}
	}

	.price {
		font-size: 1rem;
		font-weight: bold;
		text-align: left;
		text-decoration: line-through;
	}

	.container-title {
		@include from($tablet) {
			font-size: 1.4rem;
		}
	}

	::-webkit-scrollbar {
		height: 6px;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:active {
		background: #aab2b7;
	}

	.color-container {
		min-width: 24px;
		height: 24px;
		border-radius: 6px;
		text-align: center;
		color: black;
		border: solid 1px $grey;
		width: auto;
		max-width: 50px;
		padding-left: 5px;
		padding-right: 5px;
	}
</style>
