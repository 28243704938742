<template>
	<section class="section has-background-white">
		<div class="container" @scroll="handleScroll">
			<p class="is-size-3 is-hidden-touch">{{ $t('views.Whishlist.title') }}</p>
			<p class="mt-10 mb-12 is-size-5">{{ $tc('views.Cart.items', whishlist.length, [whishlist.length]) }}</p>

			<div class="is-hidden-touch has-radius py-8 has-background-white level is-mobile pr-20 pl-8 border">
				<div class="level-right">
					<div class="level-item">
						<b-checkbox size="is-medium" @input="onSelectAll" class="is-align-self-center">
							<span class="is-size-6"> {{ $t('views.Whishlist.selectAll') }} </span>
						</b-checkbox>
					</div>
				</div>
				<div class="level-left">
					<div class="level-item py-1 pl-7">
						<a :disabled="!isAnySelected" :loading="isLoading" @click="removeAll" class="has-text-red is-uppercase">{{ $t('views.Whishlist.remove') }}</a>
					</div>
				</div>
			</div>
			<div v-if="IS_MOBILE" class="mobile">
				<div class="is-flex is-justify-content-space-between is-flex-direction-row is-align-items-center mb-4" v-for="(item, index) in whishlist" :key="item.id">
					<article @click="actionShown = null" class="media has-background-white has-radius">
						<figure class="media-left image is-96x96 is-background-size-cover" :style="{ 'background-image': 'url(' + item.product.pictures[0].url + ')' }"></figure>
						<div class="media-content is-align-self-center">
							<h3 class="is-size-6 has-text-grey-darker">{{ item.product.title }}</h3>
							<div class="is-flex" v-if="item.product.productSales && item.product.productSales[0].isActive == true">
								<p
									class="is-size-6 has-text-grey-darker"
									:style="[
										item.product.productSales && item.product.productSales[0].isActive == true
											? { textDecoration: 'line-through' }
											: { textDecoration: 'none' },
									]"
								>
									{{ item.product.price.toFixed(2) }}€
								</p>

								<h4 class="is-size-6 has-text-grey-darker ml-2">{{ calculateSale(item.product.price, item.product.productSales[0].discount) }}€</h4>
							</div>

							<h4 class="is-size-6 has-text-grey-darker" v-else>{{ item.product.price.toFixed(2) }}€</h4>
							<b-button class="ml-4 btn" type="is-primary" @click="productDetails(item.product.id)">{{ $t('views.Whishlist.see') }}</b-button>
						</div>

						<b-icon type="is-red" icon="remove" pack="rt" @click.native="remove(item.product.id, index)"></b-icon>
					</article>
				</div>
			</div>
			<div v-else class="desktop Components-Cart-CartLine-Desktop">
				<div v-for="(item, index) in whishlist" :key="item.id" class="is-flex is-justify-content-space-between is-flex-direction-row is-align-items-center mb-4">
					<article class="media has-background-white has-radius py-8 pr-20 pl-8">
						<b-checkbox @input="onSelectItem" :native-value="item.id" v-model="productSelected" size="is-medium" class="is-align-self-center"></b-checkbox>
						<figure
							class="media-left image is-128x128 is-background-size-cover has-radius"
							:style="{ 'background-image': 'url(' + item.product.pictures[0].url + ')' }"
						></figure>
						<div class="media-content is-align-self-center">
							<h3 class="is-size-4 has-text-dark has-text-weight-bold">{{ item.product.title }}</h3>
							<div class="is-flex" v-if="item.product.productSales && item.product.productSales[0].isActive == true">
								<p
									class="is-size-5 has-text-grey"
									:style="[
										item.product.productSales && item.product.productSales[0].isActive == true
											? { textDecoration: 'line-through' }
											: { textDecoration: 'none' },
									]"
								>
									{{ item.product.price.toFixed(2) }}€
								</p>

								<h4 class="is-size-5 has-text-grey ml-2">{{ calculateSale(item.product.price, item.product.productSales[0].discount) }}€</h4>
							</div>

							<h4 class="is-size-5 has-text-grey" v-else>{{ item.product.price.toFixed(2) }}€</h4>
						</div>
						<div class="is-flex is-align-self-flex-end">
							<b-button class="ml-4 btn" type="is-white" @click="productDetails(item.product.id)">{{ $t('views.Whishlist.see') }}</b-button>
							<b-button class="ml-4 btn-remove-desktop" type="is-red-light" @click="remove(item.product.id, index)"
								><b-icon type="is-red" icon="remove" pack="rt"></b-icon
							></b-button>
						</div>
					</article>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getWhishlist, removeWhishlist, removeManyWishlist } from '@/api/feed';
	export default {
		data() {
			return {
				isLoading: null,
				page: 1,
				pageSize: 5,
				whishlist: [],
				productSelected: [],
				isAnySelected: false,
				isRequesting: false,
				offset: 60,
			};
		},

		methods: {
			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			onSelectAll(val) {
				if (val == true) {
					for (let i in this.whishlist) {
						this.productSelected.push(this.whishlist[i].id);
					}
					this.isAnySelected = true;
				} else {
					this.productSelected = [];
					this.isAnySelected = false;
				}
			},

			onSelectItem() {
				if (this.productSelected.length > 0) {
					this.isAnySelected = true;
				} else {
					this.isAnySelected = false;
				}
			},

			productDetails(id) {
				console.log(id);
				this.$router.push({ name: 'ProductDetails', params: { id: id } });
			},

			remove(id, index) {
				removeWhishlist(id).then((response) => {
					this.whishlist.splice(index, 1);
					let wishNumber = this.getBookmark;
					wishNumber--;
					this.setBookmark(wishNumber);
					this.$buefy.toast.open({
						message: this.$t('views.Whishlist.deleted'),
						position: 'is-top',
						type: 'is-primary',
					});
				});
			},

			removeAll() {
				if (this.isAnySelected) {
					this.isLoading = true;
					removeManyWishlist({
						id: this.productSelected,
					})
						.then((response) => {
							console.log(response);

							this.$buefy.toast.open({
								message: this.$t('views.Whishlist.allDeleted'),
								position: 'is-top',
								type: 'is-primary',
							});
							this.productSelected = [];

							getWhishlist({
								'filters[user][id]': this.getUser.id,
								'pagination[page]': this.page,
								'pagination[pageSize]': this.pageSize,
							})
								.then((response) => {
									this.whishlist = response.data.data;
									this.setBookmark(response.data.meta.pagination.total);
									window.addEventListener('scroll', this.handleScroll);
								})
								.catch((error) => {
									console.log(error);
								});
						})
						.catch((error) => {})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};
				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					console.log('REQUEST');
					this.isRequesting = true;
					this.page++;

					getWhishlist({
						'filters[user][id]': this.getUser.id,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
					})
						.then((response) => {
							console.log(response);

							this.whishlist.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},
		},

		created() {
			getWhishlist({
				'filters[user][id]': this.getUser.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
			})
				.then((response) => {
					console.log(response);
					this.whishlist = response.data.data;
					this.setBookmark(response.data.meta.pagination.total);
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.border {
		border: 0.5px solid $grey-light;
	}

	.is-hidden-touch.level {
		border: 0.5px solid $grey-light;
		.level-left {
			.level-item {
				border-left: 2px solid $grey-light;
			}
		}
	}

	.media {
		box-shadow: 0px 2px 48px #0000000a;
		width: -webkit-fill-available;
		padding: 1rem;
	}
	figure {
		border: 1px solid #e6e6e6;
		background-position-y: center;
	}
	.mobile figure.image {
		border-radius: $radius 0px 0px $radius;
		background-position-y: center;
	}
	h3 {
		letter-spacing: 1px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		@include until($tablet) {
			max-width: 110px;
		}
	}
	h4 {
		letter-spacing: 0.79px;
	}

	.btn-quantity {
		height: 96px;
		width: 49px;
		min-width: 49px;
		transition: transform 0.3s, width 0.3s, min-width 0.3s, margin 0.3s, padding 0.3s, border 0.3s;
	}

	.btn-remove {
		height: 96px;
		width: 49px;
		min-width: 49px;
		transition: transform 0.3s, width 0.3s, min-width 0.3s, margin 0.3s, padding 0.3s, border 0.3s;
	}

	.slide-in-left {
		transform: translateX(0px);
	}

	.slide-out-left {
		transform: translateX(-100px);
		width: 0px;
		min-width: 0px;
		margin-right: 0px !important;
		padding: 0px !important;
		border: 0px !important;
	}

	.slide-in-right {
		transform: translateX(0px);
	}

	.slide-out-right {
		transform: translateX(100px);
		width: 0px;
		min-width: 0px;
		margin-left: 0px !important;
		padding: 0px !important;
		border: 0px !important;
	}

	.btn-remove-desktop {
		height: 55px;
		width: 55px;
	}

	.btn {
		height: 55px;
		width: 176px;
		border: 0.5px solid #e6e6e6;
		border-radius: 6px;

		@include until($desktop) {
			width: auto;
			height: auto;
			position: absolute;
			right: 5px;
		}
	}
</style>
