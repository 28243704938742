<template>
	<section>
		<div v-if="IS_MOBILE">
			<ProductDetails />
		</div>
		<div v-else>
			<DesktopProductDetails />
		</div>
	</section>
</template>

<script>
	import ProductDetails from '@/components/Products/ProductDetails.vue';
	import DesktopProductDetails from '@/components/Desktop/Products/ProductDetails.vue';
	export default {
		components: {
			ProductDetails,
			DesktopProductDetails,
		},

		data() {
			return {};
		},
	};
</script>
