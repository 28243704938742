<template>
	<div style="padding-bottom: 100px">
		<div class="header-container pt-8">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-grey" custom-size="is-size-4"></b-icon>
			<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable">{{ t.back }}</p>
		</div>

		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<p class="has-text-grey-darker is-size-1">{{ t.details }}</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-upload v-model="file" accept="application/pdf" class="file-label header-btn center-inside" v-if="order.orderStatus.id == 2">
						<b-icon class="mr-2" icon="upload" size="is-size-6" type="is-black"></b-icon>{{ t.upload }}
					</b-upload>
					<br />
				</div>
			</div>
		</div>
		<p v-if="file" class="has-text-primary mt-0 pb-10" style="position: absolute; right: 0">{{ file.name }}</p>

		<div class="mt-13">
			<p class="has-text-primary is-size-4">
				{{ t.warningOne }}
				<br /><br />
				{{ t.warningTwo }}
			</p>
		</div>
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<div class="box mt-4" style="width: 400px">
						<p class="has-text-centered is-size-5">{{ t.orderSummary }}</p>
						<div class="level mb-0 mt-3">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.order }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5">{{ (order.amount / 100 + order.discount - order.shipping / 100).toFixed(2) }} €</p>
							</div>
						</div>
						<div class="level mb-0" v-if="order.discount > 0">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.discount }}</p>
							</div>
							<div class="level-right">
								<p class="level-item is-size-5">
									- {{ order.discount }} € ({{ calculateDiscount(order.amount / 100 + order.discount - order.shipping / 100, order.discount) }}%)
								</p>
							</div>
						</div>
						<div class="level mb-0">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.shippingFees }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5" v-if="order.shipping">{{ order.shipping / 100 }} €</p>
								<p class="level-item is-size-5" v-else>0 €</p>
							</div>
						</div>
						<div class="level mb-0 mt-6">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.total }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5">{{ (order.amount / 100).toFixed(2) }} €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="level-right"></div>
		</div>

		<div>
			<div class="mb-2 mt-20 items-container center-inside">
				<img v-if="order.user.picture" :src="order.user.picture.url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto; max-width: 210px">
					<p class="has-text-dark user-name">{{ order.user.name }}</p>
					<p class="has-text-dark order">ID {{ order.id }}</p>
					<p class="has-text-dark order-items">{{ getItems(order) }} Item(s)</p>
				</div>
				<div class="ml-8" style="margin: auto; transform: translateX(-190px)">
					<p class="has-text-dark user-name">{{ t.billingData }}</p>
					<p class="has-text-dark user-name is-size-6">{{ order.billing_data.address }}, {{ order.billing_data.locality }}, {{ order.billing_data.country }}</p>
					<p class="has-text-dark user-name is-size-6" v-if="order.billing_data.nif">NIF: {{ order.billing_data.nif }}</p>
					<p class="has-text-dark user-name is-size-6" v-if="order.billing_data.nif">{{ order.billing_data.company }}</p>
				</div>

				<div style="display: inline-flex">
					<p class="price mx-10 mt-2">{{ (order.amount / 100).toFixed(2) }} €</p>
				</div>
			</div>

			<div class="mt-8" v-for="(detail, index) in order.products.data" :key="detail.id">
				<div class="product-container">
					<!-- <div class="product-quantity center-inside">{{ index + 1 }}</div> -->
					<div class="product-detail ml-3">
						<div style="margin: auto; display: flex">
							<img class="product-image" :src="detail.product.pictures[0].url" />
							<div class="ml-6" style="display: table; margin: auto">
								<div style="display: table-cell; vertical-align: middle">
									<p class="has-text-grey-darker is-size-4">{{ detail.product.title }}</p>
									<p class="has-text-grey is-size-5">{{ detail.price }} €</p>
									<p class="has-text-grey is-size-5" v-if="order.discount > 0">{{ calculateProductDiscount(detail) }} € {{ t.valueWithDiscount }}</p>
								</div>
							</div>
							<div style="display: table" class="mx-10">
								<div style="display: table-cell; vertical-align: middle">
									<div style="display: inline-flex">
										<p class="has-text-dark mr-2 is-size-5">
											{{ detail.quantity }} x {{ getVariantSize(detail.variant, detail.product.productVariants, detail.size) }}
										</p>
										<div
											class="size-color center-inside"
											:style="{ backgroundColor: getVariantColor(detail.variant, detail.product.productVariants, detail.size) }"
											:class="
												getVariantColorName(detail.variant, detail.product.productVariants, detail.size) == 'Branco' ? 'has-text-black' : 'has-text-white'
											"
										>
											{{ getVariantColorName(detail.variant, detail.product.productVariants, detail.size) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-8 level" v-if="order.orderStatus.id == 2">
					<div class="level-left">
						<div class="level-item">
							<p class="has-text-grey-darker is-size-4">{{ t.available }}</p>
						</div>
					</div>

					<div class="level-right">
						<div class="level-item">
							<div
								class="check-btn yes-btn center-inside is-clickable"
								@click="checkStock(index, 'yes')"
								:style="[{ backgroundColor: getStock(index) == 'yes' ? '#99cca8' : 'white' }, { color: getStock(index) == 'yes' ? 'white' : 'black' }]"
							>
								<span>{{ t.yes }}</span>
							</div>

							<div
								class="mx-4 check-btn no-btn center-inside is-clickable"
								:style="[{ backgroundColor: getStock(index) == 'no' ? '#eb5757' : 'white' }, { color: getStock(index) == 'no' ? 'white' : 'black' }]"
								@click="checkStock(index, 'no')"
							>
								<span>{{ t.no }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-10" v-if="order.orderStatus.id == 2">
			<b-button class="btn" type="is-primary" :loading="isLoading" @click="confirm">{{ t.confirm }}</b-button>
			<b-button class="btn mt-5" type="is-danger" :loading="isLoading" @click="reject">{{ t.cancel }}</b-button>
		</div>
	</div>
</template>

<script>
	import { getOrderById, updateProductSize, updateOrderStatus, uploadImage } from '@/api/orders.js';

	export default {
		data() {
			return {
				order: [],
				productStock: [],
				isLoading: false,
				file: null,
				t: this.$t(`components.OrderDetail`),
			};
		},

		methods: {
			getVariantSize(id, variants, size) {
				console.log(id);
				console.log(variants);
				console.log(size);
				let variant = variants.filter((variant) => variant.id == id);

				let finalSize = variant[0].productSizes.filter((productSize) => productSize.id == size);

				return finalSize[0].size.name;
			},

			getVariantColorName(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.name;
			},

			getVariantColor(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.hex;
			},

			checkStock(index, value) {
				this.$set(this.productStock, index, value);
			},

			getStock(index) {
				return this.productStock[index];
			},

			async confirm() {
				if (this.productStock.includes('')) {
					this.$buefy.toast.open({
						message: this.t.verifyAvailable,
						position: 'is-top',
						type: 'is-danger',
					});
				} else if (!this.file) {
					this.$buefy.toast.open({
						message: this.t.verifyUpload,
						position: 'is-top',
						type: 'is-danger',
					});
				} else {
					this.isLoading = true;

					let order = {
						orderStatus: 3,
						file: null,
					};

					await uploadImage(this.file).then((response) => {
						console.log(response);
						order.file = response.data[0];
					});
					await updateOrderStatus(this.$route.params.id, order)
						.then((response) => {
							console.log(response);

							this.productStock.map((stock, index) => {
								console.log(this.order.products.data[index]);
								console.log(this.order.products.data[index].size);
								if (stock == 'no') {
									let data = {
										stockNumber: 0,
									};

									updateProductSize(this.order.products.data[index].size, data)
										.then((response) => {
											console.log(response);
										})
										.catch((error) => {
											this.$buefy.toast.open({
												message: this.t.error,
												position: 'is-top',
												type: 'is-danger',
											});
										});
								}
							});
							console.log(response.data.data.shipping_data.expedition.CorpoResposta);
							window.open(response.data.data.shipping_data.expedition.CorpoResposta, '_blank');
							this.$buefy.toast.open({
								message: this.t.orderConfirmed,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('StoreOrders');
						})
						.catch((error) => {
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},

			reject() {
				this.isLoading = true;
				let order = {
					orderStatus: 4,
				};
				updateOrderStatus(this.$route.params.id, order)
					.then((response) => {
						this.$buefy.toast.open({
							message: this.t.orderRejected,
							position: 'is-top',
							type: 'is-primary',
						});
						this.goToPage('StoreOrders');
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: this.t.error,
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},

			calculateDiscount(originalPrice, discountValue) {
				let salePrice = originalPrice - discountValue;

				let discount = ((originalPrice - salePrice) / originalPrice) * 100;

				if (Number.isInteger(discount)) {
					return discount;
				} else return discount.toFixed(2);
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			calculateProductDiscount(product) {
				let value = 0;
				let percentageDiscount = 0;
				let totalAmmount = (this.order.amount / 100 + this.order.discount - this.order.shipping / 100).toFixed(2);
				percentageDiscount = (this.order.discount * 100) / totalAmmount;
				let valueToSubtract = product.price * (percentageDiscount / 100);
				value += product.price - valueToSubtract;

				return value.toFixed(2);
			},
		},

		created() {
			this.setIsPageLoading(true);
			console.log(this.getUser.store.userMaxValue);

			getOrderById(this.$route.params.id)
				.then((response) => {
					this.order = response.data.data;
					console.log(this.order);

					for (let index = 0; index < this.order.products.data.length; index++) {
						this.productStock.push('');
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 1rem;
	}

	.order-items,
	.user-name {
		font-size: 1.2rem;
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
	}

	.btn {
		width: 422px;
		height: 54px;
		display: flex;
		margin: 0 auto;
	}

	.product-container {
		display: flex;
	}

	.product-quantity {
		background-color: $primary;
		border-radius: 8px;
		width: 71px;
		height: 127px;
		color: white;
		font-size: 2rem;
	}

	.product-detail {
		box-shadow: 0px 2px 48px #0000000a;
		width: 100%;
		height: 127px;
		border-radius: 20px;
	}

	.product-image {
		height: 127px;
		border-radius: 22px;
	}

	.size-color {
		height: 35px;
		border-radius: 5px;
		text-align: center;
		min-width: 50px;
		padding: 1rem;
		border: 1px solid grey;
	}

	.header-btn {
		width: 155px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	.no-btn {
		outline: $red solid 1px;
		border-radius: 8px;
		text-align: center;
		width: 60px;
		height: 30px;
	}

	.yes-btn {
		outline: $primary solid 1px;
		border-radius: 8px;
		text-align: center;
		width: 60px;
		height: 30px;
	}
</style>
