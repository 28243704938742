<template>
	<section class="section is-fullheight is-flex is-align-items-center">
		<div v-if="status == 'waiting'" class="container">
			<figure class="image is-128x128 mx-auto">
				<img class="loading-animation" src="@/assets/svg/logo.svg" />
			</figure>
			<h2 class="is-size-5 has-text-centered mt-16 has-text-grey">{{ t.waiting }}</h2>
		</div>
		<div v-else-if="status == 'success'" class="container">
			<figure class="image is-128x128 mx-auto">
				<img src="@/assets/svg/success.svg" />
			</figure>
			<h2 class="is-size-5 has-text-centered mt-16 has-text-grey">{{ t.success }}</h2>
		</div>
		<div v-else class="container">
			<figure class="image is-128x128 mx-auto">
				<img src="@/assets/svg/logo.svg" />
			</figure>
			<h2 class="is-size-5 has-text-centered mt-16 has-text-danger">{{ t.error }}</h2>
		</div>
	</section>
</template>

<script>
	import { activateAccount } from '@/api/auth';
	export default {
		data() {
			return {
				id: null,
				t: this.$t(`components.Auth.ActivateAccount`),
				status: 'waiting',
			};
		},
		created() {
			if (!this.$route.query.confirmation) return;
			this.id = this.$route.query.confirmation;

			let data = {
				confirmation: this.id,
			};

			activateAccount(data)
				.then((response) => {
					console.log(response);
					this.$buefy.toast.open({
						message: this.t.activated,
						position: 'is-top',
						type: 'is-primary',
					});
					this.status = 'success';
					setTimeout(() => {
						this.goToPage('Login');
					}, 2000);
				})
				.catch((error) => {
					console.log(error);
					this.status = 'error';
					this.$buefy.toast.open({
						message: this.$t('shared.error'),
						position: 'is-top',
						type: 'is-danger',
					});
				});
		},
	};
</script>
