import { get, post, put, remove } from '@/utils/http';
const queryString = require('qs');

export function createStore(data) {
	return post('/api/stores', data);
}

export function getBrands() {
	return get('/api/brands?sort[0]=name&pagination[limit]=1000');
}

export function getGenders() {
	return get('/api/categories?filters[level]=0');
}

export function getCategories(query = {}) {
	query = queryString.stringify(query);
	return get('/api/categories?' + query);
}

export function getSubCategories(id) {
	return get('/api/categories?populate=children&filters[id]=' + id);
}

export function getColors() {
	return get('/api/colors?sort[0]=name&pagination[limit]=1000');
}

export function getSizesGroups() {
	return get('/api/sizes?pagination[limit]=1000');
}

export function getStockStatus() {
	return get('/api/stock-statuses');
}

export function uploadImage(img, captions = []) {
	console.log(img);
	let form = new FormData();
	for (let index = 0; index < img.length; index++) {
		form.append('files', img[index][0]);
		form.append('files', img[index][1]);
		form.append('files', img[index][2]);
	}

	form.append('fileInfo', JSON.stringify(captions));

	return post('/api/upload', form);
}

export function addProductSize(body, query = {}) {
	query = queryString.stringify(query);
	return post('/api/product-sizes?' + query, {
		data: body,
	});
}

export function addProductVariant(variant, query = {}) {
	query = queryString.stringify(query);
	return post('/api/product-variants?' + query, {
		data: variant,
	});
}

export function createProduct(data) {
	return post('/api/products?populate=productSales', data);
}

export function addProductSale(data, query = {}) {
	query = queryString.stringify(query);
	return post('/api/product-sales?', { data });
}

export function getProducts(id, page, pageSize, mainCategory, subCategory, size, minPrice, maxPrice, color, brand, onlySale, time, stringFilter, bol) {
	console.log(mainCategory);
	let isArray = Array.isArray(mainCategory);
	let categoryUnissex = [];

	if (!isArray) {
		if (mainCategory == 1 || mainCategory == 54) {
			if (!categoryUnissex.includes(109)) {
				categoryUnissex.push(109, mainCategory);
			}
		} else if (mainCategory == 97) {
			categoryUnissex = [];
			categoryUnissex.push(97);
		}
	} else {
		if (mainCategory.includes(1) || mainCategory.includes(54)) {
			console.log(mainCategory);

			if (!categoryUnissex.includes(109)) {
				categoryUnissex = mainCategory;
			}
		} else if (mainCategory.includes(97) && !mainCategory.includes(1) && !mainCategory.includes(54)) {
			let remove = mainCategory.filter((item) => item !== 109);
			categoryUnissex = remove;
		}
	}

	console.log(categoryUnissex);
	const query = queryString.stringify(
		{
			filters: {
				$and: [
					...(subCategory
						? [
								{
									$or: [
										{
											category: {
												id: {
													$in: subCategory,
												},
											},
										},
										/* {
											category: {
												parent: {
													id: {
														$in: subCategory,
													},
												},
											},
										}, */
										{
											category: {
												parent: {
													parent: {
														id: {
															$in: subCategory,
														},
													},
												},
											},
										},
										/* {
											category: {
												parent: {
													parent: {
														parent: {
															id: {
																$in: subCategory,
															},
														},
													},
												},
											},
										},
										{
											category: {
												parent: {
													parent: {
														parent: {
															parent: {
																id: {
																	$in: subCategory,
																},
															},
														},
													},
												},
											},
										}, */
									],
								},
						  ]
						: []),
					...(mainCategory
						? [
								{
									$or: [
										{
											category: {
												parent: {
													parent: {
														id: {
															$in: categoryUnissex,
														},
													},
												},
											},
										},
										{
											category: {
												parent: {
													id: {
														$in: categoryUnissex,
													},
												},
											},
										},
									],
								},
						  ]
						: []),
					{
						store: {
							id: {
								$eq: id,
							},
						},
					},
					...(bol
						? [
								{
									isActive: true,
								},
						  ]
						: []),

					...(size
						? [
								{
									productVariants: {
										productSizes: {
											size: {
												slug: {
													$in: size,
												},
											},
										},
									},
								},
						  ]
						: []),
					...(minPrice && maxPrice
						? [
								{
									price: {
										$lte: maxPrice,
										$gte: minPrice,
									},
								},
						  ]
						: []),
					...(color
						? [
								{
									productVariants: {
										color: {
											id: {
												$in: color,
											},
										},
									},
								},
						  ]
						: []),
					...(brand
						? [
								{
									brand: {
										id: {
											$in: brand,
										},
									},
								},
						  ]
						: []),
					...(onlySale
						? [
								{
									productSales: {
										isActive: {
											$eq: true,
										},
									},
								},
						  ]
						: []),
				],
			},
			sort: time,
			...(stringFilter ? { _q: stringFilter } : []),
			populate: 'pictures,productSales',
			pagination: {
				page,
				pageSize,
			},
		},
		{ encodeValuesOnly: true }
	);

	return get('/api/products?' + query);
}

export function getProductSales() {
	return get('/api/product-sales?populate=product');
}

export function getProductById(id) {
	return get(
		'/api/products/' +
			id +
			'?populate=pictures,brand,category,category.parent.parent.parent.parent.parent,productVariants,productVariants.color,productVariants.pictures,productVariants.productSizes.stockStatus,productVariants.productSizes.size,store,productSales'
	);
}

export function updateProduct(id, data, query = {}) {
	query = queryString.stringify(query);
	return put('/api/products/' + id + '?' + query, { data });
}

export function updateProductSale(id, data, query = {}) {
	query = queryString.stringify(query);
	return put('/api/product-sales/' + id + '?' + query, { data });
}

export function editUploadImage(img, captions = []) {
	console.log(img, captions);
	let form = new FormData();
	for (let index = 0; index < img.length; index++) {
		if (img[index] instanceof File) {
			form.append('files', img[index]);
		}
	}
	let captionObject = {};
	if (captions.length == 1) {
		captionObject = { caption: captions[0].caption };
		form.append('fileInfo', JSON.stringify(captionObject));
	} else form.append('fileInfo', JSON.stringify(captions));

	return post('/api/upload', form);
}

export function updateProductSize(id, data, query) {
	query = queryString.stringify(query);
	return put('/api/product-sizes/' + id + '?' + query, { data: data });
}

export function updateProductVariant(id, data, query = {}) {
	query = queryString.stringify(query);
	return put('/api/product-variants/' + id + '?' + query, { data: data });
}

export function deleteProductVariant(id) {
	return remove('/api/product-variants/' + id);
}

export function deleteProductSize(id) {
	return remove('/api/product-sizes/' + id);
}

export function updateStore(id, data) {
	console.log(data);
	return put('/api/stores/' + id + '?populate=pictures', data);
}

export function deleteProduct(id) {
	return remove('/api/products/' + id);
}

export function stripeAccountLink() {
	return get('/api/stripe/stores/account_link');
}

export function stripeActivate() {
	return put('/api/stripe/stores/activate');
}

export function getStoreDetails(id) {
	return get('/api/stores/' + id + '?populate=pictures');
}

export function updateStoreViews(id) {
	return put('/api/stores/' + id + '/views');
}
