<template>
	<section>
		<div class="main-container">
			<div>
				<div style="display: inline-flex">
					<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" size="is-medium"> </b-icon>
					<p class="header ml-2">{{ t.profile }}</p>
				</div>

				<form @submit.prevent="update" class="mt-10">
					<b-tabs size="is-large">
						<b-tab-item :label="t.mainInfo">
							<div class="mt-10">
								<div class="photo">
									<b-upload v-model="newStoreImage" class="file-label" accept="image/*" name="photo">
										<img class="profile-photo has-radius" v-if="newStoreImage == null" :src="storeImage" />
										<img class="profile-photo has-radius" v-else-if="newStoreImage" :src="getFileURL" />
										<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
									</b-upload>
								</div>
							</div>

							<div class="columns mt-10">
								<div class="column">
									<b-field>
										<b-input
											class="input"
											size="is-medium"
											:placeholder="t.name"
											name="organization"
											type="text"
											v-model.trim="store.data.name"
											required
										></b-input>
									</b-field>
								</div>
								<div class="column">
									<b-field>
										<b-autocomplete
											class="input"
											style="height: 42px"
											size="is-medium"
											rounded
											:data="filteredCities"
											:keep-first="true"
											:open-on-focus="true"
											field="city"
											:placeholder="t.city"
											icon="magnify"
											clearable
											ref="city"
											name="city"
											v-model.trim="store.data.location_municipality"
											required
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field>
										<b-input
											class="input"
											size="is-medium"
											:placeholder="t.phone"
											name="phone"
											v-model.trim="store.data.telephone"
											v-validate="'required|mobile-phone|numeric'"
										></b-input>
									</b-field>
									<p v-show="errors.has('phone')" class="help is-danger ml-4">{{ t.invalidPhone }}</p>
								</div>

								<div class="column">
									<b-input class="input" size="is-medium" :placeholder="t.address" type="text" v-model.trim="store.data.location_address" required></b-input>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-input
										class="input"
										size="is-medium"
										:placeholder="t.zipCode"
										pattern="\d{4}-\d{3}"
										name="zip_code"
										type="text"
										v-model.trim="store.data.location_zipCode"
										required
									></b-input>
								</div>
								<div class="column">
									<!-- <b-field>
										<b-input
											class="input"
											size="is-medium"
											:placeholder="t.country"
											name="country"
											v-model.trim="store.data.location_country"
											required
										></b-input>
									</b-field> -->
									<b-field>
										<b-select size="is-medium" :placeholder="t.country" v-model.trim="store.data.location_country">
											<option value="Portugal">Portugal</option>
										</b-select></b-field
									>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field>
										<b-input class="input" size="is-medium" :placeholder="t.iban" v-model.trim="store.data.iban" type="text" required></b-input>
									</b-field>
								</div>
								<div class="column">
									<b-field>
										<b-input name="email" class="input" size="is-medium" v-model.trim="getUser.store.email" disabled type="text"></b-input>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field>
										<b-input class="input" size="is-medium" disabled v-model.trim="getUser.store.cae" type="text"></b-input>
									</b-field>
								</div>
								<div class="column">
									<b-field>
										<b-input class="input" size="is-medium" v-model.trim="getUser.store.vat" disabled type="text"></b-input>
									</b-field>
								</div>
							</div>
						</b-tab-item>

						<b-tab-item :label="t.aditionalInfo">
							<div class="columns">
								<div class="column">
									<b-field>
										<b-input
											size="is-medium"
											name="description"
											v-model.trim="store.data.description"
											maxlength="200"
											type="textarea"
											:placeholder="t.description"
										></b-input>
									</b-field>
								</div>
								<div class="column">
									<b-field>
										<b-input class="input" size="is-medium" v-model.trim="store.data.networks_instagram" placeholder="Instagram" type="text"></b-input>
									</b-field>

									<b-field>
										<b-input class="input" size="is-medium" v-model.trim="store.data.networks_facebook" placeholder="Facebook" type="text"></b-input>
									</b-field>
								</div>
							</div>
						</b-tab-item>
					</b-tabs>

					<div class="button-container has-text-centered mt-4">
						<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">{{ t.save }}</b-button>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
	import { uploadPhoto } from '@/api/user';
	import { updateStore } from '@/api/store';

	const cities = require('@/assets/cities.json');
	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Store.EditProfile`),
				showMenu: false,
				newStoreImage: null,
				storeImage: null,

				store: {
					data: {
						name: null,
						description: null,
						telephone: null,
						location_address: null,
						location_country: null,
						location_municipality: null,
						location_zipCode: null,
						networks_facebook: null,
						networks_instagram: null,
						iban: null,
						pictures: [],
					},
				},

				isLoading: false,
				email: null,
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			update() {
				this.isLoading = true;
				this.$validator.validateAll().then(async (result) => {
					if (result) {
						let newPhotoId = [];
						if (this.newStoreImage) {
							await uploadPhoto(this.newStoreImage).then((response) => {
								newPhotoId.push(response.data[0].id);
								this.store.data.pictures = newPhotoId;
								console.log(this.store.data.pictures);
							});
						}
						await updateStore(this.getUser.store.id, this.store)
							.then((response) => {
								console.log(response);
								this.updateStore(response.data.data);
								this.isLoading = false;
								this.$buefy.toast.open({
									message: this.t.updated,
									position: 'is-top',
									type: 'is-primary',
								});
								this.goToPage('Homepage');
							})
							.catch((error) => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
								console.log(error);
							});
					} else this.isLoading = false;
				});
			},
		},

		computed: {
			getFileURL() {
				return window.URL.createObjectURL(this.newStoreImage);
			},

			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.store.data.location_municipality.toLowerCase()) >= 0;
				});
			},
		},

		created() {
			this.citiesList = cities;
			this.storeImage = this.getUser.store.pictures[0].url;
			this.store.data.pictures = this.getUser.store.pictures[0].id;
			this.store.data.name = this.getUser.store.name;
			this.store.data.description = this.getUser.store.description;
			this.store.data.telephone = this.getUser.store.telephone;
			this.store.data.location_address = this.getUser.store.location_address;
			this.store.data.location_country = this.getUser.store.location_country;
			this.store.data.location_municipality = this.getUser.store.location_municipality;
			this.store.data.location_zipCode = this.getUser.store.location_zipCode;
			this.store.data.networks_facebook = this.getUser.store.networks_facebook;
			this.store.data.networks_instagram = this.getUser.store.networks_instagram;
			this.store.data.iban = this.getUser.store.iban;
			this.email = this.getUser.store.email;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.main-container {
		padding-left: 140px;
		padding-right: 140px;
		padding-bottom: 10rem;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 31;
	}

	.header {
		font-size: 1.5rem;
	}

	.photo {
		width: 125px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
		border: black solid 1px;
	}

	img {
		width: 125px;
		height: 98px;
		object-fit: cover;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 100%;
	}

	/deep/ textarea.textarea.is-medium {
		border: solid 3px $white-ter;
		border-radius: 11px;
	}

	/deep/ .control {
		width: 100%;
	}

	.btn {
		width: 400px;
		height: 52px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	.button-container {
		width: 100%;
		padding-bottom: 10px;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 0rem 1.5rem 6rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	/deep/ input.input.is-medium {
		height: 20px !important;
		border: none;
	}

	/deep/ .mdi-24px.mdi:before {
		transform: translateY(-11px);
	}

	/deep/.control {
		width: 100%;
	}
	/deep/span.select.is-medium {
		width: 100%;
	}
	/deep/select {
		width: 100%;
		border: solid 3px $white-ter;
		border-radius: 11px;
	}
</style>
