<template>
	<section>
		<div class="is-fixed-top pt-8 px-3"><b-icon @click.native="goToPage('Login')" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
		<div class="px-6 mt-6">
			<span>{{ t.signup }}</span
			><br />
		</div>

		<div class="px-6 mt-22">
			<form @submit.prevent="create">
				<b-field><b-input class="input mt-10" size="is-medium" name="name" type="text" required :placeholder="t.name" v-model.trim="form.name"></b-input></b-field>
				<b-field><b-input class="input mt-6" size="is-medium" name="email" type="email" required :placeholder="t.email" v-model.trim="form.email"></b-input></b-field>
				<p v-show="errorEmail" class="has-text-red is-size-6">{{ t.errorEmail }}</p>
				<b-field>
					<b-datepicker
						class="input mt-6"
						size="is-medium"
						v-model="form.birthday"
						:placeholder="t.birth"
						name="bday"
						:mobile-native="false"
						:locale="$i18n.locale"
						:icon-right-clickable="true"
						@icon-right-click="clearBirthday()"
						trap-focus
						:max-date="today"
					></b-datepicker>
				</b-field>
				<p class="has-text-primary is-size-6">{{ t.birthMessage }}</p>
				<p v-show="errorDate" class="has-text-red is-size-6">{{ t.enterDate }}</p>
				<p v-show="errorAge" class="has-text-red is-size-6">{{ t.errorAge }}</p>
				<b-field
					><b-input
						class="input mt-6"
						size="is-medium"
						name="new-password"
						required
						:placeholder="t.password"
						password-reveal
						type="password"
						v-model="form.password"
					></b-input
				></b-field>

				<b-field>
					<b-input
						class="input mt-6"
						size="is-medium"
						name="new-password"
						required
						:placeholder="t.confirmPassword"
						password-reveal
						type="password"
						v-model="confirmPassword"
					></b-input>
				</b-field>
				<p v-show="this.errorPassword" class="has-text-red is-size-6">{{ t.errorPassword }}</p>

				<b-field>
					<b-checkbox size="is-large" class="mt-6" v-model="form.acceptsTerms" required></b-checkbox><span class="terms">{{ t.accept }}</span>
				</b-field>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" :loading="isLoading" class="btn" native-type="submit">{{ t.signup }}</b-button>
				</div>
			</form>
			<p class="mt-1 mb-5 has-text-grey has-text-centered">
				{{ t.byRegistering }} <span class="has-text-primary terms" @click="openTerms">{{ t.terms }}</span> {{ t.and }}
				<span class="has-text-primary terms">{{ t.privacy }}</span>
				{{ t.ofRoot }}
			</p>
		</div>
	</section>
</template>

<script>
	import { createAccount } from '@/api/auth';
	import { Browser } from '@capacitor/browser';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.SignUp`),
				form: {
					email: null,
					password: null,
					name: null,
					birthday: null,
					gender: null,
					marketing: false,
					acceptsTerms: null,
					role: 'authenticated',
				},

				confirmPassword: null,
				errorPassword: false,
				errorDate: false,
				errorEmail: false,
				isLoading: false,
				today: new Date(),
				errorAge: false,
			};
		},

		methods: {
			create() {
				if (this.form.birthday === null) {
					this.errorDate = true;
				} else if (new Date().getFullYear() - this.form.birthday.getFullYear() < 18) {
					this.isLoading = false;
					this.errorDate = false;
					this.errorAge = true;
				} else if (this.form.password !== this.confirmPassword || this.form.password.length < 8) {
					this.errorPassword = true;
					this.errorDate = false;
					this.errorAge = false;
				} else {
					this.isLoading = true;
					this.errorPassword = false;
					this.errorEmail = false;
					createAccount(this.form)
						.then((response) => {
							console.log(response);
							this.addToken(response.data.jwt);
							this.addUser(response.data.user);
							this.addPw(this.form.password);
							this.$buefy.toast.open({
								message: this.t.verify,
								position: 'is-top',
								type: 'is-primary',
								duration: 4000,
							});
							console.log(response);
							this.isLoading = false;
							this.goToPage('ConfirmCode');
						})
						.catch((error) => {
							this.isLoading = false;

							if (error.response.data.error.message === 'Email is already taken') {
								this.errorEmail = true;
							}
						});
				}
			},

			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				/* 	const openCapacitorSite = async () => {
					await Browser.open({ url: routeData.href });
				};
				openCapacitorSite(); */
				window.open(routeData.href, '_blank');
			},
		},
		created() {
			this.form.userType = this.getAccountType;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	span {
		font-size: 1.7rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		@include from($mobile-s) {
			margin-top: 60px;
		}
	}

	.terms {
		font-size: 1rem;
		transform: translate(-14px, 23px);
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
