<template>
	<section>
		<div class="is-flex">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-dark" size="is-medium"></b-icon>
			<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.settings }}</p>
		</div>

		<div class="px-8 mt-10">
			<p class="title">{{ t.notifications }}</p>
			<div class="ml-10 mt-3 subtitle">
				{{ t.newPosts }}
				<b-switch :rounded="false" @input="update" v-model="notificationsPosts" :outlined="false" :type="null" :passive-type="null"></b-switch>
			</div>
			<div class="ml-10 mt-3 subtitle">
				{{ t.newPlaces }}
				<b-switch :rounded="false" @input="update" v-model="notificationPlaces" :outlined="false" :type="null" :passive-type="null"></b-switch>
			</div>
		</div>
		<div class="px-8 mt-10">
			<p class="title">Emails</p>
			<div class="ml-10 mt-3 subtitle">
				{{ t.newPosts }}
				<b-switch :rounded="false" @input="update" :outlined="false" v-model="emailPosts" :type="null" :passive-type="null"></b-switch>
			</div>
			<div class="ml-10 mt-3 subtitle">
				{{ t.newPlaces }}
				<b-switch :rounded="false" @input="update" :outlined="false" v-model="emailPlaces" :type="null" :passive-type="null"></b-switch>
			</div>
		</div>
		<div class="px-8 mt-12">
			<p class="title">{{ t.other }}</p>
			<div class="ml-10 mt-3 subtitle" @click="openTerms">
				{{ t.privacy }}
				<b-icon icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
			</div>
			<div class="ml-10 mt-3 subtitle">{{ t.support }}: <a href="mailto:progressiveroottechnology@gmail.com">progressiveroottechnology@gmail.com</a></div>
		</div>

		<b-button expanded type="is-danger" size="is-medium" :loading="isLoading" class="delete-btn" @click="deleteAccount">
			<span class="is-size-6">{{ t.deleteAccount }}</span>
		</b-button>
		<!-- <div class="px-8 mt-12">
			<p class="title">{{ t.social }}</p>
			<div class="mt-3 subtitle">
				<b-icon class="social" pack="rt" icon="facebook-square" size="is-size-4" type="is-black"></b-icon>
				<span class="social-name ml-2">Facebook</span>
				<b-switch :rounded="false" :outlined="false" :type="null" :passive-type="null"></b-switch>
			</div>

			<div class="mt-3 subtitle">
				<b-icon class="social" pack="rt" icon="twitter" size="is-size-4" type="is-black"></b-icon>
				<span class="social-name ml-2">Twitter</span>
				<b-switch :rounded="false" :outlined="false" :type="null" :passive-type="null"></b-switch>
			</div>
		</div> -->
	</section>
</template>

<script>
	import { getSettings, updateSettings } from '@/api/settings';
	import { deleteAccount } from '@/api/user';
	export default {
		data() {
			return {
				notificationPlaces: null,
				notificationsPosts: null,
				emailPlaces: null,
				emailPosts: null,
				id: null,
				t: this.$t(`views.Settings`),
				isLoading: false,
			};
		},

		methods: {
			update() {
				let update = {
					data: {
						email_places: this.emailPlaces,
						email_posts: this.emailPosts,
						notifications_places: this.notificationPlaces,
						notifications_posts: this.notificationsPosts,
					},
				};

				updateSettings(this.id, update).then((response) => {
					console.log(response);
				});
			},

			deleteAccount() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteAccount,
					message: this.t.deletingAccount,
					confirmText: this.t.deleteAccount,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						deleteAccount()
							.then((response) => {
								this.isLoading = true;
								this.$socket.disconnect();
								this.onSessionExpired();
								this.$buefy.toast.open({
									message: this.t.deleteSuccess,
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch((error) => {
								if (error.response.data.error.details.code == 'completeOrders') {
									this.$buefy.toast.open({
										message: this.t.errorDelete,
										position: 'is-top',
										type: 'is-danger',
									});
								}
							})
							.finally(() => {
								this.isLoading = false;
							});
					},
				});
			},

			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.location.assign(routeData.href, '_blank');
			},
		},
		created() {
			this.setIsPageLoading(true);
			getSettings(this.getUser.id)
				.then((response) => {
					console.log(response);
					this.notificationPlaces = response.data.data[0].notifications_places;
					this.notificationsPosts = response.data.data[0].notifications_posts;
					this.emailPlaces = response.data.data[0].email_places;
					this.emailPosts = response.data.data[0].email_posts;
					this.id = response.data.data[0].id;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
		height: 85vh;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.title {
		font-size: 1rem;
	}

	.subtitle {
		font-size: 0.857rem;
	}

	/deep/ .switch {
		float: right;
	}

	.icon {
		float: right;
	}
	.social {
		float: left !important;
	}
	.social-name {
		display: inline-flex;
		align-items: center;
		height: 22px;
	}

	.delete-btn {
		position: absolute;
		bottom: 10px;
	}
</style>
