<template>
	<section>
		<div class="is-fixed-top pt-8 px-3"><b-icon @click.native="goToPage('AccountType')" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
		<div class="px-6 mt-10">
			<span class="welcome-text">{{ t.welcome }}</span
			><br />
			<span class="welcome-text">{{ t.signIn }}</span>
			<p class="mt-4 has-text-grey">
				{{ t.noAccount }} <span class="has-text-primary" @click="goToSignup()">{{ t.signup }}</span>
			</p>

			<form @submit.prevent="onSubmitPress">
				<b-input class="input mt-10" size="is-medium" name="email" type="email" required :placeholder="t.emailPlaceholder" v-model.trim="login.identifier"></b-input>
				<b-input
					class="input mt-6"
					size="is-medium"
					name="current-password"
					required
					:placeholder="t.passwordPlaceholder"
					password-reveal
					type="password"
					v-model="login.password"
				></b-input>

				<p class="has-text-grey mt-2 forgot" @click="goToPage('ForgotPassword')">{{ t.forgot }}</p>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" native-type="submit" :loading="isLoading" class="btn">{{ t.login }}</b-button>
					<p class="has-text-primary mt-2" @click="goToPage('Homepage')">{{ t.backToFeed }}</p>
				</div>
				<p v-show="wrongCredentials" class="has-text-red has-text-centered mt-2">{{ t.wrongCredentials }}</p>
			</form>

			<p class="has-text-centered mt-12 has-text-grey-dark" v-if="getAccountType == 'authenticated'">{{ t.continueWith }}</p>

			<div class="social-container mt-6 pb-4" v-if="getAccountType == 'authenticated'">
				<b-button class="is-full-round" type="is-green-light" @click="google">
					<b-icon icon="google" custom-size="is-size-3" type="is-green"></b-icon>
				</b-button>
				<b-button class="is-full-round" @click="facebook" type="is-green-light">
					<b-icon icon="facebook" custom-size="is-size-3" type="is-green"></b-icon>
				</b-button>
				<!-- <b-button class="is-full-round" type="is-green-light" @click="apple">
					<b-icon icon="apple" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button> -->
			</div>
		</div>
	</section>
</template>

<script>
	import { login } from '@/api/auth';
	import socialAuth from '@/mixins/modules/auth/socialAuth';

	export default {
		data() {
			return {
				t: this.$t(`components.Auth.Login`),
				login: {
					identifier: null,
					password: null,
				},
				wrongCredentials: false,
				isLoading: false,
			};
		},
		mixins: [socialAuth],
		methods: {
			onSubmitPress() {
				this.isLoading = true;
				login(this.login)
					.then((response) => {
						this.wrongCredentials = false;
						this.onLogin(response);
						this.$socket.connect();
						if (response.data.user.favoriteCategories.length == 0 && response.data.user.role.type == 'authenticated') {
							this.setIsLoginReset(true);
							this.goToPage('ChooseUserStyle');
						} else {
							if (response.data.user.role.type == 'authenticated') {
								let array = [];
								this.getUser.favoriteCategories.map((category) => {
									array.push(category.id);
								});
								this.setMainCategorySelected(array);
							}
							this.setIsLoginReset(true);
							this.setSizeSelected([]);
							this.setBrandSelected([]);
							this.setColorSelected([]);
							this.setOnlySale(null);
							this.setCategorySelected([]);
							this.setNumbers([]);
							this.setFilterStores([]);
							this.setMainCategorySelected([]);
							this.goToPage('Homepage');
						}
					})
					.catch((error) => {
						this.isLoading = false;
						if (error.response.data.error.message === 'Invalid identifier or password') {
							this.wrongCredentials = true;
						}
						if (error.response.data.error.message === 'Your account email is not confirmed' && this.getAccountType == 'store') {
							this.$buefy.snackbar.open({
								message: this.t.approvement,
								type: 'is-primary',
								position: 'is-top',
								actionText: 'Ok',
								indefinite: true,
								onAction: () => {
									this.$store.commit('desktopLoginStep/setStoreMessage', false);
								},
							});
						} else if (error.response.data.error.message === 'Your account email is not confirmed' && this.getAccountType == 'authenticated') {
							console.log(error.response);
							this.addPw(this.login.password);
							this.addRecoverEmail(this.login.identifier);
							this.addToken(error.response.data.error.details.jwt);
							this.goToPage('ConfirmCode');
						} else {
							this.$buefy.toast.open({
								message: this.$t('shared.error'),
								position: 'is-top',
								type: 'is-danger',
							});
						}
					});
			},
			facebook() {
				this.facebookLogin(this.nextStep);
			},
			google() {
				this.googleLogin(this.nextStep);
			},
			apple() {
				this.appleLogin(this.nextStep);
			},
			nextStep() {
				this.$socket.connect();
				if (!this.getUser.favoriteCategories || this.getUser.favoriteCategories.length == 0) {
					this.goToPage('ChooseUserStyle');
				} else {
					let array = [];
					this.getUser.favoriteCategories.map((category) => {
						array.push(category.id);
					});
					this.setMainCategorySelected(array);
					this.setSizeSelected([]);
					this.setBrandSelected([]);
					this.setColorSelected([]);
					this.setOnlySale(null);
					this.setCategorySelected([]);
					this.setNumbers([]);
					this.setFilterStores([]);
					this.goToPage('Homepage');
				}
			},
			goToSignup() {
				if (this.getAccountType === 'store') {
					this.goToPage('StoreSignup');
				} else this.goToPage('Signup');
			},
		},

		mounted() {
			console.log(this.getAccountType);
			console.log(this.$store.getters['desktopLoginStep/getStoreMessage']);
			if (this.$store.getters['desktopLoginStep/getStoreMessage']) {
				this.$buefy.snackbar.open({
					message: this.t.approvement,
					type: 'is-primary',
					position: 'is-top',
					actionText: 'Ok',
					indefinite: true,
					onAction: () => {
						this.$store.commit('desktopLoginStep/setStoreMessage', false);
					},
				});
			}
		},
		created() {
			this.getAuthResult(this.nextStep);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	.welcome-text {
		font-size: 1.7rem;
	}

	.forgot {
		float: right;
	}

	.button-container {
		width: 100%;
		margin-top: 70px;
		@include until($mobile-s) {
			margin-top: 50px;
		}
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.is-full-round {
		width: 64px;
		height: 64px;
	}

	.social-container {
		display: flex;
		width: 55%;
		justify-content: space-evenly;
		margin: 0 auto;
		@include from($mobile-l) {
			width: 25%;
		}
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
