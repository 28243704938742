<template>
	<section>
		<div class="media px-6 center-inside mb-6 mt-10">
			<div class="media-left">
				<figure class="mx-auto image is-96x96 is-1by1 pt-0 has-radius center-inside" style="border: 1px solid black">
					<img v-if="storeInfo.pictures" class="profile-photo has-radius" :src="storeInfo.pictures[0].url" />
					<b-icon v-else icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
				</figure>
			</div>
			<div class="media-content">
				<p class="name">{{ storeInfo.name }}</p>
				<p class="address has-text-grey">{{ storeInfo.location_municipality }}, {{ storeInfo.location_country }}</p>
				<p v-if="storeInfo.description != null" class="description has-text-grey">{{ storeInfo.description }}</p>
			</div>
			<div class="media-right"></div>
		</div>

		<b-tabs size="is-medium" class="mt-8" expanded>
			<b-tab-item :label="t.products">
				<Products
					:storeId="this.$route.params.id"
					:key="counter"
					:productCategories="productCategories"
					:productSizes="productSizes"
					:productColors="productColors"
					:productBrands="productBrands"
					:priceRange="priceRange"
					:onlySale="onlySale"
					:categoryOption="getCategory"
				/>
			</b-tab-item>
			<b-tab-item :label="t.contact">
				<Contacts :storeInfo="storeInfo" />
			</b-tab-item>
		</b-tabs>

		<BottomMenu />
	</section>
</template>

<script>
	import BottomMenu from '@/components/Parts/BottomBar.vue';
	import Products from '@/components/Store/ProductsTab.vue';
	import Contacts from '@/components/Store/ContactsTab.vue';

	import { getStoreDetails, updateStoreViews } from '@/api/store';

	export default {
		components: {
			BottomMenu,
			Products,
			Contacts,
		},

		data() {
			return {
				t: this.$t(`views.StoreProfile`),
				tab: 0,
				storeInfo: [],
				categoryOption: [],
				productCategories: [],
				productSizes: [],
				productColors: [],
				productBrands: [],
				priceRange: [],
				onlySale: null,
				counter: 0,
				searchString: null,
			};
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},

			getCategory() {
				return this.categoryOption;
			},
		},

		created() {
			this.categoryOption = this.getMainCategorySelected;
			this.productCategories = this.getCategorySelected;
			this.productSizes = this.getSizeSelected;
			this.productColors = this.getColorSelected;
			this.productBrands = this.getBrandSelected;
			this.priceRange = this.getNumbers;
			this.onlySale = this.getOnlySale;
			this.searchString = this.getFilterString;
			if (this.getUserType != 'store') {
				updateStoreViews(this.$route.params.id).then((response) => {
					console.log(response);
				});
			}
			getStoreDetails(this.$route.params.id)
				.then((response) => {
					console.log(response);
					this.storeInfo = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			if (this.getResetFilter) {
				this.goToPage('Filter');
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $white;
		border: solid 1px black;
	}

	.profile-photo {
		object-fit: cover;
	}

	.name {
		font-size: 1rem;
	}

	.address {
		font-size: 0.857rem;
	}

	.description {
		font-size: 0.714rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}
</style>
