<template>
	<section v-if="orders.length > 0" @scroll="handleScroll">
		<div class="mb-4 items-container center-inside" v-for="order in orders" :key="order.id">
			<img v-if="order.user.picture" :src="order.user.picture.url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-2" style="margin: auto">
				<p class="has-text-black user-name">{{ order.user.name }}</p>
				<p class="has-text-grey order">ID {{ order.id }}</p>
				<p class="has-text-black order-items">{{ getItems(order) }} Item(s)</p>
			</div>
			<div>
				<p class="mr-2" style="min-width: 80px; text-align: right">{{ (order.amount / 100 - order.shipping / 100).toFixed(2) }} €</p>
				<b-button class="has-background-primary has-text-white btn mr-2" @click="orderDetails(order.id)">{{ t.details }}</b-button>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noOrders }}</p>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	export default {
		data() {
			return {
				t: this.$t(`components.Store.ListRefunds`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 100,
			};
		},

		methods: {
			orderDetails(id) {
				this.$router.push({ name: 'Devolution', params: { id: id } });
			},

			getOrderTotal(products) {
				console.log(products);
				let total = 0;
				products.data.map((product) => {
					console.log(product.product.price);
					total = total + product.product.price;
				});

				return total;
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[store]': this.getUser.store.id,
						'filters[orderStatus][id][$eq]': 9,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: 'createdAt:desc',
					})
						.then((response) => {
							this.orders.push(...response.data.data);
							this.$emit('getTotalRefunds', this.orders.length);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getOrders({
				'filters[store]': this.getUser.store.id,
				'filters[orderStatus][id][$eq]': 9,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: 'createdAt:desc',
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
					this.$emit('getTotalRefunds', this.orders.length);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 70px;
		height: 80px;
		border-radius: 22px;
		object-fit: cover;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 12px;
		height: 80px;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 0.716rem;
	}

	.order-items,
	.user-name {
		font-size: 0.82rem;
	}

	.price {
		margin-left: 54px;
		@include until($mobile-s) {
			margin-left: 44px;
		}
	}

	.btn {
		width: 73px;
		font-size: 0.716rem;
		float: right;
		@include from($mobile-s) {
			margin-left: 14px;
		}
	}
</style>
