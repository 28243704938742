<template>
	<section>
		<div class="mb-2 items-container center-inside">
			<img class="ml-2" src="https://www.worten.pt/i/2fe159d72165c581ba42055b697519e02a6e4e15.jpg" />
			<div class="ml-2" style="margin: auto">
				<p class="has-text-black store-name">Title</p>
				<p class="has-text-grey order">sale discount “20%”</p>
				<p class="has-text-grey order">10.12.21 - 10.05.22</p>
			</div>
			<div style="margin: auto">
				<p class="price"><b-icon pack="rt" icon="remove" custom-size="is-size-5" type="is-red"></b-icon></p>
				<b-button class="has-background-primary has-text-white btn">{{ t.applied }}</b-button>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Store.ListVouchers`),
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img {
		width: 96px;
		height: 62px;
		border: solid $white-ter 1px;
		border-radius: 5px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 12px;
		/* 	width: 338px; */
		height: 102px;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.store-name {
		font-size: 0.716rem;
	}

	.order {
		font-size: 0.82rem;
	}

	.price {
		margin-left: 54px;
		@include until($mobile-s) {
			margin-left: 44px;
		}
	}

	.btn {
		width: 61px;
		font-size: 0.716rem;
		@include from($mobile-s) {
			margin-left: 14px;
		}
	}
</style>
