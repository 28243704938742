<template>
	<section v-if="orders.length > 0" @scroll="handleScroll">
		<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
			<img v-if="order.user.picture" :src="order.user.picture.url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto; max-width: 210px; min-width: 210px">
				<p class="has-text-dark user-name">{{ order.user.name }}</p>
				<p class="has-text-dark order">ID {{ order.id }}</p>
				<p class="has-text-dark order-items">{{ getItems(order) }} Item(s)</p>
			</div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-dark user-name">{{ t.billingData }}</p>
				<p class="has-text-dark user-name is-size-6">{{ order.billing_data.address }}, {{ order.billing_data.locality }}, {{ order.billing_data.country }}</p>
				<p class="has-text-dark user-name is-size-6" v-if="order.billing_data.nif">NIF: {{ order.billing_data.nif }}</p>
				<p class="has-text-dark user-name is-size-6" v-if="order.billing_data.nif">{{ order.billing_data.company }}</p>
			</div>
			<div style="display: inline-flex">
				<b-button class="has-background-primary has-text-white btn" @click="orderDetails(order.id)">{{ t.details }}</b-button>
				<p class="price ml-10 mr-4" style="text-align: right">{{ (order.amount / 100).toFixed(2) }} €</p>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noOrders }}</p>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	export default {
		props: ['sort'],
		data() {
			return {
				t: this.$t(`components.Store.ListOrders`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 100,
			};
		},

		methods: {
			getOrderTotal(products) {
				console.log(products);
				let total = 0;
				products.data.map((product) => {
					console.log(product.product.price);
					total = total + product.product.price;
				});

				return total;
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			orderDetails(id) {
				this.$router.push({ name: 'OrderDetail', params: { id: id } });
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[store]': this.getUser.store.id,
						'filters[orderStatus][id][$eq]': 2,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: this.sort,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
							this.$emit('getTotalOrders', this.orders.length);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},
		},

		mounted() {
			console.log(this.sort);
			this.setIsPageLoading(true);
			getOrders({
				'filters[store]': this.getUser.store.id,
				'filters[orderStatus][id][$eq]': 2,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
					this.$emit('getTotalOrders', this.orders.length);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 1rem;
	}

	.order-items,
	.user-name {
		font-size: 1.2rem;
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;
	}
</style>
