<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileStoreTransactions v-if="getUserType == 'store'" />
			<MobileUserTransactions v-else />
		</div>
		<div class="container" v-else>
			<DesktopStoreTransactions v-if="getUserType == 'store'" />
			<DesktopUserTransactions v-else />
		</div>
	</section>
</template>

<script>
	import DesktopStoreTransactions from '@/components/Desktop/Orders/StoreTransactions.vue';
	import MobileStoreTransactions from '@/components/Orders/StoreTransactions.vue';
	import DesktopUserTransactions from '@/components/Desktop/Orders/UserTransactions.vue';
	import MobileUserTransactions from '@/components/Orders/UserTransactions.vue';

	export default {
		components: {
			DesktopStoreTransactions,
			MobileStoreTransactions,
			DesktopUserTransactions,
			MobileUserTransactions
		},

		data() {
			return {};
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},
	};
</script>
