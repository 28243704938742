<template>
	<div class="sidebar pl-12">
		<p class="has-text-centered menu-title">{{ t.filter }}</p>
		<div style="display: inline-flex" class="mt-12">
			<p class="menu-text">{{ t.onlySale }}</p>
			<b-switch
				class="ml-4"
				:rounded="false"
				:outlined="false"
				:type="null"
				:passive-type="null"
				v-model="onlySale"
				@input="changeSaleSwitch(onlySale)"
				:native-value="true"
			></b-switch>
		</div>

		<div class="mt-4">
			<b-collapse
				class="card"
				animation="slide"
				v-for="category in categories.level1"
				:key="category.id"
				:open="isOpenLevel1 == category.id"
				@open="getCategory(category.id, 2)"
			>
				<template #trigger="props">
					<div class="card-header" role="button">
						<p class="card-header-title menu-text">{{ category.name }}</p>
						<a class="card-header-icon">
							<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
						</a>
					</div>
				</template>
				<div class="card-content">
					<div class="content">
						<b-loading :is-full-page="false" v-model="requestLoading" :can-cancel="false"></b-loading>

						<b-collapse class="card" animation="slide" v-for="category in categories.level2" :key="category.id" :open="isOpenLevel2 == category.id">
							<template #trigger="props">
								<div
									class="category-selector center-inside"
									:style="categorySelected.includes(category.id) ? `backgroundColor: #99CCA8;color:white` : 'backgroundColor:white'"
									@click="chooseCategory(category.id, null)"
								>
									{{ category.name }}
								</div>
							</template>
						</b-collapse>
					</div>
				</div>
			</b-collapse>
		</div>

		<div>
			<b-collapse class="card" animation="slide" :open="isOpenSize == true" @open="isOpenSize == true">
				<template #trigger="props">
					<div class="card-header" role="button">
						<p class="card-header-title menu-text">{{ t.size }}</p>
						<a class="card-header-icon">
							<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
						</a>
					</div>
				</template>
				<div class="card-content" style="max-height: 400px; overflow-y: scroll; padding: 0">
					<div class="sizes-container mt-4" v-for="size in sizes" :key="size.id">
						<div
							class="sizes center-inside mr-3"
							:style="sizeSelected.includes(size.slug) ? `backgroundColor: #99CCA8;color:white` : 'backgroundColor:white'"
							@click="chooseSize(size.slug)"
						>
							{{ size.name }}
						</div>
					</div>
				</div>
			</b-collapse>
		</div>

		<div>
			<b-collapse class="card" animation="slide" :open="isOpenSize == true" @open="isOpenSize == true">
				<template #trigger="props">
					<div class="card-header" role="button">
						<p class="card-header-title menu-text">{{ t.colors }}</p>
						<a class="card-header-icon">
							<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
						</a>
					</div>
				</template>

				<div class="card-content" style="max-height: 600px; overflow-y: scroll">
					<div class="sizes-container mt-4" v-for="color in colors" :key="color.id">
						<div
							class="brands-container center-inside mr-3"
							:style="colorSelected.includes(color.id) ? `backgroundColor: #99CCA8;color:white` : 'backgroundColor:white'"
							@click="chooseColor(color.id)"
						>
							{{ color.name }}
						</div>
					</div>
				</div>
			</b-collapse>
		</div>

		<div v-if="$route.name != 'StoreProfile'">
			<b-collapse class="card" animation="slide" :open="isOpenSize == true" @open="isOpenSize == true">
				<template #trigger="props">
					<div class="card-header" role="button">
						<p class="card-header-title menu-text">{{ t.brands }}</p>
						<a class="card-header-icon">
							<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
						</a>
					</div>
				</template>

				<div class="card-content" style="max-height: 600px; overflow-y: scroll">
					<b-field>
						<b-input
							:placeholder="t.search"
							custom-class="has-background-white"
							class="has-border has-radius"
							icon="magnify"
							icon-clickable
							v-debounce:1s="searchBrands"
							v-model="brandSearch"
							icon-right="close-circle"
							icon-right-clickable
							@icon-right-click="clearSearchBrands"
						>
						</b-input>
					</b-field>
					<div class="mt-4" v-for="brand in brands" :key="brand.id">
						<div
							class="brands-container center-inside mr-3"
							:style="brandSelected.includes(brand.id) ? `backgroundColor: #99CCA8;color:white` : 'backgroundColor:white'"
							@click="chooseBrand(brand.id)"
						>
							{{ brand.name }}
						</div>
					</div>
				</div>
			</b-collapse>
		</div>
		<div v-if="$route.name != 'StoreProfile'">
			<b-collapse class="card" animation="slide" :open="isOpenSize == true" @open="isOpenSize == true">
				<template #trigger="props">
					<div class="card-header" role="button">
						<p class="card-header-title menu-text">{{ t.stores }}</p>
						<a class="card-header-icon">
							<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
						</a>
					</div>
				</template>

				<div class="card-content" style="max-height: 600px; overflow-y: scroll">
					<div class="mt-4" v-for="store in stores" :key="store.id">
						<div
							class="brands-container center-inside mr-3"
							:style="storesSelected.includes(store.id) ? `backgroundColor: #99CCA8;color:white` : 'backgroundColor:white'"
							@click="chooseStore(store.id)"
						>
							{{ store.name }}
						</div>
					</div>
				</div>
			</b-collapse>
		</div>

		<div class="mt-6">
			<p class="menu-text">{{ t.range }}</p>
			<b-field class="mt-8">
				<b-slider v-model="numbers" indicator :tooltip="false" :min="minPrice" :max="maxPrice" :step="1" @input="changeRange(numbers)" @dragend="getNewRangeProducts">
					<b-slider-tick class="mt-6" :value="minPrice">Min</b-slider-tick>
					<b-slider-tick class="mt-6" :value="maxPrice">Max</b-slider-tick>
				</b-slider>
			</b-field>
		</div>

		<p class="has-text-centered has-text-primary mt-16 is-clickable" @click="resetFilter">{{ t.reset }}</p>
	</div>
</template>

<script>
	import { getSizes, getCategories, getSubCategories, getColors, getBrands, getStores, getMaxProductValue, getBrandsFilter } from '@/api/feed';
	export default {
		props: ['storeCategory'],
		data() {
			return {
				isOpenLevel1: null,
				isOpenLevel2: null,
				isOpenLevel3: null,
				isOpenLevel4: null,
				sizeSelected: [],
				numbers: [],
				sizes: [],
				categories: {
					level1: [],
					level2: [],
					level3: [],
					level4: [],
				},
				requestLoading: false,
				fillBackgroundColor: false,
				isOpenSize: null,
				colors: [],
				colorSelected: [],
				brands: [],
				brandSelected: [],
				categorySelected: [],
				onlySale: null,
				category: null,
				stores: [],
				storesSelected: [],
				t: this.$t(`components.FilterBar`),
				maxPrice: 0,
				minPrice: 1000,
				brandSearch: '',
			};
		},

		methods: {
			chooseSize(id) {
				if (this.sizeSelected.includes(id)) {
					let index = this.sizeSelected
						.map((x) => {
							return x;
						})
						.indexOf(id);

					this.sizeSelected.splice(index, 1);
					this.setSizeSelected(this.sizeSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				} else {
					this.sizeSelected.push(id);
					this.setSizeSelected(this.sizeSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				}
			},

			chooseColor(id) {
				if (this.colorSelected.includes(id)) {
					let index = this.colorSelected
						.map((x) => {
							return x;
						})
						.indexOf(id);

					this.colorSelected.splice(index, 1);
					this.setColorSelected(this.colorSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				} else {
					this.colorSelected.push(id);
					this.setColorSelected(this.colorSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				}
			},

			chooseBrand(id) {
				if (this.brandSelected.includes(id)) {
					let index = this.brandSelected
						.map((x) => {
							return x;
						})
						.indexOf(id);

					this.brandSelected.splice(index, 1);
					this.setBrandSelected(this.brandSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				} else {
					this.brandSelected.push(id);
					this.setBrandSelected(this.brandSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				}
			},
			chooseStore(id) {
				console.log(id);
				console.log(this.storesSelected);
				if (this.storesSelected.includes(id)) {
					let index = this.storesSelected
						.map((x) => {
							return x;
						})
						.indexOf(id);

					this.storesSelected.splice(index, 1);
					this.setFilterStores(this.storesSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				} else {
					this.storesSelected.push(id);
					this.setFilterStores(this.storesSelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				}
			},

			chooseCategory(id) {
				console.log(id);
				console.log(this.categories.level2);
				let siblingId = null;
				let sibling = this.categories.level2.filter((category) => {
					if (category.id === id) {
						if (category.sibling) {
							siblingId = category.sibling.id;
						}
					}
				});

				if (this.categorySelected.includes(id)) {
					let index = this.categorySelected
						.map((x) => {
							return x;
						})
						.indexOf(id);

					this.categorySelected.splice(index, 1);

					if (siblingId) {
						let siblingIndex = this.categorySelected
							.map((x) => {
								return x;
							})
							.indexOf(siblingId);

						this.categorySelected.splice(siblingIndex, 1);
					}

					this.setCategorySelected(this.categorySelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				} else {
					this.categorySelected.push(id);
					if (siblingId) {
						this.categorySelected.push(siblingId);
					}

					this.setCategorySelected(this.categorySelected);
					this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
				}
			},

			getCategory(id, nextLevel, index, load) {
				if (load == undefined || load == true) {
					this.requestLoading = true;
					console.log(load);

					let receivedResponse;
					getSubCategories({
						'filters[parent][id]': id,
						sort: ['name'],
					})
						.then((response) => {
							console.log(response);
							receivedResponse = response.data.data;
							console.log(nextLevel);
							switch (nextLevel) {
								case 2:
									this.categories.level2 = receivedResponse;
									this.isOpenLevel1 = id;
									this.requestLoading = false;

									break;

								case 3:
									this.categories.level3 = receivedResponse;
									this.isOpenLevel2 = id;
									this.requestLoading = false;

									break;
								case 4:
									this.categories.level4 = receivedResponse;
									this.isOpenLevel4 = id;
									this.requestLoading = false;

									break;

								default:
									break;
							}
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					if (this.categories.level2[index].children.length == 0) {
						this.categories.level3 = [];
					}
					this.categories.level4 = [];

					this.requestLoading = load;
				}
			},

			filter(categories, sizes, colors, brands, price, onlySale, stores) {
				this.$emit('getFilters', categories, sizes, colors, brands, price, onlySale, null, null, null, null, stores);
			},

			resetFilter() {
				console.log('entra');
				if (this.getSavedFilters) {
					this.setSavedSizeSelected(this.getSizeSelected);
					this.setSavedColorSelected(this.getColorSelected);
					this.setSavedBrandSelected(this.getBrandSelected);
					this.setSavedOnlySale(this.getOnlySale);
					this.setSavedCategorySelected(this.getCategorySelected);
					this.setSavedNumbers(this.getNumbers);
					this.setSavedStores(this.getFilterStores);
				}

				this.setSizeSelected([]);
				this.setBrandSelected([]);
				this.setColorSelected([]);
				this.setOnlySale(null);
				this.setCategorySelected([]);
				this.setNumbers([this.minPrice, this.maxPrice]);
				this.setFilterStores([]);
				this.sizeSelected = this.getSizeSelected;
				this.colorSelected = this.getColorSelected;
				this.brandSelected = this.getBrandSelected;
				this.categorySelected = this.getCategorySelected;
				this.onlySale = this.getOnlySale;
				this.numbers = this.getNumbers;
				this.storesSelected = this.getFilterStores;
				this.setSavedFilters(false);

				this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
			},

			changeSaleSwitch(value) {
				console.log(value);
				this.setOnlySale(value);
				this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
			},

			changeRange(value) {
				this.setNumbers(value);
			},

			clearSearchBrands() {
				this.brandSearch = '';
				this.searchBrands();
			},

			searchBrands() {
				this.brands = [];
				getBrandsFilter({ 'filters[name][$containsi]': this.brandSearch })
					.then((response) => {
						console.log(response);
						this.brands = response.data.data;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getNewRangeProducts() {
				this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
			},
		},

		computed: {
			getMainCategory() {
				return this.$store.getters['filter/getMainCategorySelected'];
			},
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			if (this.getIsLoginReset) {
				this.setIsLoginReset(false);
				this.resetFilter();
			}

			if (this.getResetFilter && this.getSavedFilters) {
				this.resetFilter();
				this.setResetFilter(false);
			} else if (this.getSavedFilters) {
				this.setSizeSelected(this.getSavedSizeSelected);
				this.setColorSelected(this.getSavedColorSelected);
				this.setBrandSelected(this.getSavedBrandSelected);
				this.setOnlySale(this.getSavedOnlySale);
				this.setCategorySelected(this.getSavedCategorySelected);
				this.setNumbers(this.getSavedNumbers);
				this.setFilterStores(this.getSavedStores);
				this.setSavedFilters(false);

				this.sizeSelected = this.getSavedSizeSelected;
				this.colorSelected = this.getSavedColorSelected;
				this.brandSelected = this.getSavedBrandSelected;
				this.categorySelected = this.getSavedCategorySelected;
				this.onlySale = this.getSavedOnlySale;
				this.numbers = this.getSavedNumbers;
				this.storesSelected = this.getSavedStores;

				console.log('LOJAS');

				console.log(this.storesSelected);

				this.filter(this.categorySelected, this.sizeSelected, this.colorSelected, this.brandSelected, this.numbers, this.onlySale, this.storesSelected);
			}
		},

		created() {
			this.sizeSelected = this.getSizeSelected;
			this.colorSelected = this.getColorSelected;
			this.brandSelected = this.getBrandSelected;
			this.categorySelected = this.getCategorySelected;
			this.onlySale = this.getOnlySale;
			this.numbers = this.getNumbers;
			this.storesSelected = this.getFilterStores;

			if (this.$route.name == 'StoreProfile') {
				this.category = this.storeCategory;
			} else if (this.getMainCategorySelected.length == 0) {
				this.category = null;
			} else this.category = this.getMainCategorySelected;

			getSizes()
				.then((response) => {
					this.sizes = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories({
				'filters[parent]': this.category,
				sort: ['name'],
			})
				.then((response) => {
					console.log(response);
					this.categories.level1 = response.data.data.filter((item) => {
						return !item.slug.includes('unissexo');
					});

					console.log(this.categories.level1);
				})
				.catch((error) => {
					console.log(error);
				});

			getColors()
				.then((response) => {
					this.colors = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getBrands()
				.then((response) => {
					this.brands = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getStores()
				.then((response) => {
					this.stores = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getMaxProductValue()
				.then((response) => {
					console.log(response);
					if (response.data.data.length > 0) {
						this.maxPrice = Math.ceil(response.data.data[0].price);

						if (this.maxPrice == null || this.maxPrice == 0) {
							this.minPrice = 0;
						} else {
							this.minPrice = 1;
						}
					} else {
						this.minPrice = 0;
						this.maxPrice = 0;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sidebar {
		width: 260px;
		padding-bottom: 240px;
	}

	.card,
	.card-header {
		box-shadow: none !important;
	}

	.card-header-title {
		padding: 0 !important;
		font-weight: normal;
	}
	.sizes {
		min-width: 40px;
		min-height: 40px;
		max-width: 45px;
		max-height: 45px;
		margin-left: 8px;
		text-align: center;
		color: black;
		cursor: pointer;
		border: 1px solid #e6e6e6;
		border-radius: 12px;
		word-break: break-word;
		font-size: 10.5px;
	}

	.sizes-container {
		display: inline-flex;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 7px;
	}

	.menu-title {
		font-size: 2.2rem;
		color: $grey-darker;
	}

	.menu-text {
		color: $grey-darker;
		font-size: 1.2rem;
	}

	.category-selector {
		width: 140px;
		height: 43px;
		text-align: center;
		color: black;
		cursor: pointer;
		border: 1px solid #e6e6e6;
		border-radius: 12px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.brands-container {
		width: 140px;
		min-height: 43px;
		text-align: center;
		color: black;
		cursor: pointer;
		border: 1px solid #e6e6e6;
		border-radius: 12px;
	}
</style>
