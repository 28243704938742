<template>
	<section :key="categoryCounter">
		<SideBar class="left" @getFilters="getFilters" />
		<div class="feed-content">
			<!-- <EmphasisProdcuts @getFilters="getFilters" /> -->
			<div class="options-container">
				<b-field v-for="category in categories" :key="category.id">
					<b-checkbox-button class="checkbox mx-2" size="is-large" v-model="categoryOption" @input="selectedFilter" :native-value="category.id" type="is-grey-darker">
						<span class="btn-text">{{ category.name }}</span>
					</b-checkbox-button>
				</b-field>
			</div>

			<div class="my-8 px-4">
				<Feed
					:key="counter"
					:productCategories="productCategories"
					:productSizes="productSizes"
					:productColors="productColors"
					:productBrands="productBrands"
					:priceRange="priceRange"
					:onlySale="onlySale"
					:comments="comments"
					:shopped="shopped"
					:reviewed="reviewed"
					:liked="liked"
					:filterStores="filterStores"
				/>
			</div>
		</div>
	</section>
</template>

<script>
	import SideBar from '@/components/Desktop/Parts/FeedSideBar.vue';
	import EmphasisProdcuts from '@/components/Desktop/Homepage/EmpashisProducts.vue';
	import FlashSale from '@/components/Homepage/FlashSale.vue';
	import Feed from '@/components/Desktop/Homepage/Feed.vue';
	import SearchProducts from '@/components/Desktop/Homepage/SearchProducts.vue';
	import { getCategories } from '@/api/feed';

	export default {
		components: {
			SideBar,
			EmphasisProdcuts,
			FlashSale,
			Feed,
			SearchProducts,
		},

		data() {
			return {
				showMenu: false,
				filterOption: null,
				activeTab: 0,
				categoryOption: [],
				productCategories: [],
				productSizes: [],
				productColors: [],
				productBrands: [],
				priceRange: [],
				onlySale: null,
				counter: 0,
				categories: [],
				categoryCounter: 0,
				comments: null,
				shopped: null,
				reviewed: null,
				liked: null,
				filterStores: [],
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			selectedFilter() {
				console.log(this.categoryOption);

				if (this.categoryOption.includes(1) || this.categoryOption.includes(54)) {
					if (!this.categoryOption.includes(109)) {
						this.categoryOption.push(109);
					}
				} else if (this.categoryOption.includes(97) && !this.categoryOption.includes(1) && !this.categoryOption.includes(54)) {
					let remove = this.categoryOption.filter((item) => item !== 109);
					this.categoryOption = remove;
				} else if (this.categoryOption.length == 1) {
					if (this.categoryOption.includes(109)) {
						this.categoryOption = [];
					}
				}
				this.setMainCategorySelected(this.categoryOption);
				this.categoryCounter++;
			},

			getFilters(productCategories, productSizes, productColors, productBrands, range, onlySale, comments, shopped, reviewed, liked, stores) {
				console.log(productCategories);
				console.log(productSizes);
				console.log(productColors);
				console.log(productBrands);
				console.log(range);
				console.log(onlySale);
				console.log(comments);
				console.log(shopped);
				console.log(reviewed);
				console.log(stores);
				this.productCategories = productCategories;
				this.productSizes = productSizes;
				this.productColors = productColors;
				this.productBrands = productBrands;
				this.priceRange = range;
				if (onlySale == true) {
					this.onlySale = onlySale;
				} else this.onlySale = null;
				this.comments = comments;
				this.shopped = shopped;
				this.reviewed = reviewed;
				this.liked = liked;
				this.filterStores = stores;
				this.counter++;
			},
		},

		created() {
			this.productCategories = this.getCategorySelected;
			this.productSizes = this.getSizeSelected;
			this.productColors = this.getColorSelected;
			this.productBrands = this.getBrandSelected;
			this.priceRange = this.getNumbers;
			this.onlySale = this.getOnlySale;
			this.filterStores = this.getFilterStores;

			if (this.getToken) {
				if (this.getUser.favoriteCategories == undefined) {
					this.setMainCategorySelected([]);
				} else {
					if (this.getUser.favoriteCategories.length == 1 && this.getMainCategorySelected.length == 0) {
						this.categoryOption.push(this.getUser.favoriteCategories[0].id);
						let array = [];
						array.push(this.getUser.favoriteCategories[0].id);
						this.setMainCategorySelected(array);
					} else if (this.getUser.favoriteCategories.length > 1 && this.getMainCategorySelected.length == 0) {
						let array = [];
						this.getUser.favoriteCategories.map((category) => {
							this.categoryOption.push(category.id);
							array.push(category.id);
						});
						this.setMainCategorySelected(array);
					} else {
						let array = [];
						this.getMainCategorySelected.map((category) => {
							console.log(category);
							this.categoryOption.push(category);
							array.push(category);
						});
						this.setMainCategorySelected(array);
					}
				}
			} else this.setMainCategorySelected([]);

			getCategories({
				'filters[level]': 0,
			})
				.then((response) => {
					this.categories = response.data.data.filter((category) => category.id != 109);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 31;
	}

	.left {
		position: absolute;
		left: 0;
	}

	.feed-content {
		padding-left: 300px;
		padding-right: 60px;
	}

	/deep/ .checkbox {
		border-radius: 11px;
		width: 111px;
		height: 42px;
	}

	.btn-text {
		font-size: 0.8rem;
		font-weight: 600;
	}

	.options-container {
		text-align: center;
		display: flex;
		white-space: nowrap;
		position: absolute;
		left: 320px;
	}

	/deep/ .tabs.is-toggle li.is-active a {
		background-color: $white-ter;
		border-color: $white-ter;
		color: $grey-darker;
		z-index: 1;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 0rem 1.5rem 6rem;
	}
	hr {
		margin: 0;
	}

	.terms-container {
		display: inline-flex;
		color: $grey;
		position: absolute;
		right: 0;
		width: 230px;
		justify-content: space-evenly;
	}
</style>
