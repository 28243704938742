<template>
	<section @scroll="handleScroll">
		<div class="is-flex">
			<b-icon @click.native="goToPage('Homepage')" class="is-clickable" icon="chevron-left" type="is-dark" size="is-medium"></b-icon>
			<p @click="goToPage('Homepage')" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.transactions }}</p>
		</div>
		<p class="py-4">{{ orders.length }} {{ t.orders }}</p>
		<div class="mb-4 items-container center-inside" v-for="order in orders" :key="order.id" @click="orderDetails(order.id, order.orderStatus.id)">
			<img v-if="order.user.picture" :src="order.user.picture.url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-2" style="margin: auto">
				<p class="has-text-grey user-name">{{ order.user.name }}</p>
				<p class="has-text-dark order">{{ order.id }}</p>
				<p class="has-text-dark order-items">{{ getItems(order) }} Item(s)</p>
				<p class="has-text-dark order">{{ order.createdAt | moment() }}</p>
			</div>
			<div class="mr-4">
				<p class="has-text-primary has-text-centered">{{ (order.amount / 100).toFixed(2) }} €</p>
				<p v-if="order.orderStatus.id == 10" class="has-text-centered has-text-danger is-size-7">{{ t.returned }}</p>
			</div>
		</div>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	import moment from 'moment';
	export default {
		data() {
			return {
				t: this.$t(`components.Store.TransactionsHistory`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 20,
				sort: 'createdAt:desc',
			};
		},

		methods: {
			orderDetails(id, orderStatus) {
				if (orderStatus == 10) {
					this.$router.push({ name: 'Devolution', params: { id: id } });
				} else this.$router.push({ name: 'OrderDetail', params: { id: id } });
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[store]': this.getUser.store.id,
						'filters[$or]': [
							{
								orderStatus: 3,
							},
							{
								orderStatus: 8,
							},
							{
								orderStatus: 10,
							},
						],
						/* 'filters[orderStatus][id][$eq]': 3,
						'filters[orderStatus][id][$eq]': 8, */
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: this.sort,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD.MM.YYYY');
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getOrders({
				'filters[store]': this.getUser.store.id,
				/* 'filters[orderStatus][id][$eq]': 3,
				'filters[orderStatus][id][$eq]': 8, */
				'filters[$or]': [
					{
						orderStatus: 3,
					},
					{
						orderStatus: 8,
					},
					{
						orderStatus: 10,
					},
				],
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 86px;
		height: 80px;
		border-radius: 12px;
	}

	img {
		object-fit: cover;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 12px;
		height: 80px;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 0.716rem;
	}

	.order-items,
	.user-name {
		font-size: 0.82rem;
	}

	.btn {
		width: 73px;
		font-size: 0.716rem;
		@include from($mobile-s) {
			margin-left: 14px;
		}
	}
</style>
