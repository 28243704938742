<template>
	<section>
		<div @scroll="handleScroll">
			<div class="px-6">
				<b-button type="is-primary" @click="goToPage('AddProduct')" class="btn">
					<b-icon class="mr-2" pack="rt" icon="plus" size="is-size-6" type="is-white"> </b-icon>{{ t.add }}
				</b-button>
				<!-- 	<div class="search-container mt-6">
					<b-field>
						<b-input :placeholder="t.search" type="search" icon="magnify" icon-clickable> </b-input>
					</b-field>
					<div class="icon-box center-inside has-background-primary ml-3"><b-icon pack="rt" icon="filter" size="is-size-4" type="is-white"></b-icon></div>
				</div> -->
			</div>
			<div class="items-list px-6 mt-6">
				<div>
					<span>{{ totalProducts }} Items</span>
					<b-icon @click.native="openFilter" class="right" pack="rt" icon="sort" size="is-size-6" type="is-black"> </b-icon>
				</div>
				<div v-show="showFilter" class="filter-window mt-2">
					<p class="is-size-6 ml-2">{{ t.orderBy }}</p>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="createdAt:desc" @input="changeSort" size="is-small" v-model="sort">{{ t.new }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="price:desc" @input="changeSort" size="is-small" v-model="sort">{{ t.priceHigh }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="price:asc" @input="changeSort" size="is-small" v-model="sort">{{ t.priceLow }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="createdAt:asc" size="is-small" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
					</b-field>
				</div>

				<div class="columns mt-6 is-mobile is-multiline">
					<div v-for="(product, index) in storeProducts" :key="index" class="column is-half">
						<div class="products-container">
							<div
								class="img"
								@click="editProduct(product.id)"
								:style="[product.pictures ? { 'background-image': `url(${product.pictures[0].url})` } : { background: '#FFF' }]"
							>
								<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
									>{{ product.productSales[0].discount }}% {{ t.off }}</span
								>
							</div>
							<div class="media">
								<div class="media-left" style="max-width: 105px; min-width: 105px">
									<p class="product-name mt-2">{{ product.title }}</p>
									<div class="is-flex">
										<p
											:style="[
												product.productSales.length > 0 && product.productSales[0].isActive == true
													? { textDecoration: 'line-through' }
													: { textDecoration: 'none', fontWeight: 'bold' },
											]"
										>
											{{ product.price.toFixed(2) }} €
										</p>
										<p class="ml-2 has-text-weight-bold" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
											{{ calculateSale(product.price, product.productSales[0].discount) }} €
										</p>
									</div>
								</div>
								<div class="media-content"></div>
								<div class="media-right ml-0">
									<p @click="editProduct(product.id)" class="product-name mt-2 mr-4">{{ t.edit }}</p>
									<b-icon @click.native="removeProduct(product.id, index)" pack="rt" icon="remove" size="is-size-5" type="is-red"></b-icon>
								</div>
							</div>
							<p class="has-text-danger is-size-7" v-if="!product.isActive">{{ t.inactive }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getProducts, deleteProduct } from '@/api/store';
	export default {
		components: {},

		data() {
			return {
				t: this.$t(`components.Store.StoreProducts`),
				storeProducts: [],
				page: 1,
				totalProducts: 0,
				pageItems: 8,
				isRequesting: false,
				offset: 300,
				sort: 'createdAt:desc',
				showFilter: false,
				offset: 75,
			};
		},

		methods: {
			editProduct(id) {
				this.$router.push({ name: 'EditProduct', params: { id: id } });
			},

			openFilter() {
				this.showFilter = !this.showFilter;
			},

			removeProduct(id, index) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteProduct,
					message: this.t.deleteMessage,
					confirmText: this.t.deleteProduct,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						deleteProduct(id);
						this.$buefy.toast.open({
							message: this.t.deletedSuccess,
							position: 'is-top',
							type: 'is-primary',
						});
						this.storeProducts.splice(index, 1);
					},
				});
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
						.then((response) => {
							this.storeProducts.push(...response.data.data);
							this.endScroll = false;
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			changeSort() {
				this.setIsPageLoading(true);
				this.page = 1;
				getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
					.then((response) => {
						console.log(response);
						this.storeProducts = response.data.data;
						this.totalProducts = response.data.meta.pagination.total;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: this.$t('shared.error'),
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},
		},

		created() {
			this.setIsPageLoading(true);

			getProducts(this.getUser.store.id, this.page, this.pageItems, null, null, null, null, null, null, null, null, this.sort, null, false)
				.then((response) => {
					console.log(response);
					this.storeProducts = response.data.data;
					this.totalProducts = response.data.meta.pagination.total;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
					this.$buefy.toast.open({
						message: this.$t('shared.error'),
						position: 'is-top',
						type: 'is-danger',
					});
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.search-container .field {
		border: 0.5px solid $grey;
		border-radius: 5px;
		width: 274px;
		@include until($mobile-s) {
			width: 230px;
		}
	}

	.icon-box {
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.search-container {
		display: inline-flex;
	}

	.right {
		float: right;
	}

	.items-list {
		padding-bottom: 90px;
	}

	.img {
		border-radius: 7px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		width: 100%;
		height: 144px;
		border: 1px solid $grey-light;
	}

	.tag {
		width: 79px;
		height: 32px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		transform: translate(10px, 10px);
	}

	.product-name {
		font-size: 0.858rem;
		text-align: left;
		font-weight: 300;
	}

	.price {
		font-size: 1rem;
		font-weight: 600;
		text-align: left;
	}

	.columns {
		justify-content: unset !important;
	}

	.filter-window {
		width: 124px;
		height: 150px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 0.718rem;
		position: absolute;
		right: 23px;
		z-index: 10;
		padding: 2px;
		background-color: #ffffff;
	}

	/deep/ .control.has-icons-left .icon,
	.control.has-icons-right .icon {
		color: $black;
	}
</style>
