<template>
	<section>
		<div class="px-6 tabs">
			<span @click="tab = 0">
				{{ t.create }}
				<hr class="span-hr" :class="{ primary: tab === 0 }" />
			</span>
			<span @click="tab = 1">
				{{ t.vouchers }}
				<hr class="span-hr" :class="{ primary: tab === 1 }" />
			</span>
		</div>
		<hr class="absolute" />
		<CreateVouchers v-if="tab === 0" />
		<ListVouchers v-if="tab === 1" />
	</section>
</template>

<script>
	import CreateVouchers from '@/components/Store/CreateVoucher.vue';
	import ListVouchers from '@/components/Store/ListVouchers.vue';
	export default {
		components: {
			CreateVouchers,
			ListVouchers,
		},

		data() {
			return {
				t: this.$t(`views.StoreVouchers`),
				tab: 0,
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $primary;
	}

	.name {
		font-size: 1rem;
	}

	.address {
		font-size: 0.857rem;
	}

	.description {
		font-size: 0.714rem;
	}

	.tabs {
		display: flex;
		justify-content: space-evenly;
		padding-top: 100px;
	}

	.absolute {
		position: absolute;
		top: 108px;
		left: 20px;
		right: 20px;
		z-index: -1;
	}

	.primary {
		background-color: $primary;
		margin-top: 8px !important;
	}

	.span-hr {
		margin-top: 8px !important;
	}
</style>
