<template>
	<div>
		<div class="header-container pt-8">
			<b-icon @click.native="updateStep" class="pointer" icon="chevron-left" type="is-grey-darker" custom-size="is-size-4"></b-icon>
			<p @click="updateStep" class="header ml-2 pointer">{{ t.back }}</p>
		</div>
		<p class="sign-text">{{ t.create }}</p>

		<hr />
		<form @submit.prevent="create">
			<div style="display: inline-flex">
				<b-field :label="t.name">
					<b-input class="input" size="is-medium" name="name" :placeholder="t.name" type="text" required v-model.trim="form.name"></b-input>
				</b-field>
				<b-field :label="t.date" class="ml-4">
					<b-datepicker
						class="input"
						size="is-medium"
						:placeholder="t.date"
						name="bday"
						:mobile-native="false"
						:locale="$i18n.locale"
						:icon-right-clickable="true"
						@icon-right-click="clearBirthday()"
						trap-focus
						required
						v-model="form.birthday"
						:max-date="today"
					></b-datepicker>
				</b-field>
			</div>
			<div style="width: 285px; float: right">
				<p class="has-text-primary is-size-6">{{ t.birthMessage }}</p>

				<p v-show="errorDate" class="has-text-red is-size-6">{{ t.enterDate }}</p>
				<p v-if="errorAge" class="has-text-red is-size-6">{{ t.errorAge }}</p>
			</div>

			<div style="display: inline-flex" class="mt-4">
				<b-field :label="t.email">
					<b-input class="input" size="is-medium" name="email" :placeholder="t.email" type="email" v-model.trim="form.email" required></b-input>
				</b-field>
				<b-field :label="t.password" class="ml-4">
					<b-input
						class="input"
						size="is-medium"
						name="new-password"
						:placeholder="t.password"
						type="password"
						v-model="form.password"
						password-reveal
						required
					></b-input>
				</b-field>
			</div>
			<p v-show="errorEmail" class="has-text-red is-size-6 mt-2">{{ t.errorEmail }}</p>
			<div style="display: inline-flex" class="mt-4">
				<b-field>
					<b-checkbox class="mt-6" v-model="form.acceptsTerms" required></b-checkbox
					><span class="remember"
						>{{ t.accept }}<span class="has-text-primary is-clickable" @click="openTerms">{{ t.terms }} </span>
					</span>
				</b-field>
				<b-field :label="t.confirm" class="margin">
					<b-input
						class="input"
						size="is-medium"
						name="new-password"
						:placeholder="t.confirm"
						v-model="confirmPassword"
						type="password"
						password-reveal
						required
					></b-input>
				</b-field>
			</div>
			<p v-show="this.errorPassword" style="float: right" class="has-text-red is-size-6 mt-2">{{ t.errorPassword }}</p>

			<div class="center-inside pt-14">
				<b-button type="is-primary" :loading="isLoading" class="btn-signin" native-type="submit">{{ t.signup }}</b-button> <span>{{ t.or }}</span>
				<b-button class="is-full-round" type="is-white" @click="google">
					<b-icon icon="google" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button>
				<b-button class="is-full-round" type="is-white" @click="facebook">
					<b-icon icon="facebook" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button>
				<!-- <b-button class="is-full-round" type="is-white" @click="apple">
					<b-icon icon="apple" custom-size="is-size-4" type="is-green"></b-icon>
				</b-button> -->
			</div>
		</form>
	</div>
</template>

<script>
	import { createAccount } from '@/api/auth';

	import socialAuth from '@/mixins/modules/auth/socialAuth';
	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Register`),
				form: {
					email: null,
					password: null,
					name: null,
					birthday: null,
					gender: null,
					marketing: false,
					acceptsTerms: null,
					role: 'authenticated',
				},

				confirmPassword: null,
				errorPassword: false,
				errorDate: false,
				errorEmail: false,
				isLoading: false,
				today: new Date(),
				errorAge: false,
			};
		},
		mixins: [socialAuth],
		methods: {
			updateStep() {
				this.$store.commit('desktopLoginStep/setStep', 0);
			},
			goToCode() {
				this.$store.commit('desktopLoginStep/setStep', 2);
			},
			facebook() {
				this.facebookLogin(this.nextStep);
			},
			google() {
				this.googleLogin(this.nextStep);
			},
			apple() {
				this.appleLogin(this.nextStep);
			},
			nextStep() {
				if (!this.getUser.favoriteCategories || this.getUser.favoriteCategories.length == 0) {
					this.goToPage('ChooseUserStyle');
				} else this.goToPage('Homepage');
			},
			create() {
				this.isLoading = true;
				if (this.form.birthday === null) {
					this.errorDate = true;
					this.isLoading = false;
				} else if (new Date().getFullYear() - this.form.birthday.getFullYear() < 18) {
					this.isLoading = false;
					this.errorDate = false;
					this.errorAge = true;
				} else if (this.form.password !== this.confirmPassword || this.form.password.length < 8) {
					this.errorPassword = true;
					this.errorDate = false;
					this.isLoading = false;
					this.errorAge = false;
				} else {
					this.errorPassword = false;
					this.errorEmail = false;
					this.errorAge = false;
					this.errorDate = false;
					createAccount(this.form)
						.then((response) => {
							this.addToken(response.data.jwt);
							this.addUser(response.data.user);
							this.addPw(this.form.password);
							this.$buefy.toast.open({
								message: this.t.verify,
								position: 'is-top',
								type: 'is-primary',
								duration: 4000,
							});

							this.isLoading = false;
							this.goToCode();
						})
						.catch((error) => {
							console.log(error);
							this.isLoading = false;
							if (error.response.data.error.message === 'Email is already taken') {
								this.errorEmail = true;
							}
						});
				}
			},
			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.open(routeData.href, '_blank');
			},
		},
		created() {
			this.getAuthResult(this.nextStep);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 20px;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	hr {
		width: 40px;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 300px;
	}

	.remember {
		font-size: 1rem;
		transform: translate(0, 20px);
		color: $grey;
		width: 280px;
	}

	.btn-signin {
		width: 360px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		justify-content: space-between !important;
		width: 540px;
	}

	.is-full-round {
		box-shadow: 5px 5px 27px #00000021 !important;
	}

	/* 	.margin {
		margin-left: 84px;
	} */

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	.pointer {
		cursor: pointer;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
