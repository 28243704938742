<template>
	<section>
		<div class="header-container pt-8">
			<b-icon @click.native="updateStep" class="pointer" icon="chevron-left" type="is-grey-darker" custom-size="is-size-4"></b-icon>
			<p @click="updateStep" class="header ml-2 pointer">{{ t.back }}</p>
		</div>
		<div class="px-6 mt-14 title">
			<p>{{ t.message }} {{ this.getRecoverEmail }}</p>

			<form @submit.prevent="verify">
				<div class="number-container">
					<input class="input mt-12" type="number" ref="input1" maxlength="1" @input="firstNumber" :value="numberOne" required />
					<input class="input mt-12" type="number" ref="input2" maxlength="1" @input="secondNumber" :value="numberTwo" required />
					<input class="input mt-12" type="number" ref="input3" maxlength="1" @input="thirdNumber" :value="numberThree" required />
					<input class="input mt-12" type="number" ref="input4" maxlength="1" @input="forthNumber" :value="numberFour" required />
				</div>

				<div class="mt-12 password-container">
					<b-field :label="t.password">
						<b-input class="password-input" size="is-medium" :placeholder="t.password" type="password" v-model="password" password-reveal required></b-input>
					</b-field>

					<b-field :label="t.confirmPassword" class="mt-8">
						<b-input
							class="password-input"
							size="is-medium"
							:placeholder="t.confirmPassword"
							type="password"
							v-model="confirmPassword"
							password-reveal
							required
						></b-input>
					</b-field>
				</div>
				<p v-if="errorPassword" class="has-text-danger is-size-6 has-text-centered mt-3">{{ t.errorPassword }}</p>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" native-type="submit" :loading="isLoading" class="btn">{{ t.change }}</b-button>
				</div>
			</form>

			<div class="has-text-centered mt-14">
				<span @click="resend" class="is-clickable">{{ t.resend }} </span>
			</div>
		</div>
	</section>
</template>

<script>
	import { changePassword, forgotPassword } from '@/api/auth';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.ConfirmEmailCode`),
				email: null,
				number: null,
				numberOne: null,
				numberTwo: null,
				numberThree: null,
				numberFour: null,
				password: null,
				confirmPassword: null,
				isLoading: false,
				errorPassword: false,
			};
		},
		methods: {
			firstNumber(event) {
				const value = event.target.value;
				console.log(event);

				if (String(value).length <= 1) {
					this.numberOne = value;
				}
				this.$forceUpdate();
				this.$refs.input2.focus();
			},

			secondNumber(event) {
				const value = event.target.value;
				/* if (event.inputType == 'deleteContentBackward') {
					this.$refs.input1.focus();
				} else {
					
				} */
				if (String(value).length <= 1) {
					this.numberTwo = value;
				}
				this.$forceUpdate();
				this.$refs.input3.focus();
			},
			thirdNumber(event) {
				const value = event.target.value;
				/* if (event.inputType == 'deleteContentBackward') {
					this.$refs.input2.focus();
				} else {
					
				} */
				if (String(value).length <= 1) {
					this.numberThree = value;
				}
				this.$forceUpdate();
				this.$refs.input4.focus();
			},
			forthNumber(event) {
				const value = event.target.value;

				/* if (event.inputType == 'deleteContentBackward') {
					this.$refs.input3.focus();
				} else {
					
				} */
				if (String(value).length <= 1) {
					this.numberFour = value;
				}
				this.$forceUpdate();
			},

			/* prev(event, number) {
				if (event.code == 'Backspace' || event.code == 'Delete') {
					if (number == 1) {
						this.$refs.input1.focus();
					} else if (number == 2) {
						this.$refs.input2.focus();
					} else if (number == 3) {
						this.$refs.input3.focus();
					}
				}
			}, */

			updateStep() {
				this.$store.commit('desktopLoginStep/setStep', 0);
			},
			verify() {
				this.number = this.numberOne.concat(this.numberTwo, this.numberThree, this.numberFour);
				this.isLoading = true;
				if (this.password !== this.confirmPassword || this.password.length < 8) {
					this.errorPassword = true;
					this.isLoading = false;
				} else {
					this.errorPassword = false;
					let query = {
						code: this.number,
						email: this.getRecoverEmail,
					};

					let body = {
						password: this.password,
						passwordConfirmation: this.confirmPassword,
					};
					changePassword(query, body)
						.then((response) => {
							console.log(response);
							this.isLoading = false;
							this.$buefy.toast.open({
								message: this.t.passwordChanged,
								position: 'is-top',
								type: 'is-primary',
							});
							this.updateStep();
						})
						.catch((error) => {
							console.log(error);
							console.log(error.response.data.error.message);
							if (error.response.data.error.message == 'Passwords do not match') {
								this.$buefy.toast.open({
									message: this.t.errorPassword,
									position: 'is-top',
									type: 'is-danger',
									duration: 4000,
								});
							} else if (error.response.data.error.message == 'Incorrect code provided') {
								this.$buefy.toast.open({
									message: this.t.errorCode,
									position: 'is-top',
									type: 'is-danger',
								});
							} else {
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
									duration: 4000,
								});
							}
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},

			resend() {
				forgotPassword(this.getRecoverEmail)
					.then((response) => {
						console.log(response);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: this.t.emailSent,
							position: 'is-top',
							type: 'is-primary',
							duration: 4000,
						});
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: 'An error occurred',
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.title {
		font-size: 1.35rem;
		font-weight: 500;
	}

	.input {
		border: none;
		background: none;
		border-bottom: solid 1px $grey;
		width: 50px;
		padding: 0;
		text-align: center;
		font-weight: bold;
		font-size: 1.35rem;
	}

	.number-container {
		display: flex;
		justify-content: space-between;
		@include from($mobile-l) {
			width: 360px;
			margin: 0 auto;
		}
	}

	.button-container {
		width: 100%;
		margin-top: 80px;
		@include from($mobile-s) {
			margin-top: 80px;
		}
		@include from($mobile-l) {
			width: 50%;
			margin: 80px auto;
		}
	}

	.btn {
		width: 100%;
		height: 46px;
		border-radius: 14px;
	}

	span {
		color: $primary;
		font-size: 1rem;
	}

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	.pointer {
		cursor: pointer;
	}

	.password-input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 300px;
	}

	.password-container {
		display: grid;
		justify-content: center;
	}
</style>
