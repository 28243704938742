<template>
	<section>
		<form v-if="product" class="form">
			<div class="header-container pt-8 px-6">
				<div style="display: inline-flex">
					<b-icon icon="chevron-left " @click.native="goToPage('DesktopStoreProducts')" type="is-grey-dark" class="pointer" custom-size="is-size-4"> </b-icon>
					<p class="has-text-grey-dark ml-2 pointer" @click="goToPage('DesktopStoreProducts')">{{ t.back }}</p>
				</div>
			</div>

			<b-tabs size="is-large">
				<b-tab-item :label="t.product">
					<div class="left-container">
						<div class="input-container">
							<b-field style="width: 98%">
								<b-input class="input mt-6" v-model.trim="product.title" name="title" v-validate="'required'" :placeholder="t.name"></b-input>
							</b-field>
							<p v-show="errors.has('title')" class="help is-danger">{{ t.requiredField }}</p>
							<b-field style="width: 98%" class="mt-6 mb-5">
								<b-input v-model.trim="product.description" name="description" v-validate="'required'" :placeholder="t.description" type="textarea"></b-input>
							</b-field>
							<p v-show="errors.has('description')" class="help is-danger">{{ t.requiredField }}</p>

							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-select
											class="input small mt-2"
											type="text"
											name="gender"
											v-validate="'required'"
											ref="level0"
											:placeholder="t.gender"
											expanded
											v-model="selectedCategories.level0"
											@input="(option) => onCategorySelected(option, 0)"
										>
											<option v-for="category in availableCategories.level0" :value="category" :key="category.id">
												{{ category.name }}
											</option>
										</b-select>
									</b-field>
									<p v-show="errors.has('gender')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-select class="input small mt-2" type="text" name="brand" v-model="product.brand" v-validate="'required'" :placeholder="t.brand" expanded>
											<option v-for="brand in brands" :value="brand" :key="brand.id">
												{{ brand.name }}
											</option>
										</b-select>
									</b-field>
									<p v-show="errors.has('brand')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>
							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-autocomplete
											rounded
											v-model="search.level1"
											:data="filteredLevelOne"
											:keep-first="true"
											:open-on-focus="true"
											field="name"
											:placeholder="t.category"
											icon="magnify"
											clearable
											ref="level1"
											name="level1"
											v-validate="'required'"
											@select="(option) => onCategorySelected(option, 1)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level1')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-autocomplete
											rounded
											v-model="search.level2"
											:data="filteredLevelTwo"
											:keep-first="true"
											field="name"
											:placeholder="t.subcategory"
											:open-on-focus="true"
											icon="magnify"
											clearable
											ref="level2"
											name="level2"
											v-validate="'required'"
											@select="(option) => onCategorySelected(option, 2)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level2')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>
							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-autocomplete
											v-show="availableCategories.level3.length > 0"
											rounded
											v-model="search.level3"
											:data="filteredLevelThree"
											:keep-first="true"
											:open-on-focus="true"
											field="name"
											placeholder="SubCategory2"
											icon="magnify"
											clearable
											ref="level3"
											name="level3"
											v-validate="availableCategories.level3.length > 0 ? 'required' : ''"
											@select="(option) => onCategorySelected(option, 3)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level3')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-autocomplete
											v-show="availableCategories.level4.length > 0"
											rounded
											v-model="search.level4"
											:data="filteredLevelFour"
											:keep-first="true"
											field="name"
											placeholder="SubCategory3"
											:open-on-focus="true"
											icon="magnify"
											clearable
											ref="level4"
											name="level4"
											v-validate="availableCategories.level4.length > 0 ? 'required' : ''"
											@select="(option) => onCategorySelected(option, 4)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level4')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>

							<div class="columns">
								<div class="column is-half">
									<b-field :label="t.price">
										<b-numberinput
											:controls="false"
											class="input small mt-2"
											name="price"
											v-validate="'required'"
											v-model="product.price"
											:placeholder="t.price"
											step="0.01"
											expanded
										></b-numberinput>
									</b-field>
									<p v-show="errors.has('price')" class="help is-danger">{{ t.requiredField }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-field :label="t.weight">
											<b-numberinput
												:controls="false"
												class="input small mt-2"
												name="weight"
												v-validate="'required'"
												v-model="product.weight"
												:placeholder="t.weight"
												step="0.01"
												expanded
											></b-numberinput>
										</b-field>
									</b-field>
									<p v-show="errors.has('weight')" class="help is-danger">{{ t.requiredField }}</p>
								</div>
							</div>
						</div>
					</div>

					<div class="right-container px-6">
						<!-- <div class="columns">
							<div class="column is-half">
								<b-field class="mt-4">
									<b-checkbox size="is-large" class="checkbox mt-3" v-model="product.isNewSeason">
										<span style="font-size: 0.9rem">{{ t.newSeason }} </span>
									</b-checkbox>
								</b-field>
							</div>
						</div> -->

						<div class="columns mt-4">
							<div class="column is-half">
								<b-field class="select-field">
									<b-checkbox size="is-large" class="checkbox mt-3" v-model="sale.isActive">
										<span style="font-size: 0.9rem">{{ t.isSale }} </span>
									</b-checkbox>
								</b-field>
							</div>
							<div class="column">
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										name="valueSale"
										:placeholder="t.insertValue"
										v-validate="sale.isActive == true ? 'required' : ''"
										v-model="sale.discount"
										:min="sale.isActive ? '1' : '0'"
										:max="100"
										:disabled="sale.isActive ? false : true"
										ref="discount"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueSale')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>

						<div v-if="sale.isActive" style="display: inline-flex">
							<b-field class="picker-input" :label="t.saleStart">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="sale.startAt"
									:min-date="yesterday"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>

							<b-field class="picker-input ml-4" :label="t.saleEnd">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="sale.endAt"
									:min-date="sale.startAt"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>
						</div>

						<div class="columns">
							<div class="column is-half">
								<b-field class="select-field">
									<b-checkbox size="is-large" class="checkbox mt-3" v-model="product.givesMoneyOnCard">
										<span style="font-size: 0.9rem">{{ t.moneyCard }} </span>
									</b-checkbox>
								</b-field>
							</div>
							<div class="column">
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										name="valueCard"
										v-model="product.moneyOnCardPercentage"
										v-validate="product.givesMoneyOnCard ? 'required' : ''"
										:placeholder="t.insertValue"
										:min="product.givesMoneyOnCard ? '1' : '0'"
										:disabled="product.givesMoneyOnCard ? false : true"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueCard')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>
					</div>
				</b-tab-item>
				<b-tab-item :label="t.variants">
					<div v-for="(variant, variantIndex) in productVariants" class="mt-4" :key="variantIndex">
						<div>
							<div style="display: inline-flex">
								<p style="font-size: 1.4rem">{{ t.variant }} {{ variantIndex + 1 }}</p>

								<b-icon
									@click.native="removeVariant(variantIndex, variant.id)"
									pack="rt"
									icon="remove"
									size="is-size-4"
									class="ml-3 is-clickable"
									type="is-red"
								></b-icon>
							</div>
							<p v-if="removeVariantMessage" class="has-text-red">{{ t.removeVariantError }}</p>

							<div class="photo-container mt-4">
								<b-upload
									v-model="variant.pictures[photoIndex]"
									v-for="(photo, photoIndex) in variant.pictures"
									:key="photoIndex"
									class="file-label mr-2"
									accept="image/*"
								>
									<div class="photo center-inside">
										<img v-if="photo" class="profile-photo" :src="photo.url ? photo.url : getFileURL(variantIndex, photoIndex)" alt="" />
										<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
									</div>
								</b-upload>
							</div>
							<p v-if="imageMessage" class="has-text-red mt-2">{{ t.imageRequired }}</p>

							<b-field>
								<b-select class="input-size small mt-8" type="text" name="color" v-validate="'required'" v-model="variant.color" :placeholder="t.color" expanded>
									<option v-for="color in colors" :value="color" :key="color.id">
										{{ color.name }}
									</option>
								</b-select>
							</b-field>
							<p v-show="errors.has('color')" class="help is-danger">{{ t.requiredSelect }}</p>

							<div class="mt-8">
								<div class="is-flex">
									<div class="mr-2">
										<p class="table-label">{{ t.sizes }}</p>
										<b-field v-for="(productSize, productSizeIndex) in variant.productSizes" :key="productSizeIndex">
											<div>
												<b-select
													class="input-size small mt-2"
													v-model="productSize.size"
													type="text"
													v-validate="'required'"
													name="size"
													:placeholder="t.sizes"
													expanded
												>
													<option v-for="size in sizes" :key="size.id" :value="size">
														{{ size.name }}
													</option>
												</b-select>
											</div>
										</b-field>
										<p v-show="errors.has('size')" class="help is-danger">{{ t.requiredSelect }}</p>
									</div>

									<div class="ml-2">
										<p class="table-label">{{ t.quantities }}</p>
										<b-field v-for="(productSize, productSizeIndex) in variant.productSizes" :key="productSizeIndex">
											<div class="is-flex">
												<input
													type="number"
													min="0"
													style="width: 130px; height: 40px"
													class="input my-2"
													name="unit"
													v-model="productSize.stockNumber"
													v-validate="'numeric|required'"
													:placeholder="t.units"
												/>

												<b-icon
													@click.native="removeProductLine(variantIndex, productSizeIndex)"
													pack="rt"
													icon="remove"
													size="is-size-4"
													class="ml-3 mt-3 is-clickable"
													type="is-red"
												></b-icon>
												<b-icon
													@click.native="addProductLine(variantIndex)"
													pack="rt"
													icon="plus"
													size="is-size-4"
													class="ml-5 mt-3 is-clickable"
													type="is-primary"
												></b-icon>
											</div>
										</b-field>
										<p v-show="errors.has('unit')" class="help is-danger mt-4">{{ t.requiredField }} and must be equal or more than 0</p>
									</div>
								</div>
								<p v-if="removeSizeMessage" class="has-text-red">{{ t.removeSizeError }}</p>
							</div>
						</div>
					</div>

					<b-button @click="addVariant" :loading="isLoading" type="is-primary" class="btn-small mt-14">{{ t.new }}</b-button> <br />
				</b-tab-item>
			</b-tabs>
			<div class="button-container">
				<b-button :loading="isLoading" @click="update" type="is-primary" class="btn mx-3">{{ t.post }}</b-button>
				<b-button :loading="isLoading" type="is-danger" @click="removeProduct" class="mx-3 btn-small"
					><b-icon pack="rt" icon="remove" size="is-size-5" type="is-white"></b-icon
				></b-button>
			</div>
		</form>
	</section>
</template>

<script>
	import {
		getProductById,
		getBrands,
		getCategories,
		getColors,
		getSizesGroups,
		updateProduct,
		updateProductSale,
		editUploadImage,
		addProductVariant,
		addProductSize,
		updateProductSize,
		updateProductVariant,
		deleteProductVariant,
		deleteProductSize,
		addProductSale,
		deleteProduct,
	} from '@/api/store';
	import moment from 'moment';
	export default {
		name: 'EditProduct-Desktop',
		data() {
			return {
				t: this.$t(`components.Store.EditProduct`),
				isLoading: false,
				showMenu: false,
				removeSizeMessage: false,
				removeVariantMessage: false,
				imageMessage: false,

				product: null,
				productVariants: [],

				brands: [],
				colors: [],
				sizes: [],

				selectedCategories: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				search: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				availableCategories: {
					level0: [],
					level1: [],
					level2: [],
					level3: [],
					level4: [],
					level5: [],
				},

				maxLevel: 5,

				createdRunning: true,

				today: new Date(),
				yesterday: new Date(),

				sale: {
					isNew: true,
					startAt: new Date(),
					endAt: null,
					discount: null,
					isActive: false,
				},
				isNew: true,

				captions: [],
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},
			onCategorySelected(category, level) {
				if (!category) {
					// se limpar um input de categoria tem que limpar as descendentes tb
					for (let i = level; i < this.maxLevel; i++) {
						this.selectedCategories[`level${i}`] = null;
						this.search[`level${i}`] = null;
					}

					return;
				}
				// get children
				this.selectedCategories[`level${category.level}`] = category;

				if (!this.createdRunning)
					getCategories({
						'filters[parent]': category.id,
					})
						.then((response) => {
							this.availableCategories[`level${category.level + 1}`] = response.data.data;
							if (level < 4) {
								this.$refs[`level${level + 1}`].focus();
							}
						})
						.catch((error) => {
							console.log(error);
						});
			},

			getFileURL(index, subIndex) {
				if (this.productVariants[index]) {
					return window.URL.createObjectURL(this.productVariants[index].pictures[subIndex]);
				}
			},

			removeVariant(index, id) {
				if (this.productVariants.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteVariant,
						message: this.t.deleteVariantMessage,
						confirmText: this.t.deleteVariant,
						cancelText: this.t.cancel,
						type: 'is-danger',
						hasIcon: true,
						onConfirm: () => {
							deleteProductVariant(id);
							this.productVariants.splice(index, 1);
							this.removeVariantMessage = false;
						},
					});
				} else this.removeVariantMessage = true;
			},

			addVariant() {
				this.productVariants.push({
					isNew: true,
					color: null,
					pictures: [null, null, null],
					productSizes: [
						{
							isNew: true,
							size: null,
							stockNumber: null,
							id: null,
						},
					],
					data: [
						{
							id: null,
						},
						{
							id: null,
						},
						{
							id: null,
						},
					],
					id: null,
				});
			},

			addProductLine(variantIndex) {
				this.removeSizeMessage = false;
				this.productVariants[variantIndex].productSizes.push({
					isNew: true,
					size: null,
					stockNumber: null,
					stockStatus: null,
					id: null,
				});
			},

			removeProductLine(variantIndex, sizeIndex) {
				if (this.productVariants[variantIndex].productSizes.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteProductSize,
						message: this.t.deleteProductSizeMessage,
						confirmText: this.t.deleteProductSize,
						cancelText: this.t.cancel,
						type: 'is-danger',
						hasIcon: true,
						onConfirm: () => {
							if (!this.productVariants[variantIndex].productSizes[sizeIndex].isNew) deleteProductSize(this.productVariants[variantIndex].productSizes[sizeIndex].id);

							this.productVariants[variantIndex].productSizes.splice(sizeIndex, 1);
							this.removeSizeMessage = false;
						},
					});
				} else this.removeSizeMessage = true;
			},

			update() {
				this.$validator.validateAll().then(async (result) => {
					if (!result) {
						this.$buefy.toast.open({
							message: this.t.fields,
							position: 'is-top',
							type: 'is-danger',
						});
						this.isLoading = false;
					} else if ((this.sale.isActive && this.sale.discount == 0) || (this.sale.isActive && this.sale.discount > 100)) {
						this.isLoading = false;
						this.$nextTick(() => this.$refs.discount.focus());
					} else {
						this.isLoading = true;

						let newImagesToUpload = [];

						for (let variantIndex = 0; variantIndex < this.productVariants.length; variantIndex++) {
							if (this.productVariants[variantIndex].pictures.length == 3) {
								this.productVariants[variantIndex].pictures.forEach((photo, photoIndex) => {
									if (photo) {
										if (photo instanceof File) {
											newImagesToUpload.push(photo);
											this.captions.push({ caption: `${variantIndex.toString()}_${photoIndex.toString()}` });
										}
									} else {
										this.imageMessage = true;
										this.isLoading = false;
									}
								});
							}
							if (this.isLoading == false) {
								return;
							}
						}

						if (newImagesToUpload.length > 0) {
							await editUploadImage(newImagesToUpload, this.captions)
								.then((response) => {
									response.data.forEach((photo) => {
										let [variantIndex, photoIndex] = photo.caption.split('_');
										this.productVariants[variantIndex].pictures[photoIndex] = photo;
									});
								})
								.catch((error) => {
									console.log('ERROR ON editUploadImage');
									this.isLoading = false;
									throw error;
								});
						}

						for (let variantIndex = 0; variantIndex < this.productVariants.length; variantIndex++) {
							for (let sizeIndex = 0; sizeIndex < this.productVariants[variantIndex].productSizes.length; sizeIndex++) {
								this.productVariants[variantIndex].productSizes[sizeIndex].stockStatus =
									this.productVariants[variantIndex].productSizes[sizeIndex].stockNumber > 0 ? 1 : 2;

								let clone = Object.clone(this.productVariants[variantIndex].productSizes[sizeIndex]);
								clone.size = clone.size.id;

								if (this.productVariants[variantIndex].productSizes[sizeIndex].isNew) {
									await addProductSize(clone)
										.then((response) => {
											this.productVariants[variantIndex].productSizes[sizeIndex] = response.data.data;
										})
										.catch((error) => {
											console.log('ERROR ON addProductSize');
											this.isLoading = false;
											throw error;
										});
								} else {
									await updateProductSize(clone.id, clone)
										.then((response) => {
											this.productVariants[variantIndex].productSizes[sizeIndex] = response.data.data;
										})
										.catch((error) => {
											console.log('ERROR ON updateProductSize');
											this.isLoading = false;
											throw error;
										});
								}
							}

							let clone = Object.clone(this.productVariants[variantIndex]);
							clone.color = clone.color.id;
							clone.pictures = clone.pictures.map((pic) => pic.id);
							clone.productSizes = clone.productSizes.map((ps) => ps.id);

							if (this.productVariants[variantIndex].isNew) {
								await addProductVariant(clone, {
									populate: 'color,pictures,productSizes.size',
								})
									.then((response) => {
										this.productVariants[variantIndex] = response.data.data;
									})
									.catch((error) => {
										console.log('ERROR ON addProductVariant');
										this.isLoading = false;
										throw error;
									});
							} else {
								await updateProductVariant(clone.id, clone, {
									populate: 'color,pictures,productSizes.size',
								})
									.then((response) => {
										this.productVariants[variantIndex] = response.data.data;
									})
									.catch((error) => {
										console.log('ERROR ON updateProductVariant');
										this.isLoading = false;
										throw error;
									});
							}
						}

						if (!this.sale.isNew) {
							let clone = Object.clone(this.sale);
							console.log(clone);
							console.log(moment(this.sale.endAt));
							clone.endAt = moment(this.sale.endAt).format('YYYY-MM-DD');
							clone.startAt = moment(this.sale.startAt).format('YYYY-MM-DD');
							await updateProductSale(clone.id, clone)
								.then((response) => {
									this.product.productSales[0] = response.data.data;
								})
								.catch((error) => {
									console.log('ERROR ON updateProductSale');
									this.isLoading = false;
									throw error;
								});
						} else {
							let clone = Object.clone(this.sale);
							clone.endAt = moment(this.sale.endAt).format('YYYY-MM-DD');
							clone.startAt = moment(this.sale.startAt).format('YYYY-MM-DD');
							await addProductSale(clone)
								.then((response) => {
									this.product.productSales.push(response.data.data);
								})
								.catch((error) => {
									console.log('ERROR ON addProductSale');
									this.isLoading = false;
									throw error;
								});
						}

						console.log(this.productVariants);

						let clone = Object.clone(this.product);
						clone.brand = clone.brand.id;
						clone.category = this.getLastCategory ? this.getLastCategory.id : null;
						clone.productVariants = this.productVariants.map((variant) => variant.id);
						clone.productSales = clone.productSales.map((ps) => ps.id);
						clone.store = clone.store.id;
						clone.pictures = this.productVariants[0].pictures.map((pic) => pic.id);

						await updateProduct(clone.id, clone, {
							populate: 'category,brand,store,productSales',
						})
							.then((response) => {
								this.product = response.data.data;

								this.$buefy.toast.open({
									message: this.t.productUpdated,
									position: 'is-top',
									type: 'is-primary',
								});
								this.isLoading = false;
								this.goToPage('DesktopStoreProducts');
							})
							.catch((error) => {
								console.log('ERROR ON updateProduct');
								throw error;
							})
							.finally(() => {
								this.isLoading = false;
							});
					}
				});
			},

			removeProduct() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteProduct,
					message: this.t.deleteProductMessage,
					confirmText: this.t.deleteProduct,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						deleteProduct(this.$route.params.id).finally(() => {
							this.$buefy.toast.open({
								message: this.t.productDeleted,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('DesktopStoreProducts');
						});
					},
				});
			},
		},

		computed: {
			filteredLevelOne() {
				if (this.search.level1)
					return this.availableCategories.level1.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level1.toString().toLowerCase()) >= 0);
				return this.availableCategories.level1;
			},
			filteredLevelTwo() {
				if (this.search.level2)
					return this.availableCategories.level2.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level2.toString().toLowerCase()) >= 0);
				return this.availableCategories.level2;
			},
			filteredLevelThree() {
				if (this.search.level3)
					return this.availableCategories.level3.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level3.toString().toLowerCase()) >= 0);
				return this.availableCategories.level3;
			},
			filteredLevelFour() {
				if (this.search.level4)
					return this.availableCategories.level4.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level4.toString().toLowerCase()) >= 0);
				return this.availableCategories.level4;
			},
			getLastCategory() {
				for (let i = this.maxLevel; i > 0; i--) {
					if (this.selectedCategories[`level${i}`]) {
						return this.selectedCategories[`level${i}`];
					}
				}
				return null;
			},
		},

		created() {
			this.yesterday.setDate(this.yesterday.getDate() - 1);
			getProductById(this.$route.params.id)
				.then(async (response) => {
					this.product = response.data.data;
					this.productVariants = this.product.productVariants;
					console.log(this.product);

					let setupCategories = async (category) => {
						this.selectedCategories[`level${category.level}`] = category;
						if (category.level != 0) {
							await getCategories({
								'filters[parent]': category.parent.id,
							}).then(async (response) => {
								this.availableCategories[`level${category.level}`] = response.data.data;
							});

							this.$refs[`level${category.level}`].setSelected(category);
						}
						if (category.parent) await setupCategories(category.parent);
					};

					if (this.product.category) await setupCategories(this.product.category);

					if (this.product.productSales.length > 0) {
						if (this.product.productSales[0].startAt == null || this.product.productSales[0].endAt == null) {
							this.product.productSales[0].startAt = this.today;
							this.product.productSales[0].endAt = this.today;
						} else {
							this.product.productSales[0].startAt = new Date(this.product.productSales[0].startAt);
							this.product.productSales[0].endAt = new Date(this.product.productSales[0].endAt);
						}

						this.sale = this.product.productSales[0];
					}

					this.createdRunning = false;
				})
				.catch((error) => {
					console.log(error);
				});

			getBrands()
				.then((response) => {
					this.brands = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getColors()
				.then((response) => {
					this.colors = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getSizesGroups()
				.then((response) => {
					this.sizes = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories({
				'filters[level]': 0,
				populate: 'parent',
			})
				.then((response) => {
					this.availableCategories.level0 = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.left-container {
		width: 50%;
		display: inline-block;
	}
	.right-container {
		width: 50%;
		display: inline-block;
		position: absolute;
		right: 0;
	}

	.header {
		font-size: 2.5rem;
	}

	.input {
		background-color: $white;
		border-radius: 11px;
		font-size: 1rem;
		height: 48px;
	}

	.container {
		margin-top: 20px;
	}

	.button-container {
		width: 100%;
		padding-bottom: 10px;
	}

	.btn {
		width: 350px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	.btn-small {
		width: 150px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	.photo-container {
		display: flex;
	}

	.photo {
		width: 125px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
		border: $grey-light dashed 2px;
	}

	img {
		width: 125px;
		height: 96px;
		object-fit: scale-down;
	}

	.small-input {
		display: flex;
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $grey-dark;
		right: 1.125em;
		z-index: 4;
	}

	.select-field {
		width: 400px;
	}

	.small {
		width: 96%;
	}

	/deep/ .sizes-label span {
		position: absolute;
		left: 10px;
		top: 7px;
	}

	/deep/ .field .control input {
		text-align: left;
		border: none;
	}

	/deep/ .select select {
		border: none !important;
	}

	.pointer {
		cursor: pointer;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 41;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.form {
		padding-bottom: 10rem;
		padding-left: 140px;
		padding-right: 140px;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.input-size {
		background-color: $white;
		border-radius: 11px;
		font-size: 1rem;
		height: 48px;
		width: 150px;
	}

	.top-hr {
		position: fixed;
		top: 76px;
		width: 100%;
		z-index: 40;
	}

	.table-label {
		font-size: 1.5rem;
	}

	/deep/ .picker-input {
		width: 150px;
	}

	/deep/ .has-addons {
		display: flex;
		flex-direction: column;
	}

	.textarea {
		resize: none;
	}
</style>
