import { get, post, remove } from '@/utils/http';
const queryString = require('qs');

export function createVoucher(voucher) {
	return post('/api/vouchers?populate=store,users', voucher);
}

export function getVouchers(query = {}) {
	let _query = queryString.stringify(query);
	console.log(query);
	return get('/api/vouchers?populate=store,store.pictures &' + _query);
}

export function deleteVoucher(id) {
	return remove('/api/vouchers/' + id);
}
