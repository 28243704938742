<template>
	<div>
		<b-tabs size="is-medium" expanded :animateInitially="true" :destroy-on-hide="true" v-model="tab">
			<b-tab-item :label="t.create">
				<CreateVoucher @changeTab="changeTab" />
			</b-tab-item>
			<b-tab-item label="Vouchers"><ListVoucher /></b-tab-item>
		</b-tabs>
	</div>
</template>

<script>
	import CreateVoucher from '@/components/Marketing/CreateVoucher.vue';
	import ListVoucher from '@/components/Marketing/ListVouchers.vue';
	export default {
		components: {
			CreateVoucher,
			ListVoucher,
		},
		data() {
			return {
				t: this.$t(`views.Marketing`),
				tab: 0,
			};
		},

		methods: {
			changeTab(value) {
				this.tab = value;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}
</style>
