<template>
	<div>
		<div class="header-container pt-8">
			<b-icon @click.native="updateStep" class="pointer" icon="chevron-left" type="is-grey-darker" custom-size="is-size-4"></b-icon>
			<p @click="updateStep" class="header ml-2 pointer">{{ t.back }}</p>
		</div>
		<p class="sign-text">{{ t.forgot }}</p>
		<p class="has-text-grey">{{ t.message }}</p>

		<form @submit.prevent="onSubmitPress">
			<div style="display: inline-flex" class="mt-4">
				<b-field :label="t.email">
					<b-input class="input" size="is-medium" name="email" :placeholder="t.email" type="email" v-model.trim="recover.email" required></b-input>
				</b-field>
			</div>

			<div class="center-inside pt-14">
				<b-button type="is-primary" :loading="isLoading" class="btn-signin" native-type="submit">{{ t.submit }}</b-button>
			</div>
		</form>
	</div>
</template>

<script>
	import { forgotPassword } from '@/api/auth';
	export default {
		data() {
			return {
				userType: [],
				t: this.$t(`components.Desktop.ForgotPassword`),
				recover: {
					email: null,
				},
				isLoading: false,
			};
		},

		methods: {
			updateStep() {
				this.$store.commit('desktopLoginStep/setStep', 0);
			},

			onSubmitPress() {
				this.isLoading = true;
				forgotPassword(this.recover.email)
					.then((response) => {
						console.log(response);
						this.isLoading = false;
						this.$store.commit('desktopLoginStep/setStep', 6);
						this.addRecoverEmail(this.recover.email);
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error.response.data.error.message);
						if (error.response.data.error.message == 'This email does not exist') {
							this.$buefy.toast.open({
								message: this.t.notExist,
								position: 'is-top',
								type: 'is-danger',
							});
						} else {
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 20px;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	hr {
		width: 40px;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 300px;
	}

	.remember {
		font-size: 1rem;
		transform: translate(0, 20px);
		color: $grey;
	}

	.btn-signin {
		width: 310px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		justify-content: space-between !important;
		width: 540px;
	}

	.is-full-round {
		box-shadow: 5px 5px 27px #00000021 !important;
	}

	.margin {
		margin-left: 84px;
	}

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	.pointer {
		cursor: pointer;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
	}
</style>
