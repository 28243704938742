<template>
	<section class="section">
		<div class="container">
			<b-button type="is-primary" class="m-4" v-for="(route, i) in getRoutes" :key="i" @click="goTo(route)">{{ route.name }}</b-button>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Router',
		computed: {
			getRoutes() {
				return this.$router.matcher.getRoutes().sort((a, b) => {
					var nameA = a.name.toUpperCase(); // ignore upper and lowercase
					var nameB = b.name.toUpperCase(); // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}

					// names must be equal
					return 0;
				});
			},
		},
		methods: {
			async goTo(route) {
				if (route.regex.keys[0]) {
					this.$buefy.dialog.prompt({
						message: route.regex.keys[0].name,
						trapFocus: true,
						onConfirm: (val) => {
							let r = {
								name: route.name,
								params: {},
							};
							r.params[route.regex.keys[0].name] = val;
							this.goToPage(r);
						},
					});
				} else {
					this.goToPage(route);
				}
			},
		},
	};
</script>

<style></style>
