<template>
	<section class="section">
		<div class="container">
			<div class="is-fixed-top"><b-icon @click.native="$router.back()" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
			<div class="mt-6">
				<span>{{ t.signup }}</span
				><br />
			</div>

			<div class="mt-22">
				<form @submit.prevent="create">
					<b-field
						><b-input class="input mt-10" size="is-medium" type="text" required name="organization" :placeholder="t.name" v-model.trim="form.name"></b-input
					></b-field>
					<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.storeName" v-model.trim="store.data.name"></b-input></b-field>
					<b-field
						><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.address" v-model.trim="store.data.location_address"></b-input
					></b-field>
					<b-field
						><b-input
							class="input mt-2"
							size="is-medium"
							v-model.trim="store.data.telephone"
							v-validate="'required|mobile-phone|numeric'"
							name="phone"
							ref="phone"
							type="text"
							:placeholder="t.storePhone"
						></b-input
					></b-field>
					<p v-show="errors.has('phone')" class="help is-danger">{{ t.invalidPhone }}</p>

					<b-field><b-input class="input mt-2" size="is-medium" name="email" type="email" required :placeholder="t.email" v-model.trim="form.email"></b-input></b-field>
					<p v-show="errorEmail" class="has-text-red is-size-7 mt-2">{{ t.errorEmail }}</p>
					<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.category" v-model.trim="store.data.category"></b-input></b-field>
					<b-field
						><b-input class="input mt-2" size="is-medium" required :placeholder="t.password" password-reveal type="password" v-model="form.password"></b-input
					></b-field>

					<b-field>
						<b-input class="input mt-2" size="is-medium" required :placeholder="t.confirmPassword" password-reveal type="password" v-model="confirmPassword"></b-input>
					</b-field>
					<p v-show="this.errorPassword" class="has-text-red is-size-7 mt-2">{{ t.errorPassword }}</p>

					<b-field>
						<b-datepicker
							class="input mt-6"
							size="is-medium"
							v-model="store.data.birthday"
							:placeholder="t.birth"
							name="bday"
							:mobile-native="false"
							:locale="$i18n.locale"
							:icon-right-clickable="true"
							@icon-right-click="clearBirthday()"
							trap-focus
							v-validate="'required'"
							:max-date="today"
						></b-datepicker>
					</b-field>
					<p v-show="errors.has('bday')" class="help is-danger">{{ t.emptyDate }}</p>
					<p v-if="errorAge" class="help is-danger">{{ t.errorAge }}</p>

					<b-field>
						<b-checkbox size="is-large" class="mt-6" required></b-checkbox><span class="terms">{{ t.accept }}</span>
					</b-field>

					<div class="button-container has-text-centered">
						<b-button type="is-primary" native-type="submit" :loading="isLoading" class="btn">{{ t.signup }}</b-button>
					</div>
				</form>
				<p class="mt-1 mb-5 has-text-grey has-text-centered">
					{{ t.byRegistering }} <span class="has-text-primary terms" @click="openTerms">{{ t.terms }}</span> {{ t.and }}
					<span class="has-text-primary terms">{{ t.privacy }}</span>
					{{ t.ofRoot }}
				</p>
			</div>
		</div>
	</section>
</template>

<script>
	import { createAccount, stripeAcceptTerms, login } from '@/api/auth';
	import { createStore } from '@/api/store';
	import { Browser } from '@capacitor/browser';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.StoreSignUp`),
				form: {
					email: null,
					password: null,
					name: null,
					role: 'store',
				},

				store: {
					data: {
						name: null,
						email: null,
						location_address: null,
						location_country: null,
						location_municipality: null,
						location_zipCode: null,
						cae: null,
						vat: null,
						iban: null,
						user: null,
						telephone: null,
						category: null,
						user: null,
						shippingMinValue: null,
						usermaxValue: null,
						birthday: null,
					},
				},
				confirmPassword: null,
				errorEmail: false,
				acceptsTerms: null,
				isLoading: false,
				errorPassword: false,
				errorAge: false,
				today: new Date(),
			};
		},

		methods: {
			create() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						if (this.form.password !== this.confirmPassword || this.form.password.length < 8) {
							this.errorPassword = true;
						} else if (new Date().getFullYear() - this.store.data.birthday.getFullYear() < 18) {
							this.isLoading = false;
							this.errorAge = true;
						} else {
							this.errorPassword = false;
							this.errorEmail = false;
							this.isLoading = true;
							createAccount(this.form)
								.then((response) => {
									console.log(response);

									this.addUser(response.data.user);
									this.addToken(response.data.jwt);
									this.store.data.user = this.getUser.id;
									this.store.data.email = this.form.email;

									createStore(this.store)
										.then((response) => {
											console.log(response);

											stripeAcceptTerms()
												.then((response) => {
													console.log(response);
													let loginData = {
														identifier: this.form.email,
														password: this.form.password,
													};
													login(loginData)
														.then((response) => {
															this.onLogin(response);
															this.goToPage('Homepage');
														})
														.catch((error) => {
															this.$buefy.toast.open({
																message: this.$t('shared.error'),
																position: 'is-top',
																type: 'is-danger',
															});
														});
												})
												.catch((error) => {
													console.log(error);
													this.isLoading = false;
												});
										})
										.catch((error) => {
											console.log(error);
											this.isLoading = false;
										});
								})
								.catch((error) => {
									console.log(error);
									this.isLoading = false;
									if (error.response.data.error.message === 'Email is already taken') {
										this.errorEmail = true;
									}
								});
						}
					}
				});
			},
			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				/* const openCapacitorSite = async () => {
					await Browser.open({ url: routeData.href });
				};
				openCapacitorSite(); */
				window.open(routeData.href, '_blank');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	span {
		font-size: 1.7rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		@include from($mobile-s) {
			margin-top: 60px;
		}
	}

	.terms {
		font-size: 1rem;
		transform: translate(-14px, 23px);
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.small-input {
		display: flex;
	}
	.small {
		width: 96%;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}
</style>
