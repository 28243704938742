<template>
	<section class="section">
		<div class="container">
			<form @submit.prevent="update">
				<b-tabs class="mt-2" size="is-medium" expanded>
					<b-tab-item :label="t.product">
						<b-field>
							<b-input class="input mt-6" name="title" v-validate="'required'" ref="title" :placeholder="t.name" v-model.trim="product.title"></b-input>
						</b-field>
						<p v-show="errors.has('title')" class="help is-danger">{{ t.requiredField }}</p>
						<b-field>
							<b-input
								class="mt-5"
								name="description"
								v-validate="'required'"
								ref="description"
								:placeholder="t.description"
								v-model.trim="product.description"
								type="textarea"
							></b-input>
						</b-field>
						<p v-show="errors.has('description')" class="help is-danger">{{ t.requiredField }}</p>

						<div class="columns is-mobile mt-2">
							<div class="column ml-2">
								<b-select
									class="input small mt-2"
									type="text"
									name="gender"
									v-model="selectedCategories.level0"
									ref="level0"
									v-validate="'required'"
									:placeholder="t.gender"
									expanded
									@input="(option) => onCategorySelected(option, 0)"
								>
									<option v-for="category in availableCategories.level0" :value="category" :key="category.id">
										{{ category.name }}
									</option>
								</b-select>

								<p v-show="errors.has('level1')" class="help is-danger mt-3">{{ t.requiredSelect }}</p>
							</div>

							<div class="column mr-2">
								<b-field>
									<b-select
										class="input small mt-2"
										type="text"
										v-model="product.brand"
										v-validate="'required'"
										name="brand"
										ref="brand"
										:placeholder="t.brand"
										expanded
									>
										<option v-for="brand in brands" :value="brand" :key="brand.id">
											{{ brand.name }}
										</option>
									</b-select>
								</b-field>
								<p v-show="errors.has('brand')" class="help is-danger">{{ t.requiredSelect }}</p>
							</div>
						</div>

						<div class="columns is-mobile mt-2">
							<div class="column mx-1">
								<b-field>
									<b-autocomplete
										rounded
										v-model="search.level1"
										:data="filteredLevelOne"
										:keep-first="true"
										:open-on-focus="true"
										field="name"
										:placeholder="t.category"
										icon="magnify"
										clearable
										ref="level1"
										name="level1"
										v-validate="'required'"
										class="input small mt-2"
										@select="(option) => onCategorySelected(option, 1)"
									>
										<template #empty>{{ t.noResults }}</template>
									</b-autocomplete>
								</b-field>
								<p v-show="errors.has('level1')" class="help is-danger">{{ t.requiredSelect }}</p>
							</div>
						</div>
						<div class="columns is-mobile mt-2">
							<div class="column mx-1">
								<b-field>
									<b-autocomplete
										rounded
										v-model="search.level2"
										:data="filteredLevelTwo"
										:keep-first="true"
										field="name"
										:placeholder="t.subcategory"
										:open-on-focus="true"
										icon="magnify"
										clearable
										ref="level2"
										name="level2"
										v-validate="'required'"
										class="input small mt-2"
										@select="(option) => onCategorySelected(option, 2)"
									>
										<template #empty>{{ t.noResults }}</template>
									</b-autocomplete>
								</b-field>
								<p v-show="errors.has('level2')" class="help is-danger">{{ t.requiredSelect }}</p>
							</div>
						</div>

						<div class="columns is-mobile mt-2">
							<div class="column mx-1">
								<b-field>
									<b-autocomplete
										v-show="availableCategories.level3.length > 0"
										rounded
										v-model="search.level3"
										:data="filteredLevelThree"
										:keep-first="true"
										:open-on-focus="true"
										field="name"
										placeholder="SubCategory2"
										icon="magnify"
										clearable
										ref="level3"
										name="level3"
										v-validate="availableCategories.level3.length > 0 ? 'required' : ''"
										class="input small mt-2"
										@select="(option) => onCategorySelected(option, 3)"
									>
										<template #empty>{{ t.noResults }}</template>
									</b-autocomplete>
								</b-field>
								<p v-show="errors.has('level3') && availableCategories.level3.length > 0" class="help is-danger">{{ t.requiredSelect }}</p>
							</div>
						</div>

						<div class="columns is-mobile mt-2">
							<div class="column mx-1">
								<b-field>
									<b-autocomplete
										v-show="availableCategories.level4.length > 0"
										rounded
										v-model="search.level4"
										:data="filteredLevelFour"
										:keep-first="true"
										field="name"
										placeholder="SubCategory3"
										:open-on-focus="true"
										icon="magnify"
										clearable
										ref="level4"
										name="level4"
										class="input small mt-2"
										v-validate="availableCategories.level4.length > 0 ? 'required' : ''"
										@select="(option) => onCategorySelected(option, 4)"
									>
										<template #empty>{{ t.noResults }}</template>
									</b-autocomplete>
								</b-field>
								<p v-show="errors.has('level4') && availableCategories.level4.length > 0" class="help is-danger">{{ t.requiredSelect }}</p>
							</div>
						</div>

						<div class="columns is-mobile mt-2">
							<div class="column ml-2">
								<b-field :label="t.price">
									<b-numberinput
										:controls="false"
										class="input small mt-2"
										v-validate="'required'"
										ref="price"
										name="price"
										v-model.trim="product.price"
										:placeholder="t.price"
										expanded
										step="0.01"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('price')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
							<div class="column mr-2">
								<b-field :label="t.weight">
									<b-numberinput
										:controls="false"
										class="input small mt-2"
										v-validate="'required'"
										ref="weight"
										name="weight"
										v-model.trim="product.weight"
										:placeholder="t.weight"
										expanded
										step="0.01"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('weight')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>

						<!-- <b-field class="mt-4">
							<b-checkbox size="is-large" class="checkbox mt-3" v-model="product.isNewSeason">
								<span style="font-size: 0.9rem">{{ t.newSeason }} </span>
							</b-checkbox>
						</b-field> -->

						<div class="small-input mt-4">
							<b-field class="select-field">
								<b-checkbox size="is-large" class="checkbox mt-3" v-model="sale.isActive">
									<span style="font-size: 0.9rem">{{ t.isSale }} </span>
								</b-checkbox>
							</b-field>
							<div>
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										:placeholder="t.insertValue"
										name="valueSale"
										v-validate="sale.isActive ? 'required' : ''"
										ref="valueSale"
										v-model.trim="sale.discount"
										:min="sale.isActive ? '1' : '0'"
										:max="100"
										:disabled="sale.isActive ? false : true"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueSale')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>

						<div v-if="sale.isActive" style="display: inline-flex">
							<b-field class="picker-input" :label="t.saleStart">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="sale.startAt"
									:min-date="yesterday"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>

							<b-field class="picker-input ml-4" :label="t.saleEnd">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="sale.endAt"
									:min-date="sale.startAt"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>
						</div>

						<div class="small-input mt-4">
							<b-field class="select-field">
								<b-checkbox size="is-large" class="checkbox mt-3" v-model="product.givesMoneyOnCard">
									<span style="font-size: 0.9rem">{{ t.moneyCard }} </span>
								</b-checkbox>
							</b-field>

							<div>
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										:placeholder="t.insertValue"
										name="valueCard"
										ref="valueCard"
										v-model.trim="product.moneyOnCardPercentage"
										v-validate="product.givesMoneyOnCard ? 'required' : ''"
										:min="product.givesMoneyOnCard ? '1' : '0'"
										:disabled="product.givesMoneyOnCard ? false : true"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueCard')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>
					</b-tab-item>
					<b-tab-item label="Variants">
						<div v-for="(variant, variantIndex) in productVariants" class="mt-4" :key="variantIndex">
							<div style="display: inline-flex">
								<p style="font-size: 1.4rem">{{ t.variant }} {{ variantIndex + 1 }}</p>

								<b-icon @click.native="removeVariant(variantIndex, variant.id)" pack="rt" icon="remove" size="is-size-4" class="ml-3" type="is-red"></b-icon>
							</div>
							<p v-if="removeVariantMessage" class="has-text-red">{{ t.removeVariantError }}</p>

							<div class="photo-container mt-4">
								<b-upload
									v-model="variant.pictures[photoIndex]"
									v-for="(photo, photoIndex) in variant.pictures"
									:key="photoIndex"
									class="file-label mr-2"
									accept="image/*"
								>
									<div class="photo center-inside">
										<img v-if="photo" class="profile-photo" :src="photo.url ? photo.url : getFileURL(variantIndex, photoIndex)" alt="" />
										<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
									</div>
								</b-upload>
							</div>
							<p v-if="imageMessage" class="has-text-red mt-2">{{ t.imageRequired }}</p>

							<b-field>
								<b-select class="input-size small mt-8" type="text" name="color" v-validate="'required'" v-model="variant.color" :placeholder="t.color" expanded>
									<option v-for="color in colors" :value="color" :key="color.id">
										{{ color.name }}
									</option>
								</b-select>
							</b-field>
							<p v-show="errors.has('color')" class="help is-danger">{{ t.requiredSelect }}</p>

							<div class="mt-8">
								<div style="display: flex">
									<div class="mr-2">
										<p class="table-label">{{ t.sizes }}</p>
										<b-field v-for="(productSize, productSizeIndex) in variant.productSizes" :key="productSizeIndex">
											<b-select
												class="input mt-2"
												style="width: 110px; height: 40px"
												v-model="productSize.size"
												type="text"
												ref="size"
												v-validate="'required'"
												name="size"
												:placeholder="t.sizes"
											>
												<option v-for="size in sizes" :key="size.id" :value="size">
													{{ size.name }}
												</option>
											</b-select>
										</b-field>
										<p v-show="errors.has('size')" class="help is-danger mt-4">{{ t.requiredSelect }}</p>
									</div>

									<div class="ml-2">
										<p class="table-label">{{ t.quantities }}</p>
										<b-field v-for="(productSize, productSizeIndex) in variant.productSizes" :key="productSizeIndex">
											<input
												style="width: 110px; height: 40px"
												class="input mt-2"
												name="unit"
												v-model.trim="productSize.stockNumber"
												v-validate="'numeric|required'"
												:placeholder="t.units"
												ref="unit"
												type="number"
												min="0"
											/>
											<b-icon
												@click.native="removeProductLine(variantIndex, productSizeIndex)"
												pack="rt"
												icon="remove"
												size="is-size-4"
												class="ml-3 mt-3"
												type="is-red"
											></b-icon>
											<b-icon
												@click.native="addProductLine(variantIndex)"
												pack="rt"
												icon="plus"
												size="is-size-4"
												class="ml-5 mt-3"
												type="is-primary"
											></b-icon>
										</b-field>
										<p v-show="errors.has('unit')" class="help is-danger mt-4">{{ t.requiredField }} {{ t.greater }}</p>
									</div>
								</div>
								<p v-if="removeSizeMessage" class="has-text-red">{{ t.removeSizeError }}</p>
							</div>
						</div>

						<b-button @click="addVariant" :loading="isLoading" type="is-primary" class="btn-small mt-14">{{ t.new }}</b-button> <br />
					</b-tab-item>
				</b-tabs>
				<div class="button-container has-text-centered px-4">
					<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">{{ t.post }}</b-button>
					<b-button @click="removeProduct()" :loading="isLoading" type="is-danger" class="btn mt-6"
						><b-icon pack="rt" icon="remove" size="is-size-5" type="is-white"></b-icon
					></b-button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	import {
		getProductById,
		getBrands,
		getCategories,
		getColors,
		getSizesGroups,
		updateProduct,
		updateProductSale,
		editUploadImage,
		addProductVariant,
		addProductSize,
		updateProductSize,
		updateProductVariant,
		deleteProductVariant,
		deleteProductSize,
		addProductSale,
	} from '@/api/store';
	import SizesModal from '@/components/Store/SizesModal.vue';
	import moment from 'moment';
	export default {
		components: {
			SizesModal,
		},
		data() {
			return {
				t: this.$t(`components.Store.EditProduct`),
				isLoading: false,
				showMenu: false,
				removeSizeMessage: false,
				removeVariantMessage: false,
				imageMessage: false,

				product: null,
				productVariants: [],

				brands: [],
				colors: [],
				sizes: [],

				selectedCategories: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				search: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				availableCategories: {
					level0: [],
					level1: [],
					level2: [],
					level3: [],
					level4: [],
					level5: [],
				},

				maxLevel: 5,

				createdRunning: true,

				today: new Date(),
				yesterday: new Date(),

				sale: {
					isNew: true,
					startAt: new Date(),
					endAt: null,
					discount: null,
					isActive: false,
				},
				isNew: true,

				captions: [],
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},
			onCategorySelected(category, level) {
				if (!category) {
					// se limpar um input de categoria tem que limpar as descendentes tb
					for (let i = level; i < this.maxLevel; i++) {
						this.selectedCategories[`level${i}`] = null;
						this.search[`level${i}`] = null;
					}
					return;
				}
				// get children
				this.selectedCategories[`level${category.level}`] = category;

				if (!this.createdRunning)
					getCategories({
						'filters[parent]': category.id,
					})
						.then((response) => {
							this.availableCategories[`level${category.level + 1}`] = response.data.data;
							if (level < 4) {
								this.$refs[`level${level + 1}`].focus();
							}
						})
						.catch((error) => {
							console.log(error);
						});
			},

			getFileURL(index, subIndex) {
				if (this.productVariants[index]) {
					return window.URL.createObjectURL(this.productVariants[index].pictures[subIndex]);
				}
			},

			removeVariant(index, id) {
				if (this.productVariants.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteVariant,
						message: this.t.deleteVariantMessage,
						confirmText: this.t.deleteVariant,
						cancelText: this.t.cancel,
						hasIcon: true,
						onConfirm: () => {
							deleteProductVariant(id);
							this.productVariants.splice(index, 1);
							this.removeVariantMessage = false;
						},
					});
				} else this.removeVariantMessage = true;
			},

			addVariant() {
				this.productVariants.push({
					isNew: true,
					color: null,
					pictures: [null, null, null],
					productSizes: [
						{
							isNew: true,
							size: null,
							stockNumber: null,
							id: null,
						},
					],
					data: [
						{
							id: null,
						},
						{
							id: null,
						},
						{
							id: null,
						},
					],
					id: null,
				});
			},

			addProductLine(variantIndex) {
				this.removeSizeMessage = false;
				this.productVariants[variantIndex].productSizes.push({
					isNew: true,
					size: null,
					stockNumber: null,
					stockStatus: null,
					id: null,
				});
			},

			removeProductLine(variantIndex, sizeIndex) {
				if (this.productVariants[variantIndex].productSizes.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteProductSize,
						message: this.t.deleteProductSizeMessage,
						confirmText: this.t.deleteProductSize,
						cancelText: this.t.cancel,
						type: 'is-danger',
						hasIcon: true,
						onConfirm: () => {
							if (!this.productVariants[variantIndex].productSizes[sizeIndex].isNew) deleteProductSize(this.productVariants[variantIndex].productSizes[sizeIndex].id);

							this.productVariants[variantIndex].productSizes.splice(sizeIndex, 1);
							this.removeSizeMessage = false;
						},
					});
				} else this.removeSizeMessage = true;
			},

			update() {
				this.isLoading = true;
				this.$validator.validateAll().then(async (result) => {
					if (!result) {
						this.$buefy.toast.open({
							message: this.t.fields,
							position: 'is-top',
							type: 'is-danger',
						});
						this.isLoading = false;
					} else if ((this.sale.isActive && this.sale.discount == 0) || (this.sale.isActive && this.sale.discount > 100)) {
						this.isLoading = false;
						this.$nextTick(() => this.$refs.discount.focus());
					} else {
						this.isLoading = true;

						let newImagesToUpload = [];

						for (let variantIndex = 0; variantIndex < this.productVariants.length; variantIndex++) {
							if (this.productVariants[variantIndex].pictures.length == 3) {
								this.productVariants[variantIndex].pictures.forEach((photo, photoIndex) => {
									if (photo) {
										if (photo instanceof File) {
											newImagesToUpload.push(photo);
											this.captions.push({ caption: `${variantIndex.toString()}_${photoIndex.toString()}` });
										}
									} else {
										this.imageMessage = true;
										this.isLoading = false;
									}
								});
							}

							if (this.isLoading == false) {
								return;
							}
						}

						if (newImagesToUpload.length > 0) {
							await editUploadImage(newImagesToUpload, this.captions)
								.then((response) => {
									response.data.forEach((photo) => {
										let [variantIndex, photoIndex] = photo.caption.split('_');
										this.productVariants[variantIndex].pictures[photoIndex] = photo;
									});
								})
								.catch((error) => {
									console.log('ERROR ON editUploadImage');
									this.isLoading = false;
									throw error;
								});
						}

						for (let variantIndex = 0; variantIndex < this.productVariants.length; variantIndex++) {
							for (let sizeIndex = 0; sizeIndex < this.productVariants[variantIndex].productSizes.length; sizeIndex++) {
								this.productVariants[variantIndex].productSizes[sizeIndex].stockStatus =
									this.productVariants[variantIndex].productSizes[sizeIndex].stockNumber > 0 ? 1 : 2;

								let clone = Object.clone(this.productVariants[variantIndex].productSizes[sizeIndex]);
								clone.size = clone.size.id;

								if (this.productVariants[variantIndex].productSizes[sizeIndex].isNew) {
									await addProductSize(clone)
										.then((response) => {
											this.productVariants[variantIndex].productSizes[sizeIndex] = response.data.data;
										})
										.catch((error) => {
											console.log('ERROR ON addProductSize');
											this.isLoading = false;
											throw error;
										});
								} else {
									await updateProductSize(clone.id, clone)
										.then((response) => {
											this.productVariants[variantIndex].productSizes[sizeIndex] = response.data.data;
										})
										.catch((error) => {
											console.log('ERROR ON updateProductSize');
											this.isLoading = false;
											throw error;
										});
								}
							}

							let clone = Object.clone(this.productVariants[variantIndex]);
							clone.color = clone.color.id;
							clone.pictures = clone.pictures.map((pic) => pic.id);
							clone.productSizes = clone.productSizes.map((ps) => ps.id);

							if (this.productVariants[variantIndex].isNew) {
								await addProductVariant(clone, {
									populate: 'color,pictures,productSizes.size',
								})
									.then((response) => {
										this.productVariants[variantIndex] = response.data.data;
									})
									.catch((error) => {
										console.log('ERROR ON addProductVariant');
										this.isLoading = false;
										throw error;
									});
							} else {
								await updateProductVariant(clone.id, clone, {
									populate: 'color,pictures,productSizes.size',
								})
									.then((response) => {
										this.productVariants[variantIndex] = response.data.data;
									})
									.catch((error) => {
										console.log('ERROR ON updateProductVariant');
										this.isLoading = false;
										throw error;
									});
							}
						}

						if (!this.sale.isNew) {
							let clone = Object.clone(this.sale);
							clone.endAt = moment(this.sale.endAt).format('YYYY-MM-DD');
							clone.startAt = moment(this.sale.startAt).format('YYYY-MM-DD');
							await updateProductSale(clone.id, clone)
								.then((response) => {
									this.product.productSales[0] = response.data.data;
								})
								.catch((error) => {
									console.log('ERROR ON updateProductSale');
									this.isLoading = false;
									throw error;
								});
						} else {
							let clone = Object.clone(this.sale);
							clone.endAt = moment(this.sale.endAt).format('YYYY-MM-DD');
							clone.startAt = moment(this.sale.startAt).format('YYYY-MM-DD');
							await addProductSale(clone)
								.then((response) => {
									this.product.productSales[0] = response.data.data;
								})
								.catch((error) => {
									console.log('ERROR ON addProductSale');
									this.isLoading = false;
									throw error;
								});
						}

						let clone = Object.clone(this.product);
						clone.brand = clone.brand.id;
						clone.category = this.getLastCategory ? this.getLastCategory.id : null;
						clone.productVariants = this.productVariants.map((variant) => variant.id);
						clone.productSales = clone.productSales.map((ps) => ps.id);
						clone.store = clone.store.id;
						clone.pictures = this.productVariants[0].pictures.map((pic) => pic.id);

						await updateProduct(clone.id, clone, {
							populate: 'category,brand,store,productSales',
						})
							.then((response) => {
								this.product = response.data.data;
								this.$buefy.toast.open({
									message: this.t.productUpdated,
									position: 'is-top',
									type: 'is-primary',
								});
								this.isLoading = false;
								this.goToPage('StoreProducts');
							})
							.catch((error) => {
								console.log('ERROR ON updateProduct');
								this.isLoading = false;
								throw error;
							})
							.finally(() => {
								this.isLoading = false;
							});
					}
				});
			},

			removeProduct() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteProduct,
					message: this.t.deleteProductMessage,
					confirmText: this.t.deleteProduct,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						deleteProduct(this.$route.params.id).finally(() => {
							this.$buefy.toast.open({
								message: this.t.productDeleted,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('StoreProducts');
						});
					},
				});
			},
		},

		computed: {
			filteredLevelOne() {
				if (this.search.level1)
					return this.availableCategories.level1.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level1.toString().toLowerCase()) >= 0);
				return this.availableCategories.level1;
			},
			filteredLevelTwo() {
				if (this.search.level2)
					return this.availableCategories.level2.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level2.toString().toLowerCase()) >= 0);
				return this.availableCategories.level2;
			},
			filteredLevelThree() {
				if (this.search.level3)
					return this.availableCategories.level3.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level3.toString().toLowerCase()) >= 0);
				return this.availableCategories.level3;
			},
			filteredLevelFour() {
				if (this.search.level4)
					return this.availableCategories.level4.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level4.toString().toLowerCase()) >= 0);
				return this.availableCategories.level4;
			},
			getLastCategory() {
				for (let i = this.maxLevel; i > 0; i--) {
					if (this.selectedCategories[`level${i}`]) {
						return this.selectedCategories[`level${i}`];
					}
				}
				return null;
			},
		},

		created() {
			this.setIsPageLoading(true);
			this.yesterday.setDate(this.yesterday.getDate() - 1);
			getProductById(this.$route.params.id)
				.then(async (response) => {
					this.product = response.data.data;
					this.productVariants = this.product.productVariants;
					console.log(this.product);

					let setupCategories = async (category) => {
						this.selectedCategories[`level${category.level}`] = category;
						if (category.level != 0) {
							await getCategories({
								'filters[parent]': category.parent.id,
							}).then(async (response) => {
								this.availableCategories[`level${category.level}`] = response.data.data;
							});

							this.$refs[`level${category.level}`].setSelected(category);
						}
						if (category.parent) await setupCategories(category.parent);
					};

					if (this.product.category) await setupCategories(this.product.category);

					if (this.product.productSales.length > 0) {
						if (this.product.productSales[0].startAt == null || this.product.productSales[0].endAt == null) {
							this.product.productSales[0].startAt = this.today;
							this.product.productSales[0].endAt = this.today;
						} else {
							this.product.productSales[0].startAt = new Date(this.product.productSales[0].startAt);
							this.product.productSales[0].endAt = new Date(this.product.productSales[0].endAt);
						}

						this.sale = this.product.productSales[0];
					}

					this.createdRunning = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});

			getBrands()
				.then((response) => {
					this.brands = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getColors()
				.then((response) => {
					this.colors = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getSizesGroups()
				.then((response) => {
					this.sizes = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories({
				'filters[level]': 0,
				populate: 'parent',
			})
				.then((response) => {
					this.availableCategories.level0 = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.column {
		padding: 0 !important;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.input {
		font-size: 1rem;
		height: 48px;
	}

	.button-container {
		width: 100%;
		margin-top: 20px;
		padding-bottom: 80px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	.photo-container {
		display: flex;
		justify-content: space-between;

		@include from($mobile-l) {
			padding-left: 15%;
			padding-right: 15%;
		}
	}

	.photo {
		width: 104px;
		height: 87px;
		border-radius: 9px;
		background-color: $white;
		border: $grey-light dashed 2px;
	}

	img {
		width: 107px;
		height: 75px;
		object-fit: scale-down;
	}

	.small-input {
		display: flex;
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $grey-dark;
		right: 1.125em;
		z-index: 4;
	}

	.select-field {
		width: 167px;
	}

	.small {
		width: 96% !important;
	}

	/deep/ .sizes-label span {
		transform: translateX(-20px) !important;
	}

	/deep/ .field .control input {
		text-align: left;
		font-size: 0.9rem;
	}

	/deep/ .select {
		width: 100%;
		font-size: 0.9rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	/deep/ .control.has-icons-left .icon,
	.control.has-icons-right .icon {
		color: black;
	}

	/deep/ .control {
		width: 100%;
	}

	/deep/ .select select {
		border: none;
		width: 100%;
	}

	.textarea {
		resize: none;
	}
</style>
