<template>
	<div>
		<b-image class="img" :src="require('@/assets/svg/success.svg')" alt="root"></b-image>

		<p class="has-text-centered has-text-dark mt-10 is-size-4">{{t.thanks}}</p>
		<p class="has-text-centered has-text-grey mt-2 is-size-6">{{t.textOne}}</p>
		<p class="has-text-centered has-text-grey is-size-6">{{t.textTwo}}</p>
	</div>
</template>

<script>
	import cart from '@/mixins/modules/cart';
	export default {
		mixins: [cart],
		data() {
			return {
				t: this.$t(`views.CheckoutSuccess`)
			}
		},

		created() {
			this.getCartFromAPI(this.getUser.id).then(() => {
				this.cart = this.getCart;
				this.removeCartProduct(this.cart.cartProducts.map((cp) => cp.id))
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			});

			this.setShippingType(null);
			this.setAddress(null);
			this.setZipCode(null);
			this.setCity(null);
			this.setCountry(null);
			this.setPickupId(null);
			this.setUseDeliveryAddress(false);
			this.setBillingAddress(null);
			this.setBillingZipCode(null);
			this.setBillingCity(null);
			this.setBillingCountry(null);
			this.setExtraDiscount(0);
			this.setDiscountCode(null);
			this.setCameFromCard(false);
			this.setPaymentType(null);
			this.setNif(null);
			this.setCompany(null);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.img {
		width: 80%;
		margin: 0 auto;
		padding-top: 10%;

		@include from($mobile-l) {
			width: 300px;
			margin: 0 auto;
			padding-top: 10%;
		}
	}
</style>
