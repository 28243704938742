<template>
	<section class="section">
		<div class="container">
			<div class="is-fixed-top"><b-icon @click.native="$router.back()" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
			<div class="mt-6">
				<span>{{ t.forgot }}</span
				><br />
				<p class="text mt-6">{{ t.text }}</p>
				<form @submit.prevent="onSubmitPress">
					<b-input class="input" size="is-medium" name="email" type="email" required :placeholder="t.emailPlaceholder" v-model.trim="recover.email"></b-input>

					<div class="button-container has-text-centered">
						<b-button type="is-primary" class="btn" :loading="isLoading" native-type="submit">{{ t.recover }}</b-button>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
	import { forgotPassword } from '@/api/auth';
	export default {
		name: 'ForgotPassword',
		data() {
			return {
				t: this.$t(`components.Auth.ForgotPassword`),
				isLoading: false,
				recover: {
					email: null,
				},
			};
		},
		methods: {
			onSubmitPress() {
				this.isLoading = true;
				forgotPassword(this.recover.email)
					.then((response) => {
						this.addRecoverEmail(this.recover.email);
						this.goToPage('CheckEmail');
					})
					.catch((error) => {
						if (error.response.data.error.message == 'This email does not exist') {
							this.$buefy.toast.open({
								message: this.t.notExist,
								position: 'is-top',
								type: 'is-danger',
							});
						} else {
							this.$buefy.toast.open({
								message: this.$t('shared.error'),
								position: 'is-top',
								type: 'is-danger',
							});
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	span {
		font-size: 1.7rem;
	}

	.input {
		margin-top: 80px;
	}

	.button-container {
		width: 100%;
		margin-top: 80px;
		@include from($mobile-s) {
			margin-top: 80px;
		}
	}

	.text {
		font-size: 1rem;
		color: $grey;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}
</style>
