<template>
	<div>
		<div class="sort-container">
			<b-button @click="openFilter" type="is-white" class="btn-sort ml-10"
				><b-icon pack="rt" icon="sort" size="is-size-4" type="is-black"></b-icon><span class="sort-text">{{ t.sort }}</span></b-button
			>
			<div v-show="showFilter" class="filter-window mt-2">
				<b-field class="mt-3 ml-2">
					<b-radio native-value="createdAt:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="createdAt:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="price:desc" @input="changeSort" v-model="sort">{{ t.priceHigh }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="price:asc" @input="changeSort" v-model="sort">{{ t.priceLow }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="num_shopped:desc" @input="changeSort" v-model="sort">{{ t.shopped }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="num_likes:desc" @input="changeSort" v-model="sort">{{ t.mostLiked }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="num_reviews:desc" @input="changeSort" v-model="sort">{{ t.bestReview }}</b-radio>
				</b-field>
				<b-field class="mt-3 ml-2">
					<b-radio native-value="num_comments:desc" @input="changeSort" v-model="sort">{{ t.mostCommented }}</b-radio>
				</b-field>
			</div>
		</div>

		<FlashSale class="flash-container" />

		<div class="columns is-multiline mb-8 px-4 feed-container" v-if="existProducts">
			<div class="column is-4-desktop" v-for="(product, index) in products" :key="product.id" :ref="product.id" :id="product.id">
				<div class="media center-inside mb-6 is-clickable" @click="storeDetails(product.store.id)">
					<div class="media-left">
						<div class="photo-container mt-4">
							<figure
								v-if="product.store"
								class="image is-32x32 is-background-size-cover is-rounded is-clickable"
								:style="[product.store.pictures ? { 'background-image': `url(${product.store.pictures[0].url})` } : { background: '#FFF' }]"
							></figure>
						</div>
					</div>
					<div class="media-content pt-4">
						<p class="is-clickable" style="font-size: 11.2px" v-if="product.store">{{ product.store.name }}</p>
						<p class="address has-text-grey is-clickable" v-if="product.store">{{ product.store.location_municipality }}</p>
					</div>
					<div class="media-right"></div>
				</div>

				<div v-if="productColors.length > 0 && showImageArray.length > 0">
					<b-carousel :autoplay="false">
						<b-carousel-item v-for="(image, i) in product.pictures" :key="i">
							<figure class="image is-2by3 is-clickable" @click="productDetails(product.id, 1)">
								<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
									>{{ product.productSales[0].discount }}% {{ t.off }}</span
								>

								<br v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" />
								<span v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag mt-16"
									>{{ calculateMoneyCard(calculateSale(product.price, product.productSales[0].discount), product.moneyOnCardPercentage) }}€ {{ t.card }}</span
								>
								<span v-else-if="product.givesMoneyOnCard" class="tag">{{ calculateMoneyCard(product.price, product.moneyOnCardPercentage) }}€ {{ t.card }}</span>

								<img class="has-object-fit-contain has-radius" :src="image.url" alt="" />
							</figure>
						</b-carousel-item>
					</b-carousel>
				</div>
				<div v-else>
					<b-carousel :autoplay="false" icon-size="is-medium">
						<b-carousel-item v-for="(image, i) in product.pictures" :key="i">
							<figure class="image is-2by3 is-clickable" @click="productDetails(product.id, 1)">
								<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
									>{{ product.productSales[0].discount }}% {{ t.off }}</span
								>

								<br v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" />
								<span v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag mt-16"
									>{{ calculateMoneyCard(calculateSale(product.price, product.productSales[0].discount), product.moneyOnCardPercentage) }}€ {{ t.card }}</span
								>
								<span v-else-if="product.givesMoneyOnCard" class="tag">{{ calculateMoneyCard(product.price, product.moneyOnCardPercentage) }}€ {{ t.card }}</span>

								<img class="has-object-fit-contain has-radius" :src="image.url" alt="" />
							</figure>
						</b-carousel-item>
					</b-carousel>
				</div>

				<div class="level mb-0" v-if="getToken">
					<div class="level-left">
						<div class="mt-4 level-item" style="display: inline-flex">
							<b-icon
								v-if="!productsLiked.includes(product.id)"
								icon="heart-outline"
								@click.native="like(product.id, index)"
								class="is-clickable"
								type="is-primary"
								custom-size="is-size-4"
							>
							</b-icon>
							<b-icon v-else icon="heart" @click.native="unlike(product.id, index)" class="is-clickable" type="is-primary" custom-size="is-size-4"> </b-icon>
							<b-icon
								class="ml-4 is-clickable"
								icon="message-outline"
								type="is-primary"
								custom-size="is-size-4"
								@click.native="productDetails(product.id, 0)"
							></b-icon>
							<b-icon
								class="ml-4 right is-clickable"
								v-if="!productsWished.includes(product.id)"
								@click.native="wish(product.id)"
								icon="bookmark-outline"
								type="is-primary"
								custom-size="is-size-4"
							>
							</b-icon>
							<b-icon class="ml-4 right is-clickable" v-else @click.native="deleteWish(product.id)" icon="bookmark" type="is-primary" custom-size="is-size-4">
							</b-icon>
						</div>
					</div>
				</div>

				<div class="mt-3">
					<p class="product-name">{{ product.title }}</p>

					<p class="has-text-grey" v-if="product.category">{{ product.category.name }}</p>
					<div class="is-flex">
						<p
							class="product-name"
							:style="[
								product.productSales.length > 0 && product.productSales[0].isActive == true
									? { fontSize: '13px', textDecoration: 'line-through', transform: 'translateY(2px)' }
									: { textDecoration: 'none', fontWeight: 'bold' },
							]"
						>
							{{ product.price.toFixed(2) }} €
						</p>
						<p class="product-name has-text-dark has-text-weight-bold ml-6" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
							{{ calculateSale(product.price, product.productSales[0].discount) }} €
						</p>
					</div>
					<div class="is-flex">
						<div
							class="color-container mr-2 mt-2 is-clickable"
							v-for="variant in product.productVariants.slice(0, 3)"
							:key="variant.id"
							:style="`backgroundColor: ${variant.color.hex}`"
							@click="productDetails(product.id, 1)"
						></div>
						<span class="is-size-4 has-text-grey ml-2 mt-2" v-if="product.productVariants.length > 3">+ {{ product.productVariants.length - 3 }}</span>
					</div>

					<p class="has-text-grey-darker mt-2 is-clickable" @click="productDetails(product.id, 0)" v-if="product.comments.length > 0">
						{{ t.see }} {{ product.comments.length }} {{ t.comments }}
					</p>
				</div>
			</div>
		</div>
		<div v-else class="center-inside no-product-content">
			<p class="has-text-primary is-size-4 has-text-centered">{{ t.noProducts }}</p>
		</div>
	</div>
</template>

<script>
	import {
		getProducts,
		addLike,
		getLikes,
		removeLike,
		addWhishlist,
		getWhishlist,
		removeWhishlist,
		getStoreFilter,
		getProductsByStore,
		getBrandsFilter,
		getProductsByBrand,
	} from '@/api/feed';

	import FlashSale from '@/components/Homepage/FlashSale.vue';
	import parts from '@/mixins/modules/shared/parts';
	export default {
		props: [
			'category',
			'productCategories',
			'productSizes',
			'productColors',
			'productBrands',
			'priceRange',
			'onlySale',
			'comments',
			'shopped',
			'reviewed',
			'liked',
			'filterStores',
		],
		mixins: [parts],

		components: {
			FlashSale,
		},
		data() {
			return {
				t: this.$t(`components.Desktop.Homepage.Feed`),
				products: [],
				page: 1,
				pageItems: 12,
				isRequesting: false,
				offset: 600,
				showFilter: false,
				sort: 'createdAt:desc',
				productsLiked: [],
				productsWished: [],
				showImageArray: [],
				isAddingLike: false,
				isAddingWhishlist: false,
				existProducts: true,
				offset: 200,
			};
		},

		methods: {
			async handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};
				console.log(parts.computed.getPageRequested());
				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					console.log(this.page);

					this.isRequesting = true;
					this.page++;
					parts.methods.setPageRequested(this.page);

					let categoriesToFilter = null;
					if (this.productCategories.length > 0) {
						categoriesToFilter = this.productCategories;
					} else if (this.category == undefined) {
						categoriesToFilter = null;
					} else categoriesToFilter = this.category;
					if (this.getFilterString != null && this.getFilterString != '') {
						await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
							.then(async (response) => {
								let storeId = [];
								response.data.data.map((store) => {
									storeId.push(store.id);
								});

								if (storeId.length > 0) {
									await getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push({ ...product });
												}
											});
										}
									});
								}

								await getProducts(
									this.page,
									this.pageItems,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								).then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push({ ...product });
											}
										});
									}
									if (this.productColors.length > 0) {
										let products = this.products.map((product) => {
											return product.productVariants;
										});

										console.log(products);
										this.filterVariantPictures(products);
									}

									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isRequesting = false;
							});

						/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
							.then(async (response) => {
								let brandId = [];
								response.data.data.map((brand) => {
									brandId.push(brand.id);
								});

								if (brandId.length > 0) {
									await getProductsByBrand(this.page, this.pageItems, brandId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push({ ...product });
													this.setIsPageLoading(false);
												} else this.setIsPageLoading(false);
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								} else {
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								}
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							}) */
					} else {
						await getProducts(
							this.page,
							this.pageItems,
							this.getMainCategorySelected,
							categoriesToFilter,
							this.productSizes,
							this.priceRange[0],
							this.priceRange[1],
							this.productColors,
							this.productBrands,
							this.onlySale,
							this.sort,
							this.comments,
							this.shopped,
							this.reviewed,
							this.liked,
							this.getFilterString,
							this.filterStores
						)
							.then(async (response) => {
								if (response.data.data.length > 0) {
									response.data.data.map((product) => {
										let verify = this.products.some(function (el) {
											return el.id == product.id;
										});

										if (!verify) {
											this.products.push({ ...product });
										}
									});
								}

								if (this.productColors.length > 0) {
									let products = this.products.map((product) => {
										return product.productVariants;
									});

									console.log(products);
									this.filterVariantPictures(products);
								}
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isRequesting = false;
							});
					}
				}
			},

			filterVariantPictures(products) {
				products.forEach((variants, index) => {
					let i = variants.findIndex((variant) => this.productColors.includes(variant.color.id));
					console.log(index, i);
					this.showImageArray[index] = i;
				});
				console.log(this.showImageArray);
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			storeDetails(id) {
				parts.methods.setMenuVisible(false);
				parts.methods.setNotificationsVisible(false);
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			productDetails(id, tab) {
				parts.methods.setMenuVisible(false);
				parts.methods.setNotificationsVisible(false);
				parts.methods.setVisitedProductId(id);
				parts.methods.setSavedSort(this.sort);
				this.$router.push({ name: 'ProductDetails', params: { id: id, tab: tab } });
			},

			openFilter() {
				this.showFilter = !this.showFilter;
			},

			like(id, index) {
				if (this.getToken) {
					console.log(id);
					this.productsLiked.push(id);
					this.products[index].likes.push(id);
					let like = {
						data: {
							product: id,
						},
					};
					if (!this.isAddingLike) {
						this.isAddingLike = true;

						addLike(like)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			unlike(id, index) {
				if (this.getToken) {
					this.productsLiked.splice(
						this.productsLiked.findIndex((element) => element == id),
						1
					);
					this.products[index].likes.pop();

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						removeLike(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			wish(id) {
				if (this.getToken) {
					this.productsWished.push(id);
					let wishNumber = this.getBookmark;
					wishNumber++;
					this.setBookmark(wishNumber);
					let whish = {
						data: {
							product: id,
						},
					};
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						addWhishlist(whish)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingWhishlist = false;
							});
					}
				} else this.goToPage('Home');
			},

			deleteWish(id) {
				if (this.getToken) {
					let wishNumber = this.getBookmark;
					wishNumber--;
					this.setBookmark(wishNumber);
					this.productsWished.splice(this.productsWished.findIndex((element) => element == id));

					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						removeWhishlist(id)
							.then((response) => {})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingWhishlist = false;
							});
					}
				}
			},

			async changeSort() {
				this.setIsPageLoading(true);

				this.page = 1;
				parts.methods.setPageRequested(this.page);
				let categoriesToFilter = null;
				if (this.productCategories.length > 0) {
					categoriesToFilter = this.productCategories;
				} else if (this.category == undefined) {
					categoriesToFilter = null;
				} else categoriesToFilter = this.category;

				if (this.getFilterString != null && this.getFilterString != '') {
					this.setIsPageLoading(true);
					this.products = [];
					await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
						.then(async (response) => {
							let storeId = [];
							response.data.data.map((store) => {
								storeId.push(store.id);
							});
							let pageSize;
							if (this.canScroll) {
								pageSize = 12 * parts.computed.getPageRequested();
							} else pageSize = this.pageItems;

							if (storeId.length > 0) {
								await getProductsByStore(this.page, pageSize, storeId, this.sort).then((response) => {
									console.log(response);

									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
								});
							}

							await getProducts(
								this.page,
								pageSize,
								this.getMainCategorySelected,
								categoriesToFilter,
								this.productSizes,
								this.priceRange[0],
								this.priceRange[1],
								this.productColors,
								this.productBrands,
								this.onlySale,
								this.sort,
								this.comments,
								this.shopped,
								this.reviewed,
								this.liked,
								this.getFilterString,
								this.filterStores
							).then(async (response) => {
								if (response.data.data.length > 0) {
									response.data.data.map((product) => {
										let verify = this.products.some(function (el) {
											return el.id == product.id;
										});

										if (!verify) {
											this.products.push(product);
										}
									});
								}

								if (this.productColors.length > 0) {
									let products = this.products.map((product) => {
										return product.productVariants;
									});

									console.log(products);
									this.filterVariantPictures(products);
								}
							});
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.canScroll) {
								let element = document.getElementById(this.getVisitedProductId);
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);

								setTimeout(function () {
									element.scrollIntoView({
										top: element,
										behavior: 'auto',
									});
								}, 100);
							}
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});

					/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
						.then(async (response) => {
							console.log(response);
							let brandId = [];
							response.data.data.map((brand) => {
								brandId.push(brand.id);
							});

							if (brandId.length > 0) {
								let pageSize;
								if (this.canScroll) {
									pageSize = 10 * parts.computed.getPageRequested();
								} else pageSize = this.pageItems;
								await getProductsByBrand(this.page, pageSize, brandId, this.sort).then((response) => {
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
												this.setIsPageLoading(false);
											} else this.setIsPageLoading(false);
										});
									}
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
								});
							} else {
								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
							this.setIsPageLoading(false);
						})
						.finally(() => {
						
						}); */
				} else {
					getProducts(
						this.page,
						this.pageItems,
						this.getMainCategorySelected,
						categoriesToFilter,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.comments,
						this.shopped,
						this.reviewed,
						this.liked,
						this.getFilterString,
						this.filterStores
					)
						.then((response) => {
							console.log(response);
							this.products = response.data.data;
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.showFilter = false;
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});
				}
			},

			calculateMoneyCard(num1, num2) {
				console.log(num1);
				let valueToShow = num1 * (num2 / 100);

				if (Number.isInteger(valueToShow)) {
					return valueToShow;
				} else return valueToShow.toFixed(2);
			},
		},
		computed: {
			searchString() {
				return this.getFilterString;
			},
		},

		watch: {
			searchString(newVal) {
				if (newVal || newVal == '') {
					this.setIsPageLoading(true);
					this.page = 1;
					this.products = [];
					let categoriesToFilter = null;

					if (this.productCategories.length > 0) {
						categoriesToFilter = this.productCategories;
					} else if (this.category == undefined) {
						categoriesToFilter = null;
					} else categoriesToFilter = this.category;

					if (newVal != '') {
						getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
							.then(async (response) => {
								console.log(response);
								let storeId = [];
								response.data.data.map((store) => {
									storeId.push(store.id);
								});

								if (storeId.length > 0) {
									await getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});
												console.log(verify);
												if (!verify) {
													this.products.push(product);
												}
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								}

								await getProducts(
									this.page,
									this.pageItems,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								).then((response) => {
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
									if (this.productColors.length > 0) {
										let products = this.products.map((product) => {
											return product.productVariants;
										});

										console.log(products);
										this.filterVariantPictures(products);
									}

									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							});

						/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
							.then((response) => {
								console.log('SEGUNDO');
								console.log(response);
								let brandId = [];
								response.data.data.map((brand) => {
									brandId.push(brand.id);
								});

								if (brandId.length > 0) {
									getProductsByBrand(this.page, this.pageItems, brandId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});
												console.log(verify);
												if (!verify) {
													this.products.push(product);
													this.setIsPageLoading(false);
												} else this.setIsPageLoading(false);
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								} else {
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								}
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							}); */
					} else {
						getProducts(
							this.page,
							this.pageItems,
							this.getMainCategorySelected,
							categoriesToFilter,
							this.productSizes,
							this.priceRange[0],
							this.priceRange[1],
							this.productColors,
							this.productBrands,
							this.onlySale,
							this.sort,
							this.comments,
							this.shopped,
							this.reviewed,
							this.liked,
							this.getFilterString,
							this.filterStores
						)
							.then(async (response) => {
								this.products = response.data.data;

								if (this.productColors.length > 0) {
									let products = this.products.map((product) => {
										return product.productVariants;
									});

									console.log(products);
									this.filterVariantPictures(products);
								}

								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							});
					}
				}
			},
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},

		async created() {
			this.setIsPageLoading(true);

			let categoriesToFilter = null;
			if (this.productCategories.length > 0) {
				categoriesToFilter = this.productCategories;
			} else if (this.category == undefined) {
				categoriesToFilter = null;
			} else categoriesToFilter = this.category;

			if (this.getToken) {
				getLikes({
					'filters[user][id]': this.getUser.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsLiked = response.data.data.map((like) => {
							if (like.product) {
								return like.product.id;
							}
						});
					})
					.catch((error) => {});

				getWhishlist({
					'filters[user][id]': this.getUser.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsWished = response.data.data.map((whish) => {
							if (whish.product) {
								return whish.product.id;
							}
						});
						this.setBookmark(response.data.meta.pagination.total);
					})
					.catch((error) => {
						console.log(error);
					});
			}

			if (this.canScroll) {
				console.log('AQUIII');
				let pageSize = 12 * parts.computed.getPageRequested();
				this.sort = parts.computed.getSavedSort();
				console.log(parts.computed.getPageRequested());

				if (this.getFilterString != null && this.getFilterString != '') {
					this.setIsPageLoading(true);
					await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
						.then(async (response) => {
							let storeId = [];
							response.data.data.map((store) => {
								storeId.push(store.id);
							});
							console.log(pageSize);
							if (storeId.length > 0) {
								await getProductsByStore(this.page, pageSize, storeId, this.sort).then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
								});

								await getProducts(
									this.page,
									pageSize,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								)
									.then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push(product);
												}
											});
										}

										if (this.productColors.length > 0) {
											let products = this.products.map((product) => {
												return product.productVariants;
											});

											this.filterVariantPictures(products);
										}
									})
									.catch((error) => {
										console.log(error);
										this.$buefy.toast.open({
											message: 'Ocorreu um erro',
											position: 'is-top',
											type: 'is-danger',
										});
									});
							} else {
								await getProducts(
									this.page,
									pageSize,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								)
									.then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push(product);
												}
											});
										}

										if (this.productColors.length > 0) {
											let products = this.products.map((product) => {
												return product.productVariants;
											});

											this.filterVariantPictures(products);
										}
									})
									.catch((error) => {
										console.log(error);
										this.$buefy.toast.open({
											message: 'Ocorreu um erro',
											position: 'is-top',
											type: 'is-danger',
										});
									});
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.canScroll) {
								let element = document.getElementById(this.getVisitedProductId);
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);

								setTimeout(function () {
									element.scrollIntoView({
										top: element,
										behavior: 'auto',
									});
								}, 100);
							}
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});

					/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
						.then(async (response) => {
							console.log(response);
							let brandId = [];
							response.data.data.map((brand) => {
								brandId.push(brand.id);
							});

							if (brandId.length > 0) {
								let pageSize;
								if (this.canScroll) {
									pageSize = 10 * parts.computed.getPageRequested();
								} else pageSize = this.pageItems;
								await getProductsByBrand(this.page, pageSize, brandId, this.sort).then((response) => {
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
												this.setIsPageLoading(false);
											} else this.setIsPageLoading(false);
										});
									}
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
								});
							} else {
								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
							this.setIsPageLoading(false);
						}) */
				} else {
					getProducts(
						this.page,
						pageSize,
						this.getMainCategorySelected,
						categoriesToFilter,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.comments,
						this.shopped,
						this.reviewed,
						this.liked,
						this.getFilterString,
						this.filterStores
					)
						.then((response) => {
							this.products = response.data.data;

							if (this.productColors.length > 0) {
								let products = this.products.map((product) => {
									return product.productVariants;
								});

								this.filterVariantPictures(products);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.getFilterString == null || this.getFilterString == '') {
								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
								console.log(this.getVisitedProductId);
								let element = document.getElementById(this.getVisitedProductId);

								console.log(element);
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);
								setTimeout(function () {
									element.scrollIntoView({
										top: element,
										behavior: 'auto',
									});
								}, 100);
							}
						});
				}
			} else {
				parts.methods.setPageRequested(this.page);
				if (this.getFilterString != null && this.getFilterString != '') {
					getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
						.then(async (response) => {
							let storeId = [];
							response.data.data.map((store) => {
								storeId.push(store.id);
							});

							if (storeId.length > 0) {
								await getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
								});
							}

							await getProducts(
								this.page,
								this.pageItems,
								this.getMainCategorySelected,
								categoriesToFilter,
								this.productSizes,
								this.priceRange[0],
								this.priceRange[1],
								this.productColors,
								this.productBrands,
								this.onlySale,
								this.sort,
								this.comments,
								this.shopped,
								this.reviewed,
								this.liked,
								this.getFilterString,
								this.filterStores
							)
								.then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});

											if (!verify) {
												this.products.push(product);
											}
										});
									}

									if (this.productColors.length > 0) {
										let products = this.products.map((product) => {
											return product.productVariants;
										});

										this.filterVariantPictures(products);
									}
								})
								.catch((error) => {
									console.log(error);
									this.$buefy.toast.open({
										message: 'Ocorreu um erro',
										position: 'is-top',
										type: 'is-danger',
									});
								});
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});
				} else {
					await getProducts(
						this.page,
						this.pageItems,
						this.getMainCategorySelected,
						categoriesToFilter,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.comments,
						this.shopped,
						this.reviewed,
						this.liked,
						this.getFilterString,
						this.filterStores
					)
						.then((response) => {
							console.log(response);
							if (response.data.data.length > 0) {
								response.data.data.map((product) => {
									let verify = this.products.some(function (el) {
										return el.id == product.id;
									});

									if (!verify) {
										this.products.push(product);
									}
								});
							}

							if (this.productColors.length > 0) {
								let products = this.products.map((product) => {
									return product.productVariants;
								});

								this.filterVariantPictures(products);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});
				}
			}
		},

		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.name {
		font-size: 1.357rem;
		color: $grey-darker;

		@include until($widescreen) {
			font-size: 1.1rem;
		}
	}

	.address {
		font-size: 0.8rem;
		color: $grey;
	}

	.product-images {
		width: 100%;
		display: inline-flex;
	}

	.main-photo {
		/* 	width: 300px; */
		height: 400px;
		border-radius: 0px;
		opacity: 1;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		backdrop-filter: blur(26px) brightness(1);
	}
	.has-border {
		border: 1px solid $grey-light;
		border-radius: 0px;
	}

	.likes {
		font-size: 0.9rem;
	}

	.rating {
		font-size: 1.2rem;
	}

	.product-name {
		font-size: 1.1rem;

		@include until($widescreen) {
			font-size: 1rem;
		}
	}

	.tag {
		min-width: 100px;
		max-width: 135px;
		min-height: 50px;
		max-height: 58px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 1rem;
		z-index: 2;
	}

	.filter-window {
		width: 244px;
		height: 278px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		position: absolute;
		right: 5px;
		z-index: 10;
		padding: 1px;
		background-color: #ffffff;
		top: 40px;
		text-align: left;
		font-size: 1rem;
	}

	.btn-sort {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
		z-index: 10;
	}

	.sort-container {
		text-align: center;
		display: flex;
		white-space: nowrap;
		position: absolute;
		right: 80px;
	}

	.sort-text {
		color: $grey;
		font-size: 1.1rem;
	}

	.flash-container {
		margin-top: 120px !important;
	}

	.no-product-content {
		padding-top: 10%;
	}

	.feed-container {
		padding-top: 60px;
	}

	.color-container {
		min-width: 23px;
		height: 23px;
		border-radius: 6px;
		text-align: center;
		color: black;
		border: solid 1px $grey;
		width: auto;
		max-width: 50px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.product-title {
		max-width: 100%;

		p {
			max-width: 100%;
		}
	}

	.blured-image {
		/* width: 300px; */
		/* 	height: 400px; */
		border-radius: 16px;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	img {
		border-radius: 0px;
		border: 1px solid #aab2b72c;
		backdrop-filter: blur(26px) brightness(1);
	}
</style>
