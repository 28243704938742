<template>
	<section>
		<div class="image-container">
			<b-image class="img" :src="require('@/assets/svg/sent.svg')" alt="root"></b-image>
		</div>
		<p class="has-text-centered title mt-14">{{ t.check }}</p>
		<p class="has-text-centered has-text-grey px-6">{{ t.message }}</p>

		<!-- <div class="button-container has-text-centered">
			<b-button type="is-primary" class="btn">{{ t.go }}</b-button>
		</div> -->
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.CheckEmail`),
				email: null,
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.image-container {
		padding-top: 60px;
		@include until($mobile-l) {
			padding-top: 20px;
		}
	}

	.img {
		width: 200px;
		margin: 0 auto;
		padding-top: 100px;
		@include until($mobile-m) {
			width: 50%;
			padding-top: 60px;
		}
	}

	.title {
		font-size: 1.7rem;
		font-weight: 500;
	}

	.button-container {
		width: 100%;
		margin-top: 80px;
		@include from($mobile-s) {
			margin-top: 80px;
		}
	}

	.btn {
		width: 243px;
		height: 46px;
		border-radius: 14px;
		background: var(---primary) 0% 0% no-repeat padding-box;
		background: $primary 0% 0% no-repeat padding-box;
		box-shadow: 0px 9px 20px #1e253859;
		opacity: 1;
	}
</style>
