<template>
	<section v-if="!noProduct">
		<div class="content">
			<div class="header-container">
				<b-icon class="is-clickable" pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
				<p class="ml-2 is-clickable" @click="$router.go(-1)">{{ t.back }}</p>
			</div>
			<!-- <p class="mt-2">Product</p> -->

			<div>
				<div class="columns mt-4">
					<div class="column is-half">
						<b-carousel :autoplay="false" :overlay="isFullscrean" @click="toggleOverlay" class="is-clickable">
							<b-carousel-item v-for="(image, i) in variantPictures" :key="i">
								<figure class="image" :class="!isFullscrean ? 'is-4by3' : 'is-2by1'">
									<span v-if="productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true" class="tag"
										>{{ productInfo.productSales[0].discount }}% {{ t.off }}</span
									>
									<img class="has-object-fit-contain has-radius" :class="!isFullscrean ? 'has-border' : ''" :src="image.url" alt="" />
								</figure>
							</b-carousel-item>
							<span @click="toggleOverlay" class="modal-close is-large" v-if="isFullscrean" />
						</b-carousel>
						<div class="mt-8 ml-10" style="display: inline-flex; width: 100%" v-if="getUserType == 'authenticated'">
							<b-icon
								v-if="!productsLiked.includes(productInfo.id)"
								icon="heart-outline"
								@click.native="like(productInfo.id)"
								class="is-clickable"
								type="is-primary"
								custom-size="is-size-4"
							>
							</b-icon>
							<b-icon v-else icon="heart" @click.native="unlike(productInfo.id)" class="is-clickable" type="is-primary" custom-size="is-size-4"> </b-icon>
							<b-icon class="ml-4 is-clickable" icon="message-outline" type="is-primary" custom-size="is-size-4" @click.native="tab = 0"> </b-icon>
							<b-icon
								class="ml-4 right is-clickable"
								v-if="!productsWished.includes(productInfo.id)"
								@click.native="wish(productInfo.id)"
								icon="bookmark-outline"
								type="is-primary"
								custom-size="is-size-4"
							>
							</b-icon>
							<b-icon class="ml-4 right is-clickable" v-else @click.native="deleteWish(productInfo.id)" icon="bookmark" type="is-primary" custom-size="is-size-4">
							</b-icon>
						</div>
						<div class="media ml-10 center-inside">
							<div class="media-left mt-6">
								<p class="likes has-text-grey" v-if="productInfo.likes.length == 1">{{ productInfo.likes.length }} {{ t.person }} {{ t.likedOne }}</p>
								<p class="likes has-text-grey" v-if="productInfo.likes.length > 1">{{ productInfo.likes.length }} {{ t.people }} {{ t.likedMore }}</p>
							</div>
							<div class="media-content pt-4"></div>
							<div class="media-right"></div>
						</div>
					</div>
					<div class="column">
						<div class="media center-inside mb-6">
							<div class="media-left" @click="storeDetails(productInfo.store.id)">
								<div class="photo-container mt-4">
									<figure
										v-if="productInfo.store"
										class="image is-32x32 is-background-size-cover is-rounded is-clickable mx-0"
										:style="[productInfo.store.pictures ? { 'background-image': `url(${productInfo.store.pictures[0].url})` } : { background: '#FFF' }]"
									></figure>
								</div>
							</div>
							<div class="media-content pt-4" @click="storeDetails(productInfo.store.id)">
								<p class="is-size-6 is-clickable mb-0 has-text-grey-darker" v-if="productInfo.store">{{ productInfo.store.name }}</p>
								<p class="address has-text-grey is-clickable" v-if="productInfo.store">
									{{ productInfo.store.location_municipality }}
								</p>
								<!-- <p class="address has-text-grey is-clickable">
									{{ productInfo.category.name }}
								</p> -->
							</div>
							<div class="media-right"></div>
						</div>

						<p class="product-name">{{ productInfo.title }}</p>
						<p class="product-name has-text-grey-dark">{{ productInfo.category.name }}</p>

						<div style="display: inline-flex" class="mt-3">
							<p class="product-price mb-0 mr-4" v-if="productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true">
								{{ calculateSale(productInfo.price, productInfo.productSales[0].discount) }} €
							</p>
							<p
								class="product-price mb-0"
								:style="[
									productInfo.productSales.length > 0 && productInfo.productSales[0].isActive == true
										? { fontSize: '15px', textDecoration: 'line-through', transform: 'translateY(1px)' }
										: { textDecoration: 'none' },
								]"
							>
								{{ productInfo.price.toFixed(2) }} €
							</p>
						</div>

						<!-- <p class="description has-text-grey-darker">{{ t.description }}</p> -->
						<p class="description mt-3" style="white-space: pre-wrap">{{ productInfo.description }}</p>

						<p class="options has-text-grey-darker mt-4">{{ t.colors }}</p>
						<div class="is-flex">
							<div
								class="sizes mr-2 colors"
								@click="selectColor(variant.id, variant.color.hex, index)"
								:style="[
									variantSelected == index && variant.color.hex != null
										? { border: `solid 2px white`, backgroundColor: `${variant.color.hex}`, outline: `solid 2px ${variant.color.hex}` }
										: { border: `solid 1px grey`, backgroundColor: `${variant.color.hex}` },

									variantSelected == index && variant.color.hex === '#FFFFFF'
										? { border: `solid 2px grey`, backgroundColor: `${variant.color.hex}`, outline: `solid 2px black` }
										: '',
								]"
								v-for="(variant, index) in productInfo.productVariants"
								:key="variant.id"
							></div>
						</div>

						<div class="mt-5">
							<p class="options">{{ t.sizes }}</p>
							<div class="sizes-container">
								<div
									v-for="(size, index) in sizes"
									:key="index"
									class="sizes center-inside mr-2"
									:style="sizeSelected === size.id ? `backgroundColor: #99cca8;color:white` : 'backgroundColor:white;'"
									@click="chooseSize(size.id)"
								>
									{{ size.size.name }}
								</div>
								<p class="has-text-primary is-size-5" v-if="sizes.length == 0">{{ t.noStock }}</p>
							</div>
						</div>

						<div v-if="productInfo.givesMoneyOnCard" class="offer-container center-inside mt-8">
							<p class="offer-message">{{ t.message }} {{ productInfo.moneyOnCardPercentage }}% {{ t.card }}</p>
						</div>

						<div class="mt-7">
							<!-- <span class="cart-counter" v-if="getUserType == 'authenticated'">{{ getTotal }}</span>
							<b-button type="is-primary" class="btn-add" @click="goToPage('Cart')" v-if="getUserType == 'authenticated'"
								><b-icon icon="cart" pack="rt" type="is-white" custom-size="is-size-5"></b-icon
							></b-button> -->
							<b-button v-if="getUserType == 'authenticated' && getToken && sizes.length > 0" type="is-primary" class="btn" @click="add">{{ t.add }}</b-button>
							<p v-else class="has-text-primary is-size-5" style="cursor: pointer" @click="goToPage('Home')">{{ t.registerNow }}</p>
						</div>
					</div>
				</div>

				<b-tabs v-model="tab">
					<b-tab-item :label="t.comments">
						<div class="user-comment" v-if="getUserType == 'authenticated'">
							<form @submit.prevent="sendComment" v-if="getToken">
								<div style="display: flex">
									<figure
										v-if="getToken"
										class="image is-64x64 is-background-size-cover is-rounded pointer center-inside"
										:style="[getUser.picture ? { 'background-image': `url(${getUser.picture.url})` } : { background: '#FFF', border: 'solid 1px black' }]"
									>
										<b-icon v-if="!getUser.picture" icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
									</figure>

									<b-field style="width: 94%">
										<b-input class="text-comment" type="textarea" maxlength="300" v-model.trim="comment" :placeholder="t.write" required> </b-input>
									</b-field>
								</div>

								<b-button type="is-primary" class="submit-btn" native-type="submit" style="position: absolute; right: 10px" :loading="isLoading">{{
									t.submit
								}}</b-button>
							</form>
						</div>
						<div class="comment-container" @scroll="commentsScroll">
							<div v-for="comment in comments" :key="comment.id" class="mt-6" style="display: flex">
								<figure
									class="image is-64x64 is-background-size-cover is-rounded pointer center-inside"
									:style="[comment.user.picture ? { 'background-image': `url(${comment.user.picture.url})` } : { background: '#FFF', border: 'solid 1px black' }]"
								>
									<b-icon v-if="!comment.user.picture" icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
								</figure>

								<div class="vl"></div>

								<div>
									<p class="date ml-6">{{ comment.createdAt | moment() }}</p>
									<div style="display: flex">
										<p class="comment-name ml-6">{{ comment.user.name }}</p>
									</div>
									<p class="comment ml-6 mt-1">{{ comment.text }}</p>
								</div>
							</div>
						</div>
					</b-tab-item>

					<b-tab-item :label="t.reviews">
						<div class="media">
							<div class="media-left">
								<p v-if="productInfo.rating" class="has-text-primary pl-5 review">{{ productInfo.rating }}</p>
								<p v-else class="has-text-primary pl-5 review">0</p>

								<dl>
									<span class="difficulty" v-if="reviews.length == 1">{{ productInfo.reviews.length }} {{ t.review }}</span>
									<span class="difficulty" v-else>{{ productInfo.reviews.length }} {{ t.reviews }}</span>

									<div>
										<dd class="percentage-very-good" :class="getPercentage[4]">
											<div class="star">
												<b-icon v-for="index in 5" :key="index" icon="star" type="is-yellow" custom-size="is-size-4"> </b-icon>
											</div>
											<span class="text">{{ reviewsRating[4].length }}</span>
										</dd>
										<dd class="percentage-good" :class="getPercentage[3]">
											<div class="star">
												<b-icon v-for="index in 4" :key="index" icon="star" type="is-yellow" custom-size="is-size-4"> </b-icon>
											</div>
											<span class="text">{{ reviewsRating[3].length }}</span>
										</dd>
										<dd class="percentage-medium" :class="getPercentage[2]">
											<div class="star">
												<b-icon v-for="index in 3" :key="index" icon="star" type="is-yellow" custom-size="is-size-4"> </b-icon>
											</div>
											<span class="text">{{ reviewsRating[2].length }}</span>
										</dd>
										<dd class="percentage-bad" :class="getPercentage[1]">
											<div class="star">
												<b-icon v-for="index in 2" :key="index" icon="star" type="is-yellow" custom-size="is-size-4"> </b-icon>
											</div>
											<span class="text">{{ reviewsRating[1].length }}</span>
										</dd>
										<dd class="percentage-very-bad" :class="getPercentage[0]">
											<div class="star">
												<b-icon icon="star" type="is-yellow" custom-size="is-size-4"> </b-icon>
											</div>
											<span class="text">{{ reviewsRating[0].length }}</span>
										</dd>
									</div>
								</dl>
							</div>
							<div class="media-content" style="margin-left: 250px" v-if="canReview && getUserType == 'authenticated'">
								<form @submit.prevent="sendReview">
									<div style="display: inline-flex">
										<p class="has-text-grey-darker mr-4" style="font-size: 1.5rem; margin-bottom: 0">{{ t.yourRating }}</p>
										<b-rate v-model="rating"></b-rate>
									</div>
									<p v-show="ratingError" class="help is-danger">{{ t.ratingError }}</p>

									<b-field class="mt-6">
										<b-input type="textarea" maxlength="100" v-model.trim="reviewComment" :placeholder="this.t.yourReview" required> </b-input>
									</b-field>

									<b-button type="is-primary" class="submit-btn" native-type="submit" :loading="isLoading">{{ t.submit }}</b-button>
								</form>
							</div>
							<div class="media-right"></div>
						</div>

						<div style="margin-top: 100px; padding-bottom: 100px">
							<div class="comment-container" @scroll="reviewScroll">
								<div v-for="review in reviews" :key="review.id" class="mt-6" style="display: flex">
									<figure
										class="image is-64x64 is-background-size-cover is-rounded pointer center-inside"
										:style="[
											review.user.picture ? { 'background-image': `url(${review.user.picture.url})` } : { background: '#FFF', border: 'solid 1px black' },
										]"
									>
										<b-icon v-if="!review.user.picture" icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
									</figure>

									<div class="vl"></div>

									<div>
										<p class="date ml-6">{{ review.createdAt | moment() }}</p>
										<div style="display: flex">
											<p class="comment-name ml-6">{{ review.user.name }}</p>
											<b-rate class="ml-2" disabled v-model="review.rating" size="is-small"> </b-rate>
										</div>
										<p class="comment ml-6 mt-1">{{ review.comment }}</p>
									</div>
								</div>
							</div>
						</div>
					</b-tab-item>
				</b-tabs>
			</div>
		</div>
	</section>
	<section v-else>
		<div class="content">
			<div class="columns is-vcentered" style="height: 70vh">
				<div class="column">
					<p class="has-text-centered is-size-2 has-text-primary">This product is not available</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import VSwatches from 'vue-swatches';
	import {
		getProductDetails,
		addReview,
		getReviews,
		getComments,
		addComment,
		addLike,
		getLikes,
		removeLike,
		addWhishlist,
		getWhishlist,
		removeWhishlist,
		canReview,
		updateProductViews,
		getUserReviewByProduct,
	} from '@/api/feed';
	import moment from 'moment';

	export default {
		components: {
			VSwatches,
		},

		data() {
			return {
				imageIndex: 0,
				t: this.$t(`components.Products.ProductDetails`),
				swatches: [],
				color: null,
				sizeSelected: null,
				getPercentage: [],
				productInfo: [],
				sizes: [],
				reviewsRating: [],
				totalReviewers: 0,
				rating: null,
				reviewComment: null,
				isLoading: false,
				ratingError: false,
				reviews: [],
				comments: [],
				commentsPage: 1,
				commentsPageSize: 10,
				comment: null,
				reviewsPage: 1,
				reviewsPageSize: 10,
				offset: 200,
				isRequesting: false,
				cart: null,
				variantPictures: [],
				variantSelected: null,
				productsLiked: [],
				productsWished: [],
				canReview: false,
				isAddingLike: false,
				isAddingWhishlist: false,
				noProduct: false,
				tab: 0,
				isFullscrean: false,
			};
		},

		methods: {
			chooseSize(id) {
				console.log(id);
				this.sizeSelected = id;
			},

			sendReview() {
				this.isLoading = true;
				if (!this.rating) {
					this.ratingError = true;
					this.isLoading = false;
				} else {
					this.ratingError = false;
					let review = {
						data: {
							product: this.$route.params.id,
							user: this.getUser.id,
							comment: this.reviewComment,
							rating: this.rating,
						},
					};

					getUserReviewByProduct({
						'filters[product]': this.$route.params.id,
						'filters[user][id]': this.getUser.id,
					}).then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: 'Já fez uma review a este produto',
								position: 'is-top',
								type: 'is-danger',
							});
						} else {
							addReview(review)
								.then((response) => {
									this.reviews.unshift(response.data.data);
									this.reviewComment = null;
									this.rating = null;
									this.canReview = false;
									this.$buefy.toast.open({
										message: this.t.addedReview,
										position: 'is-top',
										type: 'is-primary',
									});
									getProductDetails(this.$route.params.id)
										.then((response) => {
											this.productInfo = response.data.data;
											console.log(this.productInfo);

											let reduce = this.productInfo.reviews.reduce((r, a) => {
												if (r[a.rating - 1]) {
													r[a.rating - 1].push(a.id);
												} else {
													r[a.rating - 1] = [a.id];
												}

												return r;
											}, {});

											let i = 0;

											do {
												if (!reduce[i]) {
													reduce[i] = [];
												}
												console.log(reduce[i]);
												this.reviewsRating[i] = reduce[i];
												i = i + 1;
											} while (i < 5);

											this.reviewsRating.map((review, index) => {
												if (review.length > 0) {
													this.totalReviewers = this.totalReviewers + review.length;
												}
											});

											this.reviewsRating.map((review, index) => {
												if (!(review.length * 100) / this.totalReviewers) {
													this.getPercentage[index] = `percentage-${0}`;
												} else this.getPercentage[index] = `percentage-${Math.floor((review.length * 100) / this.totalReviewers)}`;
											});
										})
										.catch((error) => {
											if (error.response.data.error.message == 'Not Found') {
												this.noProduct = true;
											}
										});
								})
								.catch((error) => {
									console.log(error);
								})
								.finally(() => (this.isLoading = false));
						}
					});
				}
			},

			add() {
				let variantId = null;
				console.log(this.cart);
				console.log(this.productInfo.store.id);
				if (!this.getToken) {
					this.goToPage('Home');
				} else {
					if (this.cart.cartProducts.length > 0) {
						if (this.productInfo.store.id == this.cart.cartProducts[0].product.store.id) {
							this.productInfo.productVariants.map((variant) => {
								if (variant.color.hex == this.color) {
									variantId = variant.id;
								}
							});

							if (this.sizeSelected && variantId) {
								this.addCartProduct(this.$route.params.id, variantId, this.sizeSelected, 1);
								if (this.cart.cartProducts.length > 0) {
									this.cart.cartProducts.map((product) => {
										console.log(product);
									});
								}

								this.$buefy.toast.open({
									message: this.t.addToCart,
									position: 'is-top',
									type: 'is-primary',
									duration: 2000,
								});
							} else {
								this.$buefy.toast.open({
									message: this.t.cartErrorMessage,
									position: 'is-top',
									type: 'is-danger',
									duration: 2000,
								});
							}
						} else {
							this.$buefy.toast.open({
								message: this.t.errorStore,
								position: 'is-top',
								type: 'is-danger',
								duration: 2000,
							});
						}
					} else {
						this.productInfo.productVariants.map((variant) => {
							if (variant.color.hex == this.color) {
								variantId = variant.id;
							}
						});

						if (this.sizeSelected && variantId) {
							this.addCartProduct(this.$route.params.id, variantId, this.sizeSelected, 1);
							if (this.cart.cartProducts.length > 0) {
								this.cart.cartProducts.map((product) => {
									console.log(product);
								});
							}
							this.$buefy.toast.open({
								message: this.t.addToCart,
								position: 'is-top',
								type: 'is-primary',
								duration: 2000,
							});
						} else {
							this.$buefy.toast.open({
								message: this.t.cartErrorMessage,
								position: 'is-top',
								type: 'is-danger',
								duration: 2000,
							});
						}
					}
				}
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			commentsScroll(e) {
				let target = {
					scrollTop: e.target.scrollTop,
					clientHeight: e.target.clientHeight,
					scrollHeight: e.target.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;

					this.isRequesting = true;
					this.commentsPage++;

					getComments(this.$route.params.id, this.commentsPage, this.commentsPageSize)
						.then((response) => {
							console.log(response);
							this.comments.push(...response.data.data.reverse());
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			sendComment() {
				this.isLoading = true;

				let userComment = {
					data: {
						product: this.$route.params.id,
						user: this.getUser.id,
						text: this.comment,
					},
				};

				console.log(userComment);

				addComment(userComment)
					.then((response) => {
						console.log(response);
						this.comments.unshift(response.data.data);
						this.comment = null;
						this.$buefy.toast.open({
							message: this.t.addedComment,
							position: 'is-top',
							type: 'is-primary',
						});
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => (this.isLoading = false));
			},

			reviewScroll(e) {
				let target = {
					scrollTop: e.target.scrollTop,
					clientHeight: e.target.clientHeight,
					scrollHeight: e.target.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.reviewsPage++;

					getReviews(this.$route.params.id, this.reviewsPage, this.reviewsPageSize)
						.then((response) => {
							console.log(response);
							this.reviews.push(...response.data.data.reverse());
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			selectColor(id, color, index) {
				this.color = color;
				this.sizes = [];
				this.sizeSelected = null;

				this.productInfo.productVariants.map((variant) => {
					console.log(variant);
					if (variant.id == id) {
						variant.productSizes.map((sizes) => {
							console.log(sizes);
							if (sizes.stockNumber > 0) {
								this.sizes.push(sizes);
							}
						});
						this.variantPictures = variant.pictures;
						this.variantSelected = index;
					}
				});
			},

			like(id) {
				if (this.getToken) {
					this.productsLiked.push(id);
					this.productInfo.likes.push('');

					let like = {
						data: {
							product: id,
						},
					};

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						addLike(like)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			unlike(id) {
				if (this.getToken) {
					this.productsLiked.splice(this.productsLiked.findIndex((element) => element == id));
					this.productInfo.likes.pop();
					if (!this.isAddingLike) {
						this.isAddingLike = true;

						removeLike(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			wish(id) {
				if (this.getToken) {
					this.productsWished.push(id);
					let wishNumber = this.getBookmark;
					wishNumber++;
					this.setBookmark(wishNumber);
					let whish = {
						data: {
							product: id,
						},
					};

					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						addWhishlist(whish)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			deleteWish(id) {
				if (this.getToken) {
					let wishNumber = this.getBookmark;
					wishNumber--;
					this.setBookmark(wishNumber);
					this.productsWished.splice(this.productsWished.findIndex((element) => element == id));
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						removeWhishlist(id)
							.then((response) => {})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			storeDetails(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			toggleOverlay() {
				const element = document.querySelector('html');
				if (!this.isFullscrean) {
					element.style.overflow = 'hidden';
					this.isFullscrean = true;
				} else {
					element.style.overflow = 'auto';
					this.isFullscrean = false;
				}
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('D MMM YYYY');
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		updated() {
			if (this.$route.params.tab == 0) {
				window.scrollTo({
					top: document.body.scrollHeight - 800 || document.documentElement.scrollHeight - 800,
					behavior: 'smooth',
				});
			}
		},

		async created() {
			this.setIsPageLoading(true);
			console.log(this.$route.params);
			this.tab = this.$route.params.tab;
			if (this.getUserType == 'authenticated' && this.getToken) {
				this.getCartFromAPI(this.getUser.id).then(() => {
					this.cart = this.getCart;

					this.cart.cartProducts.map((product) => {
						console.log(product);
					});
					this.setCart(this.cart);
					console.log(this.getCart);
				});

				await canReview(this.$route.params.id).then((response) => {
					console.log(response);
					this.canReview = response.data.canReview;
				});

				if (this.canReview) {
					await getUserReviewByProduct({
						'filters[product]': this.$route.params.id,
						'filters[user][id]': this.getUser.id,
					}).then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.canReview = false;
						} else this.canReview = true;
					});
				}

				if (this.getToken) {
					getWhishlist({
						'filters[product][id]': this.$route.params.id,
						populate: 'product',
					})
						.then((response) => {
							console.log(response);
							this.productsWished = response.data.data.map((whish) => {
								return whish.product.id;
							});
						})
						.catch((error) => {
							console.log(error);
						});

					getLikes({
						'filters[product][id]': this.$route.params.id,
						populate: 'product',
					})
						.then((response) => {
							console.log(response);
							this.productsLiked = response.data.data.map((like) => {
								return like.product.id;
							});
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						});
				}
			}

			if (this.getUserType != 'store') {
				updateProductViews(this.$route.params.id);
			}

			console.log(this.getUser);
			getProductDetails(this.$route.params.id)
				.then((response) => {
					this.productInfo = response.data.data;
					console.log(this.productInfo);

					this.productInfo.productVariants.map((variant, index) => {
						console.log(variant);
						if (index == 0) {
							variant.productSizes.map((sizes) => {
								if (sizes.stockNumber > 0) {
									this.sizes.push(sizes);
								}
							});

							this.variantPictures = variant.pictures;
							this.variantSelected = index;
							this.color = variant.color.hex;
						}
					});
					console.log(this.sizes);

					let reduce = this.productInfo.reviews.reduce((r, a) => {
						if (r[a.rating - 1]) {
							r[a.rating - 1].push(a.id);
						} else {
							r[a.rating - 1] = [a.id];
						}

						return r;
					}, {});

					let i = 0;

					do {
						if (!reduce[i]) {
							reduce[i] = [];
						}
						console.log(reduce[i]);
						this.reviewsRating[i] = reduce[i];
						i = i + 1;
					} while (i < 5);

					this.reviewsRating.map((review, index) => {
						if (review.length > 0) {
							this.totalReviewers = this.totalReviewers + review.length;
						}
					});

					this.reviewsRating.map((review, index) => {
						if (!(review.length * 100) / this.totalReviewers) {
							this.getPercentage[index] = `percentage-${0}`;
						} else this.getPercentage[index] = `percentage-${Math.floor((review.length * 100) / this.totalReviewers)}`;
					});
				})
				.catch((error) => {
					if (error.response.data.error.message == 'Not Found') {
						this.noProduct = true;
					}
				});

			getReviews(this.$route.params.id, this.reviewsPage, this.reviewsPageSize)
				.then((response) => {
					console.log(response);
					this.reviews = response.data.data.reverse();
					console.log(this.reviews);
				})
				.catch((error) => {
					console.log(error);
				});

			getComments(this.$route.params.id, this.commentsPage, this.commentsPageSize)
				.then((response) => {
					console.log(response);
					this.comments = response.data.data.reverse();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
					window.addEventListener('scroll', this.commentsScroll);
					window.addEventListener('scroll', this.reviewScroll);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.commentsScroll);
			window.removeEventListener('scroll', this.reviewScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.content {
		padding-left: 10%;
		padding-right: 10%;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: $primary;
	}

	.address {
		font-size: 1rem;
	}

	img {
		border-radius: 16px;
	}

	.has-border {
		border: 1px solid #aab2b72c;
	}

	.header-container {
		display: inline-flex;
	}

	.header-container p {
		font-size: 1.45rem;
		transform: translateY(-5px);
	}

	.sale {
		width: 86px;
		height: 37px;
		background-color: $primary;
		border-radius: 30px;
		font-size: 1rem;
	}

	.register-steps > div {
		width: 8px;
		height: 8px;
		background: white;
		border: solid 1px $primary;
		border-radius: 50%;
		margin: 15px;
	}
	.register-steps > .step-1 {
		background: $primary;
	}

	.recipe-steps {
		margin: 8px !important;
	}

	.card {
		width: 100%;
		border: 1px solid $white-ter;
		border-radius: 24px 24px 0px 0px;
	}

	.counter {
		color: $grey;
		font-size: 0.9rem;
	}

	.name,
	.price {
		font-size: 1.429rem;
	}

	.price {
		transform: translateY(-5px);
	}

	.description {
		font-size: 1.3rem;
	}

	.description-text {
		display: -webkit-box;

		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.hide-text {
		-webkit-line-clamp: 2;
	}

	/deep/ .vue-swatches__wrapper {
		display: inline-flex !important;
	}

	/deep/ .check {
		background-color: white;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		font-size: 10px;
		transform: translate(8px, 6px);
	}

	.sizes {
		min-width: 30px;
		height: 30px;
		border-radius: 8px;
		text-align: center;
		color: black;
		cursor: pointer;
		border: solid 1px $grey;
		width: auto;
		padding-left: 5px;
		padding-right: 5px;
	}

	.sizes-container {
		display: inline-flex;
	}

	.offer-message {
		font-size: 0.9rem;
		color: $dark;
		opacity: 0.7;
	}

	.offer-container {
		background: transparent linear-gradient(90deg, #99cca800 0%, #99cca890 16%, #99cca8 51%, #99cca8a2 79%, #4d665400 100%) 0% 0% no-repeat padding-box;
		height: 42px;
		width: 100%;
	}

	.btn {
		width: 363px;
		height: 52px;
		border-radius: 14px;
	}
	.btn-add {
		width: 62px;
		height: 52px;
		border-radius: 14px;
		margin-right: 20px;
	}

	.cart-counter {
		background-color: white;
		height: 15px;
		width: 15px;
		position: absolute;
		z-index: 4;
		border-radius: 50%;
		text-align: center;
		font-size: 10px;
		transform: translate(40px, 3px);
		color: $primary;
	}

	.card-steps > div {
		width: 8px;
		height: 8px;
		background: white;
		border: solid 1px $primary;
		border-radius: 50%;
		margin: 15px;
	}
	.card-steps > .step-1 {
		background: $primary;
	}

	.card-steps {
		margin: 0 auto;
		transform: translateX(-40px);
	}

	.product-name {
		font-size: 1.4rem;
		margin-bottom: 0 !important;
		color: $grey-darker;
	}

	.product-price {
		font-size: 1.4rem;
		color: $grey-dark;
	}

	.description,
	.options {
		font-size: 1.3rem;
		color: $grey;
		margin-bottom: 0 !important;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.review {
		font-size: 2rem;
	}

	dt {
		align-self: flex-start;
		width: 100%;
		font-weight: 700;
		display: block;
		text-align: center;
		font-size: 1.2em;
		font-weight: 700;
		margin-bottom: 20px;
		margin-left: 130px;
	}

	.difficulty {
		font-size: 1.3rem;
		display: inline;
		width: 10%;
		justify-content: center;
		transform: translateY(65px);
		text-align: center;
		color: $grey-darker;
	}

	.text {
		font-weight: 600;
		display: flex;
		align-items: center;
		width: 130px;
		position: absolute;
		left: 250px;
		font-size: 16px;
		justify-content: flex-end;
		color: $grey-darker;
	}

	.percentage-very-bad {
		font-size: 0.8em;
		line-height: 1;
		text-transform: uppercase;
		width: auto;
		height: 16px;
		border-radius: 12px;
		background-color: #e0e0e0;
		margin-top: 12px;
		width: 240px;
		&:after {
			content: '';
			display: block;
			background-color: $primary;
			width: 50px;
			margin-bottom: 10px;
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: background-color 0.3s ease;
			border-radius: 7px;
		}
	}

	.percentage-bad {
		font-size: 0.8em;
		line-height: 1;
		height: 16px;
		border-radius: 12px;
		background-color: #e0e0e0;
		margin-top: 12px;
		width: 240px;
		&:after {
			content: '';
			display: block;
			background-color: $primary;
			width: 50px;
			margin-bottom: 10px;
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: background-color 0.3s ease;
			border-radius: 7px;
		}
	}

	.percentage-medium {
		font-size: 0.8em;
		line-height: 1;
		text-transform: uppercase;
		height: 16px;
		border-radius: 12px;
		background-color: #e0e0e0;
		margin-top: 12px;
		width: 240px;
		&:after {
			content: '';
			display: block;
			background-color: $primary;
			width: 50px;
			margin-bottom: 10px;
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: background-color 0.3s ease;
			border-radius: 7px;
		}
	}

	.percentage-good {
		font-size: 0.8em;
		line-height: 1;
		text-transform: uppercase;
		height: 16px;
		border-radius: 12px;
		background-color: #e0e0e0;
		margin-top: 12px;
		width: 240px;
		&:after {
			content: '';
			display: block;
			background-color: $primary;
			width: 50px;
			margin-bottom: 10px;
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: background-color 0.3s ease;
			border-radius: 7px;
		}
	}

	.percentage-very-good {
		font-size: 0.8em;
		line-height: 1;
		text-transform: uppercase;
		height: 16px;
		border-radius: 12px;
		background-color: #e0e0e0;
		margin-top: 12px;
		width: 240px;
		&:after {
			content: '';
			display: block;
			background-color: $primary;
			width: 50px;
			margin-bottom: 10px;
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: background-color 0.3s ease;
			border-radius: 7px;
		}
	}

	@for $i from 0 through 100 {
		.percentage-#{$i} {
			&:after {
				$value: ($i * 1%);
				width: $value;
			}
		}
	}

	.star {
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
	}

	.content dd {
		margin-left: 9em;
	}

	/deep/ textarea.textarea {
		border: solid 3px $grey-light;
		border-radius: 2px;
		resize: none;
	}

	.submit-btn {
		width: 160px;
		height: 46px;
		background: $primary;
		border-radius: 10px;
	}

	.vl {
		border-left: 1px solid $grey-darker;
		height: 70px;
		opacity: 0.2;
	}

	.date {
		color: $primary;
		font-size: 1rem;
		margin-bottom: 0 !important;
	}

	.comment-name {
		font-size: 1.286rem;
		color: $grey-darker;
		margin-bottom: 0 !important;
	}

	.comment {
		font-size: 1.143rem;
		color: $grey-dark;
		margin-bottom: 0 !important;
		opacity: 0.6;
	}

	.tag {
		width: 135px;
		height: 58px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 1.3rem;
		z-index: 2;
	}

	.comment-container {
		overflow-y: auto;
		height: auto;
		max-height: 550px;
	}

	.user-comment {
		margin-bottom: 80px;
		margin-top: 50px;
	}

	/deep/ .text-comment textarea.textarea {
		background-color: white;
		min-height: 5em;
		border: solid 1px $grey-light;
	}

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: $grey;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:active {
		background: #aab2b7;
	}
</style>
