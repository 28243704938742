<template>
	<form @submit.prevent="checkout">
		<div class="header-container">
			<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
			<p class="header ml-2">{{ t.payment }}</p>
		</div>

		<p class="has-text-grey-darker mt-6 ml-2">{{ t.method }}</p>

		<div style="display: inline-flex; position: relative">
			<div class="columns is-multiline is-mobile ml-1 mt-2" v-if="cards.length > 0">
				<div
					class="column add-btn mr-2"
					v-for="card in cards"
					:key="card.id"
					@click="getDetails(card.billing_details.name, card.card.last4, card.card.exp_month, card.card.exp_year, card.id)"
				>
					<b-image v-if="card.card.brand == 'mastercard'" class="img" :src="require('@/assets/svg/mc_symbol.svg')" alt="root"></b-image>
					<b-image v-else-if="card.card.brand == 'visa'" class="img" :src="require('@/assets/svg/visa_blue_symbol.svg')" alt="root"></b-image>
					<p class="has-text-black is-size-6 mt-7" v-if="card.card.brand != 'mastercard' && card.card.brand != 'visa'">{{ card.card.brand }}</p>
				</div>

				<div class="add-card center-inside">
					<b-button class="add-btn" @click="addCard"
						><b-icon pack="rt" class="mt-6" style="margin-left: 1px" type="is-black" custom-size="is-size-5" icon="plus"></b-icon>
						<p>{{ t.addCard }}</p></b-button
					>
				</div>
			</div>

			<div v-else class="add-card ml-2 mt-5 center-inside">
				<b-button class="add-btn" @click="addCard"
					><b-icon pack="rt" class="mt-6" style="margin-left: 1px" type="is-black" custom-size="is-size-5" icon="plus"></b-icon>
					<p>{{ t.addCard }}</p></b-button
				>
			</div>
		</div>

		<p v-if="name" class="has-text-grey-darker mt-6 ml-2">{{ t.creditCard }}</p>

		<div v-if="name" class="card-details mt-2">
			<p class="has-text-white is-size-5">{{ name }}</p>
			<p class="has-text-white is-size-5">**** **** **** {{ number }}</p>

			<div style="display: flex; justify-content: end">
				<p class="has-text-white is-size-6 has-text-right">{{ expire }}</p>
			</div>
		</div>

		<b-collapse class="card mt-10" animation="slide" :open="isOpenShipping" @open="isOpenShipping = true">
			<template #trigger="props">
				<div class="card-header" role="button">
					<p class="card-header-title mt-4">{{ t.shipping }}</p>
					<a class="card-header-icon">
						<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
					</a>
				</div>
			</template>
			<div class="card-content">
				<div style="display: inline-flex" class="mt-8">
					<b-field>
						<b-radio size="is-medium" v-model="shippingPoint" native-value="home"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.home }}</span>
					</b-field>
					<b-field class="ml-15">
						<b-radio size="is-medium" v-model="shippingPoint" native-value="pickUp"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.pickup }}</span>
					</b-field>
				</div>
				<p v-show="errorShipping" class="has-text-danger mt-2">{{ t.selectShipping }}</p>

				<p class="is-size-6 has-text-primary">{{ t.deliveryTime }}</p>

				<div class="mt-4" v-if="shippingPoint == 'home'">
					<div class="level is-mobile" @click="isModalOpen = true">
						<div class="level-left">
							<p class="has-text-dark level-item">{{ t.addShippingAddress }}</p>
						</div>
						<div class="level-right">
							<b-icon pack="rt" icon="plus" type="is-dark" custom-size="is-size-6"> </b-icon>
						</div>
					</div>

					<div class="content info-container mt-4">
						<p class="has-text-grey mb-0" v-if="homeShipping.address && homeShipping.zip_code">{{ homeShipping.address }} , {{ homeShipping.zip_code }}</p>
						<p class="has-text-grey mb-0">{{ homeShipping.city }}</p>
						<p class="has-text-grey mb-0">{{ homeShipping.country }}</p>
					</div>
				</div>

				<div v-else-if="shippingPoint == 'pickUp'">
					<b-autocomplete
						class="mt-8"
						style="height: 42px"
						size="is-medium"
						:data="filteredCities"
						:keep-first="true"
						:open-on-focus="true"
						field="city"
						:placeholder="t.city"
						icon="magnify"
						clearable
						ref="city"
						name="city"
						v-model.trim="city"
						@input="changePickupLocality(city)"
					>
						<template #empty>{{ t.noResults }}</template>
					</b-autocomplete>

					<b-select class="mt-8" :placeholder="t.selectPickup" v-model="pickupId">
						<option v-for="pickup in pickups" :value="pickup.store_number" :key="pickup.id">
							{{ pickup.name }}
						</option>
					</b-select>

					<p class="has-text-danger mt-5" v-if="pickUpError">{{ t.pickUpError }}</p>
				</div>
			</div>
		</b-collapse>

		<b-collapse class="card mt-10" animation="slide" :open="isOpenShipping" @open="isOpenShipping = true">
			<template #trigger="props">
				<div class="card-header" role="button">
					<div class="is-flex card-header-title mt-4">
						<span class="card-header-title"
							>{{ t.billingData }} <span class="has-text-grey is-size-7 ml-2">({{ t.required }})</span></span
						>
					</div>

					<a class="card-header-icon">
						<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
					</a>
				</div>
			</template>

			<div class="card-content">
				<div class="mt-4">
					<div class="level is-mobile" @click="openBilling" v-if="showBillingContainer">
						<div class="level-left">
							<p class="has-text-dark level-item" @click="openBilling">{{ t.addBillingData }}</p>
						</div>
						<div class="level-right">
							<b-icon pack="rt" icon="plus" type="is-dark" custom-size="is-size-6"> </b-icon>
						</div>
					</div>

					<div class="content info-container mt-4" v-if="showBillingContainer">
						<p class="has-text-grey mb-0" v-if="newBillingAddress.address && newBillingAddress.zip_code">
							{{ newBillingAddress.address }} , {{ newBillingAddress.zip_code }}
						</p>
						<p class="has-text-grey mb-0">{{ newBillingAddress.city }}</p>
						<p class="has-text-grey mb-0">{{ newBillingAddress.country }}</p>
					</div>
					<b-field class="mt-4" v-if="!shippingPoint || shippingPoint == 'home'">
						<b-checkbox v-model="useDeliveryAddress">{{ t.useDelivery }}</b-checkbox>
					</b-field>
				</div>
			</div>
		</b-collapse>

		<b-collapse class="card mt-10 discount-card" animation="slide" :open="isOpenDiscount" @open="isOpenDiscount = true">
			<template #trigger="props">
				<div class="card-header" role="button">
					<p class="card-header-title mt-4">{{ t.discount }}</p>
					<a class="card-header-icon">
						<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
					</a>
				</div>
			</template>
			<div class="card-content">
				<div class="mt-4" style="display: inline-flex">
					<b-field>
						<b-radio size="is-medium" v-model="payment" native-value="money"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.money }}</span>
					</b-field>
					<b-field class="ml-15">
						<b-radio size="is-medium" v-model="payment" native-value="coupon"></b-radio
						><span class="has-text-dark" style="transform: translateY(0px)">{{ t.code }}</span>
					</b-field>
				</div>

				<div class="mt-4">
					<b-field :label="t.insertMoney">
						<b-input
							size="is-large"
							type="number"
							min="0"
							v-model="credit_discount"
							:max="userMaxDiscount"
							:disabled="!payment.includes('money') ? true : false"
							step="0.01"
							ref="credit"
							@input="verifyDecimalPlaces"
						></b-input>
					</b-field>
					<p v-if="decimalError" class="has-text-danger is-size-7">{{ t.decimalError }}</p>
					<div>
						<b-button type="is-primary" size="is-small" @click="setMaxMoney" class="has-radius mb-2"> MAX {{ t.money }} </b-button>
					</div>

					<b-field :label="t.insertCode">
						<b-input size="is-large" v-model="coupon" :disabled="!payment.includes('coupon') ? true : false"></b-input>
						<b-icon icon="check" v-if="discountString" class="check" type="is-primary"> </b-icon>
						<b-icon icon="close" v-if="discountString == false && coupon != null && coupon.length != 0" class="check" type="is-danger"> </b-icon>
					</b-field>
				</div>

				<div>
					<img src="../../assets/stripe-badge.png" alt="" />
					<p class="is-size-7 has-text-justified">{{ t.stripe }}</p>
				</div>
			</div>
		</b-collapse>

		<div class="has-background-white-ter total-container">
			<div class="level is-mobile mx-5">
				<div class="level-left">
					<div class="level-item">
						<h2 class="has-text-dark">{{ t.shippingCosts }}</h2>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<h2 class="has-text-dark">{{ getRates }}€</h2>
					</div>
				</div>
			</div>
			<div class="level is-mobile mx-5" v-if="discountApplied > 0">
				<div class="level-left">
					<div class="level-item">
						<h2 class="has-text-dark">{{ t.discountApplied }}</h2>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<h2 class="has-text-dark">{{ discountApplied }}%</h2>
					</div>
				</div>
			</div>
			<div class="level is-mobile mx-5" v-if="credit_discount > 0 && payment == 'money'">
				<div class="level-left">
					<div class="level-item">
						<h2 class="has-text-dark">{{ t.creditsApplied }}</h2>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<h2 class="has-text-dark">{{ credit_discount }}€</h2>
					</div>
				</div>
			</div>

			<div class="level is-mobile mx-5">
				<div class="level-left">
					<p class="has-text-dark">Total</p>
				</div>
				<div class="level-right">
					<p class="has-text-dark">{{ getTotalWithRates.toFixed(2) }}€</p>
				</div>
			</div>

			<b-button expanded size="is-medium" :loading="isLoading" type="is-primary" native-type="submit"
				><p class="is-size-6">{{ t.pay }}</p></b-button
			>
		</div>

		<AddressModal :isModalOpen="isModalOpen" @close="closeModal" @changeValues="changeValues" />
		<BillingModal :isModalOpen="isBillingModalOpen" @close="closeBillingModal" @changeBillingValues="changeBillingValues" :type="currentType" />
	</form>
</template>

<script>
	import { addCard, getCard } from '@/api/paymentsMethod';
	import { getPickupPoints } from '@/api/shipping.js';
	const cities = require('@/assets/cities.json');
	import AddressModal from '@/components/Desktop/Shipping/ShippingAddressModal.vue';
	import BillingModal from '@/components/Desktop/Shipping/ShippingBillingModal.vue';
	import { StripeCheckout } from '@/api/cart';
	import { storeBalance } from '@/api/balance';
	import { getShippingRates } from '@/api/cart';
	import { getVouchers } from '@/api/marketing';
	import moment from 'moment';
	import { Browser } from '@capacitor/browser';
	export default {
		components: {
			AddressModal,
			BillingModal,
		},
		data() {
			return {
				cards: [],
				name: null,
				number: null,
				expire: null,
				id: null,
				t: this.$t(`components.Wallet`),
				isOpenShipping: true,
				isOpenDiscount: true,
				payment: [],
				cart: null,
				shippingPoint: null,
				homeShipping: {
					address: null,
					zip_code: null,
					city: null,
					country: null,
				},
				citiesList: [],
				city: '',
				pickups: [],
				pickupId: null,
				isModalOpen: false,
				isLoading: false,
				storeMoneyLimit: 0,
				userMoneyLimit: 0,
				userMaxDiscount: 0,
				credit_discount: 0,
				coupon: null,
				errorShipping: false,
				total: 0,
				totalWeight: 0,
				shippingRates: [],
				shippingValue: 0,
				pickUpError: false,
				vouchers: [],
				discountString: null,
				discountApplied: 0,
				useDeliveryAddress: false,
				showBillingContainer: true,

				newBillingAddress: {
					address: null,
					zip_code: null,
					city: null,
					country: null,
					nif: null,
					company: null,
					phone: null,
				},
				isBillingModalOpen: false,
				currentType: null,
				decimalError: false,
				t: this.$t(`views.Shipping`),
			};
		},
		methods: {
			addCard() {
				if (this.shippingPoint == 'home') {
					this.setShippingType(this.shippingPoint);
					this.setAddress(this.homeShipping.address);
					this.setZipCode(this.homeShipping.zip_code);
					this.setCity(this.homeShipping.city);
					this.setCountry(this.homeShipping.country);
				} else if (this.shippingPoint == 'pickUp') {
					this.setShippingType(this.shippingPoint);
					this.setPickupId(this.pickupId);
				} else {
					this.setShippingType(this.shippingPoint);
					this.setAddress(this.homeShipping.address);
					this.setZipCode(this.homeShipping.zip_code);
					this.setCity(this.homeShipping.city);
					this.setCountry(this.homeShipping.country);
				}

				if (this.useDeliveryAddress && this.shippingPoint == 'home') {
					this.setUseDeliveryAddress(true);
				} else {
					this.setUseDeliveryAddress(false);
					this.setBillingAddress(this.newBillingAddress.address);
					this.setBillingZipCode(this.newBillingAddress.zip_code);
					this.setBillingCity(this.newBillingAddress.city);
					this.setBillingCountry(this.newBillingAddress.country);
					this.setNif(this.newBillingAddress.nif);
					this.setCompany(this.newBillingAddress.company);
					this.setPhone(this.newBillingAddress.phone);
				}
				this.setExtraDiscount(this.credit_discount);
				this.setDiscountCode(this.coupon);
				this.setCameFromCard(true);
				this.setPaymentType(this.payment);

				let url = {
					success_url: this.$route.path,
					cancel_url: this.$route.path,
				};
				addCard(url)
					.then((response) => {
						console.log(response);
						window.location.href = response.data.url;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getDetails(name, number, month, year, id) {
				this.name = name;
				this.number = number;
				this.expire = month + '/' + year;
				this.id = id;
			},

			closeModal() {
				this.isModalOpen = false;
			},

			closeBillingModal() {
				this.isBillingModalOpen = false;
			},

			changeValues(userAddress) {
				console.log(userAddress);
				this.homeShipping.address = userAddress.address;
				this.homeShipping.city = userAddress.city;
				this.homeShipping.zip_code = userAddress.zip_code;
				this.homeShipping.country = userAddress.country;
				this.city = this.homeShipping.city;
				this.isModalOpen = false;
			},

			changeBillingValues(userAddress) {
				console.log(userAddress);
				this.newBillingAddress.address = userAddress.address;
				this.newBillingAddress.city = userAddress.city;
				this.newBillingAddress.zip_code = userAddress.zip_code;
				this.newBillingAddress.country = userAddress.country;
				this.newBillingAddress.nif = userAddress.nif;
				this.newBillingAddress.company = userAddress.company;
				this.newBillingAddress.phone = userAddress.phone;
				this.isBillingModalOpen = false;
			},

			changePickupLocality(city) {
				console.log(city);
				if (city != '') {
					getPickupPoints(city)
						.then((response) => {
							console.log(response);
							this.pickups = response.data;
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},

			checkout() {
				console.log(this.cart);
				this.isLoading = true;

				if (!this.shippingPoint) {
					this.errorShipping = true;
					this.isLoading = false;
				} else if (this.shippingPoint) {
					if (this.shippingPoint == 'home' && (!this.homeShipping.address || !this.homeShipping.zip_code || !this.homeShipping.city || !this.homeShipping.country)) {
						this.isLoading = false;
						this.isModalOpen = true;
						this.errorShipping = false;
					} else if (this.shippingPoint == 'pickUp' && (!this.shippingPoint || !this.pickupId)) {
						this.isLoading = false;
						this.pickUpError = true;
						this.errorShipping = false;
					} else if (
						!this.useDeliveryAddress &&
						(!this.newBillingAddress.address || !this.newBillingAddress.zip_code || !this.newBillingAddress.city || !this.newBillingAddress.country)
					) {
						this.isLoading = false;
						this.pickUpError = false;
						this.errorShipping = false;
						this.isBillingModalOpen = true;
						this.errorShipping = false;
					} else {
						let checkout = {
							store: {
								id: this.cart.cartProducts[0].product.store.id,
								account: this.cart.cartProducts[0].product.store.stripe_account,
							},
							products: [],
							shipping: {
								type: null,
								address: null,
								zip_code: null,
								locality: null,
								country: null,
								pickupId: null,
								weight: 0,
							},
							billing: {
								address: null,
								zip_code: null,
								locality: null,
								country: null,
								nif: null,
								company: null,
								phone_number: null,
							},

							credit_discount: null,
							coupon: null,
						};

						if (this.shippingPoint == 'home') {
							checkout.shipping.type = this.shippingPoint;
							checkout.shipping.address = this.homeShipping.address;
							checkout.shipping.zip_code = this.homeShipping.zip_code;
							checkout.shipping.locality = this.homeShipping.city;
							checkout.shipping.country = this.homeShipping.country;
						} else {
							checkout.shipping.type = this.shippingPoint;
							checkout.shipping.pickupId = this.pickupId;
						}

						if (this.useDeliveryAddress && this.shippingPoint == 'home') {
							checkout.billing.address = this.homeShipping.address;
							checkout.billing.zip_code = this.homeShipping.zip_code;
							checkout.billing.locality = this.homeShipping.city;
							checkout.billing.country = this.homeShipping.country;
							if (this.getUser.vat) {
								checkout.billing.nif = this.getUser.vat;
							}
						} else {
							checkout.billing.address = this.newBillingAddress.address;
							checkout.billing.zip_code = this.newBillingAddress.zip_code;
							checkout.billing.locality = this.newBillingAddress.city;
							checkout.billing.country = this.newBillingAddress.country;
							checkout.billing.nif = this.newBillingAddress.nif;
							checkout.billing.company = this.newBillingAddress.company;
							checkout.billing.phone_number = this.newBillingAddress.phone;
						}

						if (this.payment == 'money') {
							checkout.credit_discount = this.credit_discount;
						} else if (this.payment == 'coupon' && this.discountString == true) {
							checkout.coupon = this.coupon;
						}

						this.cart.cartProducts.map((product) => {
							console.log(product);
							checkout.shipping.weight = checkout.shipping.weight + product.product.weight * 100;
							checkout.products.push({
								id: product.product.id,
								stripe_id: product.product.stripe_id,
								quantity: product.quantity,
								variant: product.productVariant.id,
								size: product.productSize.id,
							});
						});

						console.log(checkout);

						StripeCheckout(checkout)
							.then((response) => {
								console.log(response);
								/* const openCapacitorSite = async () => {
									await Browser.open({ url: response.data.url })
								}
								
								openCapacitorSite(); */
								window.location.href = response.data.url;
							})
							.catch((error) => {
								console.log(error);
								if (error.response.data.error.message == 'Invalid Voucher') {
									this.$buefy.toast.open({
										message: this.t.invalidVoucher,
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									this.$buefy.toast.open({
										message: this.t.error,
										position: 'is-top',
										type: 'is-danger',
									});
								}
							})
							.finally(() => {
								this.isLoading = false;
							});
					}
				}
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			getTotalAmount() {
				console.log(this.cart);
				this.cart.cartProducts.forEach((cartProduct) => {
					console.log(cartProduct);
					if (cartProduct.product.productSales[0].isActive == true) {
						this.total += parseInt(cartProduct.quantity) * this.calculateSale(cartProduct.product.price, cartProduct.product.productSales[0].discount);
					} else this.total += parseInt(cartProduct.quantity) * cartProduct.product.price;
					this.totalWeight = this.totalWeight + cartProduct.product.weight * parseInt(cartProduct.quantity);
				});
			},

			setMaxMoney() {
				this.credit_discount = this.userMaxDiscount;
				this.$refs.credit.focus();
			},

			openBilling() {
				this.isBillingModalOpen = true;
				this.currentType = this.shippingPoint;
			},

			verifyDecimalPlaces(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalError = true;
				} else this.decimalError = false;
			},
		},

		computed: {
			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.city.toLocaleLowerCase()) >= 0;
				});
			},

			getTotalWithRates() {
				if (this.discountApplied > 0 && this.payment == 'coupon') {
					return this.total - this.total * (this.discountApplied / 100) + this.getRates;
				} else if (this.credit_discount > 0 && this.payment == 'money') {
					return this.total - this.credit_discount + this.getRates;
				} else return this.total + this.getRates;
			},

			getRates() {
				if (this.total < this.getCart.cartProducts[0].product.store.shippingMinValue) {
					this.shippingRates.map((rates) => {
						if (this.shippingPoint == 'home' && rates.delivery_type == 'home' && this.totalWeight <= 5 && rates.isLarge == false) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'home' && rates.delivery_type == 'home' && this.totalWeight > 5 && rates.isLarge == true) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'pickUp' && rates.delivery_type == 'pickUp' && this.totalWeight <= 5 && rates.isLarge == false) {
							this.shippingValue = rates.value;
						} else if (this.shippingPoint == 'pickUp' && rates.delivery_type == 'pickUp' && this.totalWeight > 5 && rates.isLarge == true) {
							this.shippingValue = rates.value;
						}
					});

					return this.shippingValue;
				} else return 0;
			},

			applyVoucher() {
				return this.coupon;
			},

			getDeliveryOption() {
				return this.useDeliveryAddress;
			},
		},

		watch: {
			applyVoucher(newVal) {
				if (newVal || newVal == '') {
					let voucher = this.vouchers.filter((code) => code.code == this.coupon);
					console.log(voucher);
					if (voucher.length > 0) {
						this.discountString = true;
						this.discountApplied = voucher[0].discount;
					} else {
						this.discountString = false;
						this.discountApplied = 0;
					}
				}
			},

			getDeliveryOption(newVal) {
				if (newVal) {
					this.showBillingContainer = false;
				} else {
					this.showBillingContainer = true;
				}
			},
		},

		updated() {
			if (this.shippingPoint == 'pickUp') {
				this.useDeliveryAddress = false;
			}
		},

		mounted() {
			getShippingRates().then((response) => {
				console.log(response);
				this.shippingRates = response.data.data;
			});
		},

		created() {
			this.setIsPageLoading(true);
			console.log(this.getCameFromCard);
			if (this.getCameFromCard == true) {
				if (this.getShippingType) {
					this.shippingPoint = this.getShippingType;
				}

				this.homeShipping = {
					address: this.getAddress,
					zip_code: this.getZipCode,
					city: this.getCity,
					country: this.getCountry,
				};

				this.newBillingAddress = {
					address: this.getBillingAddress,
					zip_code: this.getBillingZipCode,
					city: this.getBillingCity,
					country: this.getBillingCountry,
					nif: this.getNif,
					company: this.getCompany,
					phone: this.getPhone,
				};
				this.useDeliveryAddress = this.getUseDeliveryAddress;

				this.credit_discount = this.getExtraDiscount;
				this.coupon = this.getDiscountCode;
				if (this.getPaymentType) {
					this.payment = this.getPaymentType;
				}

				if (this.getShippingType == 'pickUp') {
					this.changePickupLocality(this.getCity);
				}

				this.pickupId = +this.getPickupId;
			} else {
				this.homeShipping = {
					address: this.getUser.location_address,
					zip_code: this.getUser.location_zipCode,
					city: this.getUser.location_municipality,
					country: this.getUser.location_country,
				};
			}

			if (this.homeShipping.city) {
				this.city = this.homeShipping.city;
			} else this.city = '';
			this.getCartFromAPI(this.getUser.id).then(() => {
				this.cart = this.getCart;
				console.log(this.cart);
				this.getTotalAmount();

				this.storeMoneyLimit = this.cart.cartProducts[0].product.store.userMaxValue;
				console.log(this.storeMoneyLimit);

				storeBalance({ 'filters[store]': this.cart.cartProducts[0].product.store.id })
					.then((response) => {
						console.log(response);
						if (response.data.data.length > 0) {
							this.userMoneyLimit = (this.storeMoneyLimit / 100) * response.data.data[0].credit;

							this.userMaxDiscount = this.userMoneyLimit.toFixed(2);
						} else this.userMaxDiscount = 0;
					})
					.catch((error) => {
						console.log(error);
					});
			});

			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));

			if (this.getUser.location_municipality) {
				getPickupPoints(this.getUser.location_municipality)
					.then((response) => {
						console.log(response);
						this.pickups = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				let local = '';
				getPickupPoints(local)
					.then((response) => {
						console.log(response);
						this.pickups = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			}

			getCard()
				.then((response) => {
					console.log(response);
					this.cards = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
			getVouchers({
				'filters[users]': this.getUser.id,
				...(this.getCart.cartProducts[0].product.store.id && { 'filters[store][id]': this.getCart.cartProducts[0].product.store.id }),
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					let filter = response.data.data;

					let date = moment().format('YYYY-MM-DD');

					filter.map((voucher) => {
						if (moment(date).isBetween(voucher.startAt, voucher.endAt, undefined, '[]')) {
							this.vouchers.push(voucher);
						}
					});

					console.log(this.vouchers);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => this.setIsPageLoading(false));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.add-btn {
		min-width: 100px;
		height: 72px;
		border-radius: 14px;
		border: solid 1px $primary;
		background-color: white;
		font-size: 0.75rem;
		text-align: center;
		margin-bottom: 10px;

		@include until($mobile-s) {
			min-width: 76px;
		}
		@include from($mobile-l) {
			max-width: 100px;
		}
	}

	.card-details {
		background: rgb(95, 210, 128);
		background: linear-gradient(25deg, rgba(95, 210, 128, 1) 18%, rgba(174, 214, 186, 1) 100%);
		width: 100%;
		height: 100px;
		padding: 1rem;
		border-radius: 20px;
	}

	.img {
		height: 10px;
		display: block;
		margin: 0 auto;
		width: 50%;
	}

	.card,
	.card-header {
		box-shadow: none !important;
	}

	.card-header-title,
	.card-content {
		padding: 0 !important;
		font-weight: normal;
	}

	.info-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 10px 24px 54px #0000000d;
		border-radius: 16px;
		height: auto;
		padding: 1rem;
	}

	.discount-card {
		margin-bottom: 180px;
	}

	.total-container {
		position: fixed;
		bottom: 0px;
		width: 100%;
		left: 0;
		min-height: 140px;
		padding: 1rem;
		border-top-left-radius: 24px;
		border-top-right-radius: 24px;
		z-index: 10;

		@include until($mobile-s) {
			min-height: 100px;
		}
	}

	/deep/ input.input {
		background-color: white;
		border: 1px solid #e6e6e6;
		border-radius: 15px;
		width: 300px;
		height: 43px;
		font-size: 1rem;

		@include until($mobile-l) {
			width: 100%;
		}
	}

	/deep/ select {
		background-color: white;
		height: 43px;
		width: 300px;
		border: 1px solid #e6e6e6;
		border-radius: 15px;

		@include until($mobile-l) {
			width: 365px;
		}
	}

	.check {
		transform: translateY(-33px);
		position: absolute;
		right: 20px;
		@include from($mobile-l) {
			left: 270px;
		}
	}

	/deep/ .card-header-icon {
		padding: 0.75rem 0;
	}
</style>
