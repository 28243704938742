import { get } from '@/utils/http';
const queryString = require('qs');

export function getProducts(query = {}) {
	let _query = queryString.stringify(query);
	return get('/api/products?populate=likes &' + _query);
}

export function getStoreInfo(id, query = {}) {
	let _query = queryString.stringify(query);
	return get('/api/stores/' + id + '?populate=orders &' + _query);
}
