<template>
	<section class="center-inside">
		<div class="modal" :class="{ 'is-active': isModalOpen }">
			<div class="modal-background"></div>
			<div class="modal-card px-6">
				<header class="modal-card-head">
					<p class="modal-card-title center-inside">{{ t.info }}</p>
				</header>
				<section class="modal-card-body">
					<form @submit.prevent="update">
						<b-tabs size="is-large">
							<b-tab-item :label="t.mainInfo">
								<div class="center-inside mt-4" style="width: 100%">
									<b-upload v-model="storeImage" class="file-label mr-2" accept="image/*" v-validate="'required'" name="photo">
										<div class="photo center-inside">
											<img class="profile-photo has-radius" v-if="storeImage" :src="getFileURL" />
											<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
										</div>
									</b-upload>
								</div>
								<p v-show="errors.has('photo')" class="help is-danger has-text-centered">{{ t.photoError }}</p>

								<div class="mt-4">
									<div style="display: inline-flex">
										<b-field :label="t.name">
											<b-input class="input" size="is-medium" :placeholder="t.name" type="text" required v-model.trim="form.data.name" disabled></b-input>
										</b-field>

										<b-field class="ml-4" :label="t.city">
											<b-autocomplete
												class="input"
												style="height: 42px"
												size="is-medium"
												rounded
												v-model.trim="form.data.location_municipality"
												:data="filteredCities"
												:keep-first="true"
												:open-on-focus="true"
												field="city"
												:placeholder="t.city"
												icon="magnify"
												clearable
												ref="city"
												name="city"
												required
												:disabled="isLoading ? true : false"
											>
												<template #empty>No results found</template>
											</b-autocomplete>
										</b-field>
									</div>
								</div>

								<div>
									<div style="display: inline-flex" class="mt-4">
										<b-field :label="t.cae">
											<b-input
												class="input"
												size="is-medium"
												:placeholder="t.cae"
												type="text"
												v-model.trim="form.data.cae"
												:class="{ 'error-text': errors.has('cae') }"
												v-validate="'numeric|max:5|min:4|required'"
												name="cae"
												ref="cae"
												:disabled="isLoading ? true : false"
											></b-input>
										</b-field>
										<b-field :label="t.zip" class="ml-4">
											<b-input
												class="input"
												size="is-medium"
												:placeholder="t.zip"
												type="text"
												v-model.trim="form.data.location_zipCode"
												v-validate="'required'"
												required
												:disabled="isLoading ? true : false"
												pattern="\d{4}-\d{3}"
												name="zip_code"
											></b-input>
										</b-field>
									</div>
									<p v-show="errors.has('cae')" class="help is-danger">{{ t.invalidCae }}</p>
								</div>

								<div>
									<div style="display: inline-flex" class="mt-4">
										<b-field :label="t.nipc">
											<b-input
												class="input"
												size="is-medium"
												:placeholder="t.nipc"
												type="text"
												v-model.trim="form.data.vat"
												:class="{ 'error-text': errors.has('nipc') }"
												v-validate="'numeric|length:9|required'"
												name="nipc"
												ref="nipc"
												:disabled="isLoading ? true : false"
											></b-input>
										</b-field>
										<!-- <b-field class="ml-4" :label="t.country">
											<b-input
												class="input"
												size="is-medium"
												name="country"
												:placeholder="t.country"
												type="text"
												v-model.trim="form.data.location_country"
												v-validate="'required'"
												required
											></b-input>
										</b-field> -->
										<b-field class="ml-4" :label="t.country">
											<b-select
												size="is-medium"
												:placeholder="t.country"
												name="country"
												v-model.trim="form.data.location_country"
												v-validate="'required'"
												required
												:disabled="isLoading ? true : false"
											>
												<option value="Portugal">Portugal</option>
											</b-select></b-field
										>
									</div>
									<p v-show="errors.has('nipc')" class="help is-danger">{{ t.invalidNipc }}</p>
								</div>

								<div>
									<div style="display: inline-flex" class="mt-4">
										<b-field :label="t.fullAddress">
											<b-input
												class="input"
												size="is-medium"
												:placeholder="t.fullAddress"
												type="text"
												v-model.trim="form.data.location_address"
												v-validate="'required'"
												required
												disabled
											></b-input>
										</b-field>
										<b-field :label="t.email" class="ml-4">
											<b-input class="input" size="is-medium" disabled :placeholder="t.email" type="email" v-model.trim="form.data.email"></b-input>
										</b-field>
									</div>
								</div>
								<div>
									<div style="display: inline-flex" class="mt-4">
										<div>
											<b-field :label="t.iban">
												<b-input
													class="input"
													size="is-medium"
													:placeholder="t.iban"
													type="text"
													v-model.trim="form.data.iban"
													:class="{ 'error-text': errors.has('iban') }"
													v-validate="'length:25|required'"
													name="iban"
													ref="iban"
													:disabled="isLoading ? true : false"
												></b-input>
											</b-field>
											<p v-show="errors.has('iban')" class="help is-danger">{{ t.invalidIban }}</p>
										</div>
										<div>
											<b-field class="ml-4" :label="t.phone">
												<b-input
													class="input"
													size="is-medium"
													name="phone"
													:placeholder="t.phone"
													type="text"
													v-model.trim="form.data.telephone"
													v-validate="'required|mobile-phone|numeric'"
													:disabled="isLoading ? true : false"
												></b-input>
											</b-field>
											<p v-show="errors.has('phone')" class="help is-danger ml-4">{{ t.invalidPhone }}</p>
										</div>
									</div>
								</div>

								<div>
									<div style="display: inline-flex" class="mt-4">
										<b-field :label="t.maximum">
											<b-input
												class="input"
												size="is-medium"
												placeholder="Insert value (%)"
												type="number"
												v-model.trim="form.data.userMaxValue"
												required
												v-validate="'numeric|required'"
												min="1"
												step="1"
												:disabled="isLoading ? true : false"
											></b-input>
										</b-field>
										<b-field :label="t.shipping" class="ml-0">
											<!-- tinha ml-4 -->
											<b-input
												class="input"
												size="is-medium"
												placeholder="Insert value (€)"
												type="number"
												v-model.trim="form.data.shippingMinValue"
												required
												v-validate="'numeric|required'"
												min="15"
												step="0.01"
												:disabled="isLoading ? true : false"
											></b-input>
										</b-field>
									</div>
								</div>
							</b-tab-item>
							<b-tab-item :label="t.aditionalInfo">
								<b-field class="ml-4 mt-4" :label="t.description">
									<b-input
										size="is-medium"
										name="description"
										maxlength="200"
										type="textarea"
										:placeholder="t.description"
										v-model.trim="form.data.description"
									></b-input>
								</b-field>

								<b-field class="ml-4 mt-4" label="Facebook">
									<b-input
										size="is-medium"
										class="input"
										name="description"
										type="input"
										placeholder="Facebook"
										v-model.trim="form.data.networks_facebook"
									></b-input>
								</b-field>

								<b-field class="ml-4 mt-4" label="Instagram">
									<b-input
										size="is-medium"
										class="input"
										name="description"
										type="input"
										placeholder="Instagram"
										v-model.trim="form.data.networks_instagram"
									></b-input>
								</b-field>
							</b-tab-item>
						</b-tabs>

						<div class="button-container has-text-centered mt-8">
							<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">Guardar</b-button>
						</div>
					</form>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
	import { updateStore, stripeAccountLink } from '@/api/store';
	import { uploadPhoto } from '@/api/user';
	const cities = require('@/assets/cities.json');
	export default {
		props: ['isModalOpen'],
		data() {
			return {
				t: this.$t(`components.Desktop.StoreInfo`),
				form: {
					data: {
						name: null,
						email: null,
						location_address: null,
						location_country: null,
						location_municipality: '',
						location_zipCode: null,
						cae: null,
						vat: null,
						iban: null,
						telephone: null,
						description: null,
						networks_facebook: null,
						networks_instagram: null,
						pictures: [],
						shippingMinValue: null,
						userMaxValue: 0,
					},
				},
				isLoading: false,
				storeImage: null,
			};
		},

		methods: {
			update() {
				this.isLoading = true;
				this.$validator.validateAll().then((result) => {
					if (result) {
						uploadPhoto(this.storeImage)
							.then((response) => {
								console.log(response);
								this.form.data.pictures.push(response.data[0].id);
								console.log(this.form.data.pictures);

								updateStore(this.getUser.store.id, this.form)
									.then((response) => {
										console.log(response);
										this.updateStore(response.data.data);
										this.isLoading = false;
										this.$emit('close');

										stripeAccountLink()
											.then((response) => {
												console.log(response);
												window.location.href = response.data.url;
											})
											.catch((error) => {
												console.log(error);
											});
									})
									.catch((error) => {
										console.log(error);
									})
									.finally(() => {
										this.isLoading = false;
									});
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isLoading = false;
							});
					} else this.isLoading = false;
				});
			},
		},

		computed: {
			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.form.data.location_municipality.toLowerCase()) >= 0;
				});
			},

			getFileURL() {
				return window.URL.createObjectURL(this.storeImage);
			},
		},

		created() {
			this.form.data.name = this.getUser.store.name;
			this.form.data.email = this.getUser.email;
			this.form.data.location_address = this.getUser.store.location_address;
			this.form.data.telephone = this.getUser.store.telephone;
			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.modal-card {
		height: auto;
		width: auto;
	}

	.modal-card-head {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.modal-card-body {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 20px;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	hr {
		width: 40px;
	}

	.input {
		border: solid 3px $white-ter;
		border-radius: 11px;
		width: 300px;
	}

	.remember {
		font-size: 1rem;
		transform: translate(0, 20px);
		color: $grey;
		width: 280px;
	}

	.btn-signin {
		width: 360px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		width: 600px;
	}

	.is-full-round {
		box-shadow: 5px 5px 27px #00000021 !important;
	}

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	.pointer {
		cursor: pointer;
	}

	.photo {
		width: 125px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
		border: $grey-light dashed 2px;
	}

	img {
		width: 125px;
		height: 96px;
		object-fit: cover;
	}

	/deep/ .textarea:not([rows]) {
		max-height: 40em;
		min-height: 5em;
		border: solid 3px $white-ter;
		border-radius: 11px;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
	}

	/* /deep/.control {
		width: 300px;
	} */
	/deep/span.select.is-medium {
		width: 300px;
	}
	/deep/select {
		width: 300px;
		border: solid 3px $white-ter;
		border-radius: 11px;
	}
</style>
