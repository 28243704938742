<template>
	<section :key="key">
		<div class="main-container">
			<div class="header-container px-6">
				<div class="level">
					<div class="level-left">
						<div class="level-item">
							<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" class="is-clickable" size="is-medium"> </b-icon>
							<p class="header ml-2">{{ t.profile }}</p>
						</div>
					</div>
					<div class="level-right">
						<div class="level-item">
							<b-button @click="openFilter" type="is-white" class="btn-sort"
								><b-icon pack="rt" icon="sort" size="is-size-4" type="is-black"></b-icon><span class="sort-text">{{ t.sort }}</span></b-button
							>
						</div>
					</div>
					<div v-show="showFilter" class="filter-window mt-2 px-6 py-2">
						<div v-if="tab == 0">
							<p class="is-size-6">{{ t.sort }}</p>
							<b-field class="mt-3">
								<b-radio native-value="createdAt:desc" @input="changeSort" v-model="sortVoucher">{{ t.new }}</b-radio>
							</b-field>
							<b-field class="mt-3">
								<b-radio native-value="discount:desc" @input="changeSort" v-model="sortVoucher">{{ t.discountHigh }}</b-radio>
							</b-field>
							<b-field class="mt-3">
								<b-radio native-value="discount:asc" @input="changeSort" v-model="sortVoucher">{{ t.discountLow }}</b-radio>
							</b-field>
							<b-field class="mt-3">
								<b-radio native-value="createdAt:asc" @input="changeSort" v-model="sortVoucher">{{ t.old }}</b-radio>
							</b-field>

							<p class="is-size-6">{{ t.stores }}</p>
							<b-field class="mt-3">
								<b-radio :native-value="null" @input="changeSort" v-model="filterStore">{{ t.all }}</b-radio>
							</b-field>
							<b-field class="mt-3" v-for="(store, index) in stores" :key="index">
								<b-radio v-if="index == 0" :native-value="store.id" @input="changeSort" v-model="filterStore">{{ store.name }}</b-radio>
								<b-radio
									v-else-if="index + 1 != stores.length && stores[index] != stores[index - 1].name && stores[index] != stores[index + 1].name"
									:native-value="store.id"
									@input="changeSort"
									v-model="filterStore"
									>{{ store.name }}</b-radio
								>
							</b-field>
						</div>
						<div v-if="tab == 1">
							<p class="is-size-6">{{ t.orderBy }}</p>
							<b-field class="mt-3">
								<b-radio native-value="id:desc" @input="changeSort" v-model="sortOrder">{{ t.newOrders }}</b-radio>
							</b-field>
							<b-field class="mt-3">
								<b-radio native-value="id:asc" @input="changeSort" v-model="sortOrder">{{ t.oldOrders }}</b-radio>
							</b-field>
						</div>
						<div v-if="tab == 2">
							<p class="is-size-6">{{ t.orderBy }}</p>
							<b-field class="mt-3">
								<b-radio native-value="id:desc" @input="changeSort" v-model="sortDevolution">{{ t.newOrders }}</b-radio>
							</b-field>
							<b-field class="mt-3">
								<b-radio native-value="id:asc" @input="changeSort" v-model="sortDevolution">{{ t.oldOrders }}</b-radio>
							</b-field>
						</div>
					</div>
				</div>
			</div>

			<div class="media px-6 center-inside mt-10">
				<div class="media-left">
					<div class="photo center-inside has-radius" :style="'border:solid 1px black'">
						<img class="has-radius" v-if="img" :src="img" alt="" />
						<b-icon v-else icon="account-outline  " type="is-black" custom-size="is-size-2"> </b-icon>
					</div>
				</div>
				<div class="media-content">
					<p class="name">{{ getUser.name }}</p>
					<p class="address has-text-grey">{{ address }}</p>
					<p class="description has-text-grey">{{ getUser.description }}</p>
				</div>
				<div class="media-right"></div>
			</div>

			<b-tabs v-model="tab" position="is-left" size="is-large" class="mt-10">
				<b-tab-item :label="t.discounts">
					<Discounts :sort="sortVoucher" :store="filterStore" @getStores="getStores" />
				</b-tab-item>
				<b-tab-item :label="t.order">
					<Orders :sort="sortOrder" />
				</b-tab-item>
				<b-tab-item :label="t.devolutions"> <Devolutions :sort="sortDevolution" /> </b-tab-item>
			</b-tabs>
		</div>
	</section>
</template>

<script>
	import Discounts from '@/components/Profile/DiscountsTab.vue';
	import Orders from '@/components/Profile/OrdersTab.vue';
	import Devolutions from '@/components/Profile/DevolutionsTab.vue';

	export default {
		components: {
			Discounts,
			Orders,
			Devolutions,
		},

		data() {
			return {
				t: this.$t(`views.UserProfile`),
				tab: 0,
				address: null,
				img: null,
				showFilter: false,
				sortVoucher: 'createdAt:desc',
				key: 0,
				sortOrder: 'createdAt:desc',
				sortDevolution: 'createdAt:desc',
				stores: [],
				filterStore: null,
			};
		},

		methods: {
			openFilter() {
				this.showFilter = !this.showFilter;
			},

			changeSort() {
				this.showFilter = false;
				this.key++;
			},

			getStores(stores) {
				console.log(stores);
				this.stores = stores;
			},
		},

		mounted() {
			if (this.getUser.location_municipality && this.getUser.location_country) {
				this.address = this.getUser.location_municipality + ', ' + this.getUser.location_country;
			}

			if (this.getUser.picture) {
				this.img = this.getUser.picture.url;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	/* .main-container {
		padding-left: 140px;
		padding-right: 140px;
	} */

	.pointer {
		cursor: pointer;
	}

	.header {
		font-size: 1.5rem;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 105px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
		/* 	border: black solid 1px; */
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.name {
		font-size: 1.5rem;
	}

	.address {
		font-size: 1.357rem;
	}

	.description {
		font-size: 1.143rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: black;
	}

	.btn-sort {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
	}

	.filter-window {
		width: 250px;
		height: auto;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 23px;
		z-index: 10;
		padding: 1px;
		background-color: #ffffff;
		top: 41px;
	}
</style>
