<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileDevolutionDetail/>
		</div>
		<div class="container" v-else>
			<DesktopDevolutionDetail />
		</div>
	</section>
</template>

<script>
	import DesktopDevolutionDetail from '@/components/Desktop/Orders/DevolutionDetail.vue';
	import MobileDevolutionDetail from '@/components/Orders/DevolutionDetails.vue'
	
	export default {
		components: {
			DesktopDevolutionDetail,
			MobileDevolutionDetail
		},

		data() {
			return {};
		},
	};
</script>