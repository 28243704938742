<template>
	<section class="px-6">
		<form @submit.prevent="create">
			<b-field>
				<b-input class="input" required :placeholder="t.title"></b-input>
			</b-field>

			<b-field>
				<b-input class="input mt-4" required :placeholder="t.description"></b-input>
			</b-field>

			<b-field>
				<b-input class="input mt-4" required :placeholder="t.discount"></b-input>
			</b-field>

			<div class="mt-6">
				<p class="has-text-weight-medium">Send to</p>
				<b-checkbox size="is-large" class="checkbox mt-4">
					<p class="is-size-6">{{ t.all }}</p>
				</b-checkbox>
				<b-checkbox size="is-large" class="checkbox mt-4 ml-7">
					<p class="is-size-6">{{ t.birthday }}</p>
				</b-checkbox>
				<br />
				<b-checkbox size="is-large" class="checkbox mt-6">
					<p class="is-size-6">{{ t.man }}</p>
				</b-checkbox>
				<b-checkbox size="is-large" class="checkbox mt-4 ml-4">
					<p class="is-size-6">{{ t.woman }}</p>
				</b-checkbox>
			</div>

			<div class="mt-6 datepicker-container">
				<div>
					<p>{{ t.start }}</p>
					<b-field>
						<b-datepicker
							class="datepicker-input mt-2 center-inside"
							placeholder="__/__/____"
							size="is-medium"
							name="bday"
							:mobile-native="false"
							:locale="$i18n.locale"
							:icon-right-clickable="true"
							@icon-right-click="clearBirthday()"
							trap-focus
						></b-datepicker>
					</b-field>
				</div>

				<div>
					<p>{{ t.end }}</p>
					<b-field>
						<b-datepicker
							class="datepicker-input mt-2 center-inside"
							placeholder="__/__/____"
							size="is-medium"
							name="bday"
							:mobile-native="false"
							:locale="$i18n.locale"
							:icon-right-clickable="true"
							@icon-right-click="clearBirthday()"
							trap-focus
						></b-datepicker>
					</b-field>
				</div>
			</div>
			<div class="button-container has-text-centered mt-8">
				<b-button native-type="submit" type="is-primary" class="btn">{{ t.create }}</b-button>
			</div>
		</form>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Store.CreateVouchers`),
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	form {
		padding-bottom: 80px;
	}
	.input {
		height: 48px;
	}

	.button-container {
		width: 100%;
		margin-top: 20px;
		padding-bottom: 20px;
		@include from($mobile-s) {
			margin-top: 30px;
			padding-bottom: 30px;
		}
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	/deep/ .b-checkbox.checkbox input[type='checkbox'] + .check {
		border-radius: 10px;
		width: 30px;
		height: 30px;
	}

	.datepicker-input {
		width: 148px;
		height: 48px;
	}

	/deep/ .datepicker .dropdown .input[readonly],
	.datepicker .dropdown-trigger .input[readonly] {
		text-align: center;
	}

	.datepicker-container {
		display: flex;
		justify-content: space-between;
	}
</style>
