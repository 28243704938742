<template>
	<section class="center-inside">
		<div class="modal" :class="{ 'is-active': reasonsModal }">
			<div class="modal-background"></div>
			<div class="modal-card px-6">
				<header class="modal-card-head">
					<p class="modal-card-title center-inside">{{t.title}}</p>
					<button type="button" class="delete ml-4" @click="$emit('close')" />
				</header>
				<section class="modal-card-body">
					<b-field class="mr-10" style="min-width: 200px">
						<b-select :placeholder="t.placeholder" size="is-medium" expanded v-model="reason">
							<option :value="motive.id" v-for="motive in rejectMotives" :key="motive.id">{{ motive.description }}</option>
						</b-select>
					</b-field>
					<p class="has-text-danger" v-if="selectError">{{t.errorReason}}</p>

					<b-button class="mt-14 btn" @click="saveMotive">{{t.save}}</b-button>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
	import { rejectionMotives } from '@/api/orders';
	export default {
		props: ['reasonsModal'],

		data() {
			return {
				rejectMotives: [],
				reason: null,
				selectError: false,
				t: this.$t(`components.RejectModal`),
			};
		},

		methods: {
			saveMotive() {
				if (!this.reason) {
					this.selectError = true;
				} else {
					this.selectError = false;
					this.$emit('setMotive', this.reason);
				}
			},
		},

		mounted() {
			rejectionMotives()
				.then((response) => {
					console.log(response);
					this.rejectMotives = response.data.data;
				})
				.catch((error) => {
					console.log(error);
					this.$buefy.toast.open({
						message: this.$t('shared.error'),
						position: 'is-top',
						type: 'is-danger',
					});
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.modal-card {
		height: auto;
		width: auto;

		@include until($touch) {
			font-size: 1rem;
			width: 96%;
		}
	}

	.modal-card-head {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;

		
	}
	.modal-card-head p  {

		@include until($mobile-l) {
			max-width: 87%;
		}
	}

	.modal-card-body {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		min-height: 200px;
	}

	.input {
		border-radius: 11px;
		border: solid 1px $grey-light;
	}

	.btn {
		background-color: $primary;
		color: white;
		display: flex;
		margin: 0 auto;
		width: 50%;
		height: 40px;
	}

	.modal-card-title {
		@include until($touch) {
			font-size: 1rem;
		}
	}
</style>
