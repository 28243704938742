<template>
	<section>
		<div class="main-container">
			<div class="header-container px-6">
				<div class="level">
					<div class="level-left">
						<div class="level-item">
							<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" class="is-clickable" type="is-grey-darker" size="is-medium"> </b-icon>
							<p class="header ml-2">{{ t.settings }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="columns px-10 mt-15">
				<div class="column is-half">
					<p class="has-text-dark is-size-5">{{ t.notifications }}</p>

					<div class="border mt-6">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.newPosts }}</p>
							</div>
							<div class="level-right">
								<b-switch :rounded="false" @input="update" v-model="notificationsPosts" :outlined="false" :type="null" :passive-type="null"></b-switch>
							</div>
						</div>
					</div>

					<div class="border mt-6">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.newPlaces }}</p>
							</div>
							<div class="level-right">
								<b-switch :rounded="false" @input="update" v-model="notificationPlaces" :outlined="false" :type="null" :passive-type="null"></b-switch>
							</div>
						</div>
					</div>
				</div>
				<div class="column">
					<p class="has-text-dark is-size-5">Emails</p>

					<div class="border mt-6">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.newPosts }}</p>
							</div>
							<div class="level-right">
								<b-switch :rounded="false" @input="update" :outlined="false" v-model="emailPosts" :type="null" :passive-type="null"></b-switch>
							</div>
						</div>
					</div>

					<div class="border mt-6">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.newPlaces }}</p>
							</div>
							<div class="level-right">
								<b-switch :rounded="false" @input="update" :outlined="false" v-model="emailPlaces" :type="null" :passive-type="null"></b-switch>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="columns px-10 mt-5">
				<div class="column is-half">
					<p class="has-text-dark is-size-5">{{ t.other }}</p>

					<div class="border mt-6 is-clickable" @click="openTerms">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.privacy }}</p>
							</div>
							<div class="level-right">
								<b-icon icon="chevron-right" type="is-grey" custom-size="is-size-2"> </b-icon>
							</div>
						</div>
					</div>

					<div class="mt-6">
						<div class="level p-6">
							<div class="level-left">
								<p class="has-text-grey">{{ t.support }}: <a href="mailto:progressiveroottechnology@gmail.com">progressiveroottechnology@gmail.com</a></p>
							</div>
						</div>
					</div>

					<b-button expanded type="is-danger" size="is-large" class="mt-6 border" :loading="isLoading" @click="deleteAccount">
						<span class="is-size-6">{{ t.deleteAccount }}</span>
					</b-button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getSettings, updateSettings } from '@/api/settings';
	import { deleteAccount } from '@/api/user';
	export default {
		data() {
			return {
				notificationPlaces: null,
				notificationsPosts: null,
				emailPlaces: null,
				emailPosts: null,
				id: null,
				t: this.$t(`views.Settings`),
				isLoading: false,
			};
		},

		methods: {
			update() {
				let update = {
					data: {
						email_places: this.emailPlaces,
						email_posts: this.emailPosts,
						notifications_places: this.notificationPlaces,
						notifications_posts: this.notificationsPosts,
					},
				};

				updateSettings(this.id, update).then((response) => {
					console.log(response);
				});
			},

			deleteAccount() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteAccount,
					message: this.t.deletingAccount,
					confirmText: this.t.deleteAccount,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;
						deleteAccount()
							.then((response) => {
								this.isLoading = true;
								this.$socket.disconnect();
								this.onSessionExpired();
								this.$buefy.toast.open({
									message: this.t.deleteSuccess,
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch((error) => {
								if (error.response.data.error.details.code == 'completeOrders') {
									this.$buefy.toast.open({
										message: this.t.errorDelete,
										position: 'is-top',
										type: 'is-danger',
									});
								}
							})
							.finally(() => {
								this.isLoading = false;
							});
					},
				});
			},

			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.open(routeData.href, '_blank');
			},
		},
		created() {
			this.setIsPageLoading(true);
			getSettings(this.getUser.id)
				.then((response) => {
					console.log(response);
					this.notificationPlaces = response.data.data[0].notifications_places;
					this.notificationsPosts = response.data.data[0].notifications_posts;
					this.emailPlaces = response.data.data[0].email_places;
					this.emailPosts = response.data.data[0].email_posts;
					this.id = response.data.data[0].id;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	/* .main-container {
		padding-left: 140px;
		padding-right: 140px;
	} */

	.header {
		font-size: 1.5rem;
	}

	.border {
		border: 1px solid #e6e6e6;
		border-radius: 20px;
		height: 60px;
	}

	/deep/ .switch {
		float: right;
	}
</style>
