<template>
	<section>
		<div class="is-fixed-top pt-8 px-3"><b-icon icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
		<div class="px-6 mt-6">
			<span>{{ t.signup }}</span
			><br />
		</div>

		<div class="px-6 mt-22">
			<form @submit.prevent="create">
				<b-field
					><b-input class="input mt-10" size="is-medium" type="text" required :placeholder="t.name" name="organization" v-model.trim="form.data.name"></b-input
				></b-field>
				<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.cae" v-model.trim="form.data.cae"></b-input></b-field>
				<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.nipc" v-model.trim="form.data.vat"></b-input></b-field>
				<b-field
					><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.fullAddress" v-model.trim="form.data.location_address"></b-input
				></b-field>
				<div class="small-input">
					<b-field
						><b-input
							class="input small mt-2 mr-2"
							size="is-medium"
							type="text"
							required
							:placeholder="t.zip"
							pattern="\d{4}-\d{3}"
							name="zip_code"
							v-model.trim="form.data.location_zipCode"
						></b-input
					></b-field>
					<b-field
						><b-input class="input small mt-2 ml-2" size="is-medium" type="text" required :placeholder="t.city" v-model.trim="form.data.location_municipality"></b-input
					></b-field>
				</div>

				<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.country" v-model.trim="form.data.location_country"></b-input></b-field>
				<b-field><b-input class="input mt-2" size="is-medium" name="email" type="email" required :placeholder="t.email" v-model.trim="form.data.email"></b-input></b-field>
				<b-field><b-input class="input mt-2" size="is-medium" type="text" required :placeholder="t.iban" v-model.trim="form.data.iban"></b-input></b-field>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" native-type="submit" :loading="isLoading" class="btn">{{ t.signup }}</b-button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	import { createStore } from '@/api/store';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.StoreInfo`),
				form: {
					data: {
						name: null,

						email: null,
						location_address: null,
						location_country: null,

						location_municipality: null,
						location_zipCode: null,
						cae: null,
						vat: null,
						iban: null,
						user: null,
					},
				},

				isLoading: false,
			};
		},

		methods: {
			create() {
				console.log(this.form);
				this.isLoading = true;
				createStore(this.form)
					.then((response) => {
						console.log(response);
						this.isLoading = false;
						this.goToPage('ConfirmCode');
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	span {
		font-size: 1.7rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		@include from($mobile-s) {
			margin-top: 60px;
		}
	}

	.terms {
		font-size: 1rem;
		transform: translate(-14px, 23px);
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.small-input {
		display: flex;
	}
	.small {
		width: 96%;
	}
</style>
