<template>
	<section :key="renderKey">
		<div class="main-container">
			<p class="dashboard has-text-grey-dark">Dashboard</p>

			<div class="columns mt-6">
				<div class="column is-one-quarter card">
					<div class="dashboard-icon has-background-violet center-inside"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.viewers }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem">{{ storeViews }}</p>
					</div>
				</div>
				<div class="column column is-one-quarter card">
					<div class="dashboard-icon has-background-red center-inside"><b-icon pack="rt" icon="box1" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.products }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem">{{ productsSold }}</p>
					</div>
				</div>
				<div class="column is-one-quarter card">
					<div class="dashboard-icon has-background-purple center-inside"><b-icon icon="heart" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.liked }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-if="liked">{{ liked }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-else>{{ t.noProducts }}</p>
					</div>
				</div>
			</div>
			<div class="columns mt-6">
				<div class="column is-one-quarter card">
					<div class="dashboard-icon has-background-yellow center-inside"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.viewed }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-if="views">{{ views }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-else>{{ t.noProducts }}</p>
					</div>
				</div>
				<div class="column column is-one-quarter card">
					<div class="dashboard-icon has-background-cyan center-inside"><b-icon pack="rt" icon="box1" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.orders }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem">{{ orders.length }}</p>
					</div>
				</div>
				<div class="column is-one-quarter card">
					<div class="dashboard-icon has-background-primary center-inside"><b-icon pack="rt" icon="star" size="is-size-4" type="is-white"></b-icon></div>
					<div>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.715rem">{{ t.reviewed }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-if="reviews">{{ reviews }}</p>
						<p class="ml-4 has-text-grey-dark" style="font-size: 0.858rem" v-else>{{ t.noProducts }}</p>
					</div>
				</div>
			</div>

			<div class="mt-12" style="border: solid 1px #e6e6e6; padding: 4rem; border-radius: 20px">
				<div class="level">
					<div class="level-left">
						<p class="level-item">{{ totalAmount.toFixed(2) }} €</p>
					</div>

					<b-field class="level-right">
						<b-select class="mt-2 level-item" @input="changeGraph" v-model="graphFilter">
							<option value="month">{{ t.month }}</option>
							<option value="year">{{ t.year }}</option>
						</b-select>
					</b-field>
				</div>

				<highcharts class="mt-10" :options="chartOptions"></highcharts>
			</div>
		</div>

		<RegisterStoreModal @close="close" :isModalOpen="openModal" />
	</section>
</template>

<script>
	import RegisterStoreModal from '@/components/Desktop/Store/RegisterStoreModal.vue';

	import { stripeAccountLink } from '@/api/store';
	import { getLoggedUser } from '@/api/user';
	import { getUserLineGraph } from '@/api/orders';
	import { getProducts, getStoreInfo } from '@/api/dashboard';
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';

	exportingInit(Highcharts);
	export default {
		components: {
			RegisterStoreModal,
		},

		data() {
			return {
				t: this.$t(`components.Desktop.Store.Dashboard`),
				showMenu: false,
				openModal: false,
				renderKey: 0,
				graphFilter: 'month',
				totalAmount: 0,
				reviews: null,
				views: null,
				sold: 0,
				orders: 0,
				liked: null,
				storeViews: 0,
				productsSold: 0,

				chartOptions: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'areaspline',
					},
					title: {
						text: '',
					},

					xAxis: {
						categories: [],
						gridLineWidth: 1,
					},
					yAxis: {
						title: {
							text: '',
						},
						gridLineWidth: 0,
					},
					legend: {
						layout: 'vertical',
						align: 'left',
						verticalAlign: 'bottom',
						x: 150,
						y: 100,
						floating: true,
						borderWidth: 1,
						backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
					},
					tooltip: {
						headerFormat: '<table><span style="font-size:12px;text-align:center">{point.key}</span></table>',
						pointFormat: '<p style="font-size:15px"><b>{point.y:1f} €</b></p>',

						shared: true,
						useHTML: true,
						backgroundColor: '#99cca8',
						style: {
							color: 'white',
							textAlign: 'center',
						},
					},
					credits: {
						enabled: false,
					},
					plotOptions: {
						areaspline: {
							fillOpacity: 0.5,
						},
					},
					series: [
						{
							name: '',
							data: [],
							color: '#99cca8',
						},
					],
				},
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			close() {
				this.openModal = false;
				this.renderKey++;
			},

			changeGraph() {
				this.setIsPageLoading(true);
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						console.log(response);

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			if (this.getUser.store.vat == null) {
				this.openModal = true;
				this.setIsPageLoading(false);
			}

			if (!this.openModal) {
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						console.log(response);

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
						this.chartOptions.series[0].data.map((order) => {
							this.totalAmount += order;
						});
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});

				getProducts({
					sort: ['num_reviews:desc'],
					'pagination[pageSize]': 1,
					'filters[store][id]': this.getUser.store.id,
				}).then((response) => {
					console.log(response);
					if (response.data.data.length > 0 && response.data.data[0].reviews.length > 0) {
						this.reviews = response.data.data[0].title;
					}
				});

				getProducts({
					sort: ['views:desc'],
					'pagination[pageSize]': 1,
					'filters[store][id]': this.getUser.store.id,
				}).then((response) => {
					console.log(response);
			
					if (response.data.data.length > 0 &&  response.data.data[0].views > 0) {
						this.views = response.data.data[0].title;
					}
				});
				getProducts({
					'filters[store][id]': this.getUser.store.id,
					sort: ['num_likes:desc'],
					'pagination[pageSize]': 1,
				}).then((response) => {
					console.log(response);

					if (response.data.data.length > 0 &&  response.data.data[0].likes.length > 0) {
						this.liked = response.data.data[0].title;
					}
				});

				getLoggedUser()
					.then((response) => {
						console.log(response);

						this.addUser(response.data);
						if (!this.getUser.store.isActive && this.getUserType == 'store' && this.getUser.store.vat != null) {
							stripeAccountLink()
								.then((response) => {
									console.log(response);
									window.location.href = response.data.url;
								})
								.catch((error) => {
									console.log(error);
								});
						}
					})
					.catch((error) => {
						console.log(error.response);
					});

				getStoreInfo(this.getUser.store.id)
					.then((response) => {
						console.log(response);
						this.storeViews = response.data.data.views;
						this.orders = response.data.data.orders;
						this.productsSold = response.data.data.products_sold;
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.main-container {
		padding-left: 140px;
		padding-right: 140px;
	}

	.dashboard {
		font-size: 2.5rem;
	}

	.dashboard-icon {
		width: 50px;
		height: 50px;
		border-radius: 5px;
	}

	.card {
		display: flex;
		border-radius: 20px;
		border: 1px solid $grey-light;
		box-shadow: 0px 2px 48px #0000000b;
	}

	.columns {
		justify-content: space-between;
	}

	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 0rem 1.5rem 6rem;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 31;
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $primary !important;
	}
</style>
