<template>
	<section class="is-relative is-fullheight">
		<div class="container">
			<div class="is-flex">
				<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-dark" size="is-medium"></b-icon>
				<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable is-size-4">{{ t.change }}</p>
			</div>
			<form class="mt-20" @submit.prevent="change">
				<b-field
					><b-input
						class="input has-background-white"
						size="is-medium"
						required
						name="current-password"
						:placeholder="t.old"
						password-reveal
						type="password"
						v-model="credentials.oldPassword"
					></b-input
				></b-field>
				<b-field
					><b-input
						class="input mt-6 has-background-white"
						size="is-medium"
						required
						name="new-password"
						:placeholder="t.new"
						password-reveal
						type="password"
						v-model="credentials.password"
					></b-input
				></b-field>

				<b-field>
					<b-input
						class="input mt-6 has-background-white"
						size="is-medium"
						required
						:placeholder="t.confirm"
						name="new-password"
						password-reveal
						type="password"
						v-model="confirmPassword"
					></b-input>
				</b-field>
				<p v-show="passwordError" class="has-text-red has-text-centered">{{ t.match }}</p>
				<p v-show="oldPasswordError" class="has-text-red has-text-centered">{{ t.invalidOld }}</p>

				<b-button native-type="submit" expanded size="is-large" :loading="isLoading" type="is-primary" class="btn mt-20">
					<span class="is-size-6">{{ t.update }}</span>
				</b-button>
			</form>
		</div>
	</section>
</template>

<script>
	import { updatePreferences } from '@/api/user';
	export default {
		data() {
			return {
				t: this.$t(`components.Shared.ChangePassword`),
				credentials: {
					password: null,
					oldPassword: null,
				},

				confirmPassword: null,
				passwordError: false,
				oldPasswordError: false,
				isLoading: false,
			};
		},

		methods: {
			change() {
				if (this.credentials.password !== this.confirmPassword || this.credentials.password.length < 8) {
					this.passwordError = true;
				} else {
					this.isLoading = true;
					this.passwordError = false;
					this.oldPasswordError = false;
					updatePreferences(this.credentials)
						.then((response) => {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: this.t.changed,
								position: 'is-top',
								type: 'is-primary',
							});

							this.goToPage('Homepage');
						})
						.catch((error) => {
							this.isLoading = false;
							if (error.response.data.error.message == 'oldPassword doesnt match') {
								this.oldPasswordError = true;
								this.passwordError = false;
							}
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/ input.input.is-medium {
		height: 40px !important;
	}
</style>
