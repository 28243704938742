<template>
	<section class="section is-relative is-fullheight is-flex is-align-items-center">
		<div class="container">
			<figure class="image is-128x128 mx-auto">
				<img :src="require('@/assets/svg/sent.svg')" alt="" />
			</figure>
			<p class="has-text-centered title mt-14">{{ t.check }}</p>
			<p class="has-text-centered has-text-grey px-6">{{ t.message }}</p>

			<b-button type="is-primary" class="mt-4" expanded size="is-large" @click="goToPage('ResetpasswordCode')">
				<span class="is-size-5">{{ t.next }}</span>
			</b-button>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.CheckEmail`),
				email: null,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 1.7rem;
		font-weight: 500;
	}
</style>
