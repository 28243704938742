<template>
	<section>
		<SideBar class="left" :storeCategory="getCategory" @getFilters="getFilters" :key="key" />
		<div class="main-container">
			<div class="header-container px-6">
				<div style="display: inline-flex">
					<b-icon pack="rt" class="is-clickable" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" size="is-medium"> </b-icon>
					<p class="header ml-2">{{ t.profile }}</p>
				</div>
			</div>
			<div class="media px-6 center-inside mb-6">
				<div class="media-left">
					<div class="photo-container mt-4">
						<div class="photo center-inside">
							<img v-if="storeInfo.pictures" class="profile-photo" :src="storeInfo.pictures[0].url" />
							<b-icon v-else icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
						</div>
					</div>
				</div>
				<div class="media-content">
					<p class="name">{{ storeInfo.name }}</p>
					<p class="address has-text-grey">{{ storeInfo.location_municipality }}, {{ storeInfo.location_country }}</p>
					<p v-if="storeInfo.description != null" class="description has-text-grey">{{ storeInfo.description }}</p>
				</div>
				<div class="media-right"></div>
			</div>

			<b-tabs size="is-large" class="mt-8">
				<b-tab-item :label="t.products">
					<Products
						:storeId="this.$route.params.id"
						@selectedFilter="selectedFilter"
						:key="key"
						:subCategoriesSelected="subCategoriesSelected"
						:sizesSelected="sizesSelected"
						:colorSelected="colorsSelected"
						:priceSelected="priceSelected"
						:brandSelected="brandsSelected"
						:onlySale="onlySale"
						:categoryOption="getCategory"
					/>
				</b-tab-item>
				<b-tab-item :label="t.contact">
					<Contacts :storeInfo="storeInfo" />
				</b-tab-item>
			</b-tabs>
		</div>
	</section>
</template>

<script>
	import Products from '@/components/Desktop/Store/ProductsTab.vue';
	import Contacts from '@/components/Desktop/Store/ContactsTab.vue';
	import SideBar from '@/components/Desktop/Parts/FeedSideBar.vue';

	import { getStoreDetails, getCategories, updateStoreViews } from '@/api/store';
	export default {
		components: {
			Products,
			Contacts,
			SideBar,
		},

		data() {
			return {
				t: this.$t(`views.StoreProfile`),
				showMenu: false,
				storeInfo: [],
				categoryOption: [],
				categories: [],
				key: 0,
				subCategoriesSelected: [],
				sizesSelected: [],
				colorsSelected: [],
				brandsSelected: [],
				priceSelected: [],
				onlySale: null,
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			selectedFilter(value) {
				this.categoryOption = value;
				this.key++;
			},

			getFilters(categories, sizes, colors, brands, price, onlySale) {
				this.subCategoriesSelected = categories;
				this.sizesSelected = sizes;
				this.colorsSelected = colors;
				this.brandsSelected = brands;
				this.priceSelected = price;
				if (onlySale == true) {
					this.onlySale = onlySale;
				} else this.onlySale = null;
				this.key++;
			},
		},

		computed: {
			getCategory() {
				return this.categoryOption;
			},

			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		created() {
			this.categoryOption = this.getMainCategorySelected;
			if (this.getUserType != 'store') {
				updateStoreViews(this.$route.params.id).then((response) => {
					console.log(response);
				});
			}
			getStoreDetails(this.$route.params.id)
				.then((response) => {
					console.log(response);
					this.storeInfo = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.top-hr {
		position: fixed;
		top: 76px;
		width: 100%;
		z-index: 40;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 41;
	}

	.main-container {
		padding-left: 300px;
		padding-right: 60px;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $white;
		border: solid 1px black;
	}

	.profile-photo {
		max-height: 78px;
		border-radius: 12px;
		height: 78px;
	}

	.name {
		font-size: 1.35rem;
	}

	.address {
		font-size: 1.143rem;
	}

	.description {
		font-size: 1rem;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.pointer {
		cursor: pointer;
	}

	.header {
		font-size: 1.5rem;
	}

	.left {
		position: absolute;
		left: 0;
	}
</style>
