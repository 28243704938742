<template>
	<section class="center-inside">
		<div class="modal" :class="{ 'is-active': isModalOpen }">
			<div class="modal-background"></div>
			<div class="modal-card px-6">
				<form @submit.prevent="save">
					<section class="modal-card-body">
						<div style="display: inline-flex">
							<p>Size</p>
							<button type="button" class="delete" @click="$emit('closeModal')" />
						</div>

						<b-field class="mt-6" v-for="(size, index) in sizes" :key="size.id">
							<b-field class="size-field">
								<b-checkbox size="is-large" class="checkbox" :true-value="size.id" :false-value="null" v-model="selectedSizes[index]">
									<p :class="{ selected: selectedSizes[index] }">{{ size.attributes.name }}</p>
								</b-checkbox>
							</b-field>
							<b-field>
								<b-input class="input" v-model="units[index]" :required="selectedSizes[index] ? true : false" placeholder="Insert units"></b-input>
							</b-field>
						</b-field>

						<div class="button-container has-text-centered mt-8">
							<b-button native-type="submit" type="is-primary" class="btn">Save</b-button>
						</div>
					</section>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
	import { getSizesGroups, getStockStatus, addProductSize } from '@/api/store';
	export default {
		props: ['isModalOpen', 'mySizes'],
		data() {
			return {
				selectedSizes: [],
				sizes: [],
				units: [],
				status: [],
			};
		},

		methods: {
			check: function (e) {
				console.log();
			},

			save(event) {
				let productStock = {};
				for (let index = 0; index < this.selectedSizes.length; index++) {
					if (this.selectedSizes[index]) {
						productStock[index] = {
							data: {
								size: this.selectedSizes[index].toString(),
								stockNumber: +this.units[index],
								stockStatus: this.status[0].id.toString(),
							},
						};
					}
				}
				console.log(productStock);
				addProductSize(productStock)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});

				/* this.$emit('size', productStock); */
			},
		},

		created() {
			getSizesGroups()
				.then((response) => {
					this.sizes = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getStockStatus()
				.then((response) => {
					console.log(response);
					this.status = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			if (this.mySizes.length > 0) {
				for (let index = 0; index < this.mySizes.length; index++) {
					console.log(this.mySizes[index]);
					this.selectedSizes[index] = this.mySizes[index].size;
					this.units[index] = this.mySizes[index].stockNumber;
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.modal-card {
		height: auto;
		width: 260px;
	}

	/deep/ .b-checkbox.checkbox input[type='checkbox'] + .check {
		border-radius: 12px;
		width: 40px;
		height: 40px;
	}

	/deep/.b-checkbox.checkbox input[type='checkbox']:checked + .check {
		background: $primary no-repeat center center;
		border-color: $primary;
	}

	/deep/.control-label {
		transform: translate(-45px);
		color: $grey;

		@include until($mobile-s) {
			transform: translate(-42px);
		}
	}

	.selected {
		color: $white;
	}

	.size-field {
		width: 50px !important;
	}

	.input {
		font-size: 1rem;
		height: 48px;
		color: $black;
	}

	.btn {
		width: 143px;
		height: 32px;
		border-radius: 8px;
		font-size: 1rem;
	}

	.delete {
		position: absolute;
		right: 40px;
	}
</style>
