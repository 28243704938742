<template>
	<section class="section has-background-white">
		<div v-if="cart" class="container mt-10">
			<div class="level is-mobile">
				<div class="level-left">
					<div class="level-item">
						<div>
							<h1 class="is-size-4 has-text-black is-hidden-desktop">{{ $t('views.Cart.title') }}</h1>
							<h1 class="is-size-3 has-text-black is-hidden-mobile">{{ $t('views.Cart.title') }}</h1>
							<h2 class="is-size-6 has-text-grey-darker is-hidden-desktop">{{ $tc('views.Cart.items', getTotal, [getTotal]) }}</h2>
							<h2 class="is-size-5 has-text-grey-darker is-hidden-mobile mt-4 mb-6">{{ $tc('views.Cart.items', getTotal, [getTotal]) }}</h2>
						</div>
					</div>
				</div>
				<div class="level-right is-hidden-desktop">
					<div class="level-item">
						<div>
							<b-button :disabled="!cart.cartProducts.length" @click="removeAll" :type="!cart.cartProducts.length ? 'is-grey-light' : 'is-red-light'">
								<b-icon pack="rt" :type="!cart.cartProducts.length ? 'is-grey' : 'is-red'" icon="remove" size="is-large"></b-icon>
							</b-button>
						</div>
					</div>
				</div>
			</div>
			<div class="columns is-mobile">
				<div class="column is-12-touch is-8-desktop">
					<div class="is-hidden-touch has-radius py-8 has-background-white level is-mobile pr-20 pl-8">
						<div class="level-right">
							<div class="level-item">
								<b-checkbox @input="onSelectAll" size="is-medium" class="is-align-self-center">
									<span class="is-size-6"> {{ $t('views.Cart.selectAll') }} </span>
								</b-checkbox>
							</div>
						</div>
						<div class="level-left">
							<div class="level-item py-1 pl-7">
								<a :disabled="!isAnySelected" @click="onRemoveSelected" :loading="isLoading" class="has-text-red is-uppercase">{{ $t('views.Cart.remove') }}</a>
							</div>
						</div>
					</div>
					<CartLine
						v-for="(cartProduct, i) in cart.cartProducts"
						:index="i"
						:key="cartProduct.id"
						:cartProduct="cartProduct"
						@quantity="onChangeQuantity"
						@remove="onRemove"
					/>
					<div v-if="cart.cartProducts.length > 0" class="is-hidden-desktop">
						<p v-if="getTotalAmount < cart.cartProducts[0].product.store.shippingMinValue" class="is-size-6 has-text-danger mb-2">
							{{ t.freeShipping }} {{ cart.cartProducts[0].product.store.shippingMinValue }}€
						</p>
					</div>
					<div class="is-hidden-desktop level is-mobile">
						<div class="level-left">
							<h2 class="has-text-grey-darker has-letter-spacing-1px">{{ $t('views.Cart.total') }}</h2>
						</div>
						<div class="level-right">
							<h2 class="has-text-grey-dark has-letter-spacing-1px">{{ getTotalAmount.toFixed(2) }}€</h2>
						</div>
					</div>
					<b-button
						expanded
						size="is-large"
						type="is-primary"
						class="is-hidden-desktop is-size-6"
						@click="goToPage('Shipping')"
						:disabled="getCart.cartProducts.length > 0 ? false : true"
						>{{ $t('views.Cart.checkout') }}</b-button
					>
				</div>
				<div class="column is-hidden-touch is-4-desktop pl-12">
					<div class="has-radius p-10 has-background-white">
						<h2 class="is-size-5 has-text-dark">{{ $t('views.Cart.summary') }}</h2>
						<div class="level py-12 mb-0">
							<div class="level-left">
								<div class="level-item">
									<h2 class="is-size-5 has-text-dark">{{ $t('views.Cart.total') }}</h2>
								</div>
							</div>
							<div class="level-right">
								<div class="level-item">
									<h2 class="is-size-3 has-text-primary">{{ getTotalAmount.toFixed(2) }}€</h2>
								</div>
							</div>
						</div>
						<div v-if="cart.cartProducts.length > 0">
							<p v-if="getTotalAmount < cart.cartProducts[0].product.store.shippingMinValue" class="is-size-5 has-text-danger mb-2">
								{{ t.freeShipping }} {{ cart.cartProducts[0].product.store.shippingMinValue }}€
							</p>
						</div>

						<b-button
							expanded
							type="is-primary"
							size="is-large"
							@click="goToPage('Shipping')"
							:loading="isLoading"
							:disabled="getCart.cartProducts.length > 0 ? false : true"
						>
							<span class="is-size-5 is-uppercase">{{ $t('views.Cart.checkout') }}</span>
						</b-button>
						<div class="has-text-centered pt-6">
							<a @click="goToPage('Homepage')" class="has-text-primary is-size-6">{{ $t('views.Cart.backShopping') }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import CartLine from '@/components/Cart/CartLine.vue';
	import cart from '@/mixins/modules/cart';

	export default {
		name: 'Cart',
		mixins: [cart],
		components: {
			CartLine,
		},
		data() {
			return {
				cart: null,
				isLoading: null,
				t: this.$t(`views.Cart`),
			};
		},
		created() {
			this.getCartFromAPI(this.getUser.id).then(() => {
				this.cart = this.getCart;
				console.log(this.cart);
			});
		},
		watch: {
			getCart: {
				handler(newCart) {
					this.cart = newCart;
				},
				deep: true,
			},
		},
		computed: {
			getTotalAmount() {
				let total = 0;
				this.cart.cartProducts.forEach((cartProduct) => {
					console.log(cartProduct);
					if (cartProduct.product.productSales[0].isActive == true) {
						total += parseInt(cartProduct.quantity) * this.calculateSale(cartProduct.product.price, cartProduct.product.productSales[0].discount);
					} else total += parseInt(cartProduct.quantity) * cartProduct.product.price;
				});
				return total;
			},
			isAnySelected() {
				return this.cart.cartProducts.some((cartProduct) => cartProduct.selected);
			},
		},
		methods: {
			async onChangeQuantity(newQuantity, index) {
				if (newQuantity <= 0) return;
				this.cart.cartProducts[index].quantity = newQuantity;
				await this.updateCartProduct(this.cart.cartProducts[index].id, this.cart.cartProducts[index].quantity)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			onRemove(index) {
				this.removeCartProduct(this.cart.cartProducts[index].id)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			removeAll() {
				this.removeCartProduct(this.cart.cartProducts.map((cp) => cp.id))
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			onSelectAll(val) {
				for (let i in this.cart.cartProducts) {
					this.$set(this.cart.cartProducts[i], 'selected', val);
				}
			},
			onRemoveSelected() {
				if (!this.isAnySelected) return;
				this.removeCartProduct(this.cart.cartProducts.filter((cartProduct) => cartProduct.selected).map((cp) => cp.id))
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';

	.is-hidden-touch.level {
		border: 0.5px solid $grey-light;
		.level-left {
			.level-item {
				border-left: 2px solid $grey-light;
			}
		}
	}

	.columns {
		.column:nth-of-type(2) {
			> div {
				border: 0.5px solid $grey-light;
			}
		}
	}
</style>
