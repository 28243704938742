<template>
	<div>
		<Auth v-if="IS_MOBILE" />
		<AuthDesktop v-else />
	</div>
</template>

<script>
	import Auth from '@/components/Auth/Auth.vue';
	import AuthDesktop from '@/components/Desktop/Auth/Auth.vue';

	export default {
		name: 'Home',
		components: {
			Auth,
			AuthDesktop,
		},
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped></style>
