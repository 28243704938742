<template>
	<section v-if="orders.length > 0" @scroll="handleScroll">
		<div class="is-hidden-mobile">
			<p class="py-4">{{ orders.length }} {{ t.orders }}</p>
			<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
				<img v-if="order.user.picture" :src="order.store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ order.store.name }}</p>
					<p class="has-text-dark is-size-5">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
				</div>
				<div style="display: inline-flex">
					<b-button v-if="order.shipping_data.expedition" class="has-background-white has-text-dark btn" @click="tracking(order.id, order)">{{ t.details }}</b-button>
					<p class="price ml-10 mr-4" style="text-align: right">{{ (order.amount / 100).toFixed(2) }} €</p>
				</div>
			</div>
		</div>

		<div class="is-hidden-desktop">
			<p class="py-4">{{ orders.length }} {{ t.orders }}</p>
			<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id" @click="tracking(order.id, order)">
				<img v-if="order.user.picture" :src="order.store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ order.store.name }}</p>
					<p class="has-text-dark is-size-6">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
				</div>
				<div style="display: inline-flex">
					<p class="price mr-1" style="min-width: 80px; text-align: right">{{ (order.amount / 100).toFixed(2) }} €</p>
				</div>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noOrders }}</p>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	import moment from 'moment';
	export default {
		props: ['sort'],
		data() {
			return {
				t: this.$t(`components.OrdersTab`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 100,
			};
		},

		methods: {
			tracking(storeId, order) {
				console.log(order);
				if (order.shipping_data.expedition) {
					this.$router.push({ name: 'Tracking', params: { id: storeId, tracking: order.shipping_data.expedition.NrGuia } });
				}
			},

			getOrderTotal(products) {
				let total = 0;
				products.data.map((product) => {
					total = total + product.product.price;
				});

				return total;
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						sort: this.sort,
						'filters[user][id]': this.getUser.id,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						'filters[$or]': [
							{
								orderStatus: 2,
							},
							{
								orderStatus: 3,
							},
							{
								orderStatus: 5,
							},
							{
								orderStatus: 6,
							},
							{
								orderStatus: 7,
							},
							{
								orderStatus: 8,
							},
						],
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getOrders({
				sort: this.sort,
				'filters[user][id]': this.getUser.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				'filters[$or]': [
					{
						orderStatus: 2,
					},
					{
						orderStatus: 3,
					},

					{
						orderStatus: 5,
					},
					{
						orderStatus: 6,
					},
					{
						orderStatus: 7,
					},
				],
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 100px;
			height: 78px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 86px;
		}
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;

		@include until($mobile) {
			margin-left: 0;
			min-width: 50px;
			font-size: 1rem;
			letter-spacing: 1px;
		}
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;
	}
</style>
