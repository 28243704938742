<template>
	<section>
		<div class="main-container">
			<div class="header-container pt-8 px-6">
				<div style="display: inline-flex">
					<b-icon pack="rt" icon="arrow-left" @click.native="goToPage('DesktopStoreProducts')" type="is-grey-darker" class="is-clickable" size="is-medium"> </b-icon>
					<p class="has-text-black ml-2 is-clickable is-size-4" @click="goToPage('DesktopStoreProducts')">{{ t.settings }}</p>
				</div>
			</div>

			<form @submit.prevent="update" class="mt-10">
				<b-field :type="decimalError ? 'is-danger' : ''" :message="decimalError ? t.decimalError : ''" :label="t.shipping" horizontal>
					<b-input
						class="input"
						size="is-medium"
						type="number"
						min="15"
						step="0.01"
						v-model.trim="store.data.shippingMinValue"
						@input="verifyDecimalPlaces"
						required
					></b-input>
				</b-field>

				<b-field :type="decimalErroMaximum ? 'is-danger' : ''" :message="decimalErroMaximum ? t.decimalError : ''" :label="t.maximum" horizontal>
					<b-input
						class="input mt-2"
						size="is-medium"
						min="1"
						max="100"
						step="1"
						type="number"
						v-model.trim="store.data.userMaxValue"
						@input="verifyDecimalPlacesMaximum"
						required
					></b-input>
				</b-field>

				<!-- <b-field class="ml-8 mt-10" v-if="getUserType == 'authenticated'">
					<p class="is-size-6 has-text-danger is-clickable" @click="deleteAccount">{{ t.deleteAccount }}</p>
				</b-field> -->

				<div class="columns px-10 mt-5">
					<div class="column is-half">
						<p class="has-text-dark is-size-5">{{ t.other }}</p>

						<div class="border mt-6 is-clickable" @click="openTerms">
							<div class="level p-6">
								<div class="level-left">
									<p class="has-text-grey">{{ t.privacy }}</p>
								</div>
								<div class="level-right">
									<b-icon icon="chevron-right" type="is-grey" custom-size="is-size-2"> </b-icon>
								</div>
							</div>
						</div>

						<div class="mt-6">
							<div class="level p-6">
								<div class="level-left">
									<p class="has-text-grey">{{ t.support }}: <a href="mailto:progressiveroottechnology@gmail.com">progressiveroottechnology@gmail.com</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="button-container has-text-centered mt-10">
					<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">{{ t.save }}</b-button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	import { updateStore } from '@/api/store';
	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Store.Settings`),
				showMenu: false,
				isLoading: false,
				store: {
					data: {
						userMaxValue: 0,
						shippingMinValue: null,
					},
				},
				decimalError: false,
				decimalErroMaximum: false,
			};
		},

		methods: {
			open() {
				this.showMenu = !this.showMenu;
			},

			update() {
				this.isLoading = true;
				if (this.decimalError || this.decimalErroMaximum) {
					this.isLoading = false;
				} else {
					updateStore(this.getUser.store.id, this.store)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.updated,
								position: 'is-top',
								type: 'is-primary',
							});
							this.isLoading = false;
							this.goToPage('Homepage');
						})
						.catch((error) => {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: this.$t('shared.error'),
								position: 'is-top',
								type: 'is-primary',
							});
							console.log(error);
						});
				}
			},

			deleteAccount() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteAccount,
					message: this.t.deleteAccountText,
					confirmText: this.t.deleteAccount,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {},
				});
			},

			verifyDecimalPlaces(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalError = true;
				} else this.decimalError = false;
			},

			verifyDecimalPlacesMaximum(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalErroMaximum = true;
				} else this.decimalErroMaximum = false;
			},

			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.open(routeData.href, '_blank');
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			this.store.data.userMaxValue = this.getUser.store.userMaxValue;
			this.store.data.shippingMinValue = this.getUser.store.shippingMinValue;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.main-container {
		/* 	padding-left: 140px;
		padding-right: 140px; */
		padding-bottom: 150px;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 31;
	}

	.input {
		background-color: $white;
		border-radius: 15px;
		font-size: 1rem;
		height: 48px;
		width: 400px;
		border: 1px solid #e6e6e6;
	}

	.header {
		font-size: 2.5rem;
	}

	.btn {
		width: 50%;
		height: 52px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	.button-container {
		width: 100%;
		padding-bottom: 10px;
	}

	.border {
		border: 1px solid #e6e6e6;
		border-radius: 20px;
		height: 60px;
	}
</style>
