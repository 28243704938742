<template>
	<section v-if="vouchers.length > 0 && count > 0">
		<div class="media is-mobile px-6" v-if="IS_MOBILE">
			<div class="media-left"></div>
			<div class="media-content"></div>
			<div class="media-right">
				<div class="icon-box center-inside has-background-primary ml-3" @click="isModalOpen = true">
					<b-icon pack="rt" icon="filter" size="is-size-4" type="is-white"></b-icon>
				</div>
			</div>
		</div>
		<div class="columns is-multiline mt-4 is-hidden-touch">
			<div v-show="isActive(voucher.startAt, voucher.endAt)" class="column is-one-quarter-fullhd is-one-third-desktop" v-for="voucher in vouchers" :key="voucher.id">
				<div class="voucher-card">
					<figure class="image is-4by3">
						<span class="tag">{{ voucher.discount }}% {{ t.off }}</span>
						<img class="has-object-fit-contain has-radius" src="../../assets/discount_card.png" alt="" />
					</figure>

					<p class="is-size-4 mt-4 ml-2">{{ voucher.store.name }}</p>

					<div class="level">
						<div class="level-left" style="width: 100%">
							<p class="mt-4 ml-2 has-text-grey level-item is-size-5 voucher-title">{{ voucher.title }}</p>
						</div>
					</div>

					<p class="mt-2 mx-2 has-text-grey is-size-5">{{ voucher.description }}</p>
					<hr class="mb-0 mt-4" />
					<div class="level">
						<div class="level-left">
							<p class="mt-4 ml-2 mr-0 has-text-dark level-item">{{ voucher.startAt | moment() }} -</p>
							<p class="mt-4 ml-1 has-text-dark level-item">{{ voucher.endAt | moment() }}</p>
						</div>
						<div class="level-right">
							<p class="mt-4 mr-2 has-text-dark level-item is-clickable" @click="seeStore(voucher.store.id)">{{ t.go }}</p>
							<b-icon class="is-clickable mt-4" icon="chevron-right" type="is-dark" custom-size="is-size-3" @click.native="seeStore(voucher.store.id)"></b-icon>
						</div>
					</div>
					<div class="is-flex ml-2">
						<p class="mr-2 has-text-grey is-size-5">{{ voucher.code }}</p>

						<b-icon
							icon="content-copy"
							class="mt-1 is-clickable"
							type="is-primary"
							v-clipboard:copy="voucher.code"
							v-clipboard:success="onCopy"
							custom-size="is-size-4"
						>
						</b-icon>
					</div>
				</div>
			</div>
		</div>
		<div class="columns is-mobile is-multiline mt-4 is-hidden-desktop">
			<div v-show="isActive(voucher.startAt, voucher.endAt)" class="column is-full-mobile is-half-tablet" v-for="voucher in vouchers" :key="voucher.id">
				<div class="voucher-card">
					<figure class="image is-4by3">
						<span class="tag">{{ voucher.discount }}% {{ t.off }}</span>
						<img class="has-object-fit-contain has-radius" src="../../assets/discount_card.png" alt="" />
					</figure>

					<p class="is-size-4 mt-4 ml-2">{{ voucher.store.name }}</p>

					<div class="level is-mobile">
						<div class="level-left" style="width: 100%">
							<p class="mt-4 ml-2 has-text-grey level-item is-size-5 voucher-title">{{ voucher.title }}</p>
						</div>
					</div>

					<p class="mt-2 mx-2 has-text-grey is-size-6">{{ voucher.description }}</p>
					<hr class="mb-0 mt-8" />
					<div class="level is-mobile mt-4">
						<div class="level-left">
							<p class="mt-4 ml-2 mr-0 has-text-dark level-item">{{ voucher.startAt | moment() }} -</p>
							<p class="mt-4 ml-1 has-text-dark level-item">{{ voucher.endAt | moment() }}</p>
						</div>
						<div class="level-right">
							<p class="mt-4 mr-2 has-text-dark level-item is-clickable" @click="seeStore(voucher.store.id)">Let's Go</p>
							<b-icon class="is-clickable mt-4" icon="chevron-right" type="is-dark" custom-size="is-size-3" @click.native="seeStore(voucher.store.id)"></b-icon>
						</div>
					</div>
					<div class="is-flex ml-2">
						<p class="mr-2 has-text-grey is-size-5">{{ voucher.code }}</p>

						<b-icon
							icon="content-copy"
							class="mt-1 is-clickable"
							type="is-primary"
							v-clipboard:copy="voucher.code"
							v-clipboard:success="onCopy"
							custom-size="is-size-4"
						>
						</b-icon>
					</div>
				</div>
			</div>

			<b-modal v-model="isModalOpen" :can-cancel="false" full-screen>
				<div class="modal-card pt-10" style="width: auto">
					<div class="px-8">
						<div class="level is-mobile">
							<div class="level-left">
								<div class="level-item">
									<b-icon pack="rt" icon="arrow-left" @click.native="isModalOpen = false" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
								</div>
							</div>

							<div class="level-item">
								<div>
									<p class="is-size-5 has-text-dark">{{ t.filter }}</p>
								</div>
							</div>

							<div class="level-right is-hidden-desktop">
								<div class="level-item">
									<p class="is-size-6 has-text-dark" @click="resetFilter">{{ t.clear }}</p>
								</div>
							</div>
						</div>

						<p class="is-size-4">{{ t.orderBy }}</p>
						<b-field class="mt-5">
							<b-radio native-value="createdAt:desc" size="is-medium" v-model="mobileSort">{{ t.newOrders }}</b-radio>
						</b-field>
						<b-field class="">
							<b-radio native-value="discount:desc" size="is-medium" v-model="mobileSort">{{ t.discountHigh }}</b-radio>
						</b-field>
						<b-field>
							<b-radio native-value="discount:asc" size="is-medium" v-model="mobileSort">{{ t.discountLow }}</b-radio>
						</b-field>
						<b-field>
							<b-radio native-value="createdAt:asc" size="is-medium" v-model="mobileSort">{{ t.old }}</b-radio>
						</b-field>

						<p class="is-size-4 mt-8">{{ t.stores }}</p>
						<b-field class="mt-3">
							<b-radio :native-value="null" size="is-medium" v-model="mobileStore">{{ t.all }}</b-radio>
						</b-field>
						<b-field v-for="(store, index) in storesFilter" :key="index">
							<b-radio v-if="index == 0" :native-value="store.id" size="is-medium" v-model="mobileStore">{{ store.name }}</b-radio>
							<b-radio
								v-else-if="
									index + 1 != storesFilter.length && storesFilter[index] != storesFilter[index - 1].name && storesFilter[index] != storesFilter[index + 1].name
								"
								:native-value="store.id"
								size="is-medium"
								v-model="mobileStore"
								>{{ store.name }}</b-radio
							>
						</b-field>
					</div>

					<div class="button-container px-8 center-inside has-text-centered">
						<b-button type="is-primary" :loading="isLoading" class="btn" @click="apply">{{ t.apply }}</b-button>
					</div>
				</div>
			</b-modal>
		</div>
	</section>
	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noVouchers }}</p>
	</section>
</template>

<script>
	import { getVouchers } from '@/api/marketing';
	import moment from 'moment';
	export default {
		props: ['sort', 'store'],
		data() {
			return {
				t: this.$t(`components.DiscountsTab`),
				vouchers: [],
				storesFilter: [],
				isModalOpen: false,
				mobileSort: 'createdAt:desc',
				mobileStore: null,
				isLoading: false,
				count: 0,
			};
		},

		methods: {
			seeStore(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			isActive(startDate, endDate) {
				let date = moment().format('YYYY-MM-DD');

				return moment(date).isBetween(startDate, endDate, undefined, '[]');
			},

			resetFilter() {
				this.mobileSort = 'createdAt:desc';
				this.mobileStore = null;
			},

			apply() {
				this.isLoading = true;
				getVouchers({
					'filters[users]': this.getUser.id,
					...(this.mobileStore && { 'filters[store][id]': this.mobileStore }),
					sort: this.mobileSort,
				})
					.then((response) => {
						console.log(response);
						this.vouchers = response.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.isLoading = false;
						this.isModalOpen = false;
					});
			},

			onCopy() {
				this.$buefy.toast.open({
					message: this.t.clipboard,
					position: 'is-top',
					type: 'is-primary',
				});
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD MMM');
			},
		},

		created() {
			console.log(this.sort);

			if (this.IS_MOBILE) {
				getVouchers({
					'filters[users]': this.getUser.id,
					...(this.mobileStore && { 'filters[store][id]': this.mobileStore }),
					sort: this.mobileSort,
				})
					.then((response) => {
						console.log(response);
						this.vouchers = response.data.data;
						console.log(this.vouchers);
						let date = moment().format('YYYY-MM-DD');
						let filter = [];
						console.log(this.store);
						if (!this.store) {
							this.vouchers.map((voucher) => {
								if (moment(date).isBetween(voucher.startAt, voucher.endAt, undefined, '[]')) {
									this.count++;
									filter = this.storesFilter.filter((obj) => {
										return obj.name;
									});

									console.log(filter);
									if (!filter.includes(voucher.store.name)) {
										this.storesFilter.push(voucher.store);
									}
								}
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				getVouchers({
					'filters[users]': this.getUser.id,
					...(this.store && { 'filters[store][id]': this.store }),
					sort: this.sort,
				})
					.then((response) => {
						console.log(response);
						this.vouchers = response.data.data;
						console.log(this.vouchers);
						let date = moment().format('YYYY-MM-DD');
						let filter = [];
						if (!this.store) {
							this.vouchers.map((voucher) => {
								if (moment(date).isBetween(voucher.startAt, voucher.endAt, undefined, '[]')) {
									this.count++;
									filter = this.storesFilter.filter((obj) => {
										return obj.name;
									});

									if (!filter.includes(voucher.store.name)) {
										this.storesFilter.push(voucher.store);
									}
								}
							});

							this.$emit('getStores', this.storesFilter);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.voucher-card {
		width: 100%;
		min-height: 500px;
		height: auto;
		border: 1px solid #f5f6f8;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 30px;
		padding: 1rem;
	}

	.image {
		border: 1px solid #e6e6e6;
		border-radius: 20px;
	}

	.tag {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 2;
		width: 110px;
		height: 45px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		font-size: 1rem;
	}

	.icon-box {
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.button-container {
		width: 100%;
		bottom: 25px;
		position: absolute;
	}

	.voucher-title {
		padding: 0;
		overflow: hidden;
		position: relative;
		display: inline-block;
		text-overflow: ellipsis;
		/* white-space: nowrap; */
		width: 100%;

		/* @include until($tablet) {
			width: 135px;
		}

		@media only screen and (min-width: $tablet) and (max-width: $touch) {
			width: 200px;
		}

		@media only screen and (min-width: 1024px) and (max-width: 1250px) {
			width: 70px;
		}

		@media only screen and (min-width: 1250px) and (max-width: 1500px) {
			width: 100px;
		}

		@media only screen and (min-width: 1500px) and (max-width: 1800px) {
			width: 120px;
		} */
	}
</style>
