<template>
	<section class="section">
		<div class="container">
			<div class="is-flex">
				<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" class="is-clickable mt-2 is-hidden-desktop" size="is-medium"> </b-icon>
				<p class="has-text-centered is-size-3">{{ t.title }}</p>
			</div>

			<VueMarkdown v-if="terms" class="is-size-5-desktop is-size-6-mobile px-2 mt-8 markdown">{{ terms }}</VueMarkdown>
		</div>
	</section>
</template>

<script>
	import { getTerms } from '@/api/terms';
	import VueMarkdown from 'vue-markdown';
	export default {
		components: {
			VueMarkdown,
		},
		data() {
			return {
				terms: null,
				t: this.$t(`views.Terms`),
			};
		},

		created() {
			getTerms().then((response) => {
				console.log(response);
				this.terms = response.data.data.terms;
				console.log(this.terms);
			});
		},
	};
</script>
