<template></template>

<script>
	import { cancelStripeCheckout } from '@/api/cart';
	export default {
		mounted() {
			this.setIsPageLoading(true);
            let id = this.$route.query.session_id
			cancelStripeCheckout(id).then((response) => {
				console.log(response);
				this.setIsPageLoading(false);
				this.goToPage('Cart');
				this.$buefy.toast.open({
					message: this.$t('components.Cart.CancelCheckout.cancelled'),
					position: 'is-top',
					type: 'is-primary',
					duration: 2000,
				});
			});
		},
	};
</script>
