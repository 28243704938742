<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileWallet />
		</div>
		<div class="container" v-else>
			<DesktopWallet />
		</div>
	</section>
</template>

<script>
	import DesktopWallet from '@/components/Desktop/Wallet/Wallet.vue';
	import MobileWallet from '@/components/Wallet/Wallet.vue';
	export default {
		components: {
			DesktopWallet,
			MobileWallet,
		},
	};
</script>
