<template>
	<div class="header-container">
		<div style="display: inline-flex">
			<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" class="is-clickable" size="is-medium"> </b-icon>
			<p class="header ml-2">{{ t.profile }}</p>
		</div>

		<div class="mt-10" style="width: 126px">
			<b-upload v-model="img" accept="image/*">
				<div class="photo center-inside is-clickable" style="border: solid 1px black">
					<b-icon v-if="!getProfilePicture && !img" custom-size="is-size-2" icon="account-outline" type="is-black"> </b-icon>
					<img v-else-if="img" class="has-radius" :src="getFileURL" />
					<img v-else class="has-radius" :src="getProfilePicture" />
				</div>
			</b-upload>
		</div>
		<b-upload v-model="img" class="mt-2 is-clickable">
			<p style="font-weight: 500">{{ t.changePhoto }}</p>
		</b-upload>

		<form @submit.prevent="updateInfo">
			<div class="columns">
				<div class="column is-half">
					<b-field><b-input class="input mt-10" size="is-medium" name="name" type="text" required :placeholder="t.name" v-model.trim="userInfo.name"></b-input></b-field>
					<b-field
						><b-input class="input mt-2" size="is-medium" name="address" type="text" :placeholder="t.address" v-model.trim="userInfo.location_address"></b-input
					></b-field>
					<b-field>
						<b-autocomplete
							class="input mt-2"
							style="height: 42px"
							size="is-medium"
							rounded
							:data="filteredCities"
							:keep-first="true"
							:open-on-focus="true"
							field="city"
							:placeholder="t.city"
							icon="magnify"
							clearable
							ref="city"
							name="city"
							v-model.trim="userInfo.location_municipality"
						>
							<template #empty>{{ t.noResults }}</template>
						</b-autocomplete>
					</b-field>
					<b-field
						><b-input
							class="input small mt-2"
							size="is-medium"
							type="number"
							:placeholder="t.nif"
							v-model.trim="userInfo.vat"
							v-validate="'numeric|length:9|'"
							name="vat"
							ref="vat"
						></b-input
					></b-field>
					<p v-show="errors.has('vat')" class="help is-danger ml-4">{{ t.invalidVat }}</p>
					<b-field
						><b-input
							class="input mt-2"
							size="is-medium"
							name="phone"
							type="number"
							:placeholder="t.phone"
							v-model.trim="userInfo.telephone"
							v-validate="'mobile-phone|numeric'"
						></b-input
					></b-field>
					<p v-show="errors.has('phone')" class="help is-danger ml-4">{{ t.invalidPhone }}</p>
				</div>

				<div class="column">
					<b-field><b-input class="input mt-10" size="is-medium" name="bio" type="text" :placeholder="t.bio" v-model.trim="userInfo.description"></b-input></b-field>
					<b-field
						:type="zipCodeError ? 'is-danger' : ''"
						:message="zipCodeError ? 'Infelizmente ainda não estamos a enviar para a tua zona, estamos a trabalhar nisso' : ''"
						><b-input
							class="input mt-2"
							size="is-medium"
							name="zip_code"
							pattern="\d{4}-\d{3}"
							type="text"
							:placeholder="t.zip"
							v-model.trim="userInfo.location_zipCode"
						></b-input
					></b-field>
					<!-- 		<b-field
					><b-input class="input small mt-2 mr-2" size="is-medium" name="email" type="text" :placeholder="t.country" v-model.trim="userInfo.location_country"></b-input
				></b-field> -->
					<b-field>
						<b-select class="mt-2" size="is-medium" :placeholder="t.country" v-model.trim="userInfo.location_country">
							<option value="Portugal">Portugal</option>
						</b-select></b-field
					>
					<span style="font-weight: 500">{{ t.gender }}</span>
					<div class="small-input">
						<b-field>
							<b-radio size="is-large" class="mt-2 mr-8" native-value="M" v-model="userInfo.gender"
								><span class="gender-text has-text-grey-dark">{{ t.male }}</span></b-radio
							>
						</b-field>
						<b-field>
							<b-radio size="is-large" class="mt-2" native-value="F" v-model="userInfo.gender"
								><span class="gender-text has-text-grey-dark">{{ t.female }}</span></b-radio
							>
						</b-field>
					</div>
				</div>
			</div>

			<div class="button-container px-6 pb-8 has-text-centered">
				<b-button type="is-primary" :loading="isLoading" class="btn" native-type="submit">{{ t.save }}</b-button>
			</div>
		</form>
	</div>
</template>

<script>
	import { updatePreferences, uploadPhoto } from '@/api/user';

	const cities = require('@/assets/cities.json');
	export default {
		data() {
			return {
				t: this.$t(`components.Profile.EditUserProfile`),
				citiesList: [],
				isLoading: false,
				userInfo: {
					name: null,
					description: null,
					location_address: null,
					location_zipCode: null,
					location_municipality: '',
					location_country: null,
					picture: null,

					vat: null,
					gender: null,
					telephone: null,
				},
				imgReceived: null,
				img: null,
				zipCodeError: false,
			};
		},

		methods: {
			async updateInfo() {
				let zip = +this.userInfo.location_zipCode.split('-')[0];
				if (zip >= 9000 && zip <= 9980) {
					this.zipCodeError = true;
					return;
				} else {
					this.zipCodeError = false;
					this.$validator.validateAll().then(async (result) => {
						if (result) {
							this.isLoading = true;
							if (this.img) {
								await uploadPhoto(this.img)
									.then((response) => {
										console.log(response);
										this.userInfo.picture = response.data[0];
									})
									.catch((error) => {
										this.isLoading = false;
										console.log(error);
									});

								await updatePreferences(this.userInfo)
									.then((response) => {
										console.log(response);
										this.addUser(response.data);
										this.isLoading = false;
										this.$buefy.toast.open({
											message: this.t.updated,
											position: 'is-top',
											type: 'is-primary',
										});
										this.goToPage('UserProfile');
									})
									.catch((error) => {
										this.$buefy.toast.open({
											message: this.t.error,
											position: 'is-top',
											type: 'is-danger',
										});
										this.isLoading = false;
										console.log(error);
									});
							} else {
								this.isLoading = true;
								await updatePreferences(this.userInfo)
									.then((response) => {
										console.log(response);
										this.addUser(response.data);
										this.isLoading = false;
										this.$buefy.toast.open({
											message: this.t.updated,
											position: 'is-top',
											type: 'is-primary',
										});
										this.goToPage('UserProfile');
									})
									.catch((error) => {
										this.$buefy.toast.open({
											message: this.t.error,
											position: 'is-top',
											type: 'is-danger',
										});
										this.isLoading = false;
										console.log(error);
									});
							}
						}
					});
				}
			},
		},

		created() {
			this.citiesList = cities;
			this.userInfo.name = this.getUser.name;

			this.userInfo.location_address = this.getUser.location_address;
			this.userInfo.location_zipCode = this.getUser.location_zipCode;

			this.userInfo.location_country = this.getUser.location_country;
			this.userInfo.description = this.getUser.description;

			if (this.getUser.location_municipality) {
				this.userInfo.location_municipality = this.getUser.location_municipality;
			}

			if (this.getUser.picture) {
				this.userInfo.picture = this.getUser.picture;
			}

			if (this.getUser.vat) {
				this.userInfo.vat = this.getUser.vat;
			}

			if (this.getUser.gender) {
				this.userInfo.gender = this.getUser.gender;
			}
			if (this.getUser.telephone) {
				this.userInfo.telephone = this.getUser.telephone;
			}
			if (this.getUser.picture) {
				this.imgReceived = this.getUser.picture.url;
			}
		},

		computed: {
			getFileURL() {
				if (this.img) {
					return window.URL.createObjectURL(this.img);
				}
			},

			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.userInfo.location_municipality.toLowerCase()) >= 0;
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.main-container {
		padding-left: 140px;
		padding-right: 140px;
		margin-top: 80px;
	}

	.pointer {
		cursor: pointer;
	}

	.header {
		font-size: 1.5rem;
	}

	.input {
		border-radius: 11px;
		border: solid 1px $grey-light;
	}
	.small-input {
		display: flex;
	}

	/deep/ .control {
		width: 100%;
	}

	.btn {
		width: 400px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
		margin-top: 100px;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 0rem 1.5rem 6rem;
	}
	.photo {
		width: 125px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
	}

	.photo {
		width: 105px;
		height: 100px;
		background-color: $white;
	}

	/deep/.control {
		width: 100%;
	}
	/deep/span.select.is-medium {
		width: 100%;
	}
	/deep/select {
		width: 100%;
		border-radius: 11px;
		border: solid 1px $grey-light;
	}
</style>
