<template>
	<section v-if="orders.length > 0" @scroll="handleScroll">
		<div class="is-hidden-mobile">
			<p class="has-text-primary is-size-5">{{ t.pickups }}</p>
			<div class="is-flex">
				<b-autocomplete
					class="mt-8"
					style="height: 42px"
					size="is-medium"
					:data="filteredCities"
					:keep-first="true"
					:open-on-focus="true"
					field="city"
					:placeholder="t.search"
					icon="magnify"
					clearable
					ref="city"
					name="city"
					v-model.trim="city"
					@input="changePickupLocality(city)"
				>
					<template #empty>{{ t.noResults }}</template>
				</b-autocomplete>

				<b-select class="mt-8 ml-4" size="is-medium" :placeholder="t.selectPickup" v-model="pickupId">
					<option value="null" disabled selected>{{ t.citiesList }}</option>
					<option v-for="pickup in pickups" :value="pickup.store_number" :key="pickup.id" :placeholder="t.citiesList">
						{{ pickup.name }}
					</option>
				</b-select>
			</div>
			<p class="py-4">{{ orders.length }} {{ t.orders }}</p>
			<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
				<img v-if="order.store" :src="order.store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ order.store.name }}</p>
					<p class="has-text-dark is-size-5">{{ getItems(order) }} items</p>
					<p class="has-text-dark is-size-5">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
				</div>
				<div style="display: inline-flex">
					<b-button class="has-background-primary has-text-white btn" v-if="order.orderStatus.id == 8" @click="devolution(order.id)">{{ t.return }}</b-button>
					<p class="price ml-10 mr-4" style="text-align: right">{{ (order.amount / 100).toFixed(2) }}€</p>
				</div>
			</div>
		</div>

		<div class="is-hidden-desktop">
			<p class="has-text-primary is-size-6">{{ t.pickups }}</p>
			<b-autocomplete
				class="mt-8"
				style="height: 42px"
				size="is-medium"
				:data="filteredCities"
				:keep-first="true"
				:open-on-focus="true"
				field="city"
				:placeholder="t.search"
				icon="magnify"
				clearable
				ref="city"
				name="city"
				v-model.trim="city"
				@input="changePickupLocality(city)"
			>
				<template #empty>{{ t.noResults }}</template>
			</b-autocomplete>

			<b-select class="mt-8 is-size-5" :placeholder="t.selectPickup" v-model="pickupId">
				<option value="null" disabled selected>{{ t.citiesList }}</option>
				<option v-for="pickup in pickups" :value="pickup.store_number" :key="pickup.id">
					{{ pickup.name }}
				</option>
			</b-select>
			<p class="py-4 mt-2">{{ orders.length }} {{t.orders}}</p>
			<div class="mb-2 mt-4 items-container center-inside" v-for="order in orders" :key="order.id">
				<img v-if="order.store" :src="order.store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ order.store.name }}</p>
					<p class="has-text-dark is-size-6">{{ order.orderStatus.name }} - {{ order.updatedAt | moment() }}</p>
				</div>
				<div>
					<p class="price mr-1" style="min-width: 80px; text-align: right">{{ (order.amount / 100).toFixed(2) }} €</p>
					<b-button v-if="order.orderStatus.id == 8" class="has-background-primary has-text-white btn ml-5" @click="devolution(order.id)">{{ t.return }}</b-button>
				</div>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noOrders }}</p>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	import moment from 'moment';
	const cities = require('@/assets/cities.json');
	import { getPickupPoints } from '@/api/shipping.js';
	export default {
		props: ['sort'],
		data() {
			return {
				t: this.$t(`components.DevolutionsTab`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 100,
				citiesList: [],
				city: '',
				pickups: [],
				pickupId: null,
			};
		},

		methods: {
			devolution(id) {
				this.$router.push({ name: 'Devolution', params: { id: id } });
			},

			getOrderTotal(products) {
				let total = 0;
				products.data.map((product) => {
					total = total + product.product.price;
				});

				return total;
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;
					let date = moment().format('yyyy-MM-DD');
					getOrders({
						sort: this.sort,
						'filters[user][id]': this.getUser.id,
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						'filters[$or]': [
							{
								orderStatus: 9,
							},
							{
								orderStatus: 8,
							},
						],
						'filters[return_limit_date][$gte]': date,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},
			changePickupLocality(city) {
				this.pickupId = null;
				console.log(city);
				if (city != '') {
					getPickupPoints(city)
						.then((response) => {
							console.log(response);
							this.pickups = response.data;
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},
		computed: {
			filteredCities() {
				return this.citiesList.filter((option) => {
					return option.city.toString().toLowerCase().indexOf(this.city.toLocaleLowerCase()) >= 0;
				});
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			this.citiesList = cities.slice().sort((a, b) => a.city.localeCompare(b.city));
			let date = moment().format('yyyy-MM-DD');
			getOrders({
				'filters[user][id]': this.getUser.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				'filters[$or]': [
					{
						orderStatus: 8,
					},
					{
						orderStatus: 9,
					},
				],
				'filters[return_limit_date][$gte]': date,
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 100px;
			height: 78px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 86px;
		}
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;

		@include until($mobile) {
			margin-left: 0;
			min-width: 50px;
			font-size: 1rem;
			letter-spacing: 1px;
		}
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;

		@include until($mobile) {
			width: 60px;
			height: 35px;
		}
	}

	/deep/ select {
		background-color: white;
		height: 43px;
		width: 300px;
		border: 1px solid #f5f6f8;
		border-radius: 12px;

		@include until($mobile-l) {
			width: 365px;
		}
	}
</style>
