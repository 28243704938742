<template>
	<section>
		<div @scroll="handleScroll">
			<div class="px-3">
				<div class="search-container">
					<b-field>
						<b-input
							:placeholder="t.search"
							type="text"
							v-model="searchString"
							icon="magnify"
							icon-right="close"
							v-debounce:1s="search"
							icon-clickable
							icon-right-clickable
							@icon-click="search"
							@icon-right-click="clear"
						>
						</b-input>
					</b-field>
					<div @click="goToPage('Filter')" class="icon-box center-inside has-background-primary ml-3">
						<b-icon pack="rt" icon="filter" size="is-size-4" type="is-white"></b-icon>
					</div>
				</div>
			</div>
			<div class="items-list px-3 mt-6">
				<div>
					<span>{{ totalProducts }} Items</span>
					<b-icon @click.native="openFilter" class="right" pack="rt" icon="sort" size="is-size-6" type="is-black"> </b-icon>
				</div>
				<div v-show="showFilter" class="filter-window mt-2">
					<b-field class="mt-3 ml-2">
						<b-radio native-value="id:desc" @input="updateSort" v-model="sort">{{ t.new }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="price:desc" @input="updateSort" v-model="sort">{{ t.priceHigh }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="price:asc" @input="updateSort" v-model="sort">{{ t.priceLow }}</b-radio>
					</b-field>
				</div>

				<div class="columns mt-6 is-mobile is-multiline">
					<div v-for="(product, index) in storeProducts" :key="index" class="column is-half">
						<div class="products-container" @click="productDetails(product.id)">
							<div class="img" :style="[product.pictures ? { 'background-image': `url(${product.pictures[0].url})` } : { background: '#FFF' }]">
								<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag"
									>{{ product.productSales[0].discount }}% {{ t.off }}</span
								>
							</div>
							<div class="media">
								<div class="media-left">
									<p class="product-name mt-2">{{ product.title }}</p>
									<div style="display: inline-flex">
										<p
											class="price"
											:style="[
												product.productSales.length > 0 && product.productSales[0].isActive == true
													? { textDecoration: 'line-through' }
													: { textDecoration: 'none' },
											]"
										>
											{{ product.price }} €
										</p>
										<p class="price ml-4" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
											{{ calculateSale(product.price, product.productSales[0].discount) }} €
										</p>
									</div>
								</div>
								<div class="media-content"></div>
								<div class="media-right"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getProducts } from '@/api/store';
	export default {
		props: ['storeId', 'productCategories', 'productSizes', 'productColors', 'productBrands', 'priceRange', 'onlySale', 'categoryOption'],
		data() {
			return {
				t: this.$t(`components.Store.StoreProducts`),
				showFilter: false,
				storeProducts: [],
				sort: 'id:desc',
				searchString: null,
				page: 1,
				totalProducts: 0,
				pageItems: 8,
				isRequesting: false,
				offset: 300,
				categoryOptionSelected: [],
			};
		},
		methods: {
			openFilter() {
				this.showFilter = !this.showFilter;
			},

			search() {
				this.storeProducts = [];
				this.page = 1;
				getProducts(
					this.storeId,
					this.page,
					this.pageItems,
					this.categoryOptionSelected,
					this.productCategories,
					this.productSizes,
					this.priceRange[0],
					this.priceRange[1],
					this.productColors,
					this.productBrands,
					this.onlySale,
					this.sort,
					this.searchString,
					true
				)
					.then((response) => {
						console.log(response);
						this.storeProducts.push(...response.data.data);
						this.totalProducts = response.data.meta.pagination.total;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			clear() {
				this.searchString = '';
				this.search();
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getProducts(
						this.storeId,
						this.page,
						this.pageItems,
						this.categoryOptionSelected,
						this.productCategories,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.searchString,
						true
					)
						.then((response) => {
							console.log(response);
							this.storeProducts.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			productDetails(id) {
				this.$router.push({ name: 'ProductDetails', params: { id: id } });
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			updateSort() {
				this.page = 1;
				getProducts(
					this.storeId,
					this.page,
					this.pageItems,
					this.categoryOptionSelected,
					this.productCategories,
					this.productSizes,
					this.priceRange[0],
					this.priceRange[1],
					this.productColors,
					this.productBrands,
					this.onlySale,
					this.sort,
					this.searchString,
					true
				)
					.then((response) => {
						console.log(response);
						this.storeProducts = response.data.data;
						this.totalProducts = response.data.meta.pagination.total;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.showFilter = false;
						this.setIsPageLoading(false);
					});
			},
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		mounted() {
			this.setIsPageLoading(true);
			this.categoryOptionSelected = this.categoryOption;
			console.log(this.categoryOptionSelected);

			getProducts(
				this.storeId,
				this.page,
				this.pageItems,
				this.categoryOptionSelected,
				this.productCategories,
				this.productSizes,
				this.priceRange[0],
				this.priceRange[1],
				this.productColors,
				this.productBrands,
				this.onlySale,
				this.sort,
				null,
				true
			)
				.then((response) => {
					console.log(response);
					window.addEventListener('scroll', this.handleScroll);
					this.storeProducts = response.data.data;
					this.totalProducts = response.data.meta.pagination.total;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		/* watch: {
			searchString(newVal) {
				if (newVal || newVal == '') {
					this.setIsPageLoading(true);
					this.page = 1;
					this.products = [];

					getProducts(
						this.storeId,
						this.page,
						this.pageItems,
						this.categoryOptionSelected,
						this.subCategoriesSelected,
						this.sizesSelected,
						this.priceSelected[0],
						this.priceSelected[1],
						this.colorSelected,
						this.brandSelected,
						this.onlySale,
						this.sort,
						this.searchString
					)
						.then((response) => {
							console.log(response);
							this.storeProducts = response.data.data;
							this.totalProducts = response.data.meta.pagination.total;
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
						});
				}
			},
		}, */
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.search-container .field {
		border: 0.5px solid $grey;
		border-radius: 5px;
		width: 274px;
		@include until($mobile-s) {
			width: 230px;
		}
	}

	.icon-box {
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.search-container {
		display: inline-flex;
	}

	.right {
		float: right;
	}

	/deep/ .control.has-icons-left .icon,
	.control.has-icons-right .icon {
		color: $black;
	}

	.filter-window {
		width: 116px;
		height: 99px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 0.718rem;
		position: absolute;
		right: 5px;
		z-index: 10;
		padding: 1px;
		background-color: $white;
	}

	/deep/ .b-checkbox.checkbox input[type='checkbox'] + .check {
		width: 14px;
		height: 14px;
		border-radius: 50px;
	}

	.items-list {
		padding-bottom: 90px;
	}

	.img {
		border-radius: 7px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		width: 100%;
		height: 144px;
		border: 1px solid $grey-light;
	}

	.tag {
		width: 81px;
		height: 30px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		transform: translate(10px, 10px);
	}

	.product-name {
		font-size: 0.858rem;
		text-align: left;
		font-weight: 300;
	}

	.price {
		font-size: 1rem;
		font-weight: 600;
		text-align: left;
	}

	.columns {
		justify-content: unset !important;
	}

	.media-left {
		width: 90% !important;
	}
</style>
