<template>
	<section>
		<div>
			<div class="search-container px-6">
				<b-field>
					<b-input
						:placeholder="t.search"
						icon="magnify"
						v-model="searchString"
						v-debounce:1s="search"
						icon-clickable
						icon-right="close-circle"
						icon-right-clickable
						@icon-right-click="clearSearch"
					>
					</b-input>
				</b-field>
				<div @click="goToPage('Filter')" class="icon-box center-inside has-background-primary ml-3">
					<b-icon pack="rt" icon="filter" size="is-size-4" type="is-white"></b-icon>
				</div>
			</div>
			<div class="options-container mt-20 px-6 pt-4">
				<b-field>
					<b-radio-button class="checkbox" size="is-large" @input="changeFilter" v-model="filterOption" native-value="shopped" type="is-black">
						<span class="btn-text">{{ t.shopped }}</span>
					</b-radio-button>
				</b-field>
				<b-field>
					<b-radio-button class="checkbox ml-2" size="is-large" @input="changeFilter" v-model="filterOption" native-value="likes" type="is-black">
						<span class="btn-text">{{ t.mostLiked }}</span>
					</b-radio-button>
				</b-field>
				<b-field>
					<b-radio-button class="checkbox ml-2 mr-4" size="is-large" @input="changeFilter" v-model="filterOption" native-value="reviews" type="is-black">
						<span class="btn-text">{{ t.bestReview }}</span>
					</b-radio-button>
				</b-field>
				<b-field>
					<b-radio-button class="checkbox ml-2 mr-4" size="is-large" @input="changeFilter" v-model="filterOption" native-value="comments" type="is-black">
						<span class="btn-text">{{ t.mostCommented }}</span>
					</b-radio-button>
				</b-field>
			</div>
			<FlashSale />
			<Feed
				:category="categoryOption"
				:key="counter"
				:productCategories="productCategories"
				:productSizes="productSizes"
				:productColors="productColors"
				:productBrands="productBrands"
				:priceRange="priceRange"
				:onlySale="onlySale"
				:comments="comments"
				:shopped="shopped"
				:reviewed="reviewed"
				:liked="liked"
				:filterStores="filterStores"
			/>
			<BottomMenu />
		</div>
	</section>
</template>

<script>
	import BottomMenu from '@/components/Parts/BottomBar.vue';
	import FlashSale from '@/components/Homepage/FlashSale.vue';
	import Feed from '@/components/Homepage/Feed.vue';
	import parts from '@/mixins/modules/shared/parts';
	export default {
		components: {
			BottomMenu,
			FlashSale,
			Feed,
		},
		data() {
			return {
				filterOption: null,
				t: this.$t(`components.Homepage.Homepage`),
				categoryOption: null,
				productCategories: [],
				productSizes: [],
				productColors: [],
				productBrands: [],
				priceRange: [],
				onlySale: null,
				counter: 0,
				comments: null,
				shopped: null,
				reviewed: null,
				liked: null,
				searchString: null,
				filterStores: [],
			};
		},

		methods: {
			getFilters(productCategories, productSizes, productColors, productBrands, range, onlySale, stores) {
				console.log(stores);
				this.productCategories = productCategories;
				this.productSizes = productSizes;
				this.productColors = productColors;
				this.productBrands = productBrands;
				this.priceRange = range;
				if (onlySale == true) {
					this.onlySale = onlySale;
				} else this.onlySale = null;
				this.filterStores = stores;
				this.counter++;
			},

			changeFilter() {
				if (this.filterOption == 'shopped') {
					this.comments = false;
					this.shopped = true;
					this.reviewed = false;
					this.liked = false;
					this.counter++;
				} else if (this.filterOption == 'likes') {
					this.comments = false;
					this.shopped = false;
					this.reviewed = false;
					this.liked = true;
					this.counter++;
				} else if (this.filterOption == 'reviews') {
					this.comments = false;
					this.shopped = false;
					this.reviewed = true;
					this.liked = false;
					this.counter++;
				} else if (this.filterOption == 'comments') {
					this.comments = true;
					this.shopped = false;
					this.reviewed = false;
					this.liked = false;
					this.counter++;
				}
				/* parts.methods.setSavedMobileEmphasisProdcuts(this.filterOption) */
			},

			search() {
				console.log(this.searchString);
				this.setFilterString(this.searchString);
			},
			clearSearch() {
				this.searchString = '';
				this.setFilterString(this.searchString);
			},
		},

		created() {
			console.log(this.getOnlySale);
			this.productCategories = this.getCategorySelected;
			this.productSizes = this.getSizeSelected;
			this.productColors = this.getColorSelected;
			this.productBrands = this.getBrandSelected;
			this.priceRange = this.getNumbers;
			this.onlySale = this.getOnlySale;
			this.searchString = this.getFilterString;
			this.filterStores = this.getFilterStores;
			this.filterOption = this.getSavedMobileEmphasisProdcuts;

			if (this.getResetFilter || this.getSavedFilters) {
				this.goToPage('Filter');
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
		height: 100%;
	}

	.header {
		font-size: 1.429rem;
		font-weight: 600;
	}

	.header-subtitle {
		font-size: 1.36rem;
		width: 300px;
	}

	/deep/ .checkbox {
		border-radius: 10px;
		width: auto;
	}

	.btn-text {
		font-size: 0.858rem;
		font-weight: 600;
	}

	.options-container {
		text-align: center;
		display: flex;
		overflow: auto;
		white-space: nowrap;
	}

	/deep/ .button {
		border-radius: 12px;
		height: 49px;
		min-width: 100px;
	}

	/deep/.b-radio.checkbox input[type='radio']:checked + .check {
		background: $black no-repeat center center;
		border-color: $black;
	}

	.search-container .field {
		border: 0.5px solid $grey;
		border-radius: 5px;
		width: 274px;
		@include until($mobile-s) {
			width: 230px;
		}
	}

	.icon-box {
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.search-container {
		display: inline-flex;
		position: fixed;
		background-color: #fff;
		z-index: 14;
		top: 58px;
		padding-top: 50px;
		width: 100%;
		justify-content: center;

		@include until($mobile-s) {
			top: 50px;
		}
	}
</style>
