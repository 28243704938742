<template>
	<section>
		<div class="mt-2 px-6">
			<!-- <span class="has-text-grey-darker content-title">New In</span> -->

			<div class="products-container mt-4">
				<span style="display: inline-flex">
					<div
						class="img mx-3 mb-6 is-clickable "
						@click="filter(getCategorySelected, getSizeSelected, getColorSelected, getBrandSelected, getNumbers, getOnlySale, 'shopped',getFilterStores)"
					>
						<p class="has-text-white is-size-5 ml-4 pt-10 has-text-weight-bold" style="line-height: 140px">{{t.shopped}}</p>
					</div>
					<div
						class="img2 mx-3 mb-6 is-clickable"
						@click="filter(getCategorySelected, getSizeSelected, getColorSelected, getBrandSelected, getNumbers, getOnlySale, 'comments',getFilterStores)"
					>
						<p class="has-text-white is-size-5 ml-4 pt-10 has-text-weight-bold" style="line-height: 140px">{{t.mostCommented}}</p>
					</div>
					<div
						class="img3 mx-3 mb-6 is-clickable"
						@click="filter(getCategorySelected, getSizeSelected, getColorSelected, getBrandSelected, getNumbers, getOnlySale, 'reviews',getFilterStores)"
					>
						<p class="has-text-white is-size-5 ml-4 pt-10 has-text-weight-bold" style="line-height: 140px">{{t.bestReview}}</p>
					</div>
					<div
						class="img4 mx-3 mb-6 is-clickable"
						@click="filter(getCategorySelected, getSizeSelected, getColorSelected, getBrandSelected, getNumbers, getOnlySale, 'likes', getFilterStores)"
					>
						<p class="has-text-white is-size-5 ml-4 pt-10 has-text-weight-bold" style="line-height: 140px">{{t.mostLiked}}</p>
					</div>
				</span>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				comments: null,
				shopped: null,
				reviewed: null,
				liked: null,
				t: this.$t(`components.Homepage.Homepage`),
			};
		},
		methods: {
			filter(categories, sizes, colors, brands, price, onlySale, topFilter,stores) {
				
				if (topFilter == 'comments') {
					this.comments = true;
					this.shopped = false;
					this.reviewed = false;
					this.liked = false;
				} else if (topFilter == 'shopped') {
					this.comments = false;
					this.shopped = true;
					this.reviewed = false;
					this.liked = false;
				} else if (topFilter == 'reviews') {
					this.comments = false;
					this.shopped = false;
					this.reviewed = true;
					this.liked = false;
				} else if (topFilter == 'likes') {
					this.comments = false;
					this.shopped = false;
					this.reviewed = false;
					this.liked = true;
				}

				this.$emit('getFilters', categories, sizes, colors, brands, price, onlySale, this.comments, this.shopped, this.reviewed, this.liked,stores);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.products-container {
		text-align: center;
		display: flex;
		overflow: auto;
		white-space: nowrap;
		overflow-y: hidden;
	}

	.img {
		width: 350px;
		height: 136px;
		border-radius: 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../../../assets/most_shopped.png');
		text-align: left;
	}
	.img2 {
		width: 350px;
		height: 136px;
		border-radius: 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../../../assets/most_liked_panel.png');
		text-align: left;
	}
	.img3 {
		width: 350px;
		height: 136px;
		border-radius: 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../../../assets/most_reviewed.png');
		text-align: left;
	}
	.img4 {
		width: 350px;
		height: 136px;
		border-radius: 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../../../assets/most_commented_panel.png');
		text-align: left;
	}

	.content-title {
		font-size: 1.4rem;
	}

	::-webkit-scrollbar {
		height: 6px;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #aab2b7;
	}
	::-webkit-scrollbar-thumb:active {
		background: #aab2b7;
	}
</style>
