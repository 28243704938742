<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<ListStores />
		</div>
	</section>
</template>

<script>
	import ListStores from '@/components/Homepage/StoresList.vue';

	export default {
		components: {
			ListStores,
		},
	};
</script>
