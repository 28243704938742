<template>
	<section>
		<div class="is-fixed-top pt-8 px-3"><b-icon @click.native="$router.back()" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
		<div class="px-6 mt-6">
			<span>{{ t.reset }}</span
			><br />
			<p class="text mt-6">{{ t.text }}</p>
		</div>

		<div class="px-6 container">
			<form @submit.prevent="change">
				<b-field><b-input class="input mt-6" size="is-medium" required :placeholder="t.new" password-reveal type="password" v-model="password"></b-input></b-field>

				<b-field>
					<b-input class="input mt-6" size="is-medium" required :placeholder="t.confirm" password-reveal type="password" v-model="confirmPassword"></b-input>
				</b-field>
				<p v-show="errorMessage" class="has-text-red has-text-centered">{{ t.error }}</p>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" class="btn" native-type="submit">{{ t.update }}</b-button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	import { changePassword } from '@/api/auth';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.ResetPassword`),

				password: null,
				confirmPassword: null,
				token: null,
				errorMessage: false,
			};
		},

		created() {
			this.token = this.$route.query.code;
		},

		methods: {
			change() {
				if (this.password !== this.confirmPassword) {
					this.errorMessage = true;
				} else {
					this.errorMessage = false;
					let data = {
						password: this.password,
						passwordConfirmation: this.confirmPassword,
						code: this.token,
					};
					changePassword(data)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.changed,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('Login');
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';
	span {
		font-size: 1.7rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		@include from($mobile-s) {
			margin-top: 60px;
		}
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}

	.text {
		font-size: 1rem;
		color: $grey;
	}

	.container {
		margin-top: 30px;
	}
</style>
