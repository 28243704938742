<template>
	<section>
		<div class="header-container px-6 mt-10">
			<b-icon pack="rt" class="mt-1" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" custom-size="is-size-5"> </b-icon>
			<p @click="$router.go(-1)" class="is-size-5 ml-2">{{ t.comment }}</p>
		</div>

		<div class="center-inside mt-10">
			<div class="product-card">
				<div class="level is-mobile">
					<div class="level-left">
						<figure
							class="image is-32x32 is-background-size-cover is-rounded center-inside"
							:style="[product.store.pictures ? { 'background-image': `url(${product.store.pictures[0].url})` } : { background: '#FFF', border: 'solid 1px black' }]"
						>
							<b-icon v-if="!product.store.pictures" icon="account-outline" type="is-black" custom-size="is-size-3"> </b-icon>
						</figure>

						<div>
							<p class="ml-2 has-text-dark is-size-6">{{ product.store.name }}</p>
							<p class="ml-2 has-text-grey is-size-7">{{ product.store.location_municipality }}</p>
						</div>
					</div>

					<div class="level-right">
						<p class="has-text-grey is-size-7">{{ product.createdAt | moment() }}</p>
					</div>
				</div>

				<b-carousel class="mt-2" :autoplay="false">
					<b-carousel-item v-for="(image, i) in product.pictures" :key="i">
						<figure class="image is-4by3">
							<img class="has-object-fit-contain has-radius" style="border: solid 1px #aab2b72c" :src="image.url" alt="" />
						</figure>
					</b-carousel-item>
				</b-carousel>
			</div>
		</div>

		<div class="px-6 mt-4" style="display: inline-flex">
			<b-icon
				v-if="!productsLiked.includes(product.id)"
				icon="heart-outline"
				@click.native="like(product.id)"
				class="is-clickable"
				type="is-primary"
				custom-size="is-size-4"
			></b-icon>
			<b-icon v-else icon="heart" @click.native="unlike(product.id)" class="is-clickable" type="is-primary" custom-size="is-size-4"> </b-icon>
			<p class="has-text-grey ml-2">{{ product.likes.length }}</p>
			<b-icon class="ml-4" icon="message-outline" type="is-primary" custom-size="is-size-4"> </b-icon>
			<p class="has-text-grey ml-2">{{ product.comments.length }}</p>
		</div>

		<div class="comment-container px-6 mt-4" @scroll="commentsScroll">
			<div v-for="comment in comments" :key="comment.id" class="mt-6" style="display: flex">
				<figure
					class="image is-48x48 is-background-size-cover is-rounded pointer center-inside"
					:style="[comment.user.picture ? { 'background-image': `url(${comment.user.picture.url})` } : { background: '#FFF', border: 'solid 1px black' }]"
				>
					<b-icon v-if="!comment.user.picture" icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
				</figure>

				<div>
					<p class="has-text-dark has-text-weight-bold ml-6">{{ comment.user.name }}</p>

					<p class="has-text-grey ml-6">{{ comment.createdAt | moment() }}</p>

					<p class="has-text-dark ml-6 mt-1">{{ comment.text }}</p>
				</div>
			</div>
		</div>

		<b-field class="px-6 mt-4" v-if="getUserType == 'authenticated' && getToken">
			<b-input class="text-comment" type="textarea" maxlength="300" v-model.trim="comment" icon-right="send" icon-right-clickable @icon-right-click="sendComment"> </b-input>
		</b-field>
		<p v-if="commentError" class="px-6 has-text-danger" style="transform: translateY(-20px)">{{ t.errorMessage }}</p>
	</section>
</template>

<script>
	import moment from 'moment';
	import { getComments, addComment, addLike, getLikes, removeLike } from '@/api/feed';
	export default {
		props: ['product'],
		data() {
			return {
				t: this.$t(`components.Products.ProductComments`),
				comments: [],
				commentsPage: 1,
				commentsPageSize: 10,
				comment: null,
				commentError: false,
				productsLiked: [],
				totalLikes: [],
				isAddingLike: false,
				isAddingWhishlist: false,
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('D MMM YYYY');
			},
		},

		methods: {
			commentsScroll(e) {
				let target = {
					scrollTop: e.target.scrollTop,
					clientHeight: e.target.clientHeight,
					scrollHeight: e.target.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
					if (this.isRequesting) return;

					this.isRequesting = true;
					this.commentsPage++;

					getComments(this.product.id, this.commentsPage, this.commentsPageSize)
						.then((response) => {
							console.log(response);
							this.comments.push(...response.data.data.reverse());
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			sendComment() {
				if (!this.comment) {
					this.commentError = true;
				} else {
					this.commentError = false;

					let userComment = {
						data: {
							product: this.product.id,
							user: this.getUser.id,
							text: this.comment,
						},
					};

					addComment(userComment)
						.then((response) => {
							console.log(response);
							this.comments.unshift(response.data.data);
							this.comment = null;
							this.$buefy.toast.open({
								message: this.t.addedComment,
								position: 'is-top',
								type: 'is-primary',
							});
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},

			like(id) {
				if (this.getToken) {
					this.productsLiked.push(id);
					this.productInfo.likes.push({});
					let like = {
						data: {
							product: id,
						},
					};

					console.log(like);
					if (!this.isAddingLike) {
						this.isAddingLike = true;

						addLike(like)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			unlike(id) {
				if (this.getToken) {
					this.productsLiked.splice(this.productsLiked.findIndex((element) => element == id));
					this.productInfo.likes.splice(0, 1);

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						removeLike(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},
		},

		created() {
			if (this.product == undefined || this.product == null) {
				this.$router.go(-1);
			} else {
				console.log(this.product);
				this.setIsPageLoading(true);
				if (this.getToken) {
					getLikes({
						'filters[product][id]': this.product.id,
						populate: 'product',
					})
						.then((response) => {
							console.log(response);
							this.productsLiked = response.data.data.map((like) => {
								return like.product.id;
							});
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						});
				}

				getComments(this.product.id, this.commentsPage, this.commentsPageSize)
					.then((response) => {
						console.log(response);
						this.comments = response.data.data.reverse();
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: 'Ocorreu um erro',
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.setIsPageLoading(false);
						window.addEventListener('scroll', this.commentsScroll);
					});
			}
		},

		computed: {
			getStep() {
				return this.$store.getters['desktopLoginStep/getStep'];
			},

			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		destroyed() {
			window.removeEventListener('scroll', this.commentsScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	.product-card {
		width: 90%;
		padding: 1rem;
		box-shadow: 10px 4px 48px #0000000a;
		border-radius: 16px;
	}

	.comment-container {
		overflow-y: auto;
		height: 190px;

		@include from($mobile-l) {
			height: 250px;
		}
	}

	/deep/ textarea.textarea {
		border: solid 2px $grey-light;
		border-radius: 12px;
		resize: none;
		min-height: 4em;
		background-color: white;
	}

	/deep/ .control.has-icons-right .icon {
		color: $primary;
		top: 10px;
		right: 2px;
	}
</style>
