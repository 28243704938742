<template>
	<section @scroll="handleScroll">
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<div class="pt-8 is-flex">
						<b-icon pack="rt" icon="arrow-left" @click.native="$router.go(-1)" type="is-grey-darker" class="is-clickable" size="is-medium"> </b-icon>
						<p class="has-text-black ml-2 is-clickable is-size-4" @click="goToPage('DesktopStoreProducts')">{{ t.transactions }}</p>
					</div>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-button class="header-btn ml-4 right" @click="showFilter = !showFilter">
						<b-icon pack="rt" class="mr-2" icon="sort" size="is-size-6" type="is-black"></b-icon>{{ t.sort }}</b-button
					>
				</div>
				<div v-show="showFilter" class="filter-window mt-2 px-6 py-2">
					<p class="is-size-6">{{ t.orderBy }}</p>
					<b-field class="mt-3">
						<b-radio native-value="createdAt:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
					</b-field>
					<b-field class="mt-3">
						<b-radio native-value="amount:desc" @input="changeSort" v-model="sort">{{ t.priceHigh }}</b-radio>
					</b-field>
					<b-field class="mt-3">
						<b-radio native-value="amount:asc" @input="changeSort" v-model="sort">{{ t.priceLow }}</b-radio>
					</b-field>
					<b-field class="mt-3">
						<b-radio native-value="createdAt:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
					</b-field>
				</div>
			</div>
		</div>

		<p class="py-4">{{ orders.length }} {{ t.orders }}</p>
		<div class="mb-2 mt-4 items-container center-inside is-clickable" v-for="order in orders" :key="order.id" @click="orderDetails(order.id, order.orderStatus.id)">
			<img v-if="order.user.picture" :src="order.user.picture.url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-dark user-name">{{ order.user.name }}</p>
				<p class="has-text-dark order">{{ order.id }}</p>
				<p class="has-text-dark order-items">{{ getItems(order) }} Item(s)</p>
				<p class="has-text-dark order">{{ order.createdAt | moment() }}</p>
			</div>
			<div class="mr-4">
				<p class="price has-text-centered">{{ (order.amount / 100).toFixed(2) }} €</p>
				<p v-if="order.orderStatus.id == 10" class="has-text-centered has-text-danger is-size-6">{{ t.returned }}</p>
			</div>
		</div>
	</section>
</template>

<script>
	import { getOrders } from '@/api/orders';
	import moment from 'moment';
	export default {
		data() {
			return {
				t: this.$t(`components.Store.TransactionsHistory`),
				orders: [],
				page: 1,
				pageSize: 10,
				offset: 100,
				showFilter: false,
				sort: 'createdAt:desc',
			};
		},

		methods: {
			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			orderDetails(id, orderStatus) {
				if (orderStatus == 10) {
					this.$router.push({ name: 'Devolution', params: { id: id } });
				} else this.$router.push({ name: 'OrderDetail', params: { id: id } });
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getOrders({
						'filters[store]': this.getUser.store.id,
						'filters[$or]': [
							{
								orderStatus: 3,
							},
							{
								orderStatus: 8,
							},
							{
								orderStatus: 10,
							},
						],
						/* 	'filters[orderStatus][id][$eq]': 3,
						'filters[orderStatus][id][$eq]': 8, */
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: this.sort,
					})
						.then((response) => {
							this.orders.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							this.setIsPageLoading(false);
							this.isRequesting = false;
						});
				}
			},

			changeSort() {
				this.setIsPageLoading(true);
				this.page = 1;
				getOrders({
					'filters[store]': this.getUser.store.id,
					'filters[$or]': [
						{
							orderStatus: 3,
						},
						{
							orderStatus: 8,
						},
						{
							orderStatus: 10,
						},
					],
					/* 'filters[orderStatus][id][$eq]': 3,
					'filters[orderStatus][id][$eq]': 8, */
					'pagination[page]': this.page,
					'pagination[pageSize]': this.pageSize,
					sort: this.sort,
				})
					.then((response) => {
						console.log(response);
						this.orders = response.data.data;
						window.addEventListener('scroll', this.handleScroll);
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
						this.showFilter = false;
					});
			},
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD.MM.YYYY');
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getOrders({
				'filters[store]': this.getUser.store.id,
				'filters[$or]': [
					{
						orderStatus: 3,
					},
					{
						orderStatus: 8,
					},
					{
						orderStatus: 10,
					},
				],
				/* 'filters[orderStatus][id][$eq]': 3,
				'filters[orderStatus][id][$eq]': 8, */
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				sort: this.sort,
			})
				.then((response) => {
					console.log(response);
					this.orders = response.data.data;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;
	}

	img {
		object-fit: cover;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 1rem;
	}

	.order-items,
	.user-name {
		font-size: 1.2rem;
	}

	.price {
		color: $primary;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;
	}

	.filter-window {
		width: 200px;
		height: auto;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 11px;
		z-index: 10;
		padding: 1px;
		background-color: #ffffff;
		top: 54px;
	}
</style>
