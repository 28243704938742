<template>
	<div style="padding-bottom: 100px">
		<div class="header-container pt-8">
			<b-icon @click.native="$router.go(-1)" class="is-clickable" icon="chevron-left" type="is-grey" custom-size="is-size-4"></b-icon>
			<p @click="$router.go(-1)" class="has-text-dark ml-2 is-clickable">{{ t.back }}</p>
		</div>

		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<p class="has-text-grey-darker is-size-1" v-if="getUserType == 'authenticated'">{{ t.devolution }}</p>
					<p class="has-text-grey-darker is-size-1" v-if="getUserType == 'store'">{{ t.details }}</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item" v-if="getUserType == 'store' && order.orderStatus.id == 9">
					<b-upload v-model="file" accept="application/pdf" class="file-label header-btn center-inside">
						<b-icon class="mr-2" icon="upload" size="is-size-6" type="is-black"></b-icon>{{ t.upload }}
					</b-upload>
					<br />
				</div>
			</div>
		</div>
		<p v-if="file" class="has-text-primary mt-0" style="position: absolute; right: 0">{{ file.name }}</p>

		<div class="level" v-if="getUserType == 'store'">
			<div class="level-left">
				<div class="level-item">
					<div class="box mt-4" style="width: 400px">
						<p class="has-text-centered is-size-5">{{ t.refundSummary }}</p>
						<div class="level mb-0 mt-3">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.order }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5">{{ (order.amount / 100 + order.discount).toFixed(2) }} €</p>
							</div>
						</div>
						<div class="level mb-0" v-if="order.discount > 0">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.discount }}</p>
							</div>
							<div class="level-right">
								<p class="level-item is-size-5">
									- {{ order.discount }} € ({{ calculateDiscount(order.amount / 100 + order.discount - order.shipping / 100, order.discount) }}%)
								</p>
							</div>
						</div>
						<div class="level mb-0">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.shippingFees }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5" v-if="order.shipping">- {{ order.shipping / 100 }} €</p>
								<p class="level-item is-size-5" v-else>- 0 €</p>
							</div>
						</div>
						<div class="level mb-0 mt-6">
							<div class="level-left">
								<p class="level-item is-size-5">{{ t.total }}</p>
							</div>

							<div class="level-right">
								<p class="level-item is-size-5">{{ getValueToRefund() }} €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="level-right"></div>
		</div>

		<div>
			<div class="mb-2 mt-20 items-container center-inside">
				<img v-if="order.user.picture" :src="order.user.picture.url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-dark user-name">{{ order.user.name }}</p>
					<p class="has-text-dark order">ID {{ order.id }}</p>
					<p class="has-text-dark order-items">{{ getItems(order) }} Item(s)</p>
				</div>
				<div style="display: inline-flex">
					<p class="price mx-10 mt-2">{{ (order.amount / 100 - order.shipping / 100).toFixed(2) }} €</p>
				</div>
			</div>

			<div class="mt-8" v-for="(detail, index) in order.products.data" :key="detail.id">
				<div
					class="product-container"
					:class="[detail.motive && getUserType == 'store' ? 'highlighted-div' : 'transparent-div', getUserType == 'authenticated' ? 'opaque-div' : '']"
				>
					<!-- 	<div class="product-quantity center-inside mr-3" v-if="getUserType == 'store'">{{ index + 1 }}</div> -->
					<div class="product-detail">
						<div style="margin: auto; display: flex">
							<img
								class="product-image"
								:style="detail.motive || getUserType == 'authenticated' ? 'opacity:1' : 'opacity:0.4'"
								:src="detail.product.pictures[0].url"
							/>
							<div class="ml-6" style="display: table; margin: auto">
								<div style="display: table-cell; vertical-align: middle">
									<p class="has-text-grey is-size-5">{{ detail.product.title }}</p>
									<div style="display: inline-flex" class="my-2" v-if="getUserType == 'authenticated'">
										<p class="has-text-dark mr-2 is-size-4">
											{{ detail.quantity }} x {{ getVariantSize(detail.variant, detail.product.productVariants, detail.size) }}
										</p>
										<div
											class="size-color center-inside"
											:style="{ backgroundColor: getVariantColor(detail.variant, detail.product.productVariants, detail.size) }"
											:class="
												getVariantColorName(detail.variant, detail.product.productVariants, detail.size) == 'Branco' ? 'has-text-black' : 'has-text-white'
											"
										>
											{{ getVariantColorName(detail.variant, detail.product.productVariants, detail.size) }}
										</div>
									</div>
									<p class="has-text-grey is-size-5">{{ detail.price }} €</p>
									<p class="has-text-grey is-size-5" v-if="order.discount > 0">{{ calculateProductDiscount(detail) }} € {{ t.valueWithDiscount }}</p>
								</div>
							</div>
							<div style="display: table" class="mx-10">
								<div style="display: table-cell; vertical-align: middle">
									<div class="mt-2" style="display: inline-flex" v-if="getUserType == 'authenticated'">
										<b-field class="mr-10" style="min-width: 200px" v-show="devolutionArray[index] == true">
											<b-select
												v-validate="devolutionArray[index] == true ? 'required' : ''"
												:placeholder="t.returningReason"
												size="is-medium"
												expanded
												v-model="reasonArray[index]"
												:data-vv-name="'reason' + index"
											>
												<option :value="motive.id" v-for="motive in motives" :key="motive.id">{{ motive.description }}</option>
											</b-select>
										</b-field>

										<b-field class="mt-4">
											<b-checkbox v-model="devolutionArray[index]"> </b-checkbox>
										</b-field>
									</div>
									<p v-show="errors.has('reason' + index)" class="help is-danger">{{ t.selectReason }}</p>

									<div class="Components-Cart-CartLine-Desktop" v-if="getUserType == 'authenticated' && devolutionArray[index] == true">
										<div class="is-flex is-align-self-flex-end">
											<b-numberinput
												custom-class="has-background-white"
												:step="1"
												class="is-size-5"
												controls-position="compact"
												min="1"
												:editable="false"
												v-model="returnNumber[index]"
												:max="detail.quantity"
											></b-numberinput>
										</div>
									</div>

									<div style="display: inline-flex" v-if="getUserType == 'store'">
										<p class="has-text-dark mr-2 is-size-4">
											{{ detail.quantity }} x {{ getVariantSize(detail.variant, detail.product.productVariants, detail.size) }}
										</p>
										<div
											class="size-color center-inside"
											:style="{ backgroundColor: getVariantColor(detail.variant, detail.product.productVariants, detail.size) }"
											:class="
												getVariantColorName(detail.variant, detail.product.productVariants, detail.size) == 'Branco' ? 'has-text-black' : 'has-text-white'
											"
										>
											{{ getVariantColorName(detail.variant, detail.product.productVariants, detail.size) }}
										</div>
									</div>
									<div v-if="getUserType == 'store' && detail.return_quantity > 0">
										<p class="has-text-dark mr-2 is-size-4">{{ t.returnedProducts }}{{ detail.return_quantity }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p class="has-text-grey has-text-centered mt-10" v-if="getUserType == 'store' && detail.returned == true">{{ t.motive }} {{ detail.motive.description }}</p>
			</div>
		</div>

		<div class="mt-10">
			<b-checkbox class="mt-7" name="confirm" v-validate="'required:true'" v-model="checkConfirm" v-if="getUserType == 'authenticated'"
				>{{ t.confirm }} <br />
				{{ t.confirmTwo }}</b-checkbox
			>

			<b-button
				class="btn"
				type="is-primary"
				:loading="isLoading"
				@click="confirm"
				v-if="getUserType == 'authenticated'"
				:disabled="devolutionArray.includes(true) ? false : true"
				>{{ t.return }}</b-button
			>
			<b-button class="btn-store" type="is-primary" :loading="isLoading" @click="storeConfirm" v-if="getUserType == 'store' && order.orderStatus.id == 9">{{
				t.refund
			}}</b-button>
			<b-button class="btn-store mt-8" type="is-danger" :loading="isLoading" @click="rejectReasonsModal = true" v-if="getUserType == 'store' && order.orderStatus.id == 9">{{
				t.reject
			}}</b-button>
		</div>
		<p v-show="errors.has('confirm')" class="help is-danger is-size-6" v-if="getUserType == 'authenticated'">
			{{ t.confirmError }}
		</p>
		<RejectModal v-if="getUserType == 'store'" :reasonsModal="rejectReasonsModal" @close="closeModal" @setMotive="setMotive" />
	</div>
</template>

<script>
	import { getOrderById, devoltuionMotives, userReturnProduct, storeConfirmRefund, rejectDevolution, uploadImage } from '@/api/orders.js';
	import RejectModal from '@/components/Desktop/Orders/RejectModal.vue';
	export default {
		components: {
			RejectModal,
		},
		data() {
			return {
				order: [],
				devolutionArray: [],
				isLoading: false,
				reasonArray: [],
				motives: [],
				t: this.$t(`components.DevolutionDetails`),
				checkConfirm: null,
				rejectReasonsModal: false,
				file: null,
				returnNumber: [],
			};
		},

		methods: {
			getVariantSize(id, variants, size) {
				let variant = variants.filter((variant) => variant.id == id);

				let finalSize = variant[0].productSizes.filter((productSize) => productSize.id == size);

				return finalSize[0].size.name;
			},

			getVariantColorName(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.name;
			},

			getVariantColor(id, variants) {
				let variant = variants.filter((variant) => variant.id == id);

				return variant[0].color.hex;
			},

			confirm() {
				this.isLoading = true;
				this.$validator.validateAll().then((result) => {
					if (result) {
						console.log(this.devolutionArray.length);
						console.log(this.reasonArray);

						let data = {
							products: [],
						};

						for (let index = 0; index < this.devolutionArray.length; index++) {
							console.log(this.devolutionArray[index]);
							if (this.devolutionArray[index] == true) {
								if (this.reasonArray[index] == null) {
									this.$buefy.toast.open({
										message: this.t.reason,
										position: 'is-top',
										type: 'is-danger',
									});
									this.isLoading = false;
									break;
								} else {
									console.log(this.order.products.data[index]);
									data.products.push({
										id: this.order.products.data[index].product.id,
										motive: this.reasonArray[index],
										return_quantity: this.returnNumber[index],
										variant: this.order.products.data[index].variant,
										size: this.order.products.data[index].size,
									});
								}
							}
						}

						console.log(data);
						userReturnProduct(this.order.id, data)
							.then((response) => {
								console.log(response);
								this.$buefy.toast.open({
									message: this.t.request,
									position: 'is-top',
									type: 'is-primary',
								});
								this.goToPage('UserProfile');
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isLoading = false;
							});
					} else this.isLoading = false;
				});
			},

			async storeConfirm() {
				if (!this.file) {
					this.$buefy.toast.open({
						message: this.t.verifyUpload,
						position: 'is-top',
						type: 'is-danger',
					});
				} else {
					this.isLoading = true;

					let data = {
						data: {
							credit_note: null,
						},
					};

					await uploadImage(this.file).then((response) => {
						console.log(response);
						data.data.credit_note = response.data[0].id;
					});

					await storeConfirmRefund(this.order.id, data)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.refundConfirmed,
								position: 'is-top',
								type: 'is-primary',
							});
							this.goToPage('StoreOrders');
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},

			closeModal() {
				this.rejectReasonsModal = false;
			},

			setMotive(val) {
				console.log(val);
				let data = {
					motive: val,
				};
				this.rejectReasonsModal = false;
				this.isLoading = true;
				rejectDevolution(this.$route.params.id, data)
					.then((response) => {
						console.log(response);
						this.$buefy.toast.open({
							message: this.t.refundRejected,
							position: 'is-top',
							type: 'is-primary',
						});
						this.goToPage('StoreOrders');
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: this.t.error,
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},

			getItems(order) {
				let total = 0;
				order.products.data.map((product) => {
					total += product.quantity;
				});

				return total;
			},

			getValueToRefund() {
				let value = 0;
				console.log(this.order);
				if (this.order.coupon == true || this.order.discount > 0) {
					let percentageDiscount = 0;
					let totalAmmount = (this.order.amount / 100 + this.order.discount - this.order.shipping / 100).toFixed(2);
					console.log(totalAmmount);
					percentageDiscount = (this.order.discount * 100) / totalAmmount;
					console.log(percentageDiscount / 100);

					this.order.products.data.map((product) => {
						if (product.motive) {
							let valueToSubtract = product.price * product.return_quantity * (percentageDiscount / 100);
							console.log(valueToSubtract);
							value += product.price * product.return_quantity - valueToSubtract;
						}
					});
				} else
					this.order.products.data.map((product) => {
						if (product.motive) {
							value += product.price * product.return_quantity;
						}
					});

				return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
			},

			calculateDiscount(originalPrice, discountValue) {
				let salePrice = originalPrice - discountValue;

				let discount = ((originalPrice - salePrice) / originalPrice) * 100;

				if (Number.isInteger(discount)) {
					return discount;
				} else return discount.toFixed(2);
			},

			calculateProductDiscount(product) {
				let value = 0;
				let percentageDiscount = 0;
				let totalAmmount = (this.order.amount / 100 + this.order.discount - this.order.shipping / 100).toFixed(2);
				percentageDiscount = (this.order.discount * 100) / totalAmmount;
				let valueToSubtract = product.price * (percentageDiscount / 100);
				value += product.price - valueToSubtract;

				return value.toFixed(2);
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		created() {
			console.log(this.getUserType);
			this.setIsPageLoading(true);

			devoltuionMotives()
				.then((response) => {
					console.log(response);
					this.motives = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getOrderById(this.$route.params.id)
				.then((response) => {
					this.order = response.data.data;
					console.log(this.order);

					for (let index = 0; index < this.order.products.data.length; index++) {
						this.devolutionArray.push(false);
						this.reasonArray.push(null);
						this.returnNumber.push(1);
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 1rem;
	}

	.order-items,
	.user-name {
		font-size: 1.2rem;
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
	}

	.btn {
		width: 100px;
		height: 54px;
		display: flex;
		float: right;
	}

	.product-container {
		display: flex;
	}

	.product-quantity {
		background-color: $primary;
		border-radius: 8px;
		width: 71px;
		height: 127px;
		color: white;
		font-size: 2rem;
	}

	.product-detail {
		box-shadow: 0px 2px 48px #0000000a;
		width: 100%;
		height: 150px;
		border-radius: 20px;
		border: 1px solid #e6e6e6;
	}

	.product-image {
		height: 150px;
		border-radius: 22px;
		border: 1px solid #e6e6e6;
		width: 130px;
	}

	.size-color {
		height: 35px;
		border-radius: 5px;
		text-align: center;
		min-width: 50px;
		padding: 1rem;
		border: solid 1px grey;
	}

	.btn-store {
		width: 422px;
		height: 54px;
		display: flex;
		margin: 0 auto;
		margin-top: 220px;
	}

	.header-btn {
		width: 160px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
		text-align: center;
		padding: 1rem;
	}

	.transparent-div {
		background-color: #efefef;
		opacity: 0.6;
		border-radius: 20px;
	}

	.opaque-div {
		background-color: white;
		opacity: 1;
		border-radius: 20px;
	}

	.highlighted-div {
		border: $primary solid 2px;
		border-radius: 20px;
	}
</style>
