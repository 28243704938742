<template>
	<section>
		<div class="search-container mb-4 mt-10">
			<b-field>
				<b-input
					:placeholder="t.search"
					icon="magnify"
					v-model="searchString"
					icon-clickable
					icon-right="close-circle"
					icon-right-clickable
					@icon-right-click="clearSearch"
					v-debounce:1s="search"
				>
				</b-input>
			</b-field>
		</div>
		<div v-if="existingStores">
			<div class="mb-2 mt-3 items-container center-inside" v-for="store in stores" :key="store.id" @click="seeStore(store.id)">
				<img v-if="store.pictures" :src="store.pictures[0].url" />
				<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
				<div class="ml-8" style="margin: auto">
					<p class="has-text-grey">{{ store.name }}</p>
				</div>
			</div>
		</div>
		<div v-else class="center-inside" style="height: 60vh">
			<p class="has-text-primary is-size-4">{{ t.noResults }}</p>
		</div>
	</section>
</template>

<script>
	import { getStoreFilter } from '@/api/feed';
	export default {
		data() {
			return {
				stores: [],
				searchString: null,
				page: 1,
				pageSize: 10,
				offset: 100,
				t: this.$t(`views.StoresList`),
				existingStores: true,
			};
		},

		methods: {
			seeStore(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},
			clearSearch() {
				this.searchString = '';
				this.search();
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					getStoreFilter({
						...(this.searchString && { 'filters[name][$containsi]': this.searchString }),
						'filters[isActive]': true,
						populate: 'pictures',
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						sort: 'name',
					})
						.then((response) => {
							this.stores.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},

			search() {
				this.page = 1;
				this.stores = [];

				this.setIsPageLoading(true);
				getStoreFilter({
					'filters[name][$containsi]': this.searchString,
					'filters[isActive]': true,
					populate: 'pictures',
					'pagination[page]': this.page,
					'pagination[pageSize]': this.pageSize,
					sort: 'name',
				})
					.then((response) => {
						this.stores = response.data.data;
						if (this.stores.length == 0) {
							this.existingStores = false;
						} else this.existingStores = true;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: 'Ocorreu um erro',
							position: 'is-top',
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});
			},
		},

		created() {
			this.setIsPageLoading(false);
			getStoreFilter({ 'filters[isActive]': true, populate: 'pictures', 'pagination[page]': this.page, 'pagination[pageSize]': this.pageSize, sort: 'name' })
				.then((response) => {
					console.log(response);
					this.stores = response.data.data;
					if (this.stores.length == 0) {
						this.existingStores = false;
					} else this.existingStores = true;
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
					this.$buefy.toast.open({
						message: 'Ocorreu um erro',
						position: 'is-top',
						type: 'is-danger',
					});
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 100px;
			height: 78px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 80px;
		}
	}

	.search-container .field {
		border: 0.5px solid $grey;
		border-radius: 5px;
		@include from($mobile-l) {
			width: 400px;
			margin: 0 auto;
		}
	}
</style>
