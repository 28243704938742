<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<EditMobileStoreProfile />
		</div>
		<div class="container" v-else>
			<EditStoreDesktopProfile />
		</div>
	</section>
</template>

<script>
	import EditStoreDesktopProfile from '@/components/Desktop/Store/EditProfile.vue';
	import EditMobileStoreProfile from '@/components/Store/EditStoreProfile.vue';
	export default {
		components: {
			EditStoreDesktopProfile,
			EditMobileStoreProfile,
		},

		data() {
			return {};
		},
	};
</script>
