<template>
	<section class="section">
		<div class="container">
			<div class="header-container">
				<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
				<p class="header ml-2">{{ t.notifications }}</p>
			</div>
			<div class="p-3" v-if="todayUnread.length > 0">
				<p class="has-text-dark is-size-5 ml-4">{{ t.today }}</p>

				<div class="notification-card my-3" @click="goToUrl(unread.url)" v-for="(unread, index) in todayUnread" :key="index">
					<div class="media">
						<div class="media-left mt-4 ml-4">
							<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
							<figure
								v-else-if="unread.image"
								class="image is-64x64 is-background-size-cover is-rounded pointer"
								:style="[{ 'background-image': `url(${unread.image.url})` }]"
							></figure>
						</div>
						<div class="media-content mt-7">
							<p class="has-text-grey-darker text is-size-7">{{ unread.text }}</p>
							<p class="has-text-grey text is-size-7">{{ unread.createdAt | momentToday }}</p>
						</div>
						<div class="media-right">
							<div class="badge"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-3">
				<p class="has-text-dark is-size-5 ml-4">{{ t.older }}</p>

				<div class="notification-card my-3" @click="goToUrl(unread.url)" v-for="(unread, index) in pastUnread" :key="index">
					<div class="media">
						<div class="media-left mt-4 ml-4">
							<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
							<figure
								v-else-if="unread.image"
								class="image is-64x64 is-background-size-cover is-rounded pointer"
								:style="[{ 'background-image': `url(${unread.image.url})` }]"
							></figure>
						</div>
						<div class="media-content mt-7">
							<p class="has-text-grey-darker text is-size-7">{{ unread.text }}</p>
							<p class="has-text-grey text is-size-7">{{ unread.createdAt | moment }}</p>
						</div>
						<div class="media-right">
							<div class="badge"></div>
						</div>
					</div>
				</div>
				<div class="notification-card my-3" @click="goToUrl(unread.url)" v-for="(unread, index) in olderNotifications" :key="index">
					<div class="media">
						<div class="media-left mt-4 ml-4">
							<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
							<figure
								v-else-if="unread.image"
								class="image is-64x64 is-background-size-cover is-rounded pointer"
								:style="[{ 'background-image': `url(${unread.image.url})` }]"
							></figure>
						</div>
						<div class="media-content mt-7">
							<p class="has-text-grey-darker text is-size-7">{{ unread.text }}</p>
							<p class="has-text-grey text is-size-7">{{ unread.createdAt | moment }}</p>
						</div>
						<div class="media-right"></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import moment from 'moment';
	import { getNotifications } from '@/api/notifications';
	import parts from '@/mixins/modules/shared/parts';
	export default {
		mixins: [parts],
		data() {
			return {
				todayUnread: [],
				pastUnread: [],
				olderNotifications: [],
				t: this.$t(`views.Notifications`),
			};
		},

		computed: {
			notifications() {
				return this.getNotifications;
			},
		},

		filters: {
			momentToday: function (date) {
				return moment(date).fromNow();
			},

			moment: function (date) {
				return moment(date).format('MMM DD, YYYY, H:mm');
			},
		},

		methods: {
			goToUrl(url) {
				this.setNotificationsVisible(false);
				this.$router.push({ path: url });
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			let today = moment().format('DD-MM-yyyy');

			this.notifications.map((notification) => {
				if (today == moment(notification.createdAt).format('DD-MM-yyyy')) {
					this.todayUnread.push(notification);
				} else this.pastUnread.push(notification);
			});
			console.log(this.todayUnread);
			console.log(this.pastUnread);

			getNotifications({
				sort: ['id:desc'],
				'pagination[pageSize]': 10,
				'filters[wasViewed]': true,
			})
				.then((response) => {
					console.log(response);
					this.olderNotifications = response.data.data;
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		beforeDestroy() {
			console.log('destroyed');
			let ids = [];
			if (!this.showNotifications && this.getNotifications.length > 0) {
				this.getNotifications.map((notification) => {
					ids.push(notification.id);
				});
				this.$socket.emit('notification_read_many', { ids: ids });
				this.setNotifications([]);
				console.log(ids);
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.notification-card {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 48px #0000000a;
		border-radius: 12px;
		width: 100%;
		height: 92px;
		display: flex;
		margin: 0 auto;
		position: relative;
	}

	.text {
		max-width: 100%;
	}

	.badge {
		background-color: #99cca8;
		border-radius: 9999px !important;
		color: white;
		font-size: 9px;
		line-height: 1;
		width: 16px;
		height: 16px;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		position: absolute;
		right: 10px;
		top: -5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.root {
		background-image: url('../../public/img/icons/android-chrome-96x96.png');
	}
</style>
