<template>
	<section style="padding-bottom: 86px">
		<div class="icons-container ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="location" size="is-size-5" type="is-white"></b-icon></div>
			<span class="ml-4" style="width: 100%"
				><a
					:href="'https://www.google.pt/maps/search/' + storeInfo.name + storeInfo.location_address + storeInfo.location_zipCode + storeInfo.location_municipality"
					target="_blank"
					>{{ storeInfo.location_municipality }}, {{ storeInfo.location_country }}</a
				></span
			>
			<b-icon class="arrow" icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
		</div>
		<br />

		<div class="icons-container mt-6 ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="phone" size="is-size-5" type="is-white"></b-icon></div>
			<span class="ml-4" style="width: 100%"
				><a :href="`tel:` + storeInfo.telephone">{{ storeInfo.telephone }}</a></span
			>
			<b-icon class="arrow" icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
		</div>
		<br />

		<div class="icons-container mt-6 ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="email" size="is-size-5" type="is-white"></b-icon></div>
			<span class="ml-4" style="width: 100%"
				><a :href="'mailto:' + storeInfo.email">{{ storeInfo.email }}</a></span
			>
			<b-icon class="arrow" icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
		</div>
		<br />

		<div class="icons-container mt-6 ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="instagram" size="is-size-5" type="is-white"></b-icon></div>
			<span v-if="storeInfo.networks_instagram" class="ml-4 social-container"
				><a :href="storeInfo.networks_instagram" target="_blank">{{ storeInfo.networks_instagram }}</a></span
			>
			<span class="ml-4" v-else>{{ t.noInfo }}</span>
			<b-icon class="arrow" icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
		</div>
		<br />

		<div class="icons-container mt-6 ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="facebook-square" size="is-size-5" type="is-white"></b-icon></div>
			<span v-if="storeInfo.networks_facebook" class="ml-4 social-container"
				><a :href="storeInfo.networks_facebook" target="_blank">{{ storeInfo.networks_facebook }}</a></span
			>
			<span class="ml-4" v-else>{{ t.noInfo }}</span>
			<b-icon class="arrow" icon="chevron-right" type="is-black" custom-size="is-size-3"> </b-icon>
		</div>
		<br />

		<div class="icons-container mt-6 ml-3">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="share" size="is-size-6" type="is-white"></b-icon></div>
			<span class="ml-4 share"><navigator-share :on-error="onError" :on-success="onSuccess" :url="url" :title="storeInfo.name"></navigator-share></span>
		</div>
		<br />
	</section>
</template>

<script>
	import NavigatorShare from 'vue-navigator-share';
	export default {
		props: ['storeInfo'],
		components: {
			NavigatorShare,
		},
		data() {
			return {
				t: this.$t(`components.Store.Contacts`),
			};
		},

		computed: {
			url() {
				return window.location.href;
			},
		},

		methods: {
			onError(err) {
				alert(err);
				console.log(err);
			},
			onSuccess(err) {
				console.log(err);
			},
		},

		mounted() {
			let share = document.getElementsByClassName('share');
			share[0].childNodes[0].innerHTML = this.t.share;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';

	.menu {
		background-color: white;
		height: 100vh;
		width: 308px;
		z-index: 31;
		position: fixed;
		border-bottom-right-radius: 76px;
		border-top-right-radius: 42px;
		box-shadow: 3px 1px 14px 0px #515151;
	}

	.close {
		width: 40px;
		height: 40px;
		background-color: $primary;
		float: right;
		border-radius: 10px;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		background-color: $primary;
	}

	.icon-box {
		background-color: $grey-darker;
		min-width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.icons-container {
		display: inline-flex;
		width: 100%;
	}

	span {
		display: inline-flex;
		align-items: center;
	}

	.arrow {
		position: absolute;
		right: 20px;
		transform: translateY(10px);
	}

	a {
		color: black;
		width: 100%;
		z-index: 20;
	}

	.social-container {
		width: 74%;
		overflow-wrap: break-word;
		word-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
</style>
