<template>
	<div>
		<div class="header-container">
			<b-icon @click.native="goToPage('Homepage')" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
			<p class="header ml-2">{{ t.wallet }}</p>
		</div>

		<p class="has-text-grey-darker mt-6 ml-2">{{ t.method }}</p>

		<div style="display: inline-flex; position: relative">
			<div class="columns is-multiline is-mobile ml-1 mt-2" v-if="cards.length > 0">
				<div
					class="column add-btn mr-2 mb-3"
					v-for="card in cards"
					:key="card.id"
					@click="getDetails(card.billing_details.name, card.card.last4, card.card.exp_month, card.card.exp_year, card.id)"
				>
					<img v-if="card.card.brand == 'mastercard'" class="img" :src="require('@/assets/svg/mc_symbol.svg')" alt="root" />
					<img v-else-if="card.card.brand == 'visa'" class="img" :src="require('@/assets/svg/visa_blue_symbol.svg')" alt="root" />
					<p v-else class="has-text-black is-size-6 mt-7">{{ card.card.brand }}</p>
				</div>

				<div class="add-card mb-4 center-inside">
					<b-button class="add-btn" @click="addCard"
						><b-icon pack="rt" class="mt-6" style="margin-left: 1px" type="is-black" custom-size="is-size-5" icon="plus"></b-icon>
						<p>{{ t.add }}</p></b-button
					>
				</div>
			</div>

			<div v-else class="add-card ml-2 mt-5 center-inside">
				<b-button class="add-btn" @click="addCard"
					><b-icon pack="rt" class="mt-6" style="margin-left: 1px" type="is-black" custom-size="is-size-5" icon="plus"></b-icon>
					<p>{{ t.add }}</p></b-button
				>
			</div>
		</div>

		<p v-if="name" class="has-text-grey-darker mt-6 ml-2">{{ t.creditCard }}</p>

		<div v-if="name" class="card-details mt-2">
			<p class="has-text-white is-size-5">{{ name }}</p>
			<p class="has-text-white is-size-5">**** **** **** {{ number }}</p>

			<div style="display: flex; justify-content: end">
				<b-icon class="is-clickable mr-5" pack="rt" type="is-black" custom-size="is-size-6 " icon="remove" @click.native="removeCard(id)" />
				<p class="has-text-white is-size-6 has-text-right">{{ expire }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { addCard, getCard, removeCard } from '@/api/paymentsMethod';
	export default {
		data() {
			return {
				cards: [],
				name: null,
				number: null,
				expire: null,
				id: null,
				t: this.$t(`components.Wallet`),
			};
		},
		methods: {
			addCard() {
				let url = {
					success_url: this.$route.path,
					cancel_url: this.$route.path,
				};
				addCard(url)
					.then((response) => {
						console.log(response);
						window.location.href = response.data.url;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			removeCard(id) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteCard,
					message: this.t.deleteCardMessage,
					confirmText: this.t.deleteCard,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.setIsPageLoading(true);
						removeCard(id)
							.then((response) => {
								console.log(response);
								this.name = null;
								this.number = null;
								this.expire = null;
								this.id = null;
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.$buefy.toast.open({
									message: this.t.deleted,
									position: 'is-top',
									type: 'is-primary',
								});
								getCard()
									.then((response) => {
										console.log(response);
										this.cards = response.data;
									})
									.catch((error) => {
										console.log(error);
									});

								this.setIsPageLoading(false);
							});
					},
				});
			},

			getDetails(name, number, month, year, id) {
				this.name = name;
				this.number = number;
				this.expire = month + '/' + year;
				this.id = id;
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getCard()
				.then((response) => {
					console.log(response);
					this.cards = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => this.setIsPageLoading(false));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.add-btn {
		min-width: 100px;
		height: 72px;
		border-radius: 14px;
		border: solid 1px $primary;
		background-color: white;
		font-size: 0.75rem;
		text-align: center;

		@include until($mobile-s) {
			min-width: 90px;
		}
	}

	.card-details {
		background: rgb(95, 210, 128);
		background: linear-gradient(25deg, rgba(95, 210, 128, 1) 18%, rgba(174, 214, 186, 1) 100%);
		width: 90%;
		height: 100px;
		padding: 1rem;
		border-radius: 20px;
	}

	.img {
		height: 50px;
		display: block;
		margin: 0 auto;
		width: 50%;
	}
</style>
