<template>
	<section class="section is-relative is-fullheight is-flex is-flex-direction-column is-justify-content-space-between">
		<div>
			<p class="is-size-4 has-text-weight-normal">{{ t.root }}</p>
			<p class="subtitle mt-8 is-size-3">{{ t.accountType }}</p>
			<p class="is-size-5 has-text-grey">
				{{ t.youHave }} <span class="has-text-primary">{{ t.sell }}</span> {{ t.or }} <span class="has-text-primary">{{ t.buy }}</span
				>?
			</p>
		</div>
		<div class="mt-8">
			<b-image class="img" :src="require('@/assets/svg/root.svg')" alt="root"></b-image>
		</div>
		<div>
			<b-button expanded type="is-primary" size="is-large" @click="goToLogin('store')" class="mt-4">
				<span class="is-size-5">{{ t.seller }}</span>
			</b-button>
			<b-button expanded type="is-primary" size="is-large" @click="goToLogin('authenticated')" class="mt-6">
				<span class="is-size-5">{{ t.buyer }}</span>
			</b-button>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.AccountType`),
			};
		},

		methods: {
			goToLogin(type) {
				this.addAccount(type);
				this.goToPage('Login');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.subtitle {
		width: 200px;
	}

	.img {
		width: 70%;
		margin: 0 auto;
		@include until($mobile-m) {
			width: 60%;
			margin: 0 auto;
		}

		@include from($mobile-l) {
			width: 40%;
			margin: 0 auto;
		}

		@include from($desktop) {
			width: 20%;
			margin: 0 auto;
		}
	}
</style>
