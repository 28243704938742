import { get, post, remove } from '@/utils/http';

export function addCard(url) {
	return post('/api/stripe/payment_methods/setup', url);
}

export function getCard() {
	return get('/api/stripe/payment_methods?limit=20&type=card');
}

export function removeCard(id) {
	return remove('/api/stripe/payment_methods?payment_method=' + id);
}
