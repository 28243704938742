<template>
	<section>
		<div class="header-container pt-8 px-6">
			<b-icon @click.native="$router.go(-1)" icon="chevron-left" type="is-grey-darker" size="is-medium"></b-icon>
			<p class="header ml-2">{{ t.settings }}</p>
		</div>

		<div class="px-6 mt-10">
			<form @submit.prevent="update">
				<b-field :label="t.shipping" :type="decimalError ? 'is-danger' : ''" :message="decimalError ? t.decimalError : ''">
					<b-input
						class="input"
						size="is-medium"
						min="15"
						step="0.01"
						type="number"
						v-model.trim="store.data.shippingMinValue"
						required
						@input="verifyDecimalPlaces"
					></b-input>
				</b-field>
				<b-field :label="t.maximum" :type="decimalErroMaximum ? 'is-danger' : ''" :message="decimalErroMaximum ? t.decimalError : ''">
					<b-input
						class="input"
						min="1"
						max="100"
						step="1"
						size="is-medium"
						type="number"
						v-model.trim="store.data.userMaxValue"
						required
						@input="verifyDecimalPlacesMaximum"
					></b-input>
				</b-field>

				<!-- <b-field :label="t.privacy" class="mt-10" @click.native="openTerms"> </b-field> -->

				<div @click="openTerms" class="mt-10">
					{{ t.privacy }}
					<b-icon icon="chevron-right" type="is-black" class="arrow-terms" custom-size="is-size-4"> </b-icon>
				</div>

				<div class="mt-8">
					<div>{{ t.support }}: <a href="mailto:progressiveroottechnology@gmail.com">progressiveroottechnology@gmail.com</a></div>
				</div>

				<div class="button-container mt-10">
					<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn">{{ t.save }}</b-button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	import { updateStore } from '@/api/store';
	export default {
		data() {
			return {
				t: this.$t(`components.Store.Settings`),
				isLoading: false,
				store: {
					data: {
						userMaxValue: 0,
						shippingMinValue: null,
					},
				},

				decimalError: false,
				decimalErroMaximum: false,
			};
		},

		methods: {
			update() {
				this.isLoading = true;
				if (this.decimalError || this.decimalErroMaximum) {
					this.isLoading = false;
				} else {
					updateStore(this.getUser.store.id, this.store)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.updated,
								position: 'is-top',
								type: 'is-primary',
							});
							this.isLoading = false;
							this.goToPage('Homepage');
						})
						.catch((error) => {
							this.isLoading = false;
							console.log(error);
						});
				}
			},

			deleteAccount() {
				this.$buefy.dialog.confirm({
					title: this.t.deleteAccount,
					message: this.t.deleteAccountText,
					confirmText: this.t.deleteAccount,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {},
				});
			},

			verifyDecimalPlaces(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalError = true;
				} else this.decimalError = false;
			},

			verifyDecimalPlacesMaximum(val) {
				let decimalPlaces;
				decimalPlaces = val.toString().split('.')[1];

				if (decimalPlaces != undefined && decimalPlaces.length > 2) {
					this.decimalErroMaximum = true;
				} else this.decimalErroMaximum = false;
			},

			openTerms() {
				let routeData = this.$router.resolve({ name: 'Terms' });
				window.location.assign(routeData.href, '_blank');
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			this.store.data.userMaxValue = this.getUser.store.userMaxValue;
			this.store.data.shippingMinValue = this.getUser.store.shippingMinValue;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	section {
		position: relative;
		height: 85vh;
	}

	.header-container {
		display: inline-flex;
	}

	.header {
		font-size: 1.429rem;
	}

	.button-container {
		width: 100%;
		margin-top: 30px;
		margin-bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	/deep/ input.input.is-medium {
		height: 40px !important;
		border: none;
	}

	.arrow-terms {
		transform: translateY(4px);
	}
</style>
