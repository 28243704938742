<template>
	<section v-if="vouchers.length > 0">
		<div class="mb-4 items-container center-inside" v-for="(voucher, index) in vouchers" :key="index">
			<img v-if="voucher.store.pictures" :src="voucher.store.pictures[0].url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-2" style="margin: auto">
				<p class="has-text-dark user-name">{{ voucher.title }}</p>
				<p class="has-text-dark user-name">{{ t.discount }} {{ voucher.discount }}%</p>
				<p class="has-text-dark user-name">{{ voucher.startAt | moment() }} - {{ voucher.endAt | moment() }}</p>
			</div>
			<div>
				<b-icon class="is-clickable trash-btn mr-2" @click.native="remove(voucher.id, index)" pack="rt" icon="remove" size="is-size-5" type="is-red"></b-icon> <br />
				<b-button v-if="isApplied(voucher.startAt, voucher.endAt)" class="has-background-primary has-text-white btn mr-2 mt-2">{{ t.applied }}</b-button>
				<b-button v-else class="has-background-danger has-text-white btn mr-2 mt-2">{{ t.notApplied }}</b-button>
			</div>
		</div>
	</section>

	<section v-else>
		<p class="has-text-primary mt-10 is-size-4">{{ t.noVouchers }}</p>
	</section>
</template>

<script>
	import { getVouchers, deleteVoucher } from '@/api/marketing';
	import moment from 'moment';
	export default {
		data() {
			return {
				vouchers: [],
				page: 1,
				pageSize: 10,
				endScroll: false,
				t: this.$t(`components.ListVoucher`),
			};
		},

		filters: {
			moment: function (date) {
				return moment(date).format('DD/MM/YYYY');
			},
		},

		methods: {
			remove(id, index) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteVoucher,
					message: this.t.deleteMessage,
					confirmText: this.t.deleteVoucher,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;
						deleteVoucher(id)
							.then((response) => {
								console.log(response);
								this.$buefy.toast.open({
									message: this.t.deletedSucess,
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isLoading = false;
							});
						this.vouchers.splice(index, 1);
					},
				});
			},

			isApplied(startDate, endDate) {
				let date = moment().format('YYYY-MM-DD');

				return moment(date).isBetween(startDate, endDate, undefined, '[]');
			},

			handleScroll(e) {
				if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.endScroll == false) {
					this.endScroll = true;

					if (this.endScroll) {
						this.page++;

						getVouchers({
							'filters[store]': this.getUser.store.id,
							'pagination[page]': this.page,
							'pagination[pageSize]': this.pageSize,
						})
							.then((response) => {
								console.log(response);
								this.vouchers.push(...response.data.data);
								this.endScroll = false;
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.setIsPageLoading(false);
							});
					}
				}
			},
		},
		created() {
			this.setIsPageLoading(true);
			window.addEventListener('scroll', this.handleScroll);
			getVouchers({
				'filters[store]': this.getUser.store.id,
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
			})
				.then((response) => {
					console.log(response);
					this.vouchers = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	img,
	.no-img {
		width: 96px;
		height: 80px;
		border-radius: 22px;
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 12px;
		height: 80px;

		@include until($mobile-s) {
			width: auto;
		}
	}

	.order {
		font-size: 0.716rem;
	}

	.order-items,
	.user-name {
		font-size: 0.82rem;
	}

	.price {
		margin-left: 54px;
		@include until($mobile-s) {
			margin-left: 44px;
		}
	}

	.btn {
		width: 73px;
		font-size: 0.716rem;
		float: right;
		@include from($mobile-s) {
			margin-left: 14px;
		}
	}

	.trash-btn {
		float: right;
	}
</style>
