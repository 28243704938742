<template>
	<div>
		<div class="header-container pt-8 px-6">
			<b-icon @click.native="goToPage('Homepage')" class="is-clickable" icon="chevron-left" type="is-grey" custom-size="is-size-4"></b-icon>
			<p @click="goToPage('Homepage')" class="header ml-2 is-clickable has-text-grey">{{ t.back }}</p>
		</div>

		<p class="px-6 mt-2 is-size-3 has-text-grey-darker ml-2">{{ t.wallet }}</p>

		<p class="px-6 mt-10 is-size-6 has-text-dark ml-2">{{ t.method }}</p>

		<div style="display: inline-flex; position: relative">
			<div class="columns is-multiline ml-2 mt-2" v-if="cards.length > 0">
				<div class="column is-two-fifths credit-card mt-3 ml-5" v-for="card in cards" :key="card.id">
					<b-image v-if="card.card.brand == 'mastercard'" class="img" :src="require('@/assets/svg/mc_symbol.svg')" alt="root"></b-image>
					<b-image v-else-if="card.card.brand == 'visa'" class="img" :src="require('@/assets/svg/visa_symbol.svg')" alt="root"></b-image>

					<p v-else class="has-text-white is-size-3">{{ card.card.brand }}</p>
					<p class="has-text-white is-size-4 mt-2">**** **** **** {{ card.card.last4 }}</p>

					<div style="display: inline-flex" class="mt-15">
						<b-icon @click.native="removeCard(card.id)" class="is-clickable" pack="rt" type="is-black" custom-size="is-size-4 ml-2" icon="remove" />
						<p class="has-text-white is-size-5" style="margin-left: 170px">{{ card.card.exp_month }}/ {{ card.card.exp_year }}</p>
					</div>
				</div>

				<div class="add-card ml-6 mt-5 center-inside">
					<b-button @click="addCard" class="add-btn"><b-icon pack="rt" class="mt-3" type="is-white" custom-size="is-size-2" icon="plus"></b-icon></b-button>
				</div>
			</div>

			<div v-else class="add-card ml-6 mt-5 center-inside">
				<b-button @click="addCard" class="add-btn"><b-icon pack="rt" class="mt-3" type="is-white" custom-size="is-size-2" icon="plus"></b-icon></b-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { addCard, getCard, removeCard } from '@/api/paymentsMethod';
	export default {
		data() {
			return {
				cards: [],
				t: this.$t(`components.Desktop.Wallet`),
			};
		},
		methods: {
			addCard() {
				let url = {
					success_url: this.$route.path,
					cancel_url: this.$route.path,
				};
				addCard(url)
					.then((response) => {
						console.log(response);
						window.location.href = response.data.url;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			removeCard(id) {
				this.$buefy.dialog.confirm({
					title: this.t.deleteCard,
					message: this.t.deleteCardMessage,
					confirmText: this.t.deleteCard,
					cancelText: this.t.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.setIsPageLoading(true);
						removeCard(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.$buefy.toast.open({
									message: this.t.deleted,
									position: 'is-top',
									type: 'is-primary',
								});
								getCard()
									.then((response) => {
										console.log(response);
										this.cards = response.data;
									})
									.catch((error) => {
										console.log(error);
									});

								this.setIsPageLoading(false);
							});
					},
				});
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			getCard()
				.then((response) => {
					console.log(response);
					this.cards = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => this.setIsPageLoading(false));
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-container {
		display: inline-flex;
	}

	.add-card {
		width: 150px;
		height: 189px;
		background-color: $white-ter;
		border-radius: 21px;
	}

	.add-btn {
		background-color: $primary;
		width: 89px;
		height: 89px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 99999px;
		border: none;
	}

	.credit-card {
		width: 300px;
		height: 201px;
		background-color: $primary;
		border-radius: 20px;
		padding: 1rem;
		background: rgb(86, 9, 255);
		background: linear-gradient(160deg, rgba(86, 9, 255, 1) 0%, rgba(130, 79, 255, 1) 29%, rgba(140, 70, 255, 1) 54%, rgba(221, 99, 250, 1) 75%, rgba(238, 107, 249, 1) 100%);
	}

	.img {
		width: 60px;
		height: 60px;
	}
</style>
