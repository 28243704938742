<template>
	<section>
		<UserStyle v-if="getStep == 0" />
		<!-- <UserCloth v-else /> -->
	</section>
</template>

<script>
	import UserStyle from '@/components/User/ChooseUserStyle.vue';
	import UserCloth from '@/components/User/UserCloth.vue';
	export default {
		components: {
			UserStyle,
			UserCloth,
		},

		data() {
			return {
				step: 0,
			};
		},

		computed: {
			getStep() {
				return this.$store.getters['preferencesStep/getStep'];
			},
		},

		created() {
			console.log(this.getStep);
		},
	};
</script>
