<template>
	<div v-if="this.$route.path != '/interests'">
		<p class="header-subtitle pt-8">{{ t.tell }}</p>
		<p class="header-subtitle mt-2">{{ t.choose }}</p>

		<div class="options-container mt-17">
			<b-field v-for="category in categories" :key="category.id">
				<b-checkbox-button class="checkbox mt-8" size="is-large" v-model="userCategories.favoriteCategories" :native-value="category.id" type="is-black">
					<span class="btn-text">{{ category.name }}</span>
				</b-checkbox-button>
			</b-field>
		</div>

		<p v-show="showError" class="has-text-centered has-text-danger mt-8">{{ t.selectError }}</p>

		<div class="bottom-container px-6">
			<div class="button-container has-text-centered">
				<b-button type="is-primary" :loading="isLoading" class="btn" @click="completeRegister">{{ t.save }}</b-button>
			</div>
		</div>
	</div>
	<div v-else class="container">
		<p class="header-subtitle mt-2">{{ t.choose }}</p>

		<div class="options-container mt-17">
			<b-field v-for="category in categories" :key="category.id">
				<b-checkbox-button class="checkbox mt-8" size="is-large" v-model="userCategories.favoriteCategories" :native-value="category.id" type="is-black">
					<span class="btn-text">{{ category.name }}</span>
				</b-checkbox-button>
			</b-field>
		</div>

		<p v-show="showError" class="has-text-centered has-text-danger mt-8">{{ t.selectError }}</p>

		<div class="mt-20 px-6">
			<div class="has-text-centered">
				<b-button type="is-primary" :loading="isLoading" class="btn-edit" @click="completeRegister">{{ t.save }}</b-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCategories } from '@/api/userCategories';
	import { updatePreferences } from '@/api/user';
	export default {
		data() {
			return {
				categories: [],
				t: this.$t(`components.Desktop.User.ChooseStyle`),
				showError: false,
				userCategories: {
					favoriteCategories: [],
				},
				isLoading: false,
			};
		},

		methods: {
			completeRegister() {
				this.isLoading = true;
				if (this.userCategories.favoriteCategories.length == 0) {
					this.showError = true;
					this.isLoading = false;
				} else {
					this.showError = false;
					updatePreferences(this.userCategories)
						.then((response) => {
							console.log(response);
							this.addUser(response.data);
							this.setMainCategorySelected(this.userCategories.favoriteCategories);
							this.isLoading = false;
							this.$store.commit('desktopLoginStep/setStep', 0);
							this.setIsLoginReset(true);
							this.goToPage('Homepage');
						})
						.catch((error) => {
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
							this.isLoading = false;
							console.log(error);
						});
				}
			},
		},

		created() {
			if (this.getUser) {
				if (this.getUser.favoriteCategories == undefined) {
					this.userCategories.favoriteCategories = [];
				} else {
					this.getUser.favoriteCategories.map((category) => {
						this.userCategories.favoriteCategories.push(category.id);
					});
				}
			}

			getCategories({
				'filters[level]': 0,
			})
				.then((response) => {
					this.categories = response.data.data.filter((category) => category.id != 109);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.sign-text {
		font-size: 1.8rem;
	}

	/deep/ .checkbox {
		border-radius: 20px;
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		bottom: 60px;
	}

	.btn {
		width: 100%;
		height: 52px;
		border-radius: 14px;
	}
	.btn-edit {
		width: 400px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
		margin-top: 100px;
	}

	/deep/ .button {
		border-radius: 20px;
	}

	.btn-signin {
		width: 360px;
		height: 52px;
		border-radius: 22px;
	}

	.center-inside {
		justify-content: space-between !important;
		width: 540px;
	}

	.header-container {
		display: inline-flex;
		position: fixed;
		top: 0;
	}

	.pointer {
		cursor: pointer;
	}

	/deep/ .checkbox {
		border-radius: 10px;
		width: 302px;
		@include until($mobile-s) {
			width: 100%;
		}
	}

	.btn-text {
		font-size: 1rem;
		font-weight: 600;
	}

	.options-container {
		text-align: center;
	}

	.bottom-container {
		position: absolute;
		width: 80%;
		bottom: 60px;
	}

	.button-container {
		width: 100%;
	}

	.header-subtitle {
		font-size: 1.5rem;
	}
</style>
