<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileShipping />
		</div>
		<div class="container" v-else>
			<DesktopShipping />
		</div>
	</section>
</template>

<script>
	import DesktopShipping from '@/components/Desktop/Shipping/Shipping.vue';
	import MobileShipping from '@/components/Shipping/Shipping.vue';
	export default {
		components: {
			DesktopShipping,
			MobileShipping,
		},

		data() {
			return {};
		},
	};
</script>