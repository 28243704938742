<template>
	<section>
		<div class="is-fixed-top pt-8 px-3"><b-icon @click.native="goToPage('Login')" icon="chevron-left" type="is-grey-darker" size="is-medium"> </b-icon></div>
		<div class="px-6 mt-14 title">
			<p v-if="getUser">{{ t.message }} {{ getUser.email }}</p>
			<p v-else>{{ t.message }} {{ getRecoverEmail }}</p>

			<form @submit.prevent="verify">
				<div class="number-container">
					<input class="input mt-12" type="number" ref="input1" maxlength="1" @input="firstNumber" :value="numberOne" required />
					<input class="input mt-12" type="number" ref="input2" maxlength="1" @input="secondNumber" :value="numberTwo" required />
					<input class="input mt-12" type="number" ref="input3" maxlength="1" @input="thirdNumber" :value="numberThree" required />
					<input class="input mt-12" type="number" ref="input4" maxlength="1" @input="forthNumber" :value="numberFour" required />
				</div>

				<div class="button-container has-text-centered">
					<b-button type="is-primary" native-type="submit" :loading="isLoading" class="btn">{{ t.verify }}</b-button>
				</div>
			</form>

			<div class="has-text-centered mt-14">
				<span @click="resend">{{ t.resend }}</span>
			</div>
		</div>
	</section>
</template>

<script>
	import { login, activateAccount, resendActivation } from '@/api/auth';
	export default {
		data() {
			return {
				t: this.$t(`components.Auth.ConfirmEmailCode`),
				email: null,
				number: null,
				numberOne: null,
				numberTwo: null,
				numberThree: null,
				numberFour: null,
				isLoading: false,
			};
		},
		methods: {
			firstNumber(event) {
				const value = event.target.value;

				if (String(value).length <= 1) {
					this.numberOne = value;
				}
				this.$forceUpdate();
				this.$refs.input2.focus();
			},

			secondNumber(event) {
				const value = event.target.value;

				if (String(value).length <= 1) {
					this.numberTwo = value;
				}
				this.$forceUpdate();
				this.$refs.input3.focus();
			},
			thirdNumber(event) {
				const value = event.target.value;

				if (String(value).length <= 1) {
					this.numberThree = value;
				}
				this.$forceUpdate();
				this.$refs.input4.focus();
			},
			forthNumber(event) {
				const value = event.target.value;

				if (String(value).length <= 1) {
					this.numberFour = value;
				}
				this.$forceUpdate();
			},

			async verify() {
				this.number = this.numberOne.concat(this.numberTwo, this.numberThree, this.numberFour);
				this.isLoading = true;
				await activateAccount(this.number)
					.then((response) => {
						console.log(response);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: this.t.accountActivated,
							position: 'is-top',
							type: 'is-primary',
							duration: 4000,
						});

						let loginForm = {
							identifier: null,
							password: this.getPw,
						};
						if (this.getUser?.email) {
							loginForm.identifier = this.getUser.email;
						} else loginForm.identifier = this.getRecoverEmail;

						login(loginForm)
							.then((response) => {
								this.isLoading = false;
								this.wrongCredentials = false;
								console.log(response);
								this.onLogin(response);
								this.$socket.connect();
								this.addPw(null);
								if (response.data.user.favoriteCategories.length == 0 && response.data.user.role.type == 'authenticated') {
									this.goToPage('ChooseUserStyle');
								} else this.goToPage('Homepage');
							})
							.catch((error) => {
								console.log(error.response);
								this.isLoading = false;

								this.$buefy.toast.open({
									message: this.$t('shared.error'),
									position: 'is-top',
									type: 'is-danger',
								});
							});
					})
					.catch((error) => {
						console.log(error.response);
						this.isLoading = false;
						if (error.response.data.error.message == 'confirmation.invalid') {
							this.$buefy.toast.open({
								message: this.t.errorCode,
								position: 'is-top',
								type: 'is-danger',
							});
						}
					});
			},

			resend() {
				console.log(this.getRecoverEmail);
				let data = {
					email: null,
				};
				if (this.getUser?.email) {
					data.email = this.getUser.email;
				} else data.email = this.getRecoverEmail;
				this.isLoading = true;
				resendActivation(data)
					.then((response) => {
						console.log(response);

						this.$buefy.toast.open({
							message: this.t.emailSent,
							position: 'is-top',
							type: 'is-primary',
							duration: 4000,
						});
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.title {
		font-size: 1.35rem;
		font-weight: 500;
	}

	.input {
		border: none;
		background: none;
		border-bottom: solid 1px $grey;
		width: 50px;
		padding: 0;
		text-align: center;
		font-weight: bold;
		font-size: 1.35rem;
	}

	.number-container {
		display: flex;
		justify-content: space-between;
		@include from($mobile-l) {
			width: 360px;
			margin: 0 auto;
		}
	}

	.button-container {
		width: 100%;
		margin-top: 80px;
		@include from($mobile-s) {
			margin-top: 80px;
		}
		@include from($mobile-l) {
			width: 50%;
			margin: 80px auto;
		}
	}

	.btn {
		width: 100%;
		height: 46px;
		border-radius: 14px;
	}

	span {
		color: $primary;
		font-size: 1rem;
	}
</style>
