<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<EditMobileUserProfile />
		</div>
		<div v-else class="container">
			<EditDesktopUserProfile />
		</div>
	</section>
</template>

<script>
	import EditMobileUserProfile from '@/components/User/EditUserProfile.vue';
	import EditDesktopUserProfile from '@/components/Desktop/User/EditUserProfile.vue';
	export default {
		components: {
			EditMobileUserProfile,
			EditDesktopUserProfile,
		},

		data() {
			return {};
		},
	};
</script>
