<template>
	<section class="section">
		<div class="container" v-if="IS_MOBILE">
			<MobileOrderDetail/>
		</div>
		<div class="container" v-else>
			<DesktopOrderDetail />
		</div>
	</section>
</template>

<script>
	import DesktopOrderDetail from '@/components/Desktop/Orders/OrderDetail.vue';
	import MobileOrderDetail from '@/components/Orders/OrderDetail.vue'
	
	export default {
		components: {
			DesktopOrderDetail,
			MobileOrderDetail
		},

		data() {
			return {};
		},
	};
</script>
