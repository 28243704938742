<template>
	<div>
		<b-tabs size="is-medium" expanded :animateInitially="true">
			<b-tab-item :label="totalOrders">
				<ListOrders @getTotalOrders="getTotalOrders" />
			</b-tab-item>
			<b-tab-item :label="totalRefunds"><ListRefunds @getTotalRefunds="getTotalRefunds" /> </b-tab-item>
		</b-tabs>
	</div>
</template>

<script>
	import ListOrders from '@/components/Orders/ListOrders.vue';
	import ListRefunds from '@/components/Orders/ListRefunds.vue';
	export default {
		components: {
			ListOrders,
			ListRefunds,
		},

		data() {
			return {
				totalOrders: '',
				totalRefunds: '',
				t: this.$t(`views.StoreOrders`),
			};
		},
		methods: {
		getTotalOrders(val) {
				this.totalOrders = this.t.orders + '(' + val + ')';
			},
			getTotalRefunds(val) {
				this.totalRefunds = this.t.refunds + '(' + val + ')';
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}
</style>
