<template>
	<section :key="renderKey">
		<div v-if="IS_MOBILE">
			<div class="media px-6 center-inside mb-6">
				<div class="media-left">
					<figure class="mx-auto image is-96x96 is-1by1 pt-0 has-radius" style="border: 1px solid black">
						<img v-if="getUser.store.pictures" class="profile-photo has-radius" :src="getUser.store.pictures[0].url" />
						<b-icon v-else icon="account-outline" type="is-black" custom-size="is-size-2"> </b-icon>
					</figure>
				</div>
				<div class="media-content">
					<p class="name">{{ getUser.store.name }}</p>
					<p class="address has-text-grey">{{ getUser.store.location_municipality }}, {{ getUser.store.location_country }}</p>
					<p v-if="getUser.store.description != null" class="description has-text-grey">{{ getUser.store.description }}</p>
				</div>
				<div class="media-right"></div>
			</div>

			<span class="px-8 dashboard-title">Dashboard</span>
			<div class="dashboard px-8 mt-0">
				<div>
					<div class="columns is-mobile mt-6">
						<div class="column is-half card mr-2">
							<div class="dashboard-icon has-background-violet center-inside"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.viewers }}</p>
								<p class="ml-4 is-size-6">{{ storeViews }}</p>
							</div>
						</div>
						<div class="column is-half card ml-2">
							<div class="dashboard-icon has-background-red center-inside"><b-icon pack="rt" icon="box1" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.products }}</p>
								<p class="ml-4 is-size-6">{{ productsSold }}</p>
							</div>
						</div>
					</div>

					<div class="columns is-mobile mt-6">
						<div class="column is-half card mr-2">
							<div class="dashboard-icon has-background-cyan center-inside"><b-icon pack="rt" icon="box1" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.orders }}</p>
								<p class="ml-4 is-size-6">{{ orders.length }}</p>
							</div>
						</div>
						<div class="column is-half card ml-2">
							<div class="dashboard-icon has-background-yellow center-inside"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.viewed }}</p>
								<p class="ml-4 is-size-6" v-if="liked">{{ views }}</p>
								<p class="ml-4 is-size-6" v-else>{{ t.noProducts }}</p>
							</div>
						</div>
					</div>

					<div class="columns is-mobile mt-6">
						<div class="column is-half card mr-2">
							<div class="dashboard-icon has-background-purple center-inside"><b-icon icon="heart" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.liked }}</p>
								<p class="ml-4 is-size-6" v-if="liked">{{ liked }}</p>
								<p class="ml-4 is-size-6" v-else>{{ t.noProducts }}</p>
							</div>
						</div>
						<div class="column is-half card ml-2">
							<div class="dashboard-icon has-background-primary center-inside"><b-icon pack="rt" icon="star" size="is-size-4" type="is-white"></b-icon></div>
							<div>
								<p class="ml-4" style="font-size: 0.65rem">{{ t.reviewed }}</p>
								<p class="ml-4 is-size-6" v-if="reviews">{{ reviews }}</p>
								<p class="ml-4 is-size-6" v-else>{{ t.noProducts }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="px-6">
				<div class="level is-mobile mt-10">
					<div class="level-left">
						<div class="level-item">
							<p class="is-size-5 ml-2 has-text-grey-darker">{{ totalAmount.toFixed(2) }} €</p>
						</div>
					</div>
					<div class="level-right">
						<div class="level-item mr-4">
							<b-field>
								<b-select @input="changeGraph" v-model="graphFilter">
									<option value="month">{{ t.month }}</option>
									<option value="year">{{ t.year }}</option>
								</b-select>
							</b-field>
						</div>
					</div>
				</div>

				<highcharts class="mt-10" :options="chartOptions"></highcharts>
			</div>

			<RegisterStoreModal @close="close" :isModalOpen="openModal" />
		</div>
		<div v-else>
			<DesktopDashboard />
		</div>
	</section>
</template>

<script>
	import DesktopDashboard from '@/components/Desktop/Store/Dashboard.vue';
	import RegisterStoreModal from '@/components/Store/RegisterStoreModal.vue';
	import { stripeAccountLink } from '@/api/store';
	import { getLoggedUser } from '@/api/user';
	import { getUserLineGraph } from '@/api/orders';
	import { getProducts, getStoreInfo } from '@/api/dashboard';
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';

	exportingInit(Highcharts);

	export default {
		components: {
			DesktopDashboard,
			RegisterStoreModal,
		},
		data() {
			return {
				openModal: false,
				renderKey: 0,
				graphFilter: 'month',
				orders: [],
				totalAmount: 0,
				reviews: null,
				views: 0,
				sold: 0,
				orders: 0,
				liked: null,
				storeViews: 0,
				productsSold: 0,
				t: this.$t(`components.Desktop.Store.Dashboard`),

				chartOptions: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'areaspline',
					},
					title: {
						text: '',
					},

					xAxis: {
						categories: [],
						gridLineWidth: 0,
					},
					yAxis: {
						title: {
							text: '',
						},
						gridLineWidth: 1,
					},
					legend: {
						layout: 'vertical',
						align: 'left',
						verticalAlign: 'bottom',
						x: 150,
						y: 100,
						floating: true,
						borderWidth: 1,
						backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
					},
					tooltip: {
						headerFormat: '<table><span style="font-size:12px;text-align:center">{point.key}</span></table>',
						pointFormat: '<p style="font-size:15px"><b>{point.y:1f} €</b></p>',

						shared: true,
						useHTML: true,
						backgroundColor: '#99cca8',
						style: {
							color: 'white',
							textAlign: 'center',
						},
					},
					credits: {
						enabled: false,
					},
					plotOptions: {
						areaspline: {
							fillOpacity: 0.0,
						},
					},
					series: [
						{
							name: '',
							data: [],
							color: '#99cca8',
						},
					],
				},
			};
		},
		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;
			},

			close() {
				this.openModal = false;
				this.renderKey++;
			},

			changeGraph() {
				this.setIsPageLoading(true);
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						console.log(response);

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},

		mounted() {
			this.setIsPageLoading(true);
			console.log(this.getUser.store.vat);

			if (this.getUser.store.vat == null) {
				this.openModal = true;
				this.setIsPageLoading(false);
			}

			if (!this.openModal) {
				getUserLineGraph(this.graphFilter)
					.then((response) => {
						console.log(response);

						this.chartOptions.xAxis.categories = response.data.data.categories;
						this.chartOptions.series[0].data = response.data.data.data;
						this.chartOptions.series[0].data.map((order) => {
							this.totalAmount += order;
						});
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});

				getProducts({
					'filters[store][id]': this.getUser.store.id,
					sort: ['num_reviews:desc'],
					'pagination[pageSize]': 1,
				}).then((response) => {
					console.log(response);
					if (response.data.data.length > 0) {
						this.reviews = response.data.data[0].title;
					}
				});

				getProducts({
					'filters[store][id]': this.getUser.store.id,
					sort: ['views:desc'],
					'pagination[pageSize]': 1,
				}).then((response) => {
					console.log(response);
					if (response.data.data.length > 0) {
						this.views = response.data.data[0].title;
					}
				});
				getProducts({
					'filters[store][id]': this.getUser.store.id,
					sort: ['num_likes:desc'],
					'pagination[pageSize]': 1,
				}).then((response) => {
					console.log(response);
					if (response.data.data.length > 0) {
						this.liked = response.data.data[0].title;
					}
				});

				getStoreInfo(this.getUser.store.id)
					.then((response) => {
						this.storeViews = response.data.data.views;
						this.orders = response.data.data.orders;
						this.productsSold = response.data.data.products_sold;
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});

				getLoggedUser()
					.then((response) => {
						console.log(response);

						this.addUser(response.data);
						if (!this.getUser.store.isActive && this.getUserType == 'store' && this.getUser.store.vat) {
							stripeAccountLink()
								.then((response) => {
									console.log(response);
									window.location.href = response.data.url;
								})
								.catch((error) => {
									console.log(error);
								});
						}
					})
					.catch((error) => {
						console.log(error.response);
					});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 80px;
		height: auto;
		border-radius: 12px;
		background-color: $white;
		border: solid 1px black;
	}

	.name {
		font-size: 1rem;
	}

	.address {
		font-size: 0.857rem;
	}

	.description {
		font-size: 0.714rem;
	}

	.card {
		width: 164px !important;
		height: 55px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #00000014;
		border-radius: 5px;
		display: inline-flex;

		@include until($mobile-s) {
			width: 140px !important;
		}
	}

	.dashboard-icon {
		width: 32px;
		height: 32px;
		border-radius: 5px;
	}

	.dashboard {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.dashboard-title {
		text-align: left;

		@include from($mobile-l) {
			justify-content: center;
			display: flex;
		}
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $primary !important;
	}

	.profile-photo {
		height: 80px;
		object-fit: cover;
	}

	.dashboard p {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 95px;
	}
</style>
