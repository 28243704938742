<template>
	<section>
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<div class="is-flex pt-8">
						<b-icon @click.native="$router.go(-1)" class="is-clickable mt-3" icon="chevron-left" type="is-dark" custom-size="is-size-2"></b-icon>
						<p @click="$router.go(-1)" class="has-text-black ml-2 is-clickable is-size-3">{{ t.balance }}</p>
					</div>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-button class="header-btn ml-4 right" @click="openFilter">
						<b-icon pack="rt" class="mr-2" icon="sort" size="is-size-6" type="is-black"></b-icon>{{ t.sort }}</b-button
					>
				</div>
				<div v-show="showFilter" class="filter-window mt-2">
					<b-field class="mt-3 ml-2">
						<b-radio native-value="desc" @input="changeSort" v-model="sort">{{ t.creditsHigh }}</b-radio>
					</b-field>
					<b-field class="mt-3 ml-2">
						<b-radio native-value="asc" @input="changeSort" v-model="sort">{{ t.creditsLow }}</b-radio>
					</b-field>
				</div>
			</div>
		</div>

		<p class="has-text-primary is-size-5">{{ t.info }}</p>

		<p class="py-4" v-if="balanceInfo.length == 1">{{ balanceInfo.length }} {{ t.credit }}</p>
		<p class="py-4" v-else>{{ balanceInfo.length }} {{ t.credits }}</p>

		<div class="mb-2 mt-4 items-container center-inside" v-for="balance in balanceInfo" :key="balance.id">
			<img v-if="balance.store.pictures" :src="balance.store.pictures[0].url" />
			<div class="no-img center-inside" v-else><b-icon icon="account-outline" type="is-black" custom-size="is-size-1"> </b-icon></div>
			<div class="ml-8" style="margin: auto">
				<p class="has-text-dark">{{ balance.store.name }}</p>
			</div>
			<div style="display: inline-flex">
				<b-button class="has-background-white has-text-dark btn" @click="seeStore(balance.store.id)">{{ t.goToStore }}</b-button>
				<p class="price is-size-4 has-text-primary ml-10">{{ balance.credit }} €</p>
			</div>
		</div>
	</section>
</template>

<script>
	import { storeBalance } from '@/api/balance';
	export default {
		data() {
			return {
				balanceInfo: [],
				showFilter: false,
				sort: 'desc',
				page: 1,
				pageSize: 10,
				offset: 100,
				t: this.$t(`components.Balance`),
			};
		},

		created() {
			this.setIsPageLoading(true);
			storeBalance({
				'pagination[page]': this.page,
				'pagination[pageSize]': this.pageSize,
				'filters[credit][$gt]': 0,
				sort: ['credit:' + this.sort],
			})
				.then((response) => {
					console.log(response);
					this.balanceInfo = response.data.data;
					console.log(this.balanceInfo.length);
					window.addEventListener('scroll', this.handleScroll);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},

		methods: {
			seeStore(id) {
				this.$router.push({ name: 'StoreProfile', params: { id: id } });
			},

			openFilter() {
				this.showFilter = !this.showFilter;
			},

			changeSort() {
				this.showFilter = false;
				this.page = 1;
				storeBalance({
					'pagination[page]': this.page,
					'pagination[pageSize]': this.pageSize,
					'filters[credit][$gt]': 0,
					sort: ['credit:' + this.sort],
				})
					.then((response) => {
						console.log(response);
						this.balanceInfo = response.data.data;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.setIsPageLoading(false);
					});
			},

			handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;

					storeBalance({
						'pagination[page]': this.page,
						'pagination[pageSize]': this.pageSize,
						'filters[credit][$gt]': 0,
						sort: ['credit:' + this.sort],
					})
						.then((response) => {
							this.balanceInfo.push(...response.data.data);
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: this.t.error,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.isRequesting = false;
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.header-btn {
		width: 134px;
		height: 52px;
		border: 2px solid #f5f6f8;
		border-radius: 11px;
		background-color: white;
	}

	img,
	.no-img {
		width: 105px;
		height: 100px;
		border-radius: 22px;

		@include until($mobile) {
			width: 70px;
			height: 78px;
			border-radius: 12px;
		}
	}

	.items-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 20px;
		height: 102px;
		border: 1px solid $grey-light;

		@include until($mobile) {
			box-shadow: 0px 2px 48px #0000000a;
			border-radius: 12px;
			height: 80px;
		}
	}

	.price {
		color: $dark;
		font-size: 1.5rem;
		letter-spacing: 1.57px;
		min-width: 100px;

		@include until($mobile) {
			margin-left: 0;
			min-width: 50px;
			font-size: 1rem;
			letter-spacing: 1px;
		}
	}

	.btn {
		width: 159px;
		background: $primary 0% 0% no-repeat padding-box;
		border-radius: 5px;
		height: 43px;
	}

	.filter-window {
		width: 130px;
		height: 65px;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 0.718rem;
		position: absolute;
		right: 13px;
		z-index: 10;
		padding: 1px;
		background-color: #ffffff;
		top: 60px;
	}
</style>
