<template>
	<div class="px-6" :key="key">
		<div class="level">
			<div class="level-left">
				<div class="level-item">
					<p class="has-text-grey-dark is-size-2">{{ t.orders }}</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item">
					<b-button @click="showFilter = !showFilter" type="is-white" class="btn-sort"
						><b-icon pack="rt" icon="sort" size="is-size-6" type="is-black"></b-icon><span class="sort-text">{{ t.sort }}</span></b-button
					>
				</div>
			</div>
			<div v-show="showFilter" class="filter-window mt-2 px-6 py-2">
				<p class="is-size-6">{{ t.orderBy }}</p>
				<b-field class="mt-3">
					<b-radio native-value="createdAt:desc" @input="changeSort" v-model="sort">{{ t.new }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="amount:desc" @input="changeSort" v-model="sort">{{ t.totalHigh }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="amount:asc" @input="changeSort" v-model="sort">{{ t.totalLow }}</b-radio>
				</b-field>
				<b-field class="mt-3">
					<b-radio native-value="createdAt:asc" @input="changeSort" v-model="sort">{{ t.old }}</b-radio>
				</b-field>
			</div>
		</div>

		<b-tabs size="is-medium" class="mt-12" v-model="tab">
			<b-tab-item :label="totalOrders">
				<ListOrders @getTotalOrders="getTotalOrders" :sort="sort" />
			</b-tab-item>
			<b-tab-item :label="totalRefunds"><ListRefunds @getTotalRefunds="getTotalRefunds" :sort="sort" /> </b-tab-item>
		</b-tabs>
	</div>
</template>

<script>
	import ListOrders from '@/components/Desktop/Orders/ListOrders.vue';
	import ListRefunds from '@/components/Desktop/Orders/ListRefunds.vue';
	export default {
		components: {
			ListOrders,
			ListRefunds,
		},

		data() {
			return {
				showFilter: false,
				key: 0,
				sort: 'createdAt:desc',
				tab: 0,
				t: this.$t(`views.StoreOrders`),
				totalOrders: '',
				totalRefunds: '',
			};
		},

		methods: {
			changeSort() {
				this.showFilter = false;
				this.key++;
				this.tab = this.tab;
			},

			getTotalOrders(val) {
				this.totalOrders = this.t.orders + '(' + val + ')';
			},
			getTotalRefunds(val) {
				this.totalRefunds = this.t.refunds + '(' + val + ')';
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.btn-sort {
		border: 2px solid #f5f6f8;
		background-color: $white;
		border-radius: 10px;
		width: 124px;
		height: 42px;
	}

	.filter-window {
		width: 195px;
		height: auto;
		box-shadow: 0px 2px 46px #0000000a;
		border-radius: 2px;
		border: 1px solid #70707026;
		opacity: 1;
		font-size: 1rem;
		position: absolute;
		right: 23px;
		z-index: 10;
		background-color: #ffffff;
		top: 50px;
	}
</style>
