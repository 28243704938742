<template>
	<div v-if="existProducts" class="px-6 my-5 container" @scroll.passive="handleScroll">
		<div v-for="(product, index) in products" :key="product.id" :ref="product.id" :id="product.id">
			<div class="media center-inside mb-6" v-touch:tap="storeDetails(product.store.id)">
				<div class="media-left">
					<div class="photo-container mt-4">
						<figure
							v-if="product.store"
							class="image is-32x32 is-background-size-cover is-rounded pointer"
							:style="[product.store.pictures ? { 'background-image': `url(${product.store.pictures[0].url})` } : { background: '#FFF' }]"
						></figure>
					</div>
				</div>
				<div class="media-content pt-4" style="overflow: hidden">
					<p class="name" v-if="product.store">{{ product.store.name }}</p>
					<p class="address has-text-grey" v-if="product.store">{{ product.store.location_municipality }}</p>
				</div>
				<div class="media-right"></div>
			</div>

			<b-carousel :autoplay="false" v-if="productColors.length > 0 && showImageArray.length > 0">
				<b-carousel-item v-for="(image, i) in product.productVariants[showImageArray[index]].pictures" :key="i">
					<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag">{{ product.productSales[0].discount }}% {{ t.off }}</span>
					<span v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag mt-17"
						>{{ calculateMoneyCard(calculateSale(product.price, product.productSales[0].discount), product.moneyOnCardPercentage) }}€ {{ t.card }}</span
					>
					<span v-else-if="product.givesMoneyOnCard" class="tag">{{ calculateMoneyCard(product.price, product.moneyOnCardPercentage) }}€ {{ t.card }}</span>
					<figure class="image is-2by3">
						<img v-touch:tap="productDetails(product.id)" class="has-object-fit-contain" :src="image.url" alt="" />
					</figure>
				</b-carousel-item>
			</b-carousel>

			<b-carousel :autoplay="false" v-else>
				<b-carousel-item v-for="(image, i) in product.pictures" :key="i">
					<span v-if="product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag">{{ product.productSales[0].discount }}% {{ t.off }}</span>
					<span v-if="product.givesMoneyOnCard && product.productSales.length > 0 && product.productSales[0].isActive == true" class="tag mt-17"
						>{{ calculateMoneyCard(calculateSale(product.price, product.productSales[0].discount), product.moneyOnCardPercentage) }}€ {{ t.card }}</span
					>
					<span v-else-if="product.givesMoneyOnCard" class="tag">{{ calculateMoneyCard(product.price, product.moneyOnCardPercentage) }}€ {{ t.card }}</span>
					<figure class="image is-2by3">
						<img v-touch:tap="productDetails(product.id)" class="has-object-fit-contain has-radius" :src="image.url" alt="" />
					</figure>
				</b-carousel-item>
			</b-carousel>

			<div class="level is-mobile mb-0" v-if="getToken">
				<div class="level-left">
					<div class="mt-4 level-item" style="display: inline-flex; width: 70%">
						<b-icon
							v-if="!productsLiked.includes(product.id)"
							icon="heart-outline"
							@click.native="like(product.id, index)"
							class="is-clickable"
							type="is-primary"
							custom-size="is-size-4"
						>
						</b-icon>
						<b-icon v-else icon="heart" @click.native="unlike(product.id, index)" class="is-clickable" type="is-primary" custom-size="is-size-4"> </b-icon>
						<b-icon class="ml-4 is-clickable" icon="message-outline" type="is-primary" custom-size="is-size-4" @click.native="seeComments(product)"> </b-icon>
						<b-icon
							class="ml-4 right is-clickable"
							v-if="!productsWished.includes(product.id)"
							@click.native="wish(product.id)"
							icon="bookmark-outline"
							type="is-primary"
							custom-size="is-size-4"
						>
						</b-icon>
						<b-icon class="ml-4 right is-clickable" v-else @click.native="deleteWish(product.id)" icon="bookmark" type="is-primary" custom-size="is-size-4"> </b-icon>
					</div>
				</div>
			</div>

			<div class="mt-3 mb-3">
				<div class="level is-mobile mb-0">
					<div class="level-left" style="max-width: 100%">
						<p class="product-name level-item" style="max-width: 100%">{{ product.title }}</p>
					</div>
				</div>

				<p class="has-text-grey" v-if="product.category">{{ product.category.name }}</p>

				<div class="is-flex">
					<p
						class="product-name"
						:style="[
							product.productSales.length > 0 && product.productSales[0].isActive == true
								? { fontSize: '13px', textDecoration: 'line-through', transform: 'translateY(2px)' }
								: { textDecoration: 'none', fontWeight: 'bold' },
						]"
					>
						{{ product.price.toFixed(2) }} €
					</p>
					<p class="product-name has-text-dark has-text-weight-bold ml-4" v-if="product.productSales.length > 0 && product.productSales[0].isActive == true">
						{{ calculateSale(product.price, product.productSales[0].discount) }} €
					</p>
				</div>

				<div class="is-flex">
					<div
						class="color-container mr-2 mt-2"
						v-for="variant in product.productVariants.slice(0, 3)"
						:key="variant.id"
						:style="`backgroundColor: ${variant.color.hex}`"
						v-touch:tap="productDetails(product.id)"
					></div>
					<span class="is-size-4 has-text-grey ml-2 mt-4" v-if="product.productVariants.length > 3">+ {{ product.productVariants.length - 3 }}</span>
				</div>

				<p class="has-text-grey-darker mt-2 is-clickable" @click="seeComments(product)" v-if="product.comments.length > 0">
					{{ t.see }} {{ product.comments.length }} {{ t.comments }}
				</p>
			</div>
		</div>
	</div>
	<div v-else class="center-inside no-product-content">
		<div class="columns is-vcentered is-mobile">
			<div class="column">
				<p class="has-text-primary is-size-5 has-text-centered">{{ t.noProducts }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getProducts,
		addLike,
		getLikes,
		removeLike,
		addWhishlist,
		getWhishlist,
		removeWhishlist,
		getStoreFilter,
		getProductsByStore,
		getBrandsFilter,
		getProductsByBrand,
	} from '@/api/feed';
	import parts from '@/mixins/modules/shared/parts';
	import router from '@/router/index';
	export default {
		props: [
			'category',
			'productCategories',
			'productSizes',
			'productColors',
			'productBrands',
			'priceRange',
			'onlySale',
			'comments',
			'shopped',
			'reviewed',
			'liked',
			'filterStores',
		],
		mixins: [parts],
		data() {
			return {
				t: this.$t(`components.Homepage.Feed`),
				products: [],
				page: 1,
				pageItems: 10,
				isRequesting: false,
				offset: 300,
				productsLiked: [],
				productsWished: [],
				sort: null,
				showImageArray: [],
				isAddingLike: false,
				isAddingWhishlist: false,
				sort: 'createdAt:desc',
				existProducts: true,
			};
		},

		methods: {
			async handleScroll(e) {
				let target = {
					scrollTop: e.target.scrollingElement.scrollTop,
					clientHeight: e.target.scrollingElement.clientHeight,
					scrollHeight: e.target.scrollingElement.scrollHeight,
				};

				if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
					if (this.isRequesting) return;
					this.isRequesting = true;
					this.page++;
					parts.methods.setPageRequested(this.page);

					let categoriesToFilter = null;
					if (this.productCategories.length > 0) {
						categoriesToFilter = this.productCategories;
					} else if (this.category == undefined) {
						categoriesToFilter = null;
					} else categoriesToFilter = this.category;

					if (this.getFilterString != null && this.getFilterString != '') {
						await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
							.then(async (response) => {
								let storeId = [];
								response.data.data.map((store) => {
									storeId.push(store.id);
								});

								if (storeId.length > 0) {
									await getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push({ ...product });
												}
											});
										}
									});
								}

								await getProducts(
									this.page,
									this.pageItems,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								)
									.then(async (response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push({ ...product });
												}
											});
										}

										if (this.productColors.length > 0) {
											let products = this.products.map((product) => {
												return product.productVariants;
											});

											console.log(products);
											this.filterVariantPictures(products);
										}
									})
									.catch((error) => {
										console.log(error);
									})
									.finally(() => {
										this.isRequesting = false;
									});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isRequesting = false;
							});

						/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
							.then(async (response) => {
								let brandId = [];
								response.data.data.map((brand) => {
									brandId.push(brand.id);
								});

								if (brandId.length > 0) {
									await getProductsByBrand(this.page, this.pageItems, brandId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push({ ...product });
													this.setIsPageLoading(false);
												} else this.setIsPageLoading(false);
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								} else {
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								}
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							}) */
					} else {
						await getProducts(
							this.page,
							this.pageItems,
							this.getMainCategorySelected,
							categoriesToFilter,
							this.productSizes,
							this.priceRange[0],
							this.priceRange[1],
							this.productColors,
							this.productBrands,
							this.onlySale,
							this.sort,
							this.comments,
							this.shopped,
							this.reviewed,
							this.liked,
							this.getFilterString,
							this.filterStores
						)
							.then(async (response) => {
								if (response.data.data.length > 0) {
									response.data.data.map((product) => {
										let verify = this.products.some(function (el) {
											return el.id == product.id;
										});

										if (!verify) {
											this.products.push({ ...product });
										}
									});
								}

								if (this.productColors.length > 0) {
									let products = this.products.map((product) => {
										return product.productVariants;
									});

									console.log(products);
									this.filterVariantPictures(products);
								}
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isRequesting = false;
							});
					}
				}
			},

			filterVariantPictures(products) {
				products.forEach((variants, index) => {
					let i = variants.findIndex((variant) => this.productColors.includes(variant.color.id));
					console.log(index, i);
					this.showImageArray[index] = i;
				});
				console.log(this.showImageArray);
			},

			storeDetails(id) {
				return function (direction, event) {
					console.log(id);
					parts.methods.addSidebarStatus(false);
					router.push({ name: 'StoreProfile', params: { id: id } });
				};
			},

			productDetails(id) {
				return function (direction, event) {
					console.log(id);
					parts.methods.addSidebarStatus(false);
					parts.methods.setVisitedProductId(id);
					router.push({ name: 'ProductDetails', params: { id: id } });
				};
			},

			calculateSale(num1, num2) {
				let valorToSubtract = num1 * (num2 / 100);

				return (num1 - valorToSubtract).toFixed(2);
			},

			like(id, index) {
				if (this.getToken) {
					this.productsLiked.push(id);
					this.products[index].likes.push(id);
					let like = {
						data: {
							product: id,
						},
					};

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						addLike(like)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				} else this.goToPage('Home');
			},

			unlike(id, index) {
				if (this.getToken) {
					this.productsLiked.splice(
						this.productsLiked.findIndex((element) => element == id),
						1
					);
					this.products[index].likes.pop();

					if (!this.isAddingLike) {
						this.isAddingLike = true;

						removeLike(id)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								this.isAddingLike = false;
							});
					}
				}
			},

			wish(id) {
				if (this.getToken) {
					this.productsWished.push(id);
					let wishNumber = this.getBookmark;
					wishNumber++;
					this.setBookmark(wishNumber);
					let whish = {
						data: {
							product: id,
						},
					};
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						addWhishlist(whish)
							.then((response) => {
								console.log(response);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingWhishlist = false;
							});
					}
				} else this.goToPage('Home');
			},

			deleteWish(id) {
				if (this.getToken) {
					let wishNumber = this.getBookmark;
					wishNumber--;
					this.setBookmark(wishNumber);
					this.productsWished.splice(this.productsWished.findIndex((element) => element == id));
					if (!this.isAddingWhishlist) {
						this.isAddingWhishlist = true;
						removeWhishlist(id)
							.then((response) => {})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.isAddingWhishlist = false;
							});
					}
				}
			},

			seeComments(id) {
				this.$router.push({
					name: 'ProductComment',
					params: { product: id },
				});
			},

			calculateMoneyCard(num1, num2) {
				console.log(num1);
				let valueToShow = num1 * (num2 / 100);

				if (Number.isInteger(valueToShow)) {
					return valueToShow;
				} else return valueToShow.toFixed(2);
			},
		},

		computed: {
			searchString() {
				return this.getFilterString;
			},
		},

		watch: {
			async searchString(newVal) {
				if (newVal || newVal == '') {
					this.setIsPageLoading(true);
					this.page = 1;
					this.products = [];
					let categoriesToFilter = null;
					if (this.productCategories.length > 0) {
						categoriesToFilter = this.productCategories;
					} else if (this.category == undefined) {
						categoriesToFilter = null;
					} else categoriesToFilter = this.category;

					if (newVal != '') {
						await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
							.then(async (response) => {
								console.log(response);
								let storeId = [];
								response.data.data.map((store) => {
									storeId.push(store.id);
								});

								if (storeId.length > 0) {
									getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});
												console.log(verify);
												if (!verify) {
													this.products.push(product);
												}
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								}

								await getProducts(
									this.page,
									this.pageItems,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								).then((response) => {
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
									if (this.productColors.length > 0) {
										let products = this.products.map((product) => {
											return product.productVariants;
										});

										console.log(products);
										this.filterVariantPictures(products);
									}

									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
								});
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
							})
							.finally(() => {
								this.setIsPageLoading(false);
							});

						/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
							.then((response) => {
								console.log('SEGUNDO');
								console.log(response);
								let brandId = [];
								response.data.data.map((brand) => {
									brandId.push(brand.id);
								});

								if (brandId.length > 0) {
									getProductsByBrand(this.page, this.pageItems, brandId, this.sort).then((response) => {
										console.log(response);
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});
												console.log(verify);
												if (!verify) {
													this.products.push(product);
													this.setIsPageLoading(false);
												} else this.setIsPageLoading(false);
											});
										}
										if (this.products.length == 0) {
											this.existProducts = false;
										} else this.existProducts = true;
									});
								} else {
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
									this.setIsPageLoading(false);
								}
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							}); */
					} else {
						getProducts(
							this.page,
							this.pageItems,
							this.getMainCategorySelected,
							categoriesToFilter,
							this.productSizes,
							this.priceRange[0],
							this.priceRange[1],
							this.productColors,
							this.productBrands,
							this.onlySale,
							this.sort,
							this.comments,
							this.shopped,
							this.reviewed,
							this.liked,
							this.getFilterString,
							this.filterStores
						)
							.then(async (response) => {
								this.products = response.data.data;

								if (this.productColors.length > 0) {
									let products = this.products.map((product) => {
										return product.productVariants;
									});

									console.log(products);
									this.filterVariantPictures(products);
								}

								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: 'Ocorreu um erro',
									position: 'is-top',
									type: 'is-danger',
								});
								this.setIsPageLoading(false);
							});
					}
				}
			},
		},

		async created() {
			this.setIsPageLoading(true);

			let categoriesToFilter = null;
			if (this.productCategories.length > 0) {
				categoriesToFilter = this.productCategories;
			} else if (this.category == undefined) {
				categoriesToFilter = null;
			} else categoriesToFilter = this.category;

			if (this.getToken) {
				getLikes({
					'filters[user][id]': this.getUser.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsLiked = response.data.data.map((like) => {
							if (like.product) {
								return like.product.id;
							}
						});
					})
					.catch((error) => {});

				getWhishlist({
					'filters[user][id]': this.getUser.id,
					populate: 'product',
				})
					.then((response) => {
						console.log(response);
						this.productsWished = response.data.data.map((whish) => {
							if (whish.product) {
								return whish.product.id;
							}
						});
						this.setBookmark(response.data.meta.pagination.total);
					})
					.catch((error) => {
						console.log(error);
					});
			}

			if (this.canScroll) {
				let pageSize = 10 * parts.computed.getPageRequested();

				if (this.getFilterString != null && this.getFilterString != '') {
					console.log(this.getFilterString);
					this.setIsPageLoading(true);
					await getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
						.then(async (response) => {
							console.log(response);
							let storeId = [];
							response.data.data.map((store) => {
								storeId.push(store.id);
							});

							if (storeId.length > 0) {
								let pageSize;
								if (this.canScroll) {
									pageSize = 10 * parts.computed.getPageRequested();
								} else pageSize = this.pageItems;
								await getProductsByStore(this.page, pageSize, storeId, this.sort).then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
								});

								await getProducts(
									this.page,
									pageSize,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								)
									.then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push(product);
												}
											});
										}

										if (this.productColors.length > 0) {
											let products = this.products.map((product) => {
												return product.productVariants;
											});

											this.filterVariantPictures(products);
										}
									})
									.catch((error) => {
										console.log(error);
										this.$buefy.toast.open({
											message: 'Ocorreu um erro',
											position: 'is-top',
											type: 'is-danger',
										});
									});
							} else {
								await getProducts(
									this.page,
									pageSize,
									this.getMainCategorySelected,
									categoriesToFilter,
									this.productSizes,
									this.priceRange[0],
									this.priceRange[1],
									this.productColors,
									this.productBrands,
									this.onlySale,
									this.sort,
									this.comments,
									this.shopped,
									this.reviewed,
									this.liked,
									this.getFilterString,
									this.filterStores
								)
									.then((response) => {
										if (response.data.data.length > 0) {
											response.data.data.map((product) => {
												let verify = this.products.some(function (el) {
													return el.id == product.id;
												});

												if (!verify) {
													this.products.push(product);
												}
											});
										}

										if (this.productColors.length > 0) {
											let products = this.products.map((product) => {
												return product.productVariants;
											});

											this.filterVariantPictures(products);
										}
									})
									.catch((error) => {
										console.log(error);
										this.$buefy.toast.open({
											message: 'Ocorreu um erro',
											position: 'is-top',
											type: 'is-danger',
										});
									});
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.canScroll) {
								let element = document.getElementById(this.getVisitedProductId);
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);

								setTimeout(function () {
									element.scrollIntoView({
										top: element,
										behavior: 'auto',
									});
								}, 100);
							}
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});

					/* await getBrandsFilter({ 'filters[name][$contains]': this.getFilterString })
						.then(async (response) => {
							console.log(response);
							let brandId = [];
							response.data.data.map((brand) => {
								brandId.push(brand.id);
							});

							if (brandId.length > 0) {
								let pageSize;
								if (this.canScroll) {
									pageSize = 10 * parts.computed.getPageRequested();
								} else pageSize = this.pageItems;
								await getProductsByBrand(this.page, pageSize, brandId, this.sort).then((response) => {
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
												this.setIsPageLoading(false);
											} else this.setIsPageLoading(false);
										});
									}
									if (this.products.length == 0) {
										this.existProducts = false;
									} else this.existProducts = true;
								});
							} else {
								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
							this.setIsPageLoading(false);
						})
						.finally(() => {
							if (this.canScroll) {
								let element = this.$refs[this.getVisitedProductId][0].offsetTop;
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);
								window.scrollTo({
									top: element,
									behavior: 'auto',
								});
							}
							this.setIsPageLoading(false);
						}); */
				} else {
					getProducts(
						this.page,
						pageSize,
						this.getMainCategorySelected,
						categoriesToFilter,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.comments,
						this.shopped,
						this.reviewed,
						this.liked,
						this.getFilterString,
						this.filterStores
					)
						.then((response) => {
							this.products = response.data.data;

							if (this.productColors.length > 0) {
								let products = this.products.map((product) => {
									return product.productVariants;
								});

								this.filterVariantPictures(products);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.getFilterString == null || this.getFilterString == '') {
								if (this.products.length == 0) {
									this.existProducts = false;
								} else this.existProducts = true;
								this.setIsPageLoading(false);
								let element = document.getElementById(this.getVisitedProductId);
								console.log(element);
								this.page = parts.computed.getPageRequested();
								this.setCanScroll(false);

								setTimeout(function () {
									element.scrollIntoView({
										top: element,
										behavior: 'auto',
									});
								}, 100);
							}
						});
				}
			} else {
				parts.methods.setPageRequested(this.page);
				if (this.getFilterString != null && this.getFilterString != '') {
					getStoreFilter({ 'filters[name][$containsi]': this.getFilterString })
						.then(async (response) => {
							let storeId = [];
							response.data.data.map((store) => {
								storeId.push(store.id);
							});

							if (storeId.length > 0) {
								await getProductsByStore(this.page, this.pageItems, storeId, this.sort).then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});
											console.log(verify);
											if (!verify) {
												this.products.push(product);
											}
										});
									}
								});
							}

							await getProducts(
								this.page,
								this.pageItems,
								this.getMainCategorySelected,
								categoriesToFilter,
								this.productSizes,
								this.priceRange[0],
								this.priceRange[1],
								this.productColors,
								this.productBrands,
								this.onlySale,
								this.sort,
								this.comments,
								this.shopped,
								this.reviewed,
								this.liked,
								this.getFilterString,
								this.filterStores
							)
								.then((response) => {
									console.log(response);
									if (response.data.data.length > 0) {
										response.data.data.map((product) => {
											let verify = this.products.some(function (el) {
												return el.id == product.id;
											});

											if (!verify) {
												this.products.push(product);
											}
										});
									}

									if (this.productColors.length > 0) {
										let products = this.products.map((product) => {
											return product.productVariants;
										});

										this.filterVariantPictures(products);
									}
								})
								.catch((error) => {
									console.log(error);
									this.$buefy.toast.open({
										message: 'Ocorreu um erro',
										position: 'is-top',
										type: 'is-danger',
									});
								});
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});
				} else {
					await getProducts(
						this.page,
						this.pageItems,
						this.getMainCategorySelected,
						categoriesToFilter,
						this.productSizes,
						this.priceRange[0],
						this.priceRange[1],
						this.productColors,
						this.productBrands,
						this.onlySale,
						this.sort,
						this.comments,
						this.shopped,
						this.reviewed,
						this.liked,
						this.getFilterString,
						this.filterStores
					)
						.then((response) => {
							console.log(response);
							if (response.data.data.length > 0) {
								response.data.data.map((product) => {
									let verify = this.products.some(function (el) {
										return el.id == product.id;
									});

									if (!verify) {
										this.products.push(product);
									}
								});
							}

							if (this.productColors.length > 0) {
								let products = this.products.map((product) => {
									return product.productVariants;
								});

								this.filterVariantPictures(products);
							}
						})
						.catch((error) => {
							console.log(error);
							this.$buefy.toast.open({
								message: 'Ocorreu um erro',
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							if (this.products.length == 0) {
								this.existProducts = false;
							} else this.existProducts = true;
							this.setIsPageLoading(false);
						});
				}
			}
		},

		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			parts.methods.setSavedSort(this.sort);
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.container {
		padding-bottom: 100px;
		@include from($mobile-l) {
			width: $card-max-width !important;
		}
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 64px;
		height: 64px;
		border-radius: 50%;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.name {
		font-size: 0.9rem;
		color: $grey-darker;
	}

	.address {
		font-size: 0.8rem;
	}

	img {
		width: 100%;
		height: 226px;
		box-shadow: 0px 2px 48px #00000014;
		border: 1px solid #aab2b72c;
		border-radius: 0px;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.card-steps {
		margin: 0 auto;
	}

	.card-steps > div {
		width: 8px;
		height: 8px;
		background: white;
		border: solid 1px $primary;
		border-radius: 50%;
		margin: 15px;
	}
	.card-steps > .step-1 {
		background: $primary;
	}

	.card-steps {
		margin: 8px !important;
	}

	.tag {
		min-width: 100px;
		height: 48px;
		background-color: $primary;
		color: $white;
		border-radius: 50px;
		font-size: 0.85rem;
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 2;
	}

	.no-product-content {
		margin-top: 30%;
	}

	.color-container {
		min-width: 26px;
		height: 26px;
		border-radius: 8px;
		text-align: center;
		color: black;
		border: solid 1px $grey;
		width: auto;
		max-width: 50px;
		padding-left: 5px;
		padding-right: 5px;
	}
</style>
