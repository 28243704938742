<template>
	<section>
		<form class="form" @submit.prevent="save">
			<div class="header-container pt-8 px-6">
				<div style="display: inline-flex">
					<b-icon icon="chevron-left " @click.native="goToPage('DesktopStoreProducts')" type="is-grey-dark" class="pointer" custom-size="is-size-4"> </b-icon>
					<p class="has-text-grey-dark ml-2 pointer" @click="goToPage('DesktopStoreProducts')">{{ t.back }}</p>
				</div>
			</div>

			<b-tabs size="is-large">
				<b-tab-item :label="t.productDetails">
					<div class="left-container">
						<div class="input-container">
							<b-field style="width: 98%">
								<b-input class="input mt-6" v-model.trim="title" name="title" v-validate="'required'" ref="title" :placeholder="t.name"></b-input>
							</b-field>
							<p v-show="errors.has('title')" class="help is-danger">{{ t.requiredField }}</p>
							<b-field style="width: 98%" class="mt-6 mb-5">
								<b-input
									v-model.trim="description"
									name="description"
									v-validate="'required'"
									ref="description"
									:placeholder="t.description"
									type="textarea"
								></b-input>
							</b-field>
							<p v-show="errors.has('description')" class="help is-danger">{{ t.requiredField }}</p>

							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-select
											class="input small mt-2"
											type="text"
											name="gender"
											v-model="selectedCategories.level0"
											ref="level0"
											v-validate="'required'"
											:placeholder="t.gender"
											expanded
											@input="(option) => onCategorySelected(option, 0)"
										>
											<option v-for="category in availableCategories.level0" :value="category" :key="category.id">
												{{ category.name }}
											</option>
										</b-select>
									</b-field>
									<p v-show="errors.has('gender')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-select
											class="input small mt-2"
											type="text"
											name="brand"
											v-model="selectedBrand"
											v-validate="'required'"
											ref="brand"
											:placeholder="t.brand"
											expanded
										>
											<option v-for="brand in brands" :value="brand.id" :key="brand.id">
												{{ brand.name }}
											</option>
										</b-select>
									</b-field>
									<p v-show="errors.has('brand')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>

							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-autocomplete
											rounded
											v-model="search.level1"
											:data="filteredLevelOne"
											:keep-first="true"
											:open-on-focus="true"
											field="name"
											:placeholder="t.category"
											icon="magnify"
											clearable
											ref="level1"
											name="level1"
											v-validate="'required'"
											@select="(option) => onCategorySelected(option, 1)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level1')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-autocomplete
											rounded
											v-model="search.level2"
											:data="filteredLevelTwo"
											:keep-first="true"
											field="name"
											:placeholder="t.subcategory"
											:open-on-focus="true"
											icon="magnify"
											clearable
											ref="level2"
											name="level2"
											v-validate="'required'"
											@select="(option) => onCategorySelected(option, 2)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level2')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>
							<div class="columns">
								<div class="column is-half">
									<b-field>
										<b-autocomplete
											v-show="availableCategories.level3.length > 0"
											rounded
											v-model="search.level3"
											:data="filteredLevelThree"
											:keep-first="true"
											:open-on-focus="true"
											field="name"
											placeholder="SubCategory2"
											icon="magnify"
											clearable
											ref="level3"
											name="level3"
											v-validate="availableCategories.level3.length > 0 ? 'required' : ''"
											@select="(option) => onCategorySelected(option, 3)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level3') && availableCategories.level3.length > 0" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-autocomplete
											v-show="availableCategories.level4.length > 0"
											rounded
											v-model="search.level4"
											:data="filteredLevelFour"
											:keep-first="true"
											field="name"
											placeholder="SubCategory3"
											:open-on-focus="true"
											icon="magnify"
											clearable
											ref="level4"
											name="level4"
											v-validate="availableCategories.level4.length > 0 ? 'required' : ''"
											@select="(option) => onCategorySelected(option, 4)"
										>
											<template #empty>{{ t.noResults }}</template>
										</b-autocomplete>
									</b-field>
									<p v-show="errors.has('level4') && availableCategories.level4.length > 0" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>
							</div>

							<div class="columns">
								<div class="column is-half">
									<b-field :label="t.price">
										<b-numberinput
											:controls="false"
											class="input small mt-2"
											name="price"
											v-validate="'required'"
											ref="price"
											v-model="price"
											:placeholder="t.price"
											step="0.01"
											expanded
										></b-numberinput>
									</b-field>
									<p v-show="errors.has('price')" class="help is-danger">{{ t.requiredField }}</p>
								</div>

								<div class="column">
									<b-field>
										<b-field :label="t.weight">
											<b-numberinput
												:controls="false"
												class="input small mt-2"
												name="weight"
												v-validate="'required'"
												ref="weight"
												v-model="weight"
												:placeholder="t.weight"
												expanded
												step="0.01"
											></b-numberinput>
										</b-field>
									</b-field>
									<p v-show="errors.has('weight')" class="help is-danger">{{ t.requiredField }}</p>
								</div>
							</div>
						</div>
					</div>

					<div class="right-container px-6">
						<!-- <b-field class="mt-4">
							<b-checkbox size="is-large" class="checkbox mt-3" v-model="isNew">
								<span style="font-size: 0.9rem">{{ t.newSeason }} </span>
							</b-checkbox>
						</b-field> -->

						<div class="columns mt-4">
							<div class="column is-half">
								<b-field class="select-field">
									<b-checkbox size="is-large" class="checkbox mt-3" v-model="isSale">
										<span style="font-size: 0.9rem">{{ t.isSale }} </span>
									</b-checkbox>
								</b-field>
							</div>
							<div class="column">
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										name="valueSale"
										v-validate="isSale ? 'required' : ''"
										ref="valueSale"
										v-model="saleValue"
										:placeholder="t.insertValue"
										:min="isSale ? '1' : '0'"
										:max="100"
										:disabled="isSale ? false : true"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueSale')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>

						<div v-show="isSale" style="display: inline-flex">
							<b-field class="picker-input" :label="t.saleStart">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="saleStartDate"
									:min-date="yesterday"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>

							<b-field class="picker-input ml-4" :label="t.saleEnd">
								<b-datepicker
									class="input"
									size="is-medium"
									v-model="salesEndDate"
									:min-date="saleStartDate"
									:mobile-native="false"
									:locale="$i18n.locale"
									:icon-right-clickable="true"
									trap-focus
								></b-datepicker>
							</b-field>
						</div>

						<div class="columns">
							<div class="column is-half">
								<b-field class="select-field">
									<b-checkbox size="is-large" class="checkbox mt-3" v-model="isMoneyOnCard">
										<span style="font-size: 0.9rem">{{ t.moneyCard }} </span>
									</b-checkbox>
								</b-field>
							</div>
							<div class="column">
								<b-field class="select-field">
									<b-numberinput
										:controls="false"
										class="input"
										name="valueCard"
										ref="valueCard"
										v-model="moneyOnCardValue"
										v-validate="isMoneyOnCard ? 'required' : ''"
										:placeholder="t.insertValue"
										:min="isMoneyOnCard ? '1' : '0'"
										:disabled="isMoneyOnCard ? false : true"
									></b-numberinput>
								</b-field>
								<p v-show="errors.has('valueCard')" class="help is-danger">{{ t.requiredField }}</p>
							</div>
						</div>
					</div>
				</b-tab-item>
				<b-tab-item :label="t.variants">
					<div v-for="(variant, index) in variantsArray" class="mt-4" :key="index">
						<div style="display: inline-flex">
							<p style="font-size: 1.4rem">{{ t.variant }} {{ index + 1 }}</p>

							<b-icon @click.native="removeVariant(index)" pack="rt" icon="remove" size="is-size-4" class="ml-3 is-clickable" type="is-red"></b-icon>
						</div>
						<p v-if="removeVariantMessage" class="has-text-red">{{ t.removeVariantError }}</p>

						<div class="photo-container mt-4">
							<b-upload v-model="variant['images'][0]" class="file-label mr-2" accept="image/*">
								<div class="photo center-inside">
									<img class="profile-photo" v-if="variant['images'][0]" :src="getFileURL(index, 0)" />
									<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
								</div>
							</b-upload>

							<b-upload v-model="variant['images'][1]" class="file-label mx-2" accept="image/*">
								<div class="photo center-inside">
									<img class="profile-photo" v-if="variant['images'][1]" :src="getFileURL(index, 1)" />
									<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
								</div>
							</b-upload>
							<b-upload v-model="variant['images'][2]" class="file-label ml-2" accept="image/*">
								<div class="photo center-inside">
									<img class="profile-photo" v-if="variant['images'][2]" :src="getFileURL(index, 2)" />
									<b-icon v-else pack="rt" icon="plus" type="is-grey-light" custom-size="is-size-4"> </b-icon>
								</div>
							</b-upload>
						</div>
						<p v-if="imageMessage" class="has-text-red mt-2">{{ t.imageRequired }}</p>

						<b-field>
							<b-select
								class="input-size small mt-8"
								type="text"
								name="color"
								ref="color"
								v-validate="'required'"
								v-model="variant.color"
								:placeholder="t.color"
								expanded
							>
								<option v-for="color in colors" :value="color.id" :key="color.id">
									{{ color.name }}
								</option>
							</b-select>
						</b-field>
						<p v-show="errors.has('color')" class="help is-danger">{{ t.requiredSelect }}</p>

						<div class="mt-8">
							<div style="display: flex">
								<div class="mr-2">
									<p class="table-label">{{ t.sizes }}</p>
									<b-field v-for="(size, sizeIndex) in variant.sizes" :key="sizeIndex">
										<b-select
											class="input-size small mt-2"
											v-model="size.size"
											type="text"
											ref="size"
											v-validate="'required'"
											name="size"
											:placeholder="t.sizes"
											expanded
										>
											<option v-for="(size, index) in sizes" :key="index" :value="size.id">
												{{ size.name }}
											</option>
										</b-select>
									</b-field>
									<p v-show="errors.has('size')" class="help is-danger">{{ t.requiredSelect }}</p>
								</div>

								<div class="ml-2">
									<p class="table-label">{{ t.quantities }}</p>
									<b-field v-for="(size, sizeIndex) in variant.sizes" :key="sizeIndex">
										<div class="is-flex">
											<input
												type="number"
												min="0"
												style="width: 130px; height: 40px"
												class="input mt-2"
												name="unit"
												v-model="size.units"
												v-validate="'numeric|required'"
												:placeholder="t.units"
												ref="unit"
											/>
											<b-icon
												@click.native="removeProductLine(index, sizeIndex)"
												pack="rt"
												icon="remove"
												size="is-size-4"
												class="ml-3 mt-3 is-clickable"
												type="is-red"
											></b-icon>
											<b-icon
												v-if="sizeIndex + 1 === variant.sizes.length"
												@click.native="addProductLine(index)"
												pack="rt"
												icon="plus"
												size="is-size-4"
												class="ml-5 mt-3 is-clickable"
												type="is-primary"
											></b-icon>
										</div>
									</b-field>
									<p v-show="errors.has('unit')" class="help is-danger mt-4">{{ t.requiredField }} {{ t.greater }}</p>
								</div>
							</div>
							<p v-if="removeSizeMessage" class="has-text-red">{{ t.removeSizeError }}</p>
						</div>
					</div>

					<b-button @click="addVariant" :loading="isLoading" type="is-primary" class="btn-small mt-14">{{ t.new }}</b-button> <br />
				</b-tab-item>
			</b-tabs>
			<div class="button-container">
				<b-button native-type="submit" :loading="isLoading" type="is-primary" class="btn mx-3">{{ t.post }}</b-button>
			</div>
		</form>
	</section>
</template>

<script>
	import {
		getBrands,
		getColors,
		uploadImage,
		createProduct,
		getSizesGroups,
		getStockStatus,
		addProductSize,
		addProductVariant,
		updateProductSale,
		getCategories,
	} from '@/api/store';
	import moment from 'moment';

	export default {
		components: {},
		data() {
			return {
				t: this.$t(`components.Store.AddProduct`),
				image: [],
				brands: [],
				colors: [],
				categories: [],
				openModal: false,
				title: null,
				description: null,
				selectedBrand: null,
				selectedSizes: [],
				subcategory: null,
				price: null,
				weight: null,
				uploadedImages: [],
				showMenu: false,
				isNew: false,
				isSale: false,
				saleValue: null,
				isLoading: false,
				variantsArray: [],
				selectedSizesIndex: null,
				sizes: [],
				status: [],
				receivedProductSizes: [],
				captions: [],
				receivedVariants: [],
				saleStartDate: new Date(),
				salesEndDate: new Date(),
				today: new Date(),
				removeSizeMessage: false,
				removeVariantMessage: false,
				imageMessage: false,
				isMoneyOnCard: false,
				moneyOnCardValue: null,
				yesterday: new Date(),

				selectedCategories: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				search: {
					level0: null,
					level1: null,
					level2: null,
					level3: null,
					level4: null,
					level5: null,
				},

				availableCategories: {
					level0: [],
					level1: [],
					level2: [],
					level3: [],
					level4: [],
					level5: [],
				},

				maxLevel: 5,
			};
		},

		methods: {
			getFileURL(index, subIndex) {
				if (this.variantsArray[index]) {
					return window.URL.createObjectURL(this.variantsArray[index].images[subIndex]);
				}
			},

			open() {
				this.showMenu = !this.showMenu;
			},

			onCategorySelected(category, level) {
				console.log(category, level);
				console.log(this.availableCategories.level4.length);
				if (!category) {
					// se limpar um input de categoria tem que limpar as descendentes tb
					for (let i = level; i < this.maxLevel; i++) {
						this.selectedCategories[`level${i}`] = null;
						this.search[`level${i}`] = null;
					}
					return;
				}
				// get children
				this.selectedCategories[`level${category.level}`] = category;

				if (!this.createdRunning)
					getCategories({
						'filters[parent]': category.id,
					})
						.then((response) => {
							this.availableCategories[`level${category.level + 1}`] = response.data.data;
							if (level < 4) {
								this.$refs[`level${level + 1}`].focus();
							}
						})
						.catch((error) => {
							console.log(error);
						});
			},

			save() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						let stop = false;
						this.isLoading = true;
						console.log(this.variantsArray);

						let images = [];
						for (let index = 0; index < this.variantsArray.length; index++) {
							images.push(this.variantsArray[index].images);
							if (this.variantsArray[index].images.length == 3) {
								this.captions.push({ caption: index });
								this.captions.push({ caption: index });
								this.captions.push({ caption: index });
							} else {
								stop = true;
								this.imageMessage = true;
								this.isLoading = false;
							}
						}

						if (!stop) {
							uploadImage(images, this.captions)
								.then((response) => {
									console.log(response);
									this.uploadedImages = response.data;
									console.log(this.uploadedImages);

									let receivedImages = this.uploadedImages.reduce((r, a) => {
										if (r[a.caption]) {
											r[a.caption].push(a.id);
										} else {
											r[a.caption] = [a.id];
										}

										return r;
									}, {});

									console.log(receivedImages);

									let productSizes = [];

									for (let index = 0; index < this.variantsArray.length; index++) {
										for (let sizesIndex = 0; sizesIndex < this.variantsArray[index].sizes.length; sizesIndex++) {
											console.log(this.variantsArray[index].sizes[sizesIndex]);
											productSizes.push({
												size: this.variantsArray[index].sizes[sizesIndex].size.toString(),
												stockStatus: '1',
												stockNumber: +this.variantsArray[index].sizes[sizesIndex].units,
												pre_variant: index.toString(),
											});
										}
									}

									console.log(productSizes);

									addProductSize(productSizes)
										.then((response) => {
											this.receivedProductSizes = response.data.data;

											console.log(this.receivedProductSizes);
											let arrProductSizes = this.receivedProductSizes.reduce((r, a) => {
												if (r[a.pre_variant]) {
													r[a.pre_variant].push(a.id);
												} else {
													r[a.pre_variant] = [a.id];
												}

												return r;
											}, {});

											console.log(arrProductSizes);

											let variant = [];

											for (let index = 0; index < this.variantsArray.length; index++) {
												variant.push({
													color: this.variantsArray[index].color,
													pictures: receivedImages[index],
													productSizes: arrProductSizes[index],
												});
											}

											console.log(variant);

											addProductVariant(variant)
												.then((response) => {
													console.log(response);
													this.receivedVariants = response.data.data;

													let filteredVariants = [];

													for (let receivedVariantsIndex = 0; receivedVariantsIndex < this.receivedVariants.length; receivedVariantsIndex++) {
														filteredVariants.push(this.receivedVariants[receivedVariantsIndex].id);
													}

													let product = {
														data: {
															title: this.title,
															description: this.description,
															price: this.price,
															isNewSeason: this.isNew,
															weight: this.weight,
															category: this.getLastCategory ? this.getLastCategory.id : null,
															brand: this.selectedBrand,
															canBeReturned: true,
															isActive: true,
															store: this.getUser.store.id,
															user: this.getUser.id,
															productVariants: filteredVariants,
															pictures: receivedImages[0],
															givesMoneyOnCard: this.isMoneyOnCard,
															...(this.isMoneyOnCard == true && { moneyOnCardPercentage: this.moneyOnCardValue }),
														},
													};

													console.log(product);

													createProduct(product)
														.then((response) => {
															console.log(response);

															if (this.isSale) {
																let productId = response.data.data.id;
																let saleId = response.data.data.productSales[0].id;
																let productSale = {
																	startAt: moment(this.saleStartDate).format('YYYY-MM-DD'),
																	endAt: moment(this.salesEndDate).format('YYYY-MM-DD'),
																	product: productId,
																	discount: this.saleValue,
																	isActive: true,
																};
																console.log(productSale);

																updateProductSale(saleId, productSale)
																	.then((response) => {
																		console.log(response);
																		this.isLoading = false;
																		this.$buefy.toast.open({
																			message: this.t.productAdded,
																			position: 'is-top',
																			type: 'is-primary',
																		});
																		this.goToPage('DesktopStoreProducts');
																	})
																	.catch((error) => {
																		console.log(error);
																	});
															} else {
																this.isLoading = false;
																this.$buefy.toast.open({
																	message: this.t.productAdded,
																	position: 'is-top',
																	type: 'is-primary',
																});
																this.goToPage('DesktopStoreProducts');
															}
														})
														.catch((error) => {
															this.isLoading = false;
															console.log(error);
														});
												})
												.catch((error) => {
													this.isLoading = false;
													console.log(error);
												});
										})
										.catch((error) => {
											this.isLoading = false;
											console.log(error);
										});
								})
								.catch((error) => {
									this.isLoading = false;
									console.log(error);
								});
						}
					} else {
						this.$buefy.toast.open({
							message: this.t.fields,
							position: 'is-top',
							type: 'is-danger',
						});
					}
				});
			},

			addVariant() {
				this.variantsArray.push({
					images: [],
					sizes: [
						{
							size: null,
							units: null,
						},
					],
					color: null,
				});
				this.removeVariantMessage = false;
			},

			removeVariant(index) {
				if (this.variantsArray.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteVariant,
						message: this.t.deleteVariantMessage,
						confirmText: this.t.deleteVariant,
						cancelText: this.t.cancel,
						type: 'is-danger',
						hasIcon: true,
						onConfirm: () => {
							this.variantsArray.splice(index, 1);
							this.removeVariantMessage = false;
						},
					});
				} else this.removeVariantMessage = true;
			},

			addProductLine(index) {
				this.variantsArray[index].sizes.push({
					size: null,
					units: null,
				});
				this.removeSizeMessage = false;
			},

			removeProductLine(variantIndex, sizeIndex) {
				if (this.variantsArray[variantIndex].sizes.length > 1) {
					this.$buefy.dialog.confirm({
						title: this.t.deleteProductSize,
						message: this.t.deleteProductSizeMessage,
						confirmText: this.t.deleteProductSize,
						cancelText: this.t.cancel,
						type: 'is-danger',
						hasIcon: true,
						onConfirm: () => {
							this.variantsArray[variantIndex].sizes.splice(sizeIndex, 1);
							this.removeSizeMessage = false;
						},
					});
				} else this.removeSizeMessage = true;
			},
		},

		computed: {
			filteredLevelOne() {
				if (this.search.level1)
					return this.availableCategories.level1.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level1.toString().toLowerCase()) >= 0);
				return this.availableCategories.level1;
			},
			filteredLevelTwo() {
				if (this.search.level2)
					return this.availableCategories.level2.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level2.toString().toLowerCase()) >= 0);
				return this.availableCategories.level2;
			},
			filteredLevelThree() {
				if (this.search.level3)
					return this.availableCategories.level3.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level3.toString().toLowerCase()) >= 0);
				return this.availableCategories.level3;
			},
			filteredLevelFour() {
				if (this.search.level4)
					return this.availableCategories.level4.filter((category) => category.name.toString().toLowerCase().indexOf(this.search.level4.toString().toLowerCase()) >= 0);
				return this.availableCategories.level4;
			},
			getLastCategory() {
				for (let i = this.maxLevel; i > 0; i--) {
					if (this.selectedCategories[`level${i}`]) {
						return this.selectedCategories[`level${i}`];
					}
				}
				return null;
			},
		},

		created() {
			this.yesterday.setDate(this.yesterday.getDate() - 1);
			getBrands()
				.then((response) => {
					this.brands = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories({
				'filters[level]': 0,
				populate: 'parent',
			})
				.then((response) => {
					console.log(response.data.data);
					this.availableCategories.level0 = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getColors()
				.then((response) => {
					this.colors = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getSizesGroups()
				.then((response) => {
					this.sizes = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			getStockStatus()
				.then((response) => {
					console.log(response);
					this.status = response.data.data;
				})
				.catch((error) => {
					console.log(error);
				});

			this.variantsArray.push({
				images: [],
				sizes: [
					{
						size: null,
						units: null,
					},
				],
				color: null,
			});
			console.log(this.variantsArray);
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.left-container {
		width: 50%;
		display: inline-block;
	}
	.right-container {
		width: 50%;
		display: inline-block;
		position: absolute;
		right: 0;
	}

	.header {
		font-size: 2.5rem;
	}

	.input {
		border-radius: 11px;
		font-size: 1rem;
		height: 48px;
	}

	.container {
		margin-top: 20px;
	}

	.button-container {
		width: 100%;
		padding-bottom: 10px;
	}

	.btn {
		width: 350px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	.btn-small {
		width: 150px;
		height: 52px;
		border-radius: 14px;
		font-size: 1rem;
	}

	.photo-container {
		display: flex;
	}

	.photo {
		width: 125px;
		height: 100px;
		border-radius: 9px;
		background-color: $white;
		border: $grey-light dashed 2px;
	}

	img {
		width: 125px;
		height: 96px;
		object-fit: scale-down;
	}

	.small-input {
		display: flex;
	}

	/deep/ .select:not(.is-multiple):not(.is-loading)::after {
		border-color: $grey-dark;
		right: 1.125em;
		z-index: 4;
	}

	.select-field {
		width: 400px;
	}

	.small {
		width: 96%;
	}

	/deep/ .sizes-label span {
		position: absolute;
		left: 10px;
		top: 7px;
	}

	/deep/ .field .control input {
		text-align: left;
		border: none;
	}

	/deep/ .select select {
		border: none !important;
	}

	.pointer {
		cursor: pointer;
	}

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: fixed;
		right: 100px;
		top: 80px;
		z-index: 41;
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		padding: 0rem 1.5rem 6rem;
	}

	.form {
		padding-bottom: 10rem;
		padding-left: 140px;
		padding-right: 140px;
	}

	/deep/ .tabs li.is-active a {
		border-bottom-color: $primary;
		color: $primary;
	}

	.input-size {
		background-color: $white;
		border-radius: 11px;
		font-size: 1rem;
		height: 48px;
		width: 150px;
	}

	.top-hr {
		position: fixed;
		top: 76px;
		width: 100%;
		z-index: 40;
	}

	.table-label {
		font-size: 1.5rem;
	}

	/deep/ .picker-input {
		width: 150px;
	}

	.textarea {
		resize: none;
	}
</style>
