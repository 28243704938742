<template>
	<section>
		<form @submit.prevent="create">
			<div>
				<b-field>
					<b-input class="input mt-8" v-model.trim="title" name="title" required ref="title" :placeholder="t.title"></b-input>
				</b-field>

				<b-field class="mt-8">
					<b-input type="textarea" :maxlength="100" v-model.trim="description" :placeholder="t.description" required> </b-input>
				</b-field>

				<b-field class="mt-8">
					<input class="input" type="number" v-model.trim="discount" name="discount" required ref="discount" :placeholder="t.discount" min="1" />
				</b-field>

				<p class="mt-8 ml-2">{{ t.sendTo }}</p>
				<div class="media mt-4 ml-2">
					<div class="media-left">
						<div v-for="(category, index) in categories" :key="category.id">
							<b-field v-if="index % 2 == 0" class="mb-2">
								<b-checkbox v-model="targets" :native-value="category.id">{{ category.name }}</b-checkbox>
							</b-field>
						</div>
					</div>
					<div class="media-right">
						<div v-for="(category, index) in categories" :key="category.id">
							<b-field v-if="index % 2 != 0" class="mb-2">
								<b-checkbox v-model="targets" :native-value="category.id">{{ category.name }}</b-checkbox>
							</b-field>
						</div>
						<b-field>
							<b-checkbox v-model="birthday" :native-value="true">{{ t.birthday }}</b-checkbox>
						</b-field>
					</div>
				</div>
				<p v-show="errorTargets" class="has-text-danger ml-2">{{ t.errorTarget }}</p>

				<div class="is-flex mt-8 ml-2">
					<b-field class="picker-input" :label="t.start">
						<b-datepicker
							class="input"
							size="is-medium"
							v-model="startDate"
							:min-date="today"
							:mobile-native="false"
							:locale="$i18n.locale"
							:icon-right-clickable="true"
							trap-focus
							placeholder="__/__/___"
						></b-datepicker>
					</b-field>

					<b-field class="picker-input ml-4" :label="t.end">
						<b-datepicker
							class="input"
							size="is-medium"
							v-model="endDate"
							:min-date="startDate ? startDate : today"
							:mobile-native="false"
							:locale="$i18n.locale"
							:icon-right-clickable="true"
							trap-focus
							placeholder="__/__/___"
						></b-datepicker>
					</b-field>
				</div>
				<p v-show="errorDate" class="has-text-danger ml-2">{{ t.errorDate }}</p>
			</div>

			<div class="mt-10">
				<b-button native-type="submit" class="btn is-fullwidth" type="is-primary" :loading="isLoading">{{ t.create }}</b-button>
			</div>
		</form>
	</section>
</template>

<script>
	import { getCategories } from '@/api/feed';
	import { createVoucher } from '@/api/marketing';

	export default {
		data() {
			return {
				title: null,
				targets: [],
				categories: [],
				startDate: null,
				endDate: null,
				today: new Date(),
				isLoading: false,
				description: null,
				discount: null,
				birthday: false,
				errorTargets: false,
				errorDate: false,
				t: this.$t(`components.CreateVoucher`),
			};
		},

		methods: {
			create() {
				if (this.targets.length == 0 && this.birthday == false) {
					this.errorTargets = true;
					this.errorDate = false;
				} else if (!this.startDate || !this.endDate) {
					this.errorTargets = false;
					this.errorDate = true;
				} else {
					this.isLoading = true;
					let voucher = {
						data: {
							title: this.title,
							description: this.description,
							discount: this.discount,
							categories: this.targets,
							store: this.getUser.store.id,
							startAt: this.startDate,
							endAt: this.endDate,
							birthday: this.birthday,
						},
					};

					createVoucher(voucher)
						.then((response) => {
							console.log(response);
							this.$buefy.toast.open({
								message: this.t.createdSuccess,
								position: 'is-top',
								type: 'is-primary',
							});
							this.$emit('changeTab', 1);
						})
						.catch((error) => {
							if (error.response.data.error.message == 'Don`t match users') {
								this.$buefy.toast.open({
									message: this.t.noUsers,
									position: 'is-top',
									type: 'is-danger',
								});
							} else {
								this.$buefy.toast.open({
									message: this.t.error,
									position: 'is-top',
									type: 'is-danger',
								});
							}
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},
		},

		created() {
			getCategories({
				'filters[level]': 0,
			})
				.then((response) => {
					this.categories = response.data.data;
					console.log(this.categories);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.left-container {
		width: 50%;
		display: inline-block;
	}

	.input {
		border-radius: 11px;
		font-size: 1rem;
		height: 48px;
	}

	/deep/ textarea.textarea {
		resize: none;
	}

	.btn {
		height: 52px;
		border-radius: 14px;
		margin: 0 auto;
		font-size: 1rem;
		display: flex;
	}
</style>
