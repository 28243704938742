<template>
	<section>
		<p class="is-size-4 has-text-grey-darker">{{ t.tracking }}</p>

		<div class="level mt-10">
			<div class="level-left">
				<p class="level-item has-text-dark is-size-5">{{ this.$route.params.tracking }}</p>
			</div>
			<div class="level-right">
				<p class="level-item has-text-dark is-size-5">ID {{ this.$route.params.id }}</p>
			</div>
		</div>
		<div class="columns mt-4">
			<div class="column">
				<p class="has-text-grey is-size-5">{{ t.delivery }}</p>
				<div class="info-box mt-6 is-size-5">{{ deliveryAddress }}</div>

				<div class="mt-6">
					<p class="has-text-dark is-size-5">{{ t.history }}</p>
					<div class="tracking-card mt-4" v-for="(track, index) in tracking" :key="index">
						<div class="columns">
							<div class="column is-one-fifth mt-2">
								{{ track.trace_Event_Date }}
							</div>

							<div class="column mt-3">
								<p class="is-size-5 has-text-grey" v-if="track.trace_Event_CODE === 'POD'">Encomenda entregue</p>
								<p class="is-size-5 has-text-grey" v-else-if="track.trace_Event_CODE === 'OFD'">{{ track.trace_Event_Description }}</p>
								<p class="is-size-5 has-text-grey" v-else-if="track.trace_Event_CODE === 'PEC'">{{ track.trace_Event_Description }}</p>
								<p class="is-size-5 has-text-grey" v-else-if="track.trace_Event_CODE === '706'">{{ track.trace_Event_Description }}</p>
								<p class="is-size-5 has-text-grey" v-else-if="track.trace_Event_CODE === '700'">{{ track.trace_Event_Description }}</p>
								<p class="is-size-5 has-text-grey" v-else-if="track.trace_Event_CODE === 'COL'">{{ track.trace_Event_Description }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column">
				<p class="has-text-grey is-size-5">{{ t.expenses }}</p>
				<div class="info-box mt-6 is-size-5">{{ (expenses / 100).toFixed(2) }} €</div>
			</div>
		</div>

		<b-button class="is-primary" size="is-medium" @click="goToPage('Homepage')">{{ t.back }}</b-button>
	</section>
</template>

<script>
	import { tracking, getOrderById, getPickupPoints } from '@/api/orders';
	export default {
		data() {
			return {
				t: this.$t(`views.Tracking`),
				deliveryAddress: null,
				expenses: 0,
				tracking: [],
				expeditionNumber: null,
			};
		},
		async created() {
			this.setIsPageLoading(true);
			console.log(this.$route.params.id);

			//this.$route.params.id  colocar depois no pedido do tracking

			await getOrderById(this.$route.params.id)
				.then((response) => {
					console.log(response);
					this.expeditionNumber = response.data.data.shipping_data.expedition.NrGuia;
					console.log(this.expeditionNumber);
					if (response.data.data.shipping_data.address == null) {
						this.deliveryAddress = response.data.data.shipping_data.pickupId;

						let data = {
							number: response.data.data.shipping_data.pickupId,
						};

						getPickupPoints(data)
							.then((response) => {
								console.log(response);
								this.deliveryAddress = response.data[0].address + ' ' + response.data[0].locality + ', ' + response.data[0].country;
							})
							.catch((error) => {
								console.log(error);
							});
					} else {
						this.deliveryAddress =
							response.data.data.shipping_data.address + ' ' + response.data.data.shipping_data.locality + ', ' + response.data.data.shipping_data.country;
					}

					this.expenses = response.data.data.amount;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.setIsPageLoading(false);
				});

			await tracking(this.expeditionNumber)
				.then((response) => {
					console.log(response);
					this.tracking = response.data.return.traceEventsURLArr;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.info-box {
		width: 100%;
		height: 86px;
		border-radius: 20px;
		border: 1px solid #e6e6e6;
		padding: 1rem;
		display: flex;
		align-items: center;
		color: $grey;
	}

	.tracking-card {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 48px #0000000a;
		border-radius: 8px;
		height: 81px;
		padding: 1rem;
	}
</style>
